import * as ActionTypes from '../constants/ActionTypes';

const defaultState = {
  isDataFetching: false, //是否獲取資料成功
  // fabricDetailData: {},
  myFabricDataItem: {},
  listMyFabric: {
    info: {},
    data: [],
    cusTags: [],
  },
  fabricTagData: {}, //布料專用的tag，functionTagsList，attributeTagsList，otherTagsList
  // deleteCheck: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    // 新增個人布料
    case ActionTypes.MYFABRIC_ADDPERSONALITEM_REQUEST:
      return Object.assign({}, state, {
        isDataFetching: true,
      });
    case ActionTypes.MYFABRIC_ADDPERSONALITEM_FAILURE:
      return Object.assign({}, state, {
        isDataFetching: false,
      });
    case ActionTypes.MYFABRIC_ADDPERSONALITEM_SUCCESS:
      const data = action.payload.response;
      // console.log('data = ', data)
      return Object.assign({}, state, {
        isDataFetching: false,
        // fabricDetailData: data,
      });

    // 獲取個人所有布料
    case ActionTypes.MYFABRIC_GETPERSONALITEMS_REQUEST:
      return Object.assign({}, state, {
        isDataFetching: true,
      });
    case ActionTypes.MYFABRIC_GETPERSONALITEMS_FAILURE:
      return Object.assign({}, state, {
        isDataFetching: false,
      });
    case ActionTypes.MYFABRIC_GETPERSONALITEMS_SUCCESS:
      const listMyFabricRes = action.payload.response;
      let listMyFabric = Object.assign({}, state.listMyFabric);
      // if (parseInt(listMyFabricRes.page) > 1) {
      //     listMyFabric.data = listMyFabric.data || []
      //     listMyFabric.data = listMyFabric.data.concat(listMyFabricRes.data)
      // } else if (parseInt(listMyFabricRes.page) <= 1) {
      listMyFabric.data = listMyFabricRes.data;
      listMyFabric.cusTags = listMyFabricRes.cusTagsList;
      listMyFabric.functionTags = listMyFabricRes.functionTagsList;
      listMyFabric.attributeTags = listMyFabricRes.attributeTagsList;
      listMyFabric.otherTags = listMyFabricRes.otherTagsList;
      // }
      listMyFabric.info = {
        pageSize: listMyFabricRes.pageSize,
        totalPage: listMyFabricRes.totalPage,
        totalRow: listMyFabricRes.count,
      };
      return Object.assign({}, state, {
        isDataFetching: false,
        listMyFabric,
      });

    // 獲取個人單一布料
    case ActionTypes.MYFABRIC_GETPERSONALITEM_REQUEST:
      return Object.assign({}, state, {
        isDataFetching: true,
      });
    case ActionTypes.MYFABRIC_GETPERSONALITEM_FAILURE:
      return Object.assign({}, state, {
        isDataFetching: false,
      });
    case ActionTypes.MYFABRIC_GETPERSONALITEM_SUCCESS:
      const myFabricObj = action.payload.response;
      // console.log('myFabricObj = ',myFabricObj)
      return Object.assign({}, state, {
        isDataFetching: false,
        myFabricDataItem: myFabricObj,
      });

    // 刪除個人單一布料
    case ActionTypes.MYFABRIC_DELETEPERSONALITEM_REQUEST:
      return Object.assign({}, state, {
        isDataFetching: true,
      });
    case ActionTypes.MYFABRIC_DELETEPERSONALITEM_FAILURE:
      return Object.assign({}, state, {
        isDataFetching: false,
      });
    case ActionTypes.MYFABRIC_DELETEPERSONALITEM_SUCCESS:
      const delStatus = action.payload.status;
      const check = delStatus == 'success' ? true : false;
      // console.log('in check = ', check)
      return Object.assign({}, state, {
        isDataFetching: false,
        // deleteCheck: check,
      });

    // 獲取布料專用的tag
    case ActionTypes.MYFABRIC_GETTAGFORFABRIC_REQUEST:
      return Object.assign({}, state, {
        isDataFetching: true,
      });
    case ActionTypes.MYFABRIC_GETTAGFORFABRIC_FAILURE:
      return Object.assign({}, state, {
        isDataFetching: false,
      });
    case ActionTypes.MYFABRIC_GETTAGFORFABRIC_SUCCESS:
      const tagRes = action.payload.response;

      return Object.assign({}, state, {
        isDataFetching: false,
        fabricTagData: tagRes,
      });

    default:
      return state;
  }
}
