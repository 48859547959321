import app from 'firebase/app';
import 'firebase/analytics';
import { isEmpty, getAuth } from '../../Libs/Lib';

const config = {
  apiKey: 'AIzaSyD5kB8UK95VJ4dhz8Zn0yCmY6cnF5H1sRE',
  authDomain: 'textipandroid-2dfae.firebaseapp.com',
  databaseURL: 'https://textipandroid-2dfae.firebaseio.com',
  projectId: 'textipandroid-2dfae',
  storageBucket: 'textipandroid-2dfae.appspot.com',
  messagingSenderId: '375190037939',
  appId: '1:375190037939:web:b961345997c7cfb2eb97c6',
  measurementId: 'G-0GN6EE6CW8',
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.analytics = app.analytics();
  }

  setUserId(id) {
    this.analytics.setUserId(id);
  }

  addLogEvent(eventName, values) {
    const authData = getAuth();
    var userId = -1;
    var screenView = this.className;
    if (!isEmpty(authData)) {
      userId = authData.id;
    }
    values.userId = userId;

    this.analytics.logEvent(eventName, values);
  }
}

export default Firebase;
