import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../style/contentBlock.sass';

export default class ContentBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title,
      children,
      className,
      shareIcon,
      closeIcon,
      handleToggleBoardModal,
      board,
    } = this.props;

    const blockClassName = className || '';
    return (
      <div className={`contentContainer ${blockClassName}`}>
        <div className="titleBox">
          {title}
          {shareIcon && (
            <FontAwesomeIcon
              className="contentBlockShareIcon"
              icon={shareIcon}
              onClick={() => handleToggleBoardModal(board)}
            />
          )}
        </div>
        <div className="contentBox">{children}</div>
      </div>
    );
  }
}

ContentBlock.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};
