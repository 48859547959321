import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getAuth, isEmpty } from '../Libs/Lib'
import { createCatalog , updateCatalog , getCatalogDetail } from '../actions/catalog'
import * as Lang from '../Lang/default'
import MemberBase from './MemberBase'
import Form from '../components/Form'
import Loading from '../components/Loading'
import Image from '../components/Image'
import Textarea from '../components/Textarea'
import Button from '../components/Button'
import Modal from '../components/Modal'
import FabricList from '../components/FabricList'
import ContentBlock from '../components/ContentBlock'
import { SketchPicker } from 'react-color'
import FashionView from '../components/FashionView'
import { EVENT_UPLOAD_CATALOG_MAIN_PHOTO , 
    EVENT_FASHIONVIEW_CLEAR , 
    EVENT_FASHIONVIEW_SHOW_FROM_JSON,
    EVENT_FASHIONVIEW_CHECK_SHOW_PAGE_CONFIRM } from '../constants/eventAction'
import PubSub from 'pubsub-js'
import Input from '../components/Input'
import Switch from "react-switch";
import '../style/catalog.sass'
import Alert from '../components/Alert'

const MyPageList = [{name:'page 1'}]


class AddCatalog extends Component {
    constructor(props) {
        super(props)
        this.getPageList = this.getPageList.bind(this);
        this.toDeleteTargetPage = this.toDeleteTargetPage.bind(this)
        this.addOnePage = this.addOnePage.bind(this)
        this.getRightEditView = this.getRightEditView.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.saveCurrentFashionView = this.saveCurrentFashionView.bind(this)
        this.callCatalogApi = this.callCatalogApi.bind(this)
        this.getCoverImageFromJson = this.getCoverImageFromJson.bind(this)
        this.getPageInfo = this.getPageInfo.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.switchHandleChange = this.switchHandleChange.bind(this)
        this.checkShowPageConfirm = this.checkShowPageConfirm.bind(this)

        this.getPageListFromData = this.getPageListFromData.bind(this)
        this.getPageListDataFromData = this.getPageListDataFromData.bind(this)

        this.getAlertView = this.getAlertView.bind(this)
        this.alertClose = this.alertClose.bind(this)
        
        this.state = {
            isFirstCome: true,

            showAlert: false,
            alertMessage:'',

            loading: false,
            description: null,
            catalogTitle:'',
            isPublic:false,
            currentCatalogId:-1,
            pageList: MyPageList,
            pageListData: [''], //整本目錄的資料
            currentEditPageName: MyPageList[0].name,
            currentEditPageIndex: 0,
            oldEditPageIndex: 0,
            alreadyCreateCatalog:false //用來判斷是否有打過create API
        }
        this.showNewPageContent = false
        this.newPageIndex = 0
    }

    componentDidMount() {
        //先去獲取路徑看看有沒有id
        if(this.props.router.params.catalogId){
            //有id，表示編輯模式
            this.setState({
                currentCatalogId:this.props.router.params.catalogId ,
                alreadyCreateCatalog:true
            })
            //call api 獲取明細資料
            const authData = getAuth()
            if (!isEmpty(authData)) {
                let params = { memberId: authData.id, token: authData.token}
                this.props.getCatalogDetail(params , this.props.router.params.catalogId)
            }
        }else{
            this.setState({isFirstCome:false})
        }
        
        this.setState({currentEditPageName:this.state.pageList[0].name})
    }

    componentWillUnmount() {
    }

    componentWillReceiveProps(nextProps) {
        if(!this.state.isFirstCome && nextProps.createCatalogData && nextProps.createCatalogData.id && nextProps.createCatalogData.id != this.state.currentCatalogId){
            this.setState({currentCatalogId:nextProps.createCatalogData.id})
            this.setState({showAlert:true, alertMessage:'create catalog success'})
            this.props.router.replace({ pathname: `/addCatalog/`+nextProps.createCatalogData.id})
        }
        if(!this.state.isFirstCome && nextProps.updateCatalogData && nextProps.updateCatalogData.id && nextProps.updateCatalogData.id == this.state.currentCatalogId){
            //更新目錄資料完成
            this.setState({showAlert:true, alertMessage:'update catalog success'})
        }
        //首次進來才需要檢查一次
        if(this.state.isFirstCome && nextProps.catalogDetailData){
            //如果status為error，表示沒有這個目錄，就把他踢出去
            if(nextProps.catalogDetailData.status == 'error'){
                this.props.router.push('/catalog')
            }
            //如果status為success，表示有目錄，所以檢查editable看看能不能編輯
            else if(nextProps.catalogDetailData.status == 'success'){
                const data = nextProps.catalogDetailData.data
                if(data.editable){
                    const mPageList = this.getPageListFromData(data)
                    const mPageListData = this.getPageListDataFromData(data)
                    //可以編輯，將抓到的資料全部填入當前state
                    this.setState({isFirstCome:false,
                        alreadyCreateCatalog:true,
                        isPublic:data.status=='on'?true:false,
                        catalogTitle:data.title,
                        pageList: mPageList,
                        pageListData: mPageListData
                    })
                    setTimeout(()=>{
                        PubSub.publish(EVENT_FASHIONVIEW_SHOW_FROM_JSON,{jsonStr: mPageListData[this.state.currentEditPageIndex]})
                    },500)
                    
                }else{
                    //不能編輯，把他踢出去
                    this.props.router.push('/catalog')
                }


            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    getPageListFromData(data) {
        let pages = MyPageList
        try {
            pages = JSON.parse(data.content)
        } catch(e) {
            return pages
        }
        //看看page有幾頁，就建立幾頁的陣列
        if (pages.length <= 1) {
            return [{name:'page 1'}]
        } else {
            let pageNames = []
            pages.map((item, index) => {
                pageNames.push({name:'page ' + (index+1)})
            })
            return pageNames
        }
    }

    getPageListDataFromData(data) {
        let pages = []
        try {
            pages = JSON.parse(data.content)
        } catch(e) {
            return pages
        }
        //看看page有幾頁，就建立幾頁的資料陣列
        
        let pageDatas = pages
        return pageDatas
        
    }

    //將當前畫面的資料先儲存起來
    saveCurrentFashionView() {
        PubSub.publish(EVENT_UPLOAD_CATALOG_MAIN_PHOTO)
    }

    addOnePage() {
        let mList = this.state.pageList
        mList.push({name:'page ' + (this.state.pageList.length+1)});
        let mPageListData = this.state.pageListData
        mPageListData.push(JSON.stringify({}))
        this.setState({ pageList: mList, pageListData: mPageListData })
    }

    //刪除指定頁面，沒有呼叫api，所以刪除完成之後使用者要自己點存擋
    toDeleteTargetPage(index) {
        let newPageList = this.state.pageList
        //這邊要重新設定頁面名稱
        newPageList.splice(index, 1)
        newPageList = newPageList.map((item, index) => {
            return {name:'page ' + (index + 1)}
        })

        //重新設定頁面資料
        let newPageListData = this.state.pageListData
        newPageListData.splice(index, 1)

        if (index == this.state.currentEditPageIndex) {
            //刪除到目前的焦點頁面，所以焦點頁面改為目前刪除頁面的上一個
            const newCurrentEditPageIndex = index - 1
            //更改焦點頁面，所以發資料給fashionView
            PubSub.publish(EVENT_FASHIONVIEW_SHOW_FROM_JSON, {jsonStr: newPageListData[newCurrentEditPageIndex]})
            this.setState({
                currentEditPageIndex: newCurrentEditPageIndex,
                pageList:newPageList,
                pageListData:newPageListData
            })
            
        } else if (index < this.state.currentEditPageIndex) {
            //刪除的項目比目前焦點項目還要前面
            const newCurrentEditPageIndex = this.state.currentEditPageIndex - 1 

            this.setState({
                currentEditPageIndex: newCurrentEditPageIndex,
                pageList:newPageList,
                pageListData:newPageListData
            })
            
        } else {
            //刪除的項目比目前焦點項目還要後面
            this.setState({
                pageList:newPageList,
                pageListData:newPageListData
            })
        }
    }
    //獲取刪除頁面的icon
    getDeleteIcon(index){
        if(index==0){
            return <div />
        }else{
            return <Image src={require('../images/icon_trash.png')} className="pageItem_delete" onClick={(e)=>{e
                e.stopPropagation()
                let check = confirm(Lang.CATALOG_CHECK_DELETE_PAGE+' '+this.state.pageList[index].name);
                if(check){
                    this.toDeleteTargetPage(index);
                }
            }}/>   
        }
    }

    handleChange(e) {
        const { target } = e
        const { name, value } = target
        this.setState({
            [name]: value,
        })
    }
    switchHandleChange(checked){
        this.setState({isPublic:checked})
    }

    //獲取左邊的資訊畫面
    getPageInfo() {
        let titleView = 
        (<div className="editContainer">
            <label>{'title'}</label>
            <Input
                type={'input'}
                name={'catalogTitle'}
                placeholder={'title'}
                onChange={this.handleChange}
                idName={`input_title`}
                value={this.state.catalogTitle}
            />
        </div>)
        let toggleBtnView = 
        (<div className="editContainer">
            <label>{'Public'}</label>
            <Switch className="react-switch" onChange={this.switchHandleChange} checked={this.state.isPublic} />
        </div>)

        return (
            <div className="leftPageList">
                {titleView}
                {toggleBtnView}
            </div>
        )
    }

    //獲取左邊的列表
    getPageList(){
        //增加頁面的按鈕
        let addIcon = (
            <div key={"index_"+this.state.pageList.length} className="pageItem_add_block" 
            onClick={()=>{
                this.addOnePage()
            }}>
                <Image src={require('../images/icon_plus.png')} className="pageItem_add" />
            </div>
        )
        let mList = <div />

        mList = this.state.pageList.map((item,index)=>{
            let myItemViewStyle = 'itemView'
            if(index == this.state.currentEditPageIndex){
                myItemViewStyle = 'itemViewFocus'
            }

            let mItem = 
            (
                <div key={"index_"+index} className={myItemViewStyle} onClick={()=>{
                    if(index != this.state.currentEditPageIndex){
                        //先檢查當前頁面資料與fashionview的資料是不是相同
                        // this.setState({oldEditPageIndex:this.state.currentEditPageIndex,currentEditPageIndex:index ,currentEditPageName:item.name})
                        this.newPageIndex = index//目標頁面
                        this.checkShowPageConfirm(index)
                        //表示目前不在編輯的頁面上，需要詢問他是否要先儲存當前編輯的東西
                        // let check = confirm(Lang.CATALOG_CHECK_CHANGE_CURRENT_PAGE);
                        // if(check){
                        //     //先儲存當前頁面資料
                        //     // this.toDeleteTargetPage(index);
                        //     this.setState({oldEditPageIndex:this.state.currentEditPageIndex,currentEditPageIndex:index ,currentEditPageName:item.name})
                        //     this.saveCurrentFashionView()

                        //     //這邊去把目標頁面的資料，傳給fashionview
                        //     this.showNewPageContent = true
                        // }
                        
                        
                    }
                }}>
                    <div style={{flex:1}}>{item.name}</div> {this.getDeleteIcon(index)}
                </div>
            )
            
            return mItem
        })
        mList.push(addIcon)
        return <div className="leftPageList">{mList}</div>;
    }

    checkShowPageConfirm(){
        PubSub.publish(EVENT_FASHIONVIEW_CHECK_SHOW_PAGE_CONFIRM)
    }

    getRightEditView(){
        return <FashionView isEdit={true} isFirstPage={this.state.currentEditPageIndex==0?true:false}
        saveMainFileSuccess={(jsonStr)=>{
            //將新的資料置換掉舊的pageIndex資料
            let mPageListData = this.state.pageListData
            mPageListData.splice(this.state.oldEditPageIndex,1,jsonStr)
            this.setState({pageListData : mPageListData})
            
            this.callCatalogApi()

            //需要顯示新的頁面資料
            if(this.showNewPageContent){
                PubSub.publish(EVENT_FASHIONVIEW_SHOW_FROM_JSON,{jsonStr: this.state.pageListData[this.state.currentEditPageIndex]})
                this.showNewPageContent = false
            }
        }} 
        callbackShowPageConfirm={(jsonStr)=>{
            const oldJsonStr = this.state.pageListData[this.state.currentEditPageIndex]
            if(oldJsonStr === jsonStr){
                //相同，表示沒有修改過，就直接不用詢問換頁
                this.setState({oldEditPageIndex:this.state.currentEditPageIndex,currentEditPageIndex:this.newPageIndex ,currentEditPageName:this.state.pageList[this.newPageIndex].name})
                this.showNewPageContent = true

                if(this.showNewPageContent){
                    PubSub.publish(EVENT_FASHIONVIEW_SHOW_FROM_JSON,{jsonStr: this.state.pageListData[this.state.currentEditPageIndex]})
                    this.showNewPageContent = false
                }
            }else{
                //不同，要去詢問
                let check = confirm(Lang.CATALOG_CHECK_CHANGE_CURRENT_PAGE);
                if(check){
                    this.setState({oldEditPageIndex:this.state.currentEditPageIndex,currentEditPageIndex:this.newPageIndex ,currentEditPageName:this.state.pageList[this.newPageIndex].name})
                    this.saveCurrentFashionView()

                    //這邊去把目標頁面的資料，傳給fashionview
                    this.showNewPageContent = true
                }else{
                    this.setState({oldEditPageIndex:this.state.currentEditPageIndex,currentEditPageIndex:this.newPageIndex ,currentEditPageName:this.state.pageList[this.newPageIndex].name})
                    this.showNewPageContent = true

                    if(this.showNewPageContent){
                        PubSub.publish(EVENT_FASHIONVIEW_SHOW_FROM_JSON,{jsonStr: this.state.pageListData[this.state.currentEditPageIndex]})
                        this.showNewPageContent = false
                    }
                }
            }
        }}
        />
    }
    callCatalogApi(){
        const authData = getAuth()
        if (!isEmpty(authData)) {
            let params = { memberId: authData.id, token: authData.token}
            
            //call create or update API
            if(this.state.alreadyCreateCatalog){
                let cId = this.state.currentCatalogId
                if(cId == -1){
                    //沒建立過目錄，拿到目錄id
                    cId = this.props.createCatalogData.id
                    this.setState({currentCatalogId:this.props.createCatalogData.id})
                }
                
                //update api
                const mTitle = this.state.catalogTitle
                Object.assign(params,{
                    title:mTitle,
                    coverImage:this.getCoverImageFromJson(),
                    content:JSON.stringify(this.state.pageListData),
                    status:this.state.isPublic?'on':'off'
                })

                this.props.updateCatalog(JSON.stringify(params) , cId)

            }else{
                //create api
                const mTitle = this.state.catalogTitle

                Object.assign(params,{
                    title:mTitle,
                    coverImage:this.getCoverImageFromJson(),
                    content:JSON.stringify(this.state.pageListData),
                    status:this.state.isPublic?'on':'off'
                })

                this.props.createCatalog(JSON.stringify(params))
                this.setState({alreadyCreateCatalog:true})
                
            }
        }
        
    }
    getCoverImageFromJson(){
        //從陣列裡面拿page1的主圖當封面，如果第page1沒有東西，就回傳空字串
        const firstPageObj = JSON.parse(this.state.pageListData[0])
        let coverImageUrl = ''
        if(firstPageObj && firstPageObj.mainPhotoInCloudUrlPath){
            coverImageUrl = firstPageObj.mainPhotoInCloudUrlPath
        }
        return coverImageUrl

    }

    handleSubmit(e) {
        this.setState({oldEditPageIndex:this.state.currentEditPageIndex})
        //這邊先去組織所有頁面的資料
        PubSub.publish(EVENT_UPLOAD_CATALOG_MAIN_PHOTO)
        
    }
    getAlertView(){
        if(this.state.showAlert){
            return <Alert content={this.state.alertMessage} close={()=>this.alertClose()} />
        }else{
            return <div />
        }
    }
    alertClose(){
        this.setState({showAlert:false,alertMessage:''})
    }

    render() {
        const { loading } = this.state
        const loadingView = loading ? <Loading /> : null
        let contentView = null
        let leftPageList = this.getPageList() //獲取頁面列表
        let leftPageInfo = this.getPageInfo() //獲取目錄資訊
        let alertView = this.getAlertView()  //獲取alertView
        
        contentView = (
            <div className="addCatalogContainer">
                <div className="titleBlock">{Lang.CATALOG_TITLE}</div>
                <div className="catalogContentContainer">
                    <div className="layoutBlock col-12 col-md-3 pageInfoBlock">
                        {/* 左邊的頁面參數區域 */}
                        <ContentBlock title={Lang.CATALOG_PAGE_INFORMATION} className="leftContentBlock">
                            {leftPageInfo}
                        </ContentBlock>
                        {/* 左邊的頁面列表 */}
                        <ContentBlock title={Lang.CATALOG_PAGE_LIST} className="leftContentBlock">
                            {leftPageList}
                        </ContentBlock>
                    </div>
                    <div className="layoutBlock col-12 col-md-9 pageContentBlock">
                        {/* 右邊內容畫面 */}
                        <div className={`contentContainer`} style={{width:'auto'}}>
                            <div className="titleBox" >{Lang.CATALOG_PAGE_CONTENT+'  ['+this.state.currentEditPageName+']'}</div>
                            <div style={{margin:'15px'}}>{this.getRightEditView()}</div>
                        </div>
                    </div>
                </div>
                {/* 底部的儲存區域 */}
                <div className="catalogBottomBtnArea">
                    <div className="btnBox">
                        <Button
                            type="button"
                            className="btn"
                            onClick={() => {
                                location.href = '/catalog'
                            }}
                            text={'cancel'}
                        />
                        <Button type="button" className="btn btn-primary" onClick={(e) => {
                            this.handleSubmit(e)
                        }} text={'save'} />
                    </div>
                </div>
            </div>
        )
        return (
            <MemberBase>
                {loadingView}
                {alertView}
                {contentView}
            </MemberBase>
        )
    }
}

AddCatalog.propTypes = {
    // addPresentation: PropTypes.func.isRequired,
}

const mapStateToProps = () => (state) => {
    return {
        createCatalogData: state.catalog.createCatalogData, //創建目錄
        updateCatalogData: state.catalog.updateCatalogData, //更新目錄
        catalogDetailData: state.catalog.catalogDetailData, //明細資料
        
    }
}

const mapDispatchToProps = {
    createCatalog, //新增目錄
    updateCatalog,  //修改目錄
    getCatalogDetail //獲取目錄明細
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCatalog)
