import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAuth, isEmpty } from '../Libs/Lib';
import { getFabricDetail, postFabricView } from '../actions/fabricDetail';
import { getTagForFabric } from '../actions/myFabric';
import { addCollect, cancelCollect } from '../actions/collect';
import '../style/fabricDetail.sass';
import Button from './Button';
import * as Lang from '../Lang/default';
import Loading from './Loading';
import Empty from '../components/Empty';
import cookie from 'react-cookies';
import { EVENT_CART, EVENT_COLLECT_LIST_LOAD } from '../constants/eventAction';
import PubSub from 'pubsub-js';

class FabricDetail extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.clickBtn = this.clickBtn.bind(this);
    this.getLeftView = this.getLeftView.bind(this);
    this.getRightView = this.getRightView.bind(this);
    this.clickBoardcast = this.clickBoardcast.bind(this);
    this.addSwatchToCart = this.addSwatchToCart.bind(this);
    this.addFabricToCart = this.addFabricToCart.bind(this);
    this.collectFabric = this.collectFabric.bind(this);
    this.getAdditionField = this.getAdditionField.bind(this);
    this.state = {
      fabricId: props.fabricId || null,
      divStyle: props.displayModal ? 'flex' : 'none',
      currentBigImageSrc: '',
      loading: true,
      fabricDetailData: null,
      isCallingApi: true,
      presentationNo: props.presentationNo || null,
      fabricTagData: {}, //用來匹配新增的三個tag欄位
    };
  }

  componentDidMount() {
    const { fabricId } = this.state;
    this.getFabricDetailApi(fabricId);
  }

  componentWillUpdate(nextProps, nextState) {}

  componentWillReceiveProps(nextProps) {
    const {
      fabricDetailData: thisFabricDetailData,
      fabricId: thisFabricId,
      displayModal: thisDisplayModal,
    } = this.props;
    const { fabricDetailData, fabricId, displayModal, fabricTagData } =
      nextProps;
    if (fabricDetailData !== thisFabricDetailData) {
      if (fabricDetailData) {
        this.setState({
          fabricId: fabricDetailData.id,
          fabricDetailData: fabricDetailData,
          currentBigImageSrc: fabricDetailData.images_detail[0].filePath,
          loading: false,
          isCallingApi: false,
        });
      } else {
        this.setState({ loading: false, isCallingApi: false });
      }
    }

    if (fabricId !== thisFabricId) {
      this.setState({ loading: true, isCallingApi: true });
      this.getFabricDetailApi(fabricId);
    }

    if (displayModal !== thisDisplayModal) {
      this.setState({ divStyle: displayModal ? 'flex' : 'none' });
    }

    //獲取用來匹配tag的資料
    if (fabricTagData != this.state.fabricTagData) {
      this.setState({
        fabricTagData: fabricTagData,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  getFabricDetailApi(fabricId) {
    const { getFabricDetail, postFabricView, getTagForFabric } = this.props;
    const { presentationNo } = this.state;
    const authData = getAuth();
    if (fabricId !== null) {
      const params = {
        id: fabricId,
        memberId: authData.id,
        token: authData.token,
      };
      getFabricDetail(params);
      if (presentationNo) {
        postFabricView({
          memberId: authData.id,
          token: authData.token,
          fabricId: fabricId,
          presentationNo: presentationNo,
        });
      }

      getTagForFabric(params);
    } else {
      this.setState({
        fabricDetailData: {},
        loading: false,
        isCallingApi: false,
      });
    }
  }

  closeModal() {
    this.setState({ divStyle: 'none' });
    if (this.props.closed) {
      this.props.closed();
    }
  }

  clickBtn(index) {
    switch (index) {
      case 0: //chat
        break;
      case 1: //swatch
        this.addSwatchToCart();
        break;
      case 2: //fabric
        this.addFabricToCart();
        break;
    }
  }

  clickBoardcast(e) {
    const { clickBoardcast } = this.props;
    if (clickBoardcast) {
      clickBoardcast(e);
    }
  }

  collectFabric(fabricId, addLike = true) {
    const { addCollect, cancelCollect } = this.props;
    const authData = getAuth();
    if (!isEmpty(authData)) {
      const params = {
        memberId: authData.id,
        token: authData.token,
        fabricId: fabricId,
      };
      if (addLike) {
        addCollect(params, (res) => {
          this.getFabricDetailApi(fabricId);
          PubSub.publish(EVENT_COLLECT_LIST_LOAD);
        });
      } else {
        cancelCollect(params, (res) => {
          this.getFabricDetailApi(fabricId);
          PubSub.publish(EVENT_COLLECT_LIST_LOAD);
        });
      }
    }
  }

  addSwatchToCart() {
    const { presentationNo, fabricDetailData } = this.state;
    const now = new Date();
    const postTime = now.getTime();
    let shopcart = cookie.load('shopcart');
    let newCart;
    if (!shopcart || shopcart == 'undefined') {
      newCart = { items: [] };
    } else {
      newCart = shopcart;
    }
    if (newCart.items.length > 0) {
      //檢查有沒有這個布料資料在cookie裡面，如果有的話數量就要加一
      for (let i = 0; i < newCart.items.length; i++) {
        let item = newCart.items[i];
        if (item.id == this.state.fabricId && item.type == 'swatch') {
          //數量加一
          item.amount += 1;
          break;
        } else if (i == newCart.items.length - 1) {
          //將這筆swatch加入購物車
          newCart.items.push({
            id: this.state.fabricId,
            type: 'swatch',
            image: this.state.currentBigImageSrc,
            amount: 1,
            presentationNo: presentationNo,
            contents:
              fabricDetailData && fabricDetailData.contents
                ? fabricDetailData.contents
                : '--',
          });
          break;
        }
      }
    } else {
      //因為第一次加入這個布料，所以加入一整筆資料
      newCart.items.push({
        id: this.state.fabricId,
        type: 'swatch',
        image: this.state.currentBigImageSrc,
        amount: 1,
        presentationNo: presentationNo,
        contents:
          fabricDetailData && fabricDetailData.contents
            ? fabricDetailData.contents
            : '--',
      });
    }
    cookie.save('shopcart', JSON.stringify(newCart), { path: '/' });

    PubSub.publish(EVENT_CART, { open: true, postTime: postTime });
  }

  addFabricToCart() {
    const { presentationNo, fabricDetailData } = this.state;
    const now = new Date();
    const postTime = now.getTime();
    let shopcart = cookie.load('shopcart');
    let newCart;
    if (!shopcart || shopcart == 'undefined') {
      newCart = { items: [] };
    } else {
      newCart = shopcart;
    }
    if (newCart.items.length > 0) {
      //檢查有沒有這個布料資料在cookie裡面，如果有的話數量就要加一
      for (let i = 0; i < newCart.items.length; i++) {
        let item = newCart.items[i];
        if (item.id == this.state.fabricId && item.type == 'fabric') {
          //數量加一
          item.amount += 1;
          break;
        } else if (i == newCart.items.length - 1) {
          //將這筆swatch加入購物車
          newCart.items.push({
            id: this.state.fabricId,
            type: 'fabric',
            image: this.state.currentBigImageSrc,
            amount: 1,
            presentationNo: presentationNo,
            contents:
              fabricDetailData && fabricDetailData.contents
                ? fabricDetailData.contents
                : '--',
          });
          break;
        }
      }
    } else {
      //因為第一次加入這個布料，所以加入一整筆資料
      newCart.items.push({
        id: this.state.fabricId,
        type: 'fabric',
        image: this.state.currentBigImageSrc,
        amount: 1,
        presentationNo: presentationNo,
        contents:
          fabricDetailData && fabricDetailData.contents
            ? fabricDetailData.contents
            : '--',
      });
    }
    cookie.save('shopcart', JSON.stringify(newCart), { path: '/' });

    PubSub.publish(EVENT_CART, { open: true, postTime: postTime });
  }

  getLeftView() {
    const { fabricDetailData } = this.state;

    if (
      fabricDetailData != null &&
      fabricDetailData.images_detail &&
      fabricDetailData !== {} &&
      fabricDetailData != 'undefined'
    ) {
      const imageList = fabricDetailData.images_detail.map((item, index) => {
        return (
          <img
            src={item.filePath}
            key={'imageSrc_' + index}
            className="smallImage"
            id={'anchor' + index}
            onClick={() => {
              this.setState({ currentBigImageSrc: item.filePath });
            }}
          />
        );
      });
      let anchorNameLast =
        'anchor' + (fabricDetailData.images_detail.length - 1);
      const leftBtn = (
        <div
          className="imageControlArrowBox"
          onClick={() => {
            let anchorElement = document.getElementById('anchor0');
            anchorElement.scrollIntoView();
          }}
        >
          <img
            src={require('../images/iconArrowLeft.png')}
            className="imageControlArrow"
          />
        </div>
      );
      const rightBtn = (
        <div
          className="imageControlArrowBox"
          onClick={() => {
            let anchorElement = document.getElementById(anchorNameLast);
            anchorElement.scrollIntoView();
          }}
        >
          <img
            src={require('../images/iconArrowRight.png')}
            className="imageControlArrow"
          />
        </div>
      );
      return (
        <div className="leftArea col-md-6 col-12">
          <div className="text-center">
            <img src={this.state.currentBigImageSrc} className="bigImage" />
          </div>
          <div className="imageListBox">
            {leftBtn}
            <div className="imageListContainer">{imageList}</div>
            {rightBtn}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  getRightView() {
    const { fabricDetailData, fabricTagData } = this.state;
    const { clickBoardcast } = this.props;

    if (
      fabricDetailData != null &&
      (fabricDetailData.color || fabricDetailData.spec) &&
      fabricDetailData !== {} &&
      fabricDetailData != 'undefined'
    ) {
      const colorList = fabricDetailData.color.map((item, index) => {
        return (
          <div
            key={'color_' + index}
            style={{ backgroundColor: item, width: 50, height: 50 }}
            className="colorItem"
          />
        );
      });

      let specList = [
        <div className="specRow" key={'spec_999'}>
          <div className="specRowLabel">{'FABRIC NO.'}</div>
          <div className="specRowTxt">
            {fabricDetailData.fabricNo &&
            fabricDetailData.fabricNo.trim().length > 0
              ? fabricDetailData.fabricNo
              : '--'}
          </div>
        </div>,
      ];
      fabricDetailData.spec.map((item, index) => {
        if (item.label != 'FUNCTION') {
          specList.push(
            <div className="specRow" key={'spec_' + index}>
              <div className="specRowLabel">{item.label}</div>
              <div className="specRowTxt">{item.value}</div>
            </div>
          );
        }
      });

      //增加三個欄位要組進去specList
      // if(fabricTagData && fabricTagData != {}){
      //     specList = this.getAdditionField(specList)
      // }
      //最後增加price
      specList.push(
        <div className="specRow" key={'spec_price'}>
          <div className="specRowLabel">{'PRICE'}</div>
          <div className="specRowTxt">
            {fabricDetailData.price ? fabricDetailData.price : '--'}
          </div>
        </div>
      );

      const likeIcon = fabricDetailData.collect
        ? require('../images/like-active.png')
        : require('../images/like.png');
      let boardcastBtn = null;
      if (clickBoardcast) {
        boardcastBtn = (
          <div className="detailButtonBox">
            <Button
              type="button"
              className="btn btn-primary detailButton detailButtonChatTxt"
              onClick={() => {
                this.clickBoardcast(fabricDetailData);
              }}
              text={Lang.FABRIC_DETAIL_BTN_BROADCAST_FABRIC}
            />
          </div>
        );
      }
      return (
        <div className="rightArea col-md-6 col-12">
          <div className="fabricHeadlineBox">
            <div className="fabricHeadline">{fabricDetailData.headline}</div>
            <div
              className="fabricLike"
              onClick={() => {
                this.collectFabric(
                  fabricDetailData.id,
                  !fabricDetailData.collect
                );
              }}
            >
              <img src={likeIcon} />
            </div>
          </div>
          <div className="colorContainer">{colorList}</div>
          {/* <p /> */}
          <div className="specBox">
            {/* <div style={{margin:'20px',backgroundColor:'#0f0' ,display:'flex',flexDirection:'column',overflow:'true'}}> */}
            {specList}
            {/* </div> */}
          </div>
          <div className="bottomArea">
            <div className="detailButtonBox">
              <Button
                type="button"
                className="btn btn-primary detailButton getSwatchBtn"
                onClick={() => {
                  this.clickBtn(1);
                }}
                text={Lang.FABRIC_DETAIL_BTN_GET_SWATCH}
                // icon={
                //     <img
                //         src={require('../images/icon_fabricDetail_chat.png')}
                //         className="detailButtonIcon"
                //     />
                // }
              />
            </div>
            <div className="detailButtonBox">
              <Button
                type="button"
                className="btn btn-primary detailButton getFabricBtn"
                onClick={() => {
                  this.clickBtn(2);
                }}
                text={Lang.FABRIC_DETAIL_BTN_GET_FABRIC}
                // icon={
                //     <img
                //         src={require('../images/icon_fabricDetail_chat.png')}
                //         className="detailButtonIcon"
                //     />
                // }
              />
            </div>
            {boardcastBtn}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  getAdditionField(obj) {
    let mObj = obj;
    const { fabricDetailData, fabricTagData } = this.state;
    if (fabricDetailData.functionTags.length > 0) {
      let totalString = '';
      const localCheck = fabricTagData.functionTagsList;
      //用逗點切割
      const fArray = fabricDetailData.functionTags.split(',');
      for (let i = 0; i < fArray.length; i++) {
        const iData = parseInt(fArray[i]);
        for (let j = 0; j < localCheck.length; j++) {
          const jId = localCheck[j].id;
          if (iData == jId) {
            if (totalString.length > 0) {
              totalString += '，' + localCheck[j].text;
            } else {
              totalString += localCheck[j].text;
            }
            break;
          }
        }
      }
      mObj.push(
        <div className="specRow" key={'spec_10'}>
          <div className="specRowLabel">{'FUNCTION'}</div>
          <div className="specRowTxt">{totalString}</div>
        </div>
      );
    } else {
      mObj.push(
        <div className="specRow" key={'spec_10'}>
          <div className="specRowLabel">{'FUNCTION'}</div>
          <div className="specRowTxt">{'--'}</div>
        </div>
      );
    }
    if (fabricDetailData.attributeTags.length > 0) {
      let totalString = '';
      const localCheck = fabricTagData.attributeTagsList;
      //用逗點切割
      const aArray = fabricDetailData.attributeTags.split(',');
      for (let i = 0; i < aArray.length; i++) {
        const iData = parseInt(aArray[i]);
        for (let j = 0; j < localCheck.length; j++) {
          const jId = localCheck[j].id;
          if (iData == jId) {
            if (totalString.length > 0) {
              totalString += '，' + localCheck[j].text;
            } else {
              totalString += localCheck[j].text;
            }
            break;
          }
        }
      }
      mObj.push(
        <div className="specRow" key={'spec_11'}>
          <div className="specRowLabel">{'ATTRIBUTE'}</div>
          <div className="specRowTxt">{totalString}</div>
        </div>
      );
    } else {
      mObj.push(
        <div className="specRow" key={'spec_11'}>
          <div className="specRowLabel">{'ATTRIBUTE'}</div>
          <div className="specRowTxt">{'--'}</div>
        </div>
      );
    }
    if (fabricDetailData.otherTags.length > 0) {
      let totalString = '';
      const localCheck = fabricTagData.otherTagsList;
      //用逗點切割
      const oArray = fabricDetailData.otherTags.split(',');
      for (let i = 0; i < oArray.length; i++) {
        const iData = parseInt(oArray[i]);
        for (let j = 0; j < localCheck.length; j++) {
          const jId = localCheck[j].id;
          if (iData == jId) {
            if (totalString.length > 0) {
              totalString += '，' + localCheck[j].text;
            } else {
              totalString += localCheck[j].text;
            }
            break;
          }
        }
      }
      mObj.push(
        <div className="specRow" key={'spec_12'}>
          <div className="specRowLabel">{'OTHER'}</div>
          <div className="specRowTxt">{totalString}</div>
        </div>
      );
    } else {
      mObj.push(
        <div className="specRow" key={'spec_12'}>
          <div className="specRowLabel">{'OTHER'}</div>
          <div className="specRowTxt">{'--'}</div>
        </div>
      );
    }

    return mObj;
  }

  render() {
    const { loading, fabricDetailData, isCallingApi } = this.state;
    const emptyView = (
      <div className="emptyBlock">
        <Empty title={Lang.FABRIC_EMPTY_TITLE} content="" />
      </div>
    );
    const loadingView =
      fabricDetailData === null || loading ? <Loading /> : null;
    const closeBtn = (
      <div
        className="closeImgBox"
        onClick={() => {
          this.closeModal();
        }}
      >
        <img src={require('../images/icon_x.png')} className="closeImg" />
      </div>
    );
    let contentView = null;

    if ((fabricDetailData && fabricDetailData.id) || isCallingApi) {
      contentView = (
        <div className="contentContainer" onClick={(e) => e.stopPropagation()}>
          {closeBtn}
          <div className="topArea">
            {this.getLeftView()}
            {this.getRightView()}
          </div>
        </div>
      );
    } else {
      contentView = (
        <div className="contentContainer">
          {closeBtn}
          {emptyView}
        </div>
      );
    }
    return (
      <div
        className="fabricDetail"
        onClick={() => {
          this.closeModal();
        }}
        style={{ display: this.state.divStyle }}
      >
        {loadingView}
        <div className="contentOuter">{contentView}</div>
      </div>
    );
  }
}

FabricDetail.propTypes = {
  fabricId: PropTypes.number,
  title: PropTypes.string,
  closed: PropTypes.func, //modal關掉的回呼
  fabricDetailData: PropTypes.object,
  clickBoardcast: PropTypes.func,
  addCollect: PropTypes.func.isRequired,
  cancelCollect: PropTypes.func.isRequired,
  postFabricView: PropTypes.func.isRequired,
};

const mapStateToProps = () => (state) => {
  return {
    fabricDetailData: state.fabricDetail.fabricDetailData,
    fabricTagData: state.myFabric.fabricTagData,
  };
};

const mapDispatchToProps = {
  getFabricDetail,
  postFabricView,
  addCollect,
  cancelCollect,
  getTagForFabric, //獲取可以用的tag資料
};

export default connect(mapStateToProps, mapDispatchToProps)(FabricDetail);
