import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import '../../style/Common/CustomerModal.sass';

const CustomerModal = ({
  isOpen,
  handleClose,
  header,
  children,
  hideMusk,
  width,
  height,
  handleSubmit,
  onlyClose,
  onlySubmit,
  submitText,
  cancelText,
  disabled,
}) => {
  if (!isOpen) {
    return null;
  }

  const muskBackground = { backgroundColor: 'rgba(0,0,0,0.6)' };
  const disabledBtnStyle = { backgroundColor: '#cecece', color: '#191919' };

  return (
    <div
      className="modalContainer"
      onClick={hideMusk ? null : handleClose}
      style={hideMusk ? null : muskBackground}
    >
      <div className="modalMusk">
        <div
          className="mainContainer"
          style={{ maxWidth: width || 700, maxHeight: height || 800 }}
        >
          <div className="modalHeaderGroup">
            <div className="headerText">{header}</div>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={handleClose}
              className="modalCloseIcon"
            />
          </div>
          <div className="modalContent">{children}</div>
          <div className="modalFooter">
            <div className="modalFooterGroup">
              {onlySubmit ? null : (
                <button
                  disabled={disabled}
                  type="button"
                  className="modalCancelBtn"
                  onClick={handleClose}
                >
                  {cancelText || 'Cancel'}
                </button>
              )}
              {onlyClose ? null : (
                <button
                  disabled={disabled}
                  type="button"
                  className="modalSubmitBtn"
                  style={disabled ? disabledBtnStyle : null}
                  onClick={handleSubmit}
                >
                  {submitText || 'Submit'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
