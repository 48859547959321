import { CALL_API } from 'redux-api-middleware';

export default ({ getState, dispatch }) =>
  next =>
  action => {
    const callAPI = action[CALL_API];
    const config = {
      API: {
        root: process.env.REACT_APP_API_ROOT,
      },
    };
    const init = () => {
      if (window.storage === undefined) {
        if (isLocalStorageNameSupported()) {
          window.storage = localStorage;
        } else {
          window.storage = cookie;
        }
      }
    };
    const isLocalStorageNameSupported = () => {
      const testKey = 'test',
        storage = window.localStorage;

      try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
      } catch (error) {
        return false;
      }
    };
    const generateUUID = () => {
      let d = new Date().getTime();
      const uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    };
    const clean = (array, deleteValue) => {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === deleteValue) {
          array.splice(i, 1);
          i--;
        }
      }
      return array;
    };
    const get = () => {
      init();
      return window.storage.get;
    };
    const set = () => {
      init();
      return window.storage.set;
    };

    if (typeof callAPI === 'undefined') {
      return next(action);
    }

    // const auth = getState().auth

    // if (auth.isFetching) {
    //     setTimeout(() => {
    //         dispatch(action)
    //     }, 100)
    //     return () => {}
    // }

    let { endpoint, method, query } = callAPI;

    // Get params
    if (query) {
      let str = clean(
        Object.keys(query).map(key => {
          if (
            query[key] !== undefined &&
            query[key] !== '' &&
            query[key] !== null
          ) {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
            );
          } else {
            return;
          }
        }),
        undefined
      ).join('&');

      if (endpoint.indexOf('?') > -1) {
        endpoint = endpoint + '&' + str;
      } else {
        endpoint = endpoint + '?' + str;
      }

      delete action[CALL_API].query;
    }

    if (query === undefined) {
      delete action[CALL_API].query;
    }

    // const fullUrl = endpoint.indexOf(config.API.root) === -1 ? config.API.root + endpoint : endpoint
    const fullUrl =
      endpoint.indexOf('http') === -1
        ? process.env.REACT_APP_API_ROOT + endpoint
        : endpoint;

    action[CALL_API].endpoint = fullUrl;

    const header = {};
    header['Content-Type'] = 'application/json';
    // header.displayErrors = 'false'
    // header.requestId = generateUUID()

    // if (action[CALL_API] && auth.token) {
    //     header.sessionToken = auth.token
    // }

    // Save requestId
    // let requestIds = get('requestIds', { storage: 'session' })
    // if (requestIds) {
    //     requestIds.unshift(header.requestId)
    //     if (requestIds.length > 10) {
    //         requestIds.length = 10
    //     }
    //     set('requestIds', requestIds, { storage: 'session' })
    // } else {
    //     set('requestIds', [header.requestId], { storage: 'session' })
    // }

    action[CALL_API].headers = Object.assign(
      {},
      action[CALL_API].headers,
      header
    );

    // 帶入時間機制
    const ajaxObj = {
      ajaxTime: +new Date(),
      ajaxLink: endpoint,
      ajaxMethod: method,
    };
    if (typeof action[CALL_API].types[1] === 'string') {
      action[CALL_API].types[1] = {
        type: action[CALL_API].types[1],
        meta: {
          ajaxObj,
        },
      };
    } else if (typeof action[CALL_API].types[1] === 'object') {
      action[CALL_API].types[1].meta = Object.assign(
        { ajaxObj },
        action[CALL_API].types[1].meta
      );
    }

    return next(action);
  };
