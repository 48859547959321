import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Empty extends PureComponent {
  render() {
    const { title, content } = this.props;
    const titleView = title ? <div className="emptyTitle">{title}</div> : null;
    return (
      <div className="emptyBox">
        <img src={require('../images/empty.png')} alt="" />
        {titleView}
        <div className="emptyContent">{content}</div>
      </div>
    );
  }
}

Empty.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default Empty;
