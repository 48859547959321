import React from 'react';
import pptxgen from 'pptxgenjs';

const PPTXView = ({ imageList, DataList }) => {
  const pptx = new pptxgen();
  const slide = pptx.addSlide();
  slide.addImage({
    path: imageList[0],
    w: '55%',
    h: '90%',
    y: '5%',
    x: '42%',
  });
  slide.addImage({
    path: imageList[1],
    w: '32%',
    h: '50%',
    y: '45%',
    x: '5%',
  });

  const rows = [
    [
      {
        text: 'OUTERAWAR',
        options: { align: 'center', fill: 'b3b3b3', color: 'fff' },
      },
    ],
    [{ text: DataList.headline }],
    [{ text: DataList.contents }],
    [{ text: DataList.width }],
    [{ text: DataList.weight }],
  ];

  slide.addTable(rows, {
    colW: 3,
    x: '2%',
    y: '15%',
  });

  const exportPPTX = () => {
    pptx.writeFile({ fileName: 'react-demo.pptx' });
  };

  return (
    <button type="button" onClick={exportPPTX}>
      Export
    </button>
  );
};

export default PPTXView;
