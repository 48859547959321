import React, { Component } from 'react'
import PropTypes, { bool } from 'prop-types'
import { connect } from 'react-redux'
import Loading from './Loading'
import * as Lang from '../Lang/default'
import Modal from './Modal'
import FabricList from './FabricList'
import { image } from 'react-dom-factories'
import { getAuth, isEmpty ,getValidationVideoUrl } from '../Libs/Lib'
import { uploadCatalogPageMainPhoto } from '../actions/catalog'
import { EVENT_UPLOAD_CATALOG_MAIN_PHOTO , EVENT_FASHIONVIEW_CLEAR , EVENT_FASHIONVIEW_SHOW_FROM_JSON ,
    EVENT_CATALOG_DETAIL_SHOW_FABRIC ,
    EVENT_FASHIONVIEW_CHECK_SHOW_PAGE_CONFIRM} from '../constants/eventAction'
import PubSub from 'pubsub-js'
import '../style/fashionView.sass'


const typeList = [{name: 'add fabric', typeId: 1}]
const typeMainObject = {name: 'change mainPhoto', typeId: 0}
const typeMainVideo = {name: 'change video', typeId: 2}

const MyMaxRatio = 1  //長邊大於該方向邊長，將該圖片長邊縮小到此比例
const FabricItemWidth = 45 //顯示在畫面上的布料項目寬度，用來計算圖片邊界溢出
const CanvasLineColor = '#fff' //線條顏色

class FashionView extends Component {
    constructor(props) {
        super(props)
        this.showAskAlert = this.showAskAlert.bind(this)
        this.getAskAlertView = this.getAskAlertView.bind(this)
        this.clickAddType = this.clickAddType.bind(this)
        this.changeFile = this.changeFile.bind(this)
        this.previewImage = this.previewImage.bind(this)
        this.getMyMainPhotoView = this.getMyMainPhotoView.bind(this)
        this.getMyMainVideoView = this.getMyMainVideoView.bind(this)
        
        this.closeModal = this.closeModal.bind(this)
        this.getMyFabricModalView = this.getMyFabricModalView.bind(this)
        this.confirmFabricListSelect = this.confirmFabricListSelect.bind(this)
        this.getMyChooseFabricItemsPreview = this.getMyChooseFabricItemsPreview.bind(this)
        this.getMyChooseFabricItems = this.getMyChooseFabricItems.bind(this)
        this.getMyChooseFabricItemsLines = this.getMyChooseFabricItemsLines.bind(this)
        this.doClearCanvasLines = this.doClearCanvasLines.bind(this)
        this.getTotalJSONData = this.getTotalJSONData.bind(this)
        this.clearAllContentData = this.clearAllContentData.bind(this)
        this.renderAllContentFromJson = this.renderAllContentFromJson.bind(this)
        this.removeChooseFabric = this.removeChooseFabric.bind(this)
        this.checkFabricItemBound = this.checkFabricItemBound.bind(this)
        this.getMousePosition = this.getMousePosition.bind(this)
        this.rootEleClick = this.rootEleClick.bind(this)
        this.state = {
            isEdit: props.isEdit || false , //判斷目前是哪種模式
            isFirstPage: props.isFirstPage || false,
            isShowAskAlertView: false,
            askAlertView: <div />,
            isAddMainPhoto: false, //判斷目前畫面上有沒有主要的圖片
            isAddMainVideo: false, //判斷目前畫面上有沒有影片
            myEditArea:{    //可以編輯的區域寬高
                width:0,
                height:0,
            },
            mainPhotoBase64:'',
            mainPhotoArea:{width:0,height:0,x:0,y:0},
            showFabricModal:false,
            clickPos:{left:0,top:0},//滑鼠點下去的位置
            fabricItems:[], //目前在畫面上的布料
            isMoving:false, //判斷目前是不是拖動狀態
            mainPhotoInCloudUrlPath:'', //主要圖片的雲端路徑，用來判斷主要圖片有沒有上傳雲端
            mainPhotoFileItem:null, //準備要上傳的file物件
            mainPhotoFileUrl:null,  //準備要上傳的主圖在本機的路徑
            needUploadMainPhoto: false,
            currentVideoUrl: '',
            showLoading: false
        }
    }

    componentDidMount() {
        if(this.state.isEdit){
            this.pubSubToken_upload = PubSub.subscribe(EVENT_UPLOAD_CATALOG_MAIN_PHOTO, () => {
                this.getJsonData()
            })
            this.pubSubToken_clear = PubSub.subscribe(EVENT_FASHIONVIEW_CLEAR, () => {
                //將畫面上的項目都清空
                this.clearAllContentData()
            })
            this.pubSubToken_showFromJson = PubSub.subscribe(EVENT_FASHIONVIEW_SHOW_FROM_JSON, ( message , data) => {
                //使用接收到的JSON，產生畫面上的物件
                if(data && data.jsonStr){
                    this.renderAllContentFromJson(data.jsonStr)
                }
            })
            this.pubSubToken_checkShowPageConfirm = PubSub.subscribe(EVENT_FASHIONVIEW_CHECK_SHOW_PAGE_CONFIRM ,()=>{
                //獲取目前畫面json資料然後callback
                if(this.props.callbackShowPageConfirm){
                    this.props.callbackShowPageConfirm(this.getTotalJSONData())
                }
            })
        }

        const canvas = this.refs.canvas
        const ctx = canvas.getContext("2d")
        this.ctx = ctx
        this.setState({myEditArea:{width:this._acc.offsetWidth ,height:this._acc.offsetHeight}})

        if(this.props.previewJson){
            this.renderAllContentFromJson(this.props.previewJson)
        }

        
    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentWillUnmount() {
        if(this.state.isEdit){
            PubSub.unsubscribe(this.pubSubToken_upload)   
            PubSub.unsubscribe(this.pubSubToken_clear)   
            PubSub.unsubscribe(this.pubSubToken_showFromJson)   
            PubSub.unsubscribe(this.pubSubToken_checkShowPageConfirm)
        }
         
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isFirstPage != this.props.isFirstPage){
            this.setState({isFirstPage:nextProps.isFirstPage})
        }


    }

    getJsonData() {
        //如果有主要圖片物件才需要上傳
        // 情況：1.第一次進到page加入主圖  
        //      2.重新進到page又修改了主圖
        if(this.state.mainPhotoFileItem){
            if(this.state.needUploadMainPhoto){
                //先去上傳主要圖片，然後組織所有資料成json回傳
                const fileName = this.state.mainPhotoFileItem.name
                var idxDot = fileName.lastIndexOf(".") + 1;
                var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

                let data = new FormData()
                const authData = getAuth()
                if (!isEmpty(authData)) {
                    this.setState({showLoading:true})
                    data.append('memberId', authData.id)
                    data.append('token', authData.token)
                    data.append('file', this.state.mainPhotoFileItem)
                    
                    const config = {
                        method: 'POST',
                        body: data,
                    }
                    fetch(`${process.env.REACT_APP_API_ROOT}/fabricCatalogue/image`, config)
                        .then((res) => res.json())
                        .then((res) => {
                            if(res.status=='success'){
                                //上傳成功
                                const fileUrl = res.data.url
                                this.setState({mainPhotoInCloudUrlPath:fileUrl ,mainPhotoFileItem:null ,needUploadMainPhoto:false ,showLoading:false})
                                //獲取當前畫面的JSON資料
                                if(this.props.saveMainFileSuccess){
                                    this.props.saveMainFileSuccess(this.getTotalJSONData())
                                }
                                
                            }else{
                                this.setState({showLoading:false})
                            }
                        })
                }
            }else{
                //獲取json字串回丟就好
                if(this.props.saveMainFileSuccess){
                    this.props.saveMainFileSuccess(this.getTotalJSONData())
                }
            }
        }else{
            //因為沒有加過主要圖片，所以就回傳一個空的json字串回去
            if(this.props.saveMainFileSuccess){
                this.props.saveMainFileSuccess(this.getTotalJSONData())
            }
        }
        
    }
    //獲取畫面上json資料
    getTotalJSONData() {
        let mObj = {}
        //獲取當前畫面上所有JSON資料以及座標
        if(this.state.mainPhotoInCloudUrlPath && this.state.needUploadMainPhoto==false){
            Object.assign(mObj,{
                mainPhotoInCloudUrlPath:this.state.mainPhotoInCloudUrlPath, //主圖的雲端路徑
                myEditArea:this.state.myEditArea,   //顯示區域的大小
                mainPhotoArea:this.state.mainPhotoArea,  //主圖的大小位置
                fabricItems:this.state.fabricItems,  //目前畫面上的布料項目所有資訊
                videoUrl:this.state.currentVideoUrl
            })
        }else if(this.state.currentVideoUrl !='' && this.state.needUploadMainPhoto==false){
            //把影片路徑傳出去，還可以加布料
            Object.assign(mObj,{
                videoUrl:this.state.currentVideoUrl, 
                myEditArea:this.state.myEditArea,   //顯示區域的大小
                fabricItems:this.state.fabricItems,  //目前畫面上的布料項目所有資訊
                mainPhotoInCloudUrlPath:this.state.mainPhotoInCloudUrlPath, //主圖的雲端路徑
                mainPhotoArea:this.state.mainPhotoArea,  //主圖的大小位置
            })

        }else if(this.state.needUploadMainPhoto){
            //需要上傳圖片，表示一定有動過主圖
            Object.assign(mObj,{
                videoUrl:this.state.currentVideoUrl,
                mainPhotoInCloudUrlPath:'', 
                myEditArea:this.state.myEditArea,   //顯示區域的大小
                mainPhotoArea:this.state.mainPhotoArea,  //主圖的大小位置
                fabricItems:this.state.fabricItems,  //目前畫面上的布料項目所有資訊
                needUploadMainPhoto: true
            })
        }
        return JSON.stringify(mObj)
    }
    //因為切換畫面了，所以把畫面上的東西都清空
    clearAllContentData() {
        this.doClearCanvasLines()//把所有畫上去的線都清空
        this.setState({
            isShowAskAlertView: false,
            isAddMainPhoto:false,
            mainPhotoBase64:'',
            mainPhotoArea:{width:0,height:0,x:0,y:0},
            showFabricModal:false,
            clickPos:{left:0,top:0},
            fabricItems:[], 
            isMoving:false, 
            mainPhotoInCloudUrlPath:'',
            mainPhotoFileItem:null,
            mainPhotoFileUrl:null, 
            currentVideoUrl:''
        })
    }
    //重新產生畫面上物件
    renderAllContentFromJson(jsonStr) {
        this.doClearCanvasLines() //新的資料進來，所以把畫面上的線條都清空

        const jsonObj = JSON.parse(jsonStr)

        let mainPhotoInCloudUrlPath = jsonObj.mainPhotoInCloudUrlPath
        let newObj = {
            mainPhotoInCloudUrlPath: mainPhotoInCloudUrlPath && mainPhotoInCloudUrlPath!='' ? mainPhotoInCloudUrlPath : '',
            isAddMainPhoto: mainPhotoInCloudUrlPath&&mainPhotoInCloudUrlPath!='' ? true: false,
            mainPhotoArea: jsonObj.mainPhotoArea ? jsonObj.mainPhotoArea : {width:0,height:0,x:0,y:0},
            fabricItems: jsonObj.fabricItems ? jsonObj.fabricItems : [],
            isShowAskAlertView: false,
            mainPhotoBase64:mainPhotoInCloudUrlPath && mainPhotoInCloudUrlPath!='' ? mainPhotoInCloudUrlPath : '',
            mainPhotoFileUrl:null,
            needUploadMainPhoto:false, //用來決定需不需要上傳圖片
            currentVideoUrl: jsonObj.videoUrl ? jsonObj.videoUrl : ''
        }
        this.setState(newObj)

    }

    closeModal(e) {
        this.setState({
            showFabricModal: false,
        })
    }

    changeFile(e) {
        this.previewImage(e.target.files)
    }

    previewImage(fileList) {
        const mEditArea = this.state.myEditArea
        const files = Array.from(fileList)
        if (files && files.length == 1) {
            //先去檢查檔案是否合法
            files.map((item, index) => {
                const fileName = item.name
                var idxDot = fileName.lastIndexOf(".") + 1;
                var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
                    const url = URL.createObjectURL(item) //獲取到的主圖在本機端的路徑
                    //TO DO
                    let reader = new FileReader()
                    reader.readAsDataURL(item)
                    reader.onloadend = () => {

                        const mainPhoto = reader.result

                        var image = new Image();
                        image.src = mainPhoto

                        image.onload = ()=>{
                            // access image size here 
                            //抓到圖片寬跟高
                            //1.把長邊縮小到可視範圍的邊長90%
                            if(image.width > image.height && image.width > mEditArea.width){
                                //橫向的圖,又比可視範圍的寬度寬
                                const smaller = mEditArea.width * MyMaxRatio
                                const ratio = image.width / smaller
                                const rHeight = image.height / ratio

                                if(rHeight > mEditArea.height){
                                    const smallerHeight = mEditArea.height * MyMaxRatio
                                    //將短邊縮小
                                    const ratioHeight = rHeight / smallerHeight
                                    const rWidth = smaller / ratioHeight
                                    this.setState({mainPhotoArea:{width:rWidth,height:smallerHeight , x:(mEditArea.width-rWidth)/2 , y:(mEditArea.height-smallerHeight)/2}})
                                }else{

                                    this.setState({mainPhotoArea:{width:smaller,height:rHeight ,x:mEditArea.width*(1-MyMaxRatio)/2,y:0}})
                                }

                            }else if(image.height > image.width && image.height > mEditArea.height){
                                //直向的圖,又比可視範圍的高度高
                                const smaller = mEditArea.height * MyMaxRatio
                                const ratio = image.height / smaller
                                const rWidth = image.width / ratio

                                if(rWidth > mEditArea.width){
                                    const smallerWidth = mEditArea.width * MyMaxRatio
                                    //將短邊縮小
                                    const ratioWidth = rWidth / smallerWidth
                                    const rHeight = smaller / ratioWidth
                                    this.setState({mainPhotoArea:{width:smallerWidth,height:rHeight , x:(mEditArea.width-smallerWidth)/2 , y:0}})
                                }else{
                                    this.setState({mainPhotoArea:{width:rWidth,height:smaller , x:(mEditArea.width-rWidth)/2 , y:mEditArea.height*(1-MyMaxRatio)/2}})
                                }

                            }else if(image.height > image.width && image.width > mEditArea.width){ 
                                //直向的圖，圖片短邊比可視範圍短邊大
                                const smaller = mEditArea.width * MyMaxRatio
                                const ratio = image.width / smaller
                                const rHeight = image.height / ratio

                                if(rHeight > mEditArea.height){
                                    const smallerHeight = mEditArea.height * MyMaxRatio
                                    //將短邊縮小
                                    const ratioHeight = rHeight / smallerHeight
                                    const rWidth = smaller / ratioHeight
                                    this.setState({mainPhotoArea:{width:rWidth,height:smallerHeight , x:(mEditArea.width-rWidth)/2 , y:(mEditArea.height-smallerHeight)/2}})
                                }else{

                                    this.setState({mainPhotoArea:{width:smaller,height:rHeight ,x:mEditArea.width*(1-MyMaxRatio)/2,y:0}})
                                }
                                
                                
                            }else if(image.width > image.height && image.height > mEditArea.height){ 
                                //橫向的圖，圖片短邊比可視範圍短邊大
                                const smaller = mEditArea.height * MyMaxRatio
                                const ratio = image.height / smaller
                                const rWidth = image.width / ratio

                                if(rWidth > mEditArea.width){
                                    const smallerWidth = mEditArea.width * MyMaxRatio
                                    //將短邊縮小
                                    const ratioWidth = rWidth / smallerWidth
                                    const rHeight = smaller / ratioWidth
                                    this.setState({mainPhotoArea:{width:smallerWidth,height:rHeight , x:(mEditArea.width-smallerWidth)/2 , y:(mEditArea.height-rHeight)/2}})
                                }else{
                                    this.setState({mainPhotoArea:{width:rWidth,height:smaller , x:(mEditArea.width-rWidth)/2 , y:mEditArea.height*(1-MyMaxRatio)/2}})
                                }
                                
                            }else{
                                //將該張圖片置放在可視區域中間，找出座標
                                this.setState({mainPhotoArea:{width:image.width,height:image.height , x:(mEditArea.width-image.width)/2 , y:0}})

                            }

                            
                        };
                    
                        this.setState({
                            // currentVideoUrl:'', //因為選了主圖，所以把影片路徑清空
                            mainPhotoInCloudUrlPath:'', //因為換了新的主圖，所以把路徑清空
                            mainPhotoFileItem:item, //準備要上傳的file物件
                            mainPhotoFileUrl:url,   //主圖在本機端的路徑，上傳用
                            mainPhotoBase64:mainPhoto,
                            isAddMainPhoto:true,
                            isShowAskAlertView:false,
                            needUploadMainPhoto:true
                        })
                        
                        
                    }
                }else{
                    alert("Only jpg/jpeg and png files are allowed!");
                }   
            })
        }else if(files && files.length > 1){
            alert('Just can only choose an image!')
        }
    }

    getMyMainPhotoView() {
        
        return <img
            src={this.state.mainPhotoInCloudUrlPath != '' ? this.state.mainPhotoInCloudUrlPath : this.state.mainPhotoBase64}
            style={{
                // width: this.state.mainPhotoArea.width,
                // height: this.state.mainPhotoArea.height,
                // marginLeft: this.state.mainPhotoArea.x,
                // marginTop:this.state.mainPhotoArea.y
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: 0
            }}
        />
    }

    getMyMainVideoView(){
        const mWidth = this.state.myEditArea.width
        const nHeight = mWidth/16*9
        //產生影片iframe在畫面中間
        const videoUrl = getValidationVideoUrl(this.state.currentVideoUrl)
        return (
            <iframe src={videoUrl}
            width={mWidth} height={nHeight}
            frameBorder="0" allowFullScreen={false}
            style={{
                position: 'absolute',
                zIndex: this.state.isEdit?1:2,
                top: 0,
                left: 0,
            }}
            ></iframe>
        )
    }

    clickAddType(typeId) {
        switch (typeId) {
            case 0: //mainPhoto
                //打開選圖
                this.refInput.click()
                break;
            case 1: //fabric
                this.setState({showFabricModal:true})
                break;
            case 2: //video
                setTimeout(()=>{
                    let videoUrl = prompt("Enter Video URL ex.Youtube or Vimeo", this.state.currentVideoUrl);
                    if(videoUrl == "null" || videoUrl == null || videoUrl == ""){
                        //取消
                    }else{
                        //選了確定，以及有資料
                        this.setState({
                            currentVideoUrl:videoUrl,
                            // mainPhotoInCloudUrlPath:'', //因為換了影片，所以把路徑清空
                            // mainPhotoFileItem:null,
                            // mainPhotoFileUrl:null,
                            // mainPhotoBase64:'',
                            // isAddMainPhoto:false,
                            // isShowAskAlertView:false,
                            // needUploadMainPhoto:false
                        })
                    }
                },200)
                break;
            default:
                break;
        }
        this.setState({isShowAskAlertView:false})
    }

    getAskAlertView(left, top) {
        let mTypeList = null
        mTypeList = typeList.map((item, index) => {
            return (
                <div
                    key={"index_"+item.typeId}
                    className="typeItem"
                    onClick={()=>{
                        this.clickAddType(item.typeId)
                    }}
                >
                    {item.name}
                </div>
            )
        })
        mTypeList.push(
            <div
                key={"index_" + typeMainObject.typeId}
                className="typeItem"
                onClick={() => {
                    this.clickAddType(typeMainObject.typeId)
                }}
            >
                <label style={{marginBottom:'0px' ,cursor:'pointer'}}>
                    {typeMainObject.name}
                </label>
            </div>
        )
        if(!this.state.isFirstPage){
            mTypeList.push(
                <div
                    key={"index_" + typeMainVideo.typeId}
                    className="typeItem"
                    onClick={() => {
                        this.clickAddType(typeMainVideo.typeId)
                    }}
                >
                    <label style={{marginBottom:'0px',cursor:'pointer'}}>
                        {typeMainVideo.name}
                    </label>
                </div>
            )
        }
        return (
            <div className="askAlert" style={{left: left, top: top}}>
                <div style={{fontSize:'12px', color:'#787878', textAlign:'center'}}>{Lang.CATALOG_ADD_WHICH_TYPE}</div>
                <div style={{width:'100%', height:'1px', backgroundColor:'#787878'}}/>
                <div className="typeContainer" >
                    {mTypeList}
                </div>
                {/* 底部的取消按鈕 */}
                <div className="cancelBtn"
                    onClick={(e) => {
                        e.preventDefault()
                        this.setState({askAlertView:<div />, isShowAskAlertView:false})
                    }}
                >
                    {Lang.CATALOG_ADD_WHICH_TYPE_CANCEL}
                </div>
            </div>
        )
    }

    showAskAlert(left, top) {
        this.setState({
            askAlertView: this.getAskAlertView(left, top),
            isShowAskAlertView:true,
            clickPos: {
                left: left,
                top: top
            }
        })
    }

    checkFabricItemBound(clickX, clickY, imgUrl, callback) {
        //檢查圖片邊界溢出
        let img = new Image();
        img.src = imgUrl;
        img.onload = () => {
            // img.width, img.height
            // 將圖片寬度放大或是縮小到 FabricItemWidth 的數值
            const ratio = img.width / FabricItemWidth
            let rHeight = FabricItemWidth 
            if(img.height !== img.width) {
                //選取的圖片非正方形
                rHeight = img.height/ratio
            }
            //點擊的位置加上該邊，檢查看看有沒有超出邊界，有超出就要調整點擊的位置
            let mClickX = clickX
            let mClickY = clickY
            if ((clickX + FabricItemWidth) > this.state.myEditArea.width) {
                mClickX = mClickX - ((clickX + FabricItemWidth) - this.state.myEditArea.width)
            }
            if ((clickY + rHeight) > this.state.myEditArea.height) {
                mClickY = mClickY - ((clickY + rHeight) - this.state.myEditArea.height)
            }
            const dotX = clickX
            const dotY = clickY
            let picCenterX = mClickX + (FabricItemWidth / 2)
            let picCenterY = mClickY + (rHeight / 2)
            callback(mClickX, mClickY, picCenterX, picCenterY, FabricItemWidth, rHeight, dotX, dotY)
        }
    }

    confirmFabricListSelect(fabricSelectData) {
        const fabricListData = Object.values(fabricSelectData)
        
        //雖然初始座標在範圍內，但是加上圖片的寬跟高就有可能超出，所以進行邊界溢出處理
        this.checkFabricItemBound(
            this.state.clickPos.left,
            this.state.clickPos.top,
            fabricListData[0].images,
            (x, y, picCenterX, picCenterY, picWidth, picHeight, dotX, dotY) => {
                let oldItems = this.state.fabricItems
                oldItems.push({
                    x: x,
                    y: y, //圖片左上角座標
                    picCenterX: picCenterX,
                    picCenterY: picCenterY, //圖片中心點座標，要用來當作連線的終點
                    picWidth: picWidth,
                    picHeight: picHeight, //此張圖片縮放之後的寬跟高，要用來計算邊界溢出
                    dotX: dotX,
                    dotY: dotY,    //點擊的地方，要用來當作連線的起點
                    fabricId: fabricListData[0].id,
                    fabricImage: fabricListData[0].images
                })

                this.setState({
                    showFabricModal: false,
                    fabricItems: oldItems
                })
            }
        )
        
    }

    getMyFabricModalView() {
        let confirmSelect = this.confirmFabricListSelect
        return (
            <Modal displayModal={this.state.showFabricModal} closeModal={this.closeModal}>
                <FabricList
                    className="myFabricSelectContainer"
                    confirmSelect={confirmSelect}
                    selectedFabrics={[]}
                    multiple={false}
                />
            </Modal>
        )
    }

    getMyChooseFabricItemsPreview() {
        // 獲取加到畫面上的布料view，預覽模式
        const { width } = this.state.myEditArea
        const zoomRate = width / 480
        return this.state.fabricItems.map((item , index) => {
            const itemX = ((item.x - (item.picWidth / 2)) / 480) * 100
            const itemY = ((item.y - (item.picHeight / 2)) / 640) * 100
            return  (
                <div
                    key={'index_' + index + '_' + item.fabricId}
                    id={"mychooseItem" + index}
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        zIndex: index + 2,
                        left: itemX + '%',
                        top: itemY + '%'
                    }}
                    onClick={() => {
                        PubSub.publish(EVENT_CATALOG_DETAIL_SHOW_FABRIC, { fabricId: item.fabricId })
                    }}
                    className="catalogFabricItem"
                >
                    <img src={item.fabricImage} style={{width: (FabricItemWidth * zoomRate),height: (item.picHeight * zoomRate)}} draggable="false"/>
                </div>
            )
        })
        
    }
    // 獲取加到畫面上的布料view，可以編輯拖動
    getMyChooseFabricItems() {
        return this.state.fabricItems.map((item , index) => {
            return  (
                <div
                    key={'index_'+index+'_'+item.fabricId}
                    id={"mychooseItem" + index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        zIndex: index + 2,
                        left: item.x - (item.picWidth / 2),
                        top: item.y - (item.picHeight / 2)
                    }}
                    className="catalogFabricItem"
                    onMouseDown={(e) => {
                        if (!this.state.isMoving) {
                            const position = this.getMousePosition(e)
                            const 相減x = position.mouseX - item.x
                            const 相減y = position.mouseY - item.y
                            this.setState({isMoving:true ,
                                相減x: 相減x,
                                相減y: 相減y,
                            })
                        }
                    }}
                    onMouseUp={() => {
                        if (this.state.isMoving) {
                            this.setState({isMoving:false})
                        }
                    }}
                    onMouseLeave={() => {
                        if (this.state.isMoving) {
                            this.setState({isMoving:false})
                        }
                    }}
                    onMouseMove={(e) => {
                        if (this.state.isMoving) {
                            const {相減x, 相減y} = this.state
                            const position = this.getMousePosition(e)
                            // 计算modal应该随鼠标移动到的坐标
                            const x = position.mouseX
                            const y = position.mouseY               
                            
                            let oldItem = this.state.fabricItems[index]
                            //計算邊界溢出
                            let mX = x - 相減x 
                            let mY = y - 相減y 

                            let pCenterX = mX + (item.picWidth / 2)
                            let pCenterY = mY + (item.picHeight / 2)
                            if ( x - 相減x < 0) {
                                //超過左邊
                                mX = 0
                                pCenterX = mX + (item.picWidth / 2)
                            } else if ((x - 相減x) + item.picWidth > this.state.myEditArea.width) {
                                //超過右邊
                                mX = this.state.myEditArea.width - item.picWidth
                                pCenterX = mX + (item.picWidth / 2)
                            }
                            if (y - 相減y < 0) {
                                //超過上邊
                                mY = 0
                                pCenterY = mY + (item.picHeight / 2)
                            } else if ((y - 相減y) + item.picHeight > this.state.myEditArea.height) {
                                //超過下邊
                                mY = this.state.myEditArea.height - item.picHeight
                                pCenterY = mY + (item.picHeight / 2)
                            }
                            
                            oldItem.x = mX
                            oldItem.y = mY
                            oldItem.picCenterX = pCenterX
                            oldItem.picCenterY = pCenterY
                            
                            let oldItems = this.state.fabricItems
                            oldItems.splice(index, 1, oldItem)
                            this.setState({
                                fabricItems: oldItems
                            })
                        }
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        this.setState({
                            display: false,
                        })
                    }}
                >
                    <div className="catagoryFabricClose" onClick={(e) => {
                        e.stopPropagation()
                        this.removeChooseFabric(item,index)
                    }}>
                        <img src={require('../images/close-black.png')} />
                    </div>
                    <img src={item.fabricImage} style={{ width:FabricItemWidth,height:item.picHeight }} draggable="false" />
                </div>
            )
        })
    }
    // 獲取加到畫面上的布料的點的連線
    getMyChooseFabricItemsLines() {
        const { width } = this.state.myEditArea
        const zoomRate = width / 480
        if (this.state.fabricItems.length > 0) {
            this.doClearCanvasLines()
        }
        if (this.state.isEdit) {
            this.state.fabricItems.map((item , index) => {
                const ctx = this.ctx
                
                ctx.beginPath();
                ctx.moveTo(item.x, item.y);
                ctx.lineTo(item.dotX, item.dotY);
                ctx.strokeStyle = CanvasLineColor;
                ctx.lineWidth = 2;
                ctx.closePath();
                ctx.stroke();
            })    
        } else {
            setTimeout(() => {
                this.state.fabricItems.map((item , index) => {
                    const itemDotX = item.dotX * zoomRate
                    const itemDotY = item.dotY * zoomRate
                    const itemPicCenterX = item.x * zoomRate
                    const itemPicCenterY = item.y * zoomRate
                    const ctx = this.ctx
                    
                    ctx.beginPath();
                    ctx.moveTo(itemPicCenterX, itemPicCenterY);
                    ctx.lineTo(itemDotX, itemDotY);
                    ctx.strokeStyle = CanvasLineColor;
                    ctx.lineWidth = 2;
                    ctx.closePath();
                    ctx.stroke();
                })   
            }, (100)); 
        }
    }

    getMousePosition(e) {
        const X = e.nativeEvent.pageX
        const Y = e.nativeEvent.pageY
    
        let mouseX = e.pageX
        let mouseY = e.pageY
        
        return {X, Y, mouseX, mouseY}
    }
    
    doClearCanvasLines() {
        const ctx = this.ctx
        ctx.clearRect(0,0,this.state.myEditArea.width,this.state.myEditArea.height);
    }
    
    removeChooseFabric(item ,index) {
        let oldChooseFabrics = this.state.fabricItems
        if(oldChooseFabrics.length>0){
            this.doClearCanvasLines()
        }
        oldChooseFabrics.splice(index,1)
        this.setState({fabricItems:oldChooseFabrics})
    }

    rootEleClick(e){
        if(!this.state.isEdit){
            return
        }
        //顯示一個小跳窗問他要增加什麼  
        if ( this.state.isShowAskAlertView) {
            this.setState({isShowAskAlertView: false})
        } else if (this.state.isAddMainPhoto) {
            this.showAskAlert(e.nativeEvent.layerX, e.nativeEvent.layerY)
        } 
        else if (!this.state.isAddMainPhoto && this.state.isFirstPage) {
            //因為沒有加過mainPhoto，直接讓他選mainPhoto
            this.refInput.click()
        }
        else if(!this.state.isFirstPage){
            this.showAskAlert(e.nativeEvent.layerX, e.nativeEvent.layerY)
        }
    }

    render() {
        const fabricModalView = this.state.isEdit ? this.getMyFabricModalView() : null
        const fashionContainerStyle = this.state.isEdit ? {
            width: '480px',
            height: '640px'
        } : {}
        this.getMyChooseFabricItemsLines()
        let mLoadingView = null
        if(this.state.showLoading){
            mLoadingView = <Loading />
        }

        return (
            <div>
                {this.state.isShowAskAlertView && this.state.askAlertView}
                <div
                    id="rootEle"
                    className="fashionContainer"
                    style={fashionContainerStyle}
                    ref={a => this._acc = a} 
                    onClick={(e) => {
                        this.rootEleClick(e)
                    }}
                >
                    <input
                        ref={r => this.refInput = r}
                        type="file"
                        name="mainPhoto"
                        multiple={false}
                        className="chooseFile"
                        accept="image/*"
                        style={{ display:'none' }}
                        onChange={this.changeFile}
                    />  
                    {fabricModalView}
                    
                    {(this.state.mainPhotoBase64!='') && this.getMyMainPhotoView()}
                    {(this.state.currentVideoUrl!='') && this.getMyMainVideoView()}
                    {/* 這邊顯示畫面上的布料 */}
                    {(this.state.isEdit && this.state.fabricItems&&this.state.fabricItems.length>0) && this.getMyChooseFabricItems()}
                    {(!this.state.isEdit && this.state.fabricItems&&this.state.fabricItems.length>0) && this.getMyChooseFabricItemsPreview()}
                    <canvas
                        ref={'canvas'}
                        width={this.state.myEditArea.width}
                        height={this.state.myEditArea.height} 
                        style={{
                            position:'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1
                        }}
                    />
                    {mLoadingView}
                </div>
            </div>
        )
    }
}

FashionView.propTypes = {
    isEdit:bool,    //目前是否為編輯模式
    isFirstPage:bool, //是不是第一頁，第一頁不能放影片
    previewJson: PropTypes.string, //用來預覽的json資料
    getJsonData: PropTypes.func,
    saveMainFileSuccess: PropTypes.func,
    callbackShowPageConfirm: PropTypes.func,
}

const mapStateToProps = () => state => {
    return {
        mainPhotoUrl: state.catalog.uploadMainPhotoUrl
    }
}

const mapDispatchToProps = { 

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FashionView)