import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class CatalogItem extends Component {
    constructor(props) {
        super(props)
        this.onClickPreview = this.onClickPreview.bind(this)
        this.onClickEdit = this.onClickEdit.bind(this)
    }
    componentDidMount() {
    }

    onClickPreview(id) {
        if(this.props.clickPreview)
            this.props.clickPreview(id)
    }
    onClickEdit(id) {
        if(this.props.clickEdit)
            this.props.clickEdit(id)
    }

    render() {
        const { data ,removeFunc } = this.props

        const deleteBtn = data.editable ? (removeFunc ? (
            <div className="edit" onClick={() => {
                removeFunc(data.id, data.title)
            }}>
                <img src={require('../images/icon_remove.png')} />
            </div>
        ) : null) : null
        const editBtn = data.editable ? (
            <div className="edit" onClick={() => {
                this.onClickEdit(data.id)
            }}>
                <img src={require('../images/icon_edit.png')} />
            </div>
        ) : null

        return (
            <div className="card">
                <div className="cardImgContainer" onClick={() => {
                    this.onClickPreview(data.id)
                }}>
                    <img
                        className="card-img-top-catalog"
                        alt="..."
                        src={data.coverImage==''?require('../images/cardDefaultImg.jpg'):data.coverImage}
                    />
                </div>
                <div className="card-body">
                    <div className="titleRow">
                        <div className="title">{data.title}</div>
                        {editBtn}
                        {deleteBtn}
                    </div>
                </div>
            </div>
        )
    }
}

CatalogItem.propTypes = {
    data: PropTypes.object,
    clickPreview: PropTypes.func,   //點擊要進到明細
    clickEdit: PropTypes.func,      //點擊要進到編輯
    removeFunc: PropTypes.func  //點擊詢問移除項目
}
