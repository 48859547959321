import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

// 新增message
function fetchAddMessage(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.CHAT_ADD_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.CHAT_ADD_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.CHAT_ADD_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'POST',
            endpoint: '/chat',
            body: JSON.stringify(params),
        },
    }
}

export function addMessage(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchAddMessage(params)).then((res) => {
            callback(res)
        })
    }
}
// chat list
function fetchGetMessageList(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.CHAT_LIST_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.CHAT_LIST_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.CHAT_LIST_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/chat',
            query: params,
        },
    }
}

export function getMessageList(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetMessageList(params)).then((res) => {
            callback(res)
        })
    }
}
// message detail
function fetchGetMessageDetail(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.CHAT_DETAIL_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.CHAT_DETAIL_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.CHAT_DETAIL_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/chat/' + params.id,
            query: params,
        },
    }
}

export function getMessageDetail(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetMessageDetail(params)).then((res) => {
            callback(res)
        })
    }
}
