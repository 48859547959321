import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import '../../style/footer.sass'

class Footer extends Component {
    render() {
        return <div className="footer">Copyright © 2020 TEXTIP. All rights reserved.</div>
    }
}

Footer.propTypes = {}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
