import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { getAuth, isEmpty } from '../Libs/Lib'
import * as Lang from '../Lang/default'
import { getMyFabricDatas ,getTagForFabric} from '../actions/myFabric'

import { getTextipFabricDatas } from '../actions/textipFabric'
import Input from '../components/Input'
import Button from '../components/Button'
import Image from '../components/Image'
import Empty from '../components/Empty'
import Loading from '../components/Loading'
import Pagination from '../components/Pagination'

import '../style/fabricList.sass'

class FabricList extends Component {
    constructor(props) {
        super(props)
        this.handleFabricSearchChange = this.handleFabricSearchChange.bind(this)
        this.selectFabricItem = this.selectFabricItem.bind(this)
        this.confirmSelect = this.confirmSelect.bind(this)
        this.goPage = this.goPage.bind(this)
        this.getFabricDatas = this.getFabricDatas.bind(this)
        this.getMyFabricDatas = this.getMyFabricDatas.bind(this)
        this.getTextipFabricDatas = this.getTextipFabricDatas.bind(this)
        this.apiParams = this.apiParams.bind(this)
        this.clickFabricDataType = this.clickFabricDataType.bind(this)
        this.checkIsSelect = this.checkIsSelect.bind(this)
        this.getFilterView = this.getFilterView.bind(this)
        this.getTagsForFabric = this.getTagsForFabric.bind(this)
        this.clickFilterSubmit = this.clickFilterSubmit.bind(this)

        this.cusTagsListView = this.cusTagsListView.bind(this)
        this.clickCusTagsItem = this.clickCusTagsItem.bind(this)
        this.checkIsActiveCusTags = this.checkIsActiveCusTags.bind(this)

        this.setCloseFilter = this.setCloseFilter.bind(this)
        this.setOpenFilter = this.setOpenFilter.bind(this)
        this.state = {
            loading: false,
            keyword: '',
            fabricSelectData: [],
            fabricList: null,
            textipFabricList: null,
            pageInfo: {},
            textipPageInfo: {},
            multiple: props.multiple || false,
            page: 1,
            textipPage: 1,
            fabricDataType: 'new',
            showFilterView: false, //用來決定要不要顯示篩選畫面 
            fabricTagData:{},
            filterItemQuantity: 0, //用來檢查有沒有勾選篩選項目，如果大於0，就需要當作條件，並且顯示數量在filterBtn右上角
            functionTagsSelected: [],   //tag的選擇項目
            attributeTagsSelected: [],  //tag的選擇項目
            otherTagsSelected: [],      //tag的選擇項目

            old_functionTagsSelected: [],   //tag的選擇項目，舊的選項
            old_attributeTagsSelected: [],  //tag的選擇項目，舊的選項
            old_otherTagsSelected: [],      //tag的選擇項目，舊的選項
        }
    }

    componentDidMount() {
        this.getFabricDatas()
        this.getTagsForFabric()
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    componentWillReceiveProps(nextProps) {
        const {
            fabricList: thisFabricList,
            textipFabricList: thisTextipFabricList,
            selectedFabrics: thisSelectedFabrics,
            multiple: thisMultiple,
        } = this.props
        const { fabricList, textipFabricList, selectedFabrics, multiple ,fabricTagData} = nextProps
        if (thisFabricList !== fabricList) {
            this.setState({
                fabricList: fabricList.data,
                pageInfo: fabricList.info,
                loading: false,
            })
        }
        if (thisTextipFabricList !== textipFabricList) {
            this.setState({
                textipFabricList: textipFabricList.data,
                textipPageInfo: textipFabricList.info,
                loading: false,
            })
        }
        
        if (thisSelectedFabrics !== selectedFabrics) {
            let fabricSelectData = []
            selectedFabrics.map((item) => {
                fabricSelectData.push(item)
            })
            this.setState({
                fabricSelectData: fabricSelectData,
            })
        }
        if (thisMultiple !== multiple) {
            this.setState({
                multiple: multiple,
            })
        }

        if(fabricTagData != this.state.fabricTagData  ){
            this.setState({
                fabricTagData:fabricTagData,
            })
            if(!this.state.fabricId){
                this.setState({
                    loading:false
                })
            }
        }
    }
    setCloseFilter(){
        const { functionTagsSelected ,attributeTagsSelected ,otherTagsSelected} = this.state
        const {old_functionTagsSelected ,old_attributeTagsSelected ,old_otherTagsSelected} = this.state
        let mfunctionTagsSelected = []
        for(let i=0;i<old_functionTagsSelected.length;i++){
            mfunctionTagsSelected.push(old_functionTagsSelected[i])
        }
        let mattributeTagsSelected = []
        for(let j=0;j<old_attributeTagsSelected.length;j++){
            mattributeTagsSelected.push(old_attributeTagsSelected[j])
        }
        let motherTagsSelected = []
        for(let k=0;k<old_otherTagsSelected.length;k++){
            motherTagsSelected.push(old_otherTagsSelected[k])
        }
        this.setState({
            functionTagsSelected:mfunctionTagsSelected,
            attributeTagsSelected:mattributeTagsSelected,
            otherTagsSelected:motherTagsSelected
        })

    }
    setOpenFilter(){
        const { functionTagsSelected ,attributeTagsSelected ,otherTagsSelected} = this.state
        const {old_functionTagsSelected ,old_attributeTagsSelected ,old_otherTagsSelected} = this.state

        let mfunctionTagsSelected = []
        for(let i=0;i<functionTagsSelected.length;i++){
            mfunctionTagsSelected.push(functionTagsSelected[i])
        }
        let mattributeTagsSelected = []
        for(let j=0;j<attributeTagsSelected.length;j++){
            mattributeTagsSelected.push(attributeTagsSelected[j])
        }
        let motherTagsSelected = []
        for(let k=0;k<otherTagsSelected.length;k++){
            motherTagsSelected.push(otherTagsSelected[k])
        }
        this.setState({
            old_functionTagsSelected:mfunctionTagsSelected,
            old_attributeTagsSelected:mattributeTagsSelected,
            old_otherTagsSelected:motherTagsSelected
        })
    }

    getTagsForFabric(){
        //去獲取要用來顯示的資料
        this.setState({ loading: true })
        const authData = getAuth()
        const params = { memberId: authData.id, token: authData.token }
        this.props.getTagForFabric(params)
    }

    apiParams() {
        const { keyword, fabricDataType, page, textipPage ,functionTagsSelected,attributeTagsSelected,otherTagsSelected} = this.state
        const authData = getAuth()
        let params = {
            memberId: authData.id,
            token: authData.token,
            page: fabricDataType === 'new' ? page : textipPage,
            pageSize: 12,
        }
        if (keyword) {
            //如果有打字需要搜尋，就傳第一頁面，不然傳大於１就一定找不到
            params = Object.assign(params, { query: keyword ,page: 1 })
        }
        if (functionTagsSelected.length > 0) {
            params = Object.assign(params, { functionTags: functionTagsSelected })
        }
        if (attributeTagsSelected.length > 0) {
            params = Object.assign(params, { attributeTags: attributeTagsSelected })
        }
        if (otherTagsSelected.length > 0) {
            params = Object.assign(params, { otherTags: otherTagsSelected })
        }
        return params
    }

    getFabricDatas() {
        const { fabricDataType } = this.state
        const fabricApi = fabricDataType === 'new' ? 'getMyFabricDatas' : 'getTextipFabricDatas'
        this[fabricApi]()
    }

    getMyFabricDatas() {
        this.setState({ loading: true })
        const authData = getAuth()
        const { getMyFabricDatas } = this.props
        if (!isEmpty(authData)) {
            const params = this.apiParams()
            getMyFabricDatas(params, res => {
                if (res.error) {
                    this.setState({
                        fabricList: [],
                    })
                    const errorData = res.payload.response
                    alert(errorData.errorMessage)
                }
            })
        } else {
            this.setState({ loading: false })
        }
    }

    getTextipFabricDatas() {
        this.setState({ loading: true })
        const authData = getAuth()
        const { getTextipFabricDatas } = this.props
        if (!isEmpty(authData)) {
            const params = this.apiParams()
            getTextipFabricDatas(params, res => {
                if (res.error) {
                    this.setState({
                        textipFabricList: [],
                    })
                    const errorData = res.payload.response
                    alert(errorData.errorMessage)
                }
            })
        } else {
            this.setState({ loading: false })
        }
    }

    handleFabricSearchChange(e) {
        const { target } = e
        const { value } = target
        this.setState({
            keyword: value,
        })
    }

    selectFabricItem(fabricId, item) {
        
        const { fabricSelectData, multiple } = this.state
        let newFabricSelectData = []
        if (multiple) {
            newFabricSelectData = fabricSelectData
            // 多選
            if(fabricSelectData.length>0){
                for(let i=0;i<newFabricSelectData.length;i++){
                    const iitem = newFabricSelectData[i]
                    const itemId = iitem.id
                    if (fabricId!=itemId) {
                        //找看看有沒有，如果找不到，就要將它加入陣列內
                        if(i==newFabricSelectData.length-1){
                            //如果最後一個還是找不到，就需要將點擊的項目加入
                            newFabricSelectData = newFabricSelectData.push(item)
                        }
                    }else{
                        //有找到，所以要將它從陣列內刪除
                        newFabricSelectData = newFabricSelectData.splice(i,1)
                        break
                    }
                }
            }else{
                
                newFabricSelectData.push(item)
            }
            this.setState({
                fabricSelectData: fabricSelectData,
            })
            
        } else {
            // 單選
            newFabricSelectData.push(item)
            this.setState({
                fabricSelectData: newFabricSelectData,
            })
        }
        
    }

    confirmSelect() {
        const { confirmSelect } = this.props
        const { fabricSelectData } = this.state
        if (confirmSelect) {
            confirmSelect(fabricSelectData)
        }
    }

    goPage(page) {
        const { fabricDataType } = this.state
        const pageParams = fabricDataType === 'new' ? 'page' : 'textipPage'
        const fabricApi = fabricDataType === 'new' ? 'getMyFabricDatas' : 'getTextipFabricDatas'
        this.setState({
            [pageParams]: page
        })
        setTimeout(() => {
            this[fabricApi]()
        }, 100);
    }

    clickFabricDataType(type) {
        this.setState({
            fabricDataType: type
        })
        setTimeout(() => {
            this.getFabricDatas()
        }, 100);
    }

    checkIsSelect(itemId){
        const {fabricSelectData} = this.state
        let isActive = false
        for(let i=0;i<fabricSelectData.length;i++){
            const item = fabricSelectData[i]
            const iid = item.id
            if(itemId==iid){
                isActive = true
            }
        }
        return isActive
    }
    checkIsActiveCusTags(cusTagsLabel, cusTagsId) {
        for (let i = 0; i < this.state[cusTagsLabel].length; i++) {
            const itemId = this.state[cusTagsLabel][i]
            if (itemId === cusTagsId) return true
        }
        return false
    }

    clickCusTagsItem(cusTagsLabel, cusTagsId) {
        const isActive = this.checkIsActiveCusTags(cusTagsLabel, cusTagsId)
        if (isActive) {
            //這邊從陣列內移除此tagID
            for (let i = 0; i < this.state[cusTagsLabel].length; i++) {
                const oldId = this.state[cusTagsLabel][i]
                if (cusTagsId === oldId) {
                    this.state[cusTagsLabel].splice(i, 1)
                    this.setState({ [cusTagsLabel]: this.state[cusTagsLabel] })
                    break
                }
            }
        } else {
            //把tagID加入陣列內
            this.state[cusTagsLabel].push(cusTagsId)
            this.setState({ [cusTagsLabel]: this.state[cusTagsLabel] })
        }
    }

    //產生一個屬性的勾選區域
    cusTagsListView(cusTagsLabel) {

        const cusTagsLabelName = `${cusTagsLabel}TagsSelected`
        const cusTagArrayKeyName = `${cusTagsLabel}TagsList`
        const cusTagsDataLabelName = `${cusTagsLabel}Tags`
        const {fabricTagData } = this.state
        // const fabricListData = fabricDataType === 'new' ? listMyFabric : listTextipFabric
        let cusTagsListView = null
        if (fabricTagData[cusTagArrayKeyName]) {
            
            cusTagsListView = fabricTagData[cusTagArrayKeyName].map((item, index) => {
                const isActive = this.checkIsActiveCusTags(cusTagsLabelName, item.id)
                const activeClass = isActive ? 'active' : ''
                const checkBoxIcon = isActive ? require('../images/checkbox-check.png') : require('../images/checkbox.png')
                return (
                    <div
                        key={`cusTagsItem_${index}`}
                        className={`tagItem ${activeClass}`}
                        onClick={() => {
                            this.clickCusTagsItem(cusTagsLabelName, item.id)
                        }}
                    >
                        <div className="tagCheckBox"><img src={checkBoxIcon} /></div>
                        <div className="tagText">{item.text}</div>
                    </div>
                )
            })
        }
        return cusTagsListView
    }

    getFilterView(){
        const {showFilterView ,fabricTagData} = this.state
        
        
        const showClass = showFilterView ? 'show' : 'hide'
        const functionTagsListView = this.cusTagsListView('function')
        const attributeTagsListView = this.cusTagsListView('attribute')
        const otherTagsListView = this.cusTagsListView('other')
        return (
            <div className={`filterViewContainer ${showClass}`} >
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%',height:'auto',overflowY:'auto',marginBottom:'50px'}}>
                    <div className="filterContentBlock">
                        <div className="filterContentTitle">{Lang.FABRIC_FILTER_FUNCTION}</div>
                        <div className="filterContentBox">
                            {functionTagsListView}
                        </div>
                    </div>
                    <div className="filterContentBlock">
                        <div className="filterContentTitle">{Lang.FABRIC_FILTER_ATTRIBUTE}</div>
                        <div className="filterContentBox">
                            {attributeTagsListView}
                        </div>
                    </div>
                    <div className="filterContentBlock">
                        <div className="filterContentTitle">{Lang.FABRIC_FILTER_OTHERS}</div>
                        <div className="filterContentBox">
                            {otherTagsListView}
                        </div>
                    </div>
                </div>

                <div style={{position:'absolute',bottom:'0px',display:'flex',flexDirection:'row' ,marginBottom:'10px',justifyContent:'flex-end',alignItems:'flex-end'}}>
                    <div className="bottomBtnCancel" onClick={()=>{
                        this.setCloseFilter()
                        this.setState({showFilterView:false
                        })
                    }}>
                        {Lang.FABRIC_DETAIL_BTN_CANCEL}
                    </div>
                    <div className="bottomBtnSubmit" onClick={()=>{
                        this.clickFilterSubmit()
                    }}>
                        {Lang.FABRIC_DETAIL_BTN_CONFIRM}
                    </div>
                </div>
            </div>
        )
    }
    clickFilterSubmit(){
        const {functionTagsSelected,attributeTagsSelected,otherTagsSelected} = this.state
        //先去檢查有幾個條件被勾選
        let checkItemQuantity = 0
        checkItemQuantity = functionTagsSelected.length + attributeTagsSelected.length + otherTagsSelected.length
        this.setState({showFilterView:false ,filterItemQuantity: checkItemQuantity})
        setTimeout(()=>{this.getFabricDatas()},300)
    }

    render() {
        const { className } = this.props
        const { keyword, fabricSelectData, fabricList, textipFabricList, pageInfo, textipPageInfo, page, textipPage, loading, fabricDataType ,showFilterView ,fabricTagData } = this.state
        
        const fabricSelectIds = Object.keys(fabricSelectData)
        const emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.FABRIC_EMPTY_TITLE} content={Lang.FABRIC_EMPTY_CONTENT} />
                <div className="btnBox">
                    <Link className="btn btn-primary" to={`/fabric`}>
                        {Lang.FABRIC_CREATE_FABRIC}
                    </Link>
                </div>
            </div>
        )
        const fabricListData = fabricDataType === 'new' ? fabricList : textipFabricList
        const thisPage = fabricDataType === 'new' ? page : textipPage
        const thisPageInfo = fabricDataType === 'new' ? pageInfo : textipPageInfo
        const loadingView = fabricListData === null || loading ? <Loading /> : null
        const filterView = this.getFilterView()
        let contentView = null
        if (fabricListData !== null) {
            if (fabricListData.length > 0) {
                const fabricListView = fabricListData.map((item, index) => {
                    //檢查項目有沒有被選取
                    const activeClass = this.checkIsSelect(item.id)?'active' : ''
                    return (
                        <div
                            key={`fabricItem_${index}`}
                            className={`fabricItem col-6 col-md-2 layoutBlock ${activeClass}`}
                            onClick={() => {
                                this.selectFabricItem(item.id, item)
                            }}
                        >
                            <div className="card">
                                <div className="fabricImage">
                                    <Image src={item.images} className="card-img-top" />
                                </div>
                                <div className="card-body">
                                    <p className="card-text textOverflow">{item.headline}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
                contentView = (
                    <div id="fabricListViewContainer" style={{width:'100%'}}>
                        <div className="fabricListView row">
                            {fabricListView}
                        </div>
                        <Pagination data={thisPageInfo} page={thisPage} goPage={this.goPage} />
                    </div>
                )
            } else {
                contentView = emptyView
            }
        }
        return (
            <div id="fabricSelectContainer" className={className} style={{
                // position:'relative'
                }}>
                {loadingView}
                <div className="tabBlock">
                    <div className="tabBox">
                        <div
                            className={`tabItem ${fabricDataType === 'new' ? 'active' : ''}`}
                            onClick={() => {
                                this.clickFabricDataType('new')
                            }}
                        >
                            {Lang.FABRIC_DATATYPE_NEW}
                        </div>
                        <div
                            className={`tabItem ${fabricDataType === 'textip' ? 'active' : ''}`}
                            onClick={() => {
                                this.clickFabricDataType('textip')
                            }}
                        >
                            {Lang.FABRIC_DATATYPE_TEXTIP}
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',width:'100%',paddingRight:'10px'}}>
                    <div className="searchBarBlock">
                        <div className="input-group">
                            <Input
                                type="text"
                                name="keyword"
                                ref="keyword"
                                placeholder={Lang.FABRIC_LIST_SEARCH}
                                className="searchBox"
                                onChange={this.handleFabricSearchChange}
                                idName="input_keyword"
                                value={keyword}
                            />
                            <div className="input-group-append searchBtnBox">
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.getFabricDatas()
                                    }}
                                    text={Lang.FABRIC_SEARCH_SUBMIT}
                                />
                            </div>
                        </div>
                    </div>
                    {(fabricTagData&& fabricTagData!={}) &&
                        <div className="filterBtn" onClick={()=>{
                            this.setOpenFilter()
                            this.setState({showFilterView:true
                            })
                        }}>
                            <img src={require('../images/icon_download.png')} className="imgIcon" />
                            <div>Filter</div>
                            {this.state.filterItemQuantity>0 &&
                                <div className="filterItemNotify">{this.state.filterItemQuantity}</div>
                            }
                        </div>
                    }
                </div>
                
                {contentView}
                {showFilterView && filterView}
                <div className="confirmBtnBox">
                    <Button
                        type="button"
                        className="btn btn-primary confirmBtn"
                        onClick={this.confirmSelect}
                        text={Lang.FABRIC_DETAIL_BTN_CONFIRM}
                    />
                </div>
            </div>
        )
    }
}

FabricList.propTypes = {
    getMyFabricDatas: PropTypes.func.isRequired,
    getTextipFabricDatas: PropTypes.func.isRequired,
    fabricList: PropTypes.object,
    textipFabricList: PropTypes.object,
    className: PropTypes.string,
    confirmSelect: PropTypes.func,
    selectedFabrics: PropTypes.array,
    multiple: PropTypes.bool,
}

const mapStateToProps = () => (state) => {
    return {
        fabricList: state.myFabric.listMyFabric,
        textipFabricList: state.textipFabric.listTextipFabric,
        fabricTagData: state.myFabric.fabricTagData,
    }
}

const mapDispatchToProps = {
    getMyFabricDatas,
    getTextipFabricDatas,
    getTagForFabric
}

export default connect(mapStateToProps, mapDispatchToProps)(FabricList)
