import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    color: [],
}

export default function (state = defaultState, action) {
    switch (action.type) {
        // 顏色
        case ActionTypes.CATEGORY_COLOR_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.CATEGORY_COLOR_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.CATEGORY_COLOR_SUCCESS:
            let color = action.payload.response
            return Object.assign({}, state, {
                isDataFetching: false,
                color: color
            })

        default:
            return state
    }
}
