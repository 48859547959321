import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

// like
function fetchAddCollect(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.LIKE_ADD_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.LIKE_ADD_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.LIKE_ADD_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'POST',
            endpoint: '/collect/' + params.fabricId,
            body: JSON.stringify(params),
        },
    }
}

export function addCollect(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchAddCollect(params)).then((res) => {
            callback(res)
        })
    }
}
// cancel like
function fetchCancelCollect(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.LIKE_CANCEL_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.LIKE_CANCEL_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.LIKE_CANCEL_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'DELETE',
            endpoint: '/collect/' + params.fabricId,
            body: JSON.stringify(params),
        },
    }
}

export function cancelCollect(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchCancelCollect(params)).then((res) => {
            callback(res)
        })
    }
}
// like list
function fetchGetCollectList(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.LIKE_LIST_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.LIKE_LIST_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.LIKE_LIST_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/member/collect/' + params.memberId,
            query: params,
        },
    }
}

export function getCollectList(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetCollectList(params)).then((res) => {
            callback(res)
        })
    }
}
