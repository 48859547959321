import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    fabricDetailData: {},
}

export default function (state = defaultState, action) {
    switch (action.type) {
        // 布料明細
        case ActionTypes.FABRICDETAIL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.FABRICDETAIL_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.FABRICDETAIL_SUCCESS:
            const data = action.payload.response
            return Object.assign({}, state, {
                isDataFetching: false,
                fabricDetailData: data,
            })
        case ActionTypes.FABRICDETAIL_VIEW_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.FABRICDETAIL_VIEW_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.FABRICDETAIL_VIEW_SUCCESS:
            return Object.assign({}, state, {
                isDataFetching: false
            })

        default:
            return state
    }
}
