import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import * as Lang from '../Lang/default';
import { getAuth } from '../Libs/Lib';
import MemberBase from '../containers/MemberBase';
import Loading from '../components/Loading';
import ContentBlock from '../components/ContentBlock';
import Form from '../components/Form';
import {
  getMyFabricWithId,
  deleteMyFabricWithId,
  getTagForFabric,
} from '../actions/myFabric';
import PPTXView from '../components/PPTXView';

import '../style/fabricEdit.sass';

const DETAIL_IMAGE_MAX_LENGTH = 10;

class FabricEdit extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.previewImages = this.previewImages.bind(this);
    this.changeFile = this.changeFile.bind(this);
    this.getMyFabricWithId = this.getMyFabricWithId.bind(this);
    this.removeImageFromIndex = this.removeImageFromIndex.bind(this);
    this.deleteFabric = this.deleteFabric.bind(this);
    this.getTagsForFabric = this.getTagsForFabric.bind(this);
    this.state = {
      fabricId: props.params.fabricId,
      fabricData: null,
      loading: false,
      detailImageSrcList: [],
      fabricTagData: {},
    };
  }

  componentDidMount() {
    const { fabricId } = this.state;
    if (fabricId) {
      this.getMyFabricWithId(fabricId);
    }
    this.getTagsForFabric();
  }

  componentWillReceiveProps(nextProps) {
    const { myFabricDataItem, fabricTagData } = nextProps;
    if (myFabricDataItem && myFabricDataItem.id == this.state.fabricId) {
      let myDefaultImageDetailList = [];
      if (myFabricDataItem.images_detail) {
        myFabricDataItem.images_detail.map((item) => {
          myDefaultImageDetailList.push(item.filePath);
        });
        this.setState({
          loading: false,
          fabricData: myFabricDataItem,
          detailImageSrcList: myDefaultImageDetailList,
        });
      }
    }
    if (fabricTagData != this.state.fabricTagData) {
      this.setState({
        fabricTagData: fabricTagData,
      });
      if (!this.state.fabricId) {
        this.setState({
          loading: false,
        });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  getMyFabricWithId(id) {
    this.setState({ loading: true });
    const authData = getAuth();
    const params = { memberId: authData.id, token: authData.token, id: id };
    this.props.getMyFabricWithId(params);
  }

  getTagsForFabric() {
    //去獲取要用來顯示的資料
    this.setState({ loading: true });
    const authData = getAuth();
    const params = { memberId: authData.id, token: authData.token };
    this.props.getTagForFabric(params);
  }

  removeImageFromIndex(indexOriginal) {
    const { detailImageSrcList } = this.state;
    let myDefaultImageDetailList = [];
    detailImageSrcList.map((item, index) => {
      if (index != indexOriginal) myDefaultImageDetailList.push(item);
    });
    this.setState({
      detailImageSrcList: myDefaultImageDetailList,
    });
  }

  formConfig() {
    const { fabricData, fabricTagData } = this.state;

    let fabricNo = '';
    let headline = '';
    fabricNo = fabricData ? fabricData.fabricNo : '';
    headline = fabricData ? fabricData.headline : '';

    let mConfig = {
      config: {},
      data: [
        {
          component: 'Input',
          type: 'text',
          name: 'fabricNo',
          value: fabricNo,
          placeholder: Lang.FABRIC_DETAIL_TXT_FABRICNO,
          role: [{ name: 'required' }],
        },
        {
          component: 'Input',
          type: 'text',
          name: 'headline',
          value: headline,
          placeholder: Lang.FABRIC_DETAIL_TXT_TITLE,
          role: [{ name: 'required' }],
        },
        {
          component: 'Input',
          type: 'text',
          name: 'contents',
          value: fabricData ? fabricData.contents : '',
          placeholder: Lang.FABRIC_DETAIL_TXT_CONTENTS,
        },
        {
          component: 'Input',
          type: 'text',
          name: 'cuttable',
          value: fabricData ? fabricData.cuttable : '',
          placeholder: Lang.FABRIC_DETAIL_TXT_CUTTABLE,
        },
        {
          component: 'Input',
          type: 'text',
          name: 'width',
          value: fabricData ? fabricData.width : '',
          placeholder: Lang.FABRIC_DETAIL_TXT_WIDTH,
        },
        {
          component: 'Input',
          type: 'text',
          name: 'weight',
          value: fabricData ? fabricData.weight : '',
          placeholder: Lang.FABRIC_DETAIL_TXT_WEIGHT,
        },
        // {
        //     component: 'Input',
        //     type: 'text',
        //     name: 'function',
        //     value: fabricData ? fabricData.function : '',
        //     placeholder: Lang.FABRIC_DETAIL_TXT_FUNCTION,
        // },
        {
          component: 'AutoCompleteCustom',
          name: 'functions',
          value: fabricData ? fabricData.functionTags : '',
          showValueKey: 'text',
          placeholder: Lang.FABRIC_DETAIL_TXT_FUNCTION,
          completeData: fabricTagData.functionTagsList,
        },
        {
          component: 'AutoCompleteCustom',
          name: 'attribute',
          value: fabricData ? fabricData.attributeTags : '',
          showValueKey: 'text',
          placeholder: Lang.FABRIC_DETAIL_TXT_ATTRIBUTE,
          completeData: fabricTagData.attributeTagsList,
        },
        {
          component: 'AutoCompleteCustom',
          name: 'others',
          value: fabricData ? fabricData.otherTags : '',
          showValueKey: 'text',
          placeholder: Lang.FABRIC_DETAIL_TXT_OTHERS,
          completeData: fabricTagData.otherTagsList,
        },
        {
          component: 'Input',
          type: 'text',
          name: 'finish',
          value: fabricData ? fabricData.finish : '',
          placeholder: Lang.FABRIC_DETAIL_TXT_FINISH,
        },
        {
          component: 'Input',
          type: 'text',
          name: 'quality',
          value: fabricData ? fabricData.quality : '',
          placeholder: Lang.FABRIC_DETAIL_TXT_QUALITY,
        },
        {
          component: 'Input',
          type: 'text',
          name: 'price',
          format: 'number',
          value:
            fabricData && fabricData.price ? fabricData.price.toString() : '',
          placeholder: Lang.FABRIC_DETAIL_TXT_PRICE,
        },
        {
          component: 'TextareaHashtag',
          name: 'cusTags',
          value: fabricData ? fabricData.cusTags : '',
          placeholder: Lang.FABRIC_DETAIL_TXT_CUSTAG,
        },
      ],
    };
    return mConfig;
  }

  handleSubmit(e, data) {
    // e.preventDefault()
    const { detailImageSrcList, fabricId } = this.state;
    this.setState({ loading: true });
    const authData = getAuth();
    const params = {
      memberId: authData.id,
      token: authData.token,
      fabricNo: data.fabricNo,
      headline: data.headline,
      images: detailImageSrcList,
      contents: data.contents,
      cuttable: data.cuttable,
      width: data.width,
      weight: data.weight,
      functionTags: data.functions,
      attributeTags: data.attribute,
      otherTags: data.others,
      finish: data.finish,
      quality: data.quality,
      price: data.price,
      cusTags: data.cusTags,
    };
    //這邊進行資料上傳，編輯個人布料
    const config = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    const apiUrl = fabricId
      ? `${process.env.REACT_APP_API_ROOT}/myFabric/` + fabricId
      : `${process.env.REACT_APP_API_ROOT}/myFabric`;
    fetch(apiUrl, config)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          this.setState({
            loading: false,
          });
          window.location.href = '/fabric';
        } else {
          alert(res.errorMessage);
        }
      });
  }

  changeFile(e) {
    this.previewImages(e.target.files);
  }

  previewImages(fileList) {
    const files = Array.from(fileList);

    if (files && files.length > 0) {
      files.map((item, index) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onloadend = () => {
          if (this.state.detailImageSrcList.length < DETAIL_IMAGE_MAX_LENGTH) {
            let currentDetailImageSrcList = Object.assign(
              [],
              this.state.detailImageSrcList
            );
            currentDetailImageSrcList.push(reader.result);
            this.setState({ detailImageSrcList: currentDetailImageSrcList });
          }
        };
      });
    }
  }

  deleteFabric() {
    const { fabricId } = this.state;
    this.setState({ loading: true });
    const authData = getAuth();
    const params = {
      memberId: authData.id,
      token: authData.token,
      id: fabricId,
    };
    this.props.deleteMyFabricWithId(params, (res) => {
      this.setState({ loading: false });
      if (res.payload.status == 'success') {
        //刪除成功
        window.location.href = '/fabric';
      }
    });
  }

  render() {
    console.log(this.props);
    const { loading, fabricData, detailImageSrcList, fabricId, fabricTagData } =
      this.state;
    const loadingView =
      (fabricId && fabricData === null) || loading ? <Loading /> : null;
    let contentView = null;
    const formConfig = this.formConfig();
    const itemAdd = (
      <label className="itemContainer itemAdd">
        <input
          type="file"
          multiple
          name="file"
          style={{ display: 'none' }}
          onChange={this.changeFile}
        />
        <img src={require('../images/icon_plus.png')} />
      </label>
    );
    const detailImages = detailImageSrcList.map((item, index) => {
      return (
        <div className="itemContainer" key={'stock_' + index}>
          <div
            className="itemDeleteBox"
            onClick={() => {
              // 刪除這張圖片
              this.removeImageFromIndex(index);
            }}
          >
            <img
              src={require('../images/icon_x.png')}
              className="itemDelete"
              alt=""
            />
          </div>
          <img src={item} className="itemBox" alt="" />
        </div>
      );
    });
    const submitBtnText = fabricId
      ? Lang.FABRIC_UPDATE_SUBMIT
      : Lang.FABRIC_CREATE_SUBMIT;
    if (!loading) {
      const btnArray = [
        {
          text: Lang.FABRIC_DETAIL_BTN_DELETE,
          clickLink: this.deleteFabric,
          className: 'btn-danger',
        },
      ];
      contentView = (
        <div className="editFabricContainer">
          <div className="titleBlock">{Lang.FABRIC_TITLE}</div>
          <div className="fabricContentContainer">
            <div className="layoutBlock col-12 col-md-6 fabricImageListBlock">
              <ContentBlock title={Lang.FABRIC_IMAGELIST}>
                {detailImages}
                {itemAdd}
              </ContentBlock>
            </div>
            <div className="layoutBlock col-12 col-md-6 fabricColumnBlock">
              {fabricData !== null && (
                <PPTXView
                  imageList={detailImageSrcList}
                  DataList={fabricData}
                  TagList={fabricTagData}
                />
              )}
              <ContentBlock title={Lang.FABRIC_INFORMATION}>
                <Form
                  className="editFabricForm"
                  onSubmit={this.handleSubmit}
                  formConfig={formConfig}
                  btnText={submitBtnText}
                  cancelLink="/fabric"
                  btnArray={btnArray}
                />
              </ContentBlock>
            </div>
          </div>
        </div>
      );
    }
    return (
      <MemberBase>
        {loadingView}
        {contentView}
      </MemberBase>
    );
  }
}

FabricEdit.propTypes = {
  myFabricDataItem: PropTypes.object,
};

const mapStateToProps = () => (state) => {
  return {
    myFabricDataItem: state.myFabric.myFabricDataItem,
    fabricTagData: state.myFabric.fabricTagData,
  };
};

const mapDispatchToProps = {
  getMyFabricWithId,
  deleteMyFabricWithId,
  getTagForFabric, // 獲取可以用的tag資料
};

export default connect(mapStateToProps, mapDispatchToProps)(FabricEdit);
