import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case ActionTypes.PRESENTATIONVIDEO_DELETE_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.PRESENTATIONVIDEO_DELETE_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.PRESENTATIONVIDEO_DELETE_SUCCESS:
            return Object.assign({}, state, {
                isDataFetching: false
            })

        default:
            return state
    }
}
