import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Pagination extends Component {
    constructor(props) {
        super(props)
        this.goPageClick = this.goPageClick.bind(this)

        this.state = {
            page: props.page,
            data: props.data
        }
    }

    componentWillReceiveProps(nextProps) {
        const { data: thisData, page: thisPage } = this.props
        const { data, page } = nextProps

        if (data !== thisData) {
            this.setState({
                data
            })
        }

        if (page !== thisPage) {
            this.setState({
                page
            })
        }
    }

    goPageClick(page) {
        const { goPage } = this.props
        this.setState({
            page: page
        })
        if (goPage && page) {
            goPage(page)
        }
    }

    render() {
        const { page, data } = this.state
        const totalPage = data.totalPage
        let displayPageCount = 5
        displayPageCount = totalPage > displayPageCount ? displayPageCount : totalPage
        let firstPage = 1
        let lastPage = totalPage > displayPageCount ? displayPageCount : totalPage
        const prevPage = page > 1 ? page - 1 : page
        const nextPage = page < totalPage ? page + 1 : totalPage
        const halfPageCount = Math.floor(displayPageCount / 2)
        if (page > halfPageCount) {
            firstPage = page <= (totalPage - halfPageCount) ? page - halfPageCount : totalPage - displayPageCount + 1
            lastPage = totalPage > displayPageCount ? displayPageCount : firstPage + displayPageCount - 1
        }
        if (totalPage <= 1) {
            return null
        }
        let pageItemView = []
        let i
        for (i = 0; i < displayPageCount; i++) {
            const thisPage = firstPage + i
            const activeClass = page === thisPage ? 'active' : ''
            pageItemView.push(
                <li className={`page-item ${activeClass}`} key={`pageItem_${i}`}>
                    <a className="page-link" onClick={() => {
                        this.goPageClick(thisPage)
                    }}>{thisPage}</a>
                </li>
            )
        }
        return (
            <nav>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" onClick={() => {
                                this.goPageClick(prevPage)
                            }}>
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        {pageItemView}
                        <li className="page-item">
                            <a className="page-link" onClick={() => {
                                this.goPageClick(nextPage)
                            }}>
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                    <div style={{marginLeft:'10px'}}>{data.totalRow} pieces of data </div>
                </div>
            </nav>
        )
    }
}

Pagination.propTypes = {
    page: PropTypes.number,
    data: PropTypes.object,
    goPage: PropTypes.func
}
