import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

function submitLogin(params) {
    return {
        [CALL_API]: {
            types: [
                { type: ActionTypes.MEMBERLOGIN_REQUEST, meta: { params: Object.assign({}, params) } },
                { type: ActionTypes.MEMBERLOGIN_SUCCESS, meta: { params: Object.assign({}, params) } },
                { type: ActionTypes.MEMBERLOGIN_FAILURE, meta: { params: Object.assign({}, params) } }
            ],
            method: 'POST',
            endpoint: '/member/login',
            query: params
        }
    }
}

export function login(params, callback = () => {}) {
    return dispatch => {
        dispatch(submitLogin(params)).then(res => {
            callback(res)
        })
    }
}

function submitLogout() {
    return {
        type: ActionTypes.MEMBERLOGOUT_SUCCESS
    }
}

export function logout(callback = () => {}) {
    return dispatch => {
        dispatch(submitLogout())
    }
}

function fetchGetMemberData(memberId, params) {
    return {
        [CALL_API]: {
            types: [
                { type: ActionTypes.MEMBERDETAIL_REQUEST, meta: { params: Object.assign({}, params) } },
                {
                    type: ActionTypes.MEMBERDETAIL_SUCCESS,
                    meta: { memberId: memberId, params: Object.assign({}, params) }
                },
                { type: ActionTypes.MEMBERDETAIL_FAILURE, meta: { params: Object.assign({}, params) } }
            ],
            method: 'GET',
            endpoint: '/member/' + memberId,
            query: params
        }
    }
}

export function getMemberData(memberId, params) {
    return dispatch => {
        dispatch(fetchGetMemberData(memberId, params))
    }
}
