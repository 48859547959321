import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Button extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  }

  render() {
    const { text, type, className, icon } = this.props;
    const buttonType = type || 'button';
    const buttonClassName = className || '';
    return (
      <button
        className={buttonClassName}
        type={buttonType}
        onClick={this.onClick}
      >
        {icon || null}
        {text}
      </button>
    );
  }
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object,
};
