import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Alert extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alertClass: ''
        }
    }

    componentDidMount() {
        const that = this
        setTimeout(function() {
            that.setState({
                alertClass: 'show'
            })
        }, 1)
        setTimeout(function() {
            that.setState({
                alertClass: 'hide'
            })
        }, 3000)
    }

    componentWillUpdate(nextProps, nextState) {
        const { close } = this.props
        const { alertClass: thisAlertClass } = this.state
        const { alertClass } = nextState
        if (alertClass !== thisAlertClass && alertClass === 'hide') {
            setTimeout(function() {
                close()
            }, 1000)
        }
    }

    render() {
        const { alertClass } = this.state
        const { type, title, content } = this.props
        const titleView =
            title && type ? (
                <div className="alertTitle">
                    {type} - {title}
                </div>
            ) : null
        return (
            <div className={`alertBox ${alertClass}`}>
                {titleView}
                <div className="alertContent">{content}</div>
            </div>
        )
    }
}

Alert.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    close: PropTypes.func.isRequired
}
