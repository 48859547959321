import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { getAuth, isEmpty } from '../Libs/Lib'
import * as Lang from '../Lang/default'
import { getMyFabricDatas } from '../actions/myFabric'
import { getTrendBoardList } from '../actions/trendboard'
import Input from './Input'
import Button from './Button'
import Image from './Image'
import Empty from './Empty'
import Loading from './Loading'
import Pagination from './Pagination'


class TrendBoardList extends Component {
    constructor(props) {
        super(props)
        this.handleFabricSearchChange = this.handleFabricSearchChange.bind(this)
        this.selectTrendBoardItem = this.selectTrendBoardItem.bind(this)
        this.confirmSelect = this.confirmSelect.bind(this)
        this.goPage = this.goPage.bind(this)
        this.getTrendBoardListDatas = this.getTrendBoardListDatas.bind(this)
        this.apiParams = this.apiParams.bind(this)
        this.clickFabricDataType = this.clickFabricDataType.bind(this)
        this.state = {
            trendBoardListData: null,
            loading: false,
            keyword: '',
            fabricSelectData: {},
            fabricList: null,
            pageInfo: {},
            multiple: props.multiple || false,
            page: 1,
            trendBoardSelectData: {}
        }
    }

    componentDidMount() {
        this.getTrendBoardListDatas()
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    componentWillReceiveProps(nextProps) {
        const {
            trendBoardListData: thisTrendBoardListData,

            trendBoardSelectData: thisTrendBoardSelectData,
            multiple: thisMultiple,
        } = this.props
        const { trendBoardListData, multiple ,trendBoardSelectData} = nextProps
        if (thisTrendBoardListData !== trendBoardListData) {
            this.setState({
                trendBoardListData: trendBoardListData.data,
                // pageInfo: fabricList.info,
                loading: false,
            })
        }
        if (thisTrendBoardSelectData !== trendBoardSelectData) {
            let tbSelectData = {}
            trendBoardSelectData.map((item) => {
                tbSelectData[item.id] = item
            })
            this.setState({
                trendBoardSelectData: tbSelectData,
            })
        }
        if (thisMultiple !== multiple) {
            this.setState({
                multiple: multiple,
            })
        }
    }

    apiParams() {
        const { keyword, fabricDataType, page, textipPage } = this.state
        const authData = getAuth()
        let params = {
            memberId: authData.id,
            token: authData.token,
            // page: fabricDataType === 'new' ? page : textipPage,
            pageSize: 12,
        }
        // if (keyword) {
        //     //如果有打字需要搜尋，就傳第一頁面，不然傳大於１就一定找不到
        //     params = Object.assign(params, { query: keyword ,page: 1 })
        // }
        return params
    }

    getTrendBoardListDatas() {
        this.setState({ loading: true })
        const authData = getAuth()
        
        if (!isEmpty(authData)) {
            const params = this.apiParams()
            this.props.getTrendBoardList(params)
        } else {
            this.setState({ loading: false })
        }
    }

    handleFabricSearchChange(e) {
        const { target } = e
        const { value } = target
        this.setState({
            keyword: value,
        })
    }

    selectTrendBoardItem(trendId, item) {
        const { trendBoardSelectData, multiple } = this.state
        let newTrendBoardSelectData = {}
        if (multiple) {
            newTrendBoardSelectData = trendBoardSelectData
            // 多選
            if (!newTrendBoardSelectData[trendId]) {
                newTrendBoardSelectData[trendId] = item
            } else {
                delete newTrendBoardSelectData[trendId]
            }
        } else {
            // 單選
            newTrendBoardSelectData[trendId] = item
        }
        this.setState({
            trendBoardSelectData: newTrendBoardSelectData,
        })
    }

    confirmSelect() {
        const { confirmSelect } = this.props
        const { trendBoardSelectData } = this.state
        if (confirmSelect) {
            confirmSelect(trendBoardSelectData)
        }
    }

    goPage(page) {
        const { fabricDataType } = this.state
        const pageParams = fabricDataType === 'new' ? 'page' : 'textipPage'
        const fabricApi = fabricDataType === 'new' ? 'getMyFabricDatas' : 'getTextipFabricDatas'
        this.setState({
            [pageParams]: page
        })
        setTimeout(() => {
            this[fabricApi]()
        }, 100);
    }

    clickFabricDataType(type) {
        this.setState({
            fabricDataType: type
        })
        setTimeout(() => {
            this.getFabricDatas()
        }, 100);
    }

    render() {
        const { className } = this.props
        const { keyword, fabricSelectData, trendBoardListData, textipFabricList, pageInfo, textipPageInfo, page, textipPage, loading, fabricDataType ,trendBoardSelectData} = this.state
        const trendBoardSelectIds = Object.keys(trendBoardSelectData)
        const emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.TREND_EMPTY_TITLE} content={Lang.TREND_EMPTY_CONTENT} />
                <div className="btnBox">
                    <Link className="btn btn-primary" to={`/addTrend`}>
                        {Lang.TREND_EMPTY_CONTENT}
                    </Link>
                </div>
            </div>
        )


        const loadingView = trendBoardListData === null || loading ? <Loading /> : null
        let contentView = null
        if (trendBoardListData !== null) {
            if (trendBoardListData.length > 0) {
                const trendBoardListView = trendBoardListData.map((item, index) => {
                    const activeClass = trendBoardSelectIds.indexOf(String(item.id)) !== -1 ? 'active' : ''
                    return (
                        <div
                            key={`fabricItem_${index}`}
                            className={`fabricItem col-6 col-md-2 layoutBlock ${activeClass}`}
                            onClick={() => {
                                this.selectTrendBoardItem(item.id, item)
                            }}
                        >
                            <div className="card">
                                <div className="fabricImage">
                                    <Image src={item.mainImage} className="card-img-top" />
                                </div>
                                <div className="card-body">
                                    <p className="card-text textOverflow">{item.name}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
                contentView = (
                    <div className="" style={{width:'100%'}}>
                        <div className="fabricListView row">
                            {trendBoardListView}
                        </div>
                        {/* <Pagination data={thisPageInfo} page={thisPage} goPage={this.goPage} /> */}
                    </div>
                )
            } else {
                contentView = emptyView
            }
        }
        return (
            <div id="fabricSelectContainer" className={className}>
                {loadingView}
                {/* <div className="searchBarBlock">
                    <div className="input-group">
                        <Input
                            type="text"
                            name="keyword"
                            ref="keyword"
                            placeholder={Lang.FABRIC_LIST_SEARCH}
                            className="searchBox"
                            onChange={this.handleFabricSearchChange}
                            idName="input_keyword"
                            value={keyword}
                        />
                        <div className="input-group-append searchBtnBox">
                            <Button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    this.getFabricDatas()
                                }}
                                text={Lang.FABRIC_SEARCH_SUBMIT}
                            />
                        </div>
                    </div>
                </div> */}
                {contentView}
                <div className="confirmBtnBox">
                    <Button
                        type="button"
                        className="btn btn-primary confirmBtn"
                        onClick={this.confirmSelect}
                        text={Lang.FABRIC_DETAIL_BTN_CONFIRM}
                    />
                </div>
            </div>
        )
    }
}

TrendBoardList.propTypes = {
    getTrendBoardList: PropTypes.func.isRequired,
    trendBoardList: PropTypes.object,
    className: PropTypes.string,
    confirmSelect: PropTypes.func,
    selectedTrendboards: PropTypes.array,
    multiple: PropTypes.bool,
}

const mapStateToProps = () => (state) => {
    return {
        trendBoardListData: state.trendboard.trendboardListData
        // fabricList: state.myFabric.listMyFabric,
        // textipFabricList: state.textipFabric.listTextipFabric,
    }
}

const mapDispatchToProps = {
    getTrendBoardList
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendBoardList)
