import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, matchPath } from 'react-router';
import { logout } from '../../actions/member';
import '../../style/menu.sass';
import * as Lang from '../../Lang/default';

const menuData = [
  {
    text: 'Room',
    path: '/',
    icon: 'menuIcon_room.png',
    activeIcon: 'menuIcon_room_active.png',
    identity_none: false,
    identity_normal: true,
    identity_sales: true,
    identity_manager: true,
  },
  {
    text: 'Trend',
    path: '/trend',
    icon: 'menuIcon_catalog.png',
    activeIcon: 'menuIcon_catalog_active.png',
    identity_none: false,
    identity_normal: false,
    identity_sales: true,
    identity_manager: true,
  },
  {
    text: 'Fabric',
    path: '/fabric',
    icon: 'menuIcon_fabric.png',
    activeIcon: 'menuIcon_fabric_active.png',
    identity_none: false,
    identity_normal: false,
    identity_sales: true,
    identity_manager: true,
  },
  {
    text: 'Collect',
    path: '/collect',
    icon: 'menuIcon_collect.png',
    activeIcon: 'menuIcon_collect_active.png',
    identity_none: false,
    identity_normal: true,
    identity_sales: true,
    identity_manager: true,
  },
  {
    text: 'Request',
    path: '/request',
    icon: 'menuIcon_request.png',
    activeIcon: 'menuIcon_request_active.png',
    identity_none: false,
    identity_normal: true,
    identity_sales: true,
    identity_manager: true,
  },
  {
    text: 'Catalog',
    path: '/catalog',
    icon: 'menuIcon_catalog.png',
    activeIcon: 'menuIcon_catalog_active.png',
    identity_none: false,
    identity_normal: true,
    identity_sales: true,
    identity_manager: true,
  },
  {
    text: 'Records',
    path: '/records',
    icon: 'menuIcon_record.png',
    activeIcon: 'menuIcon_record_active.png',
    identity_none: false,
    identity_normal: false,
    identity_sales: true,
    identity_manager: true,
  },
];

class Menu extends Component {
  constructor(props) {
    super(props);
    this.getLastMenuData = this.getLastMenuData.bind(this);
    this.getModifyMenu = this.getModifyMenu.bind(this);

    this.state = {
      show: props.show,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  componentWillReceiveProps(nextProps) {
    const { show: thisShow } = this.props;
    const { show } = nextProps;
    if (thisShow !== show) {
      this.setState({
        show: show,
      });
    }
  }

  getLastMenuData(menuData) {
    const { memberData } = this.props;
    let mData = menuData;
    // console.log('in getLastMenuData this.props.memberData = ',memberData)
    const mIdentity = memberData.presentationType;
    switch (memberData.presentationType) {
      case 'none':
        mData = [];
        break;
      case 'normal':
        mData = this.getModifyMenu(1);
        break;
      case 'sales':
        mData = this.getModifyMenu(2);
        break;
      case 'manager':
        mData = this.getModifyMenu(3);
        break;
      default:
        mData = [];
        break;
    }
    // if(mIdentity=='none'){
    //     return []
    // }else{

    // }
    return mData;
  }

  getModifyMenu(type) {
    //1:normal 2:sales 3:manager
    let checkKey = 'identity_normal';
    if (type == 2) {
      checkKey = 'identity_sales';
    } else if (type == 3) {
      checkKey = 'identity_manager';
    }
    let newMenuData = [];
    menuData.map((item, index) => {
      const isShow = item[checkKey];
      if (isShow) {
        newMenuData.push(item);
      }
    });
    return newMenuData;
  }

  render() {
    const { show } = this.state;
    const { logout } = this.props;
    const showClass = show ? 'show' : 'hide';
    const logoImage = show ? (
      <Link className={`logoBox ${showClass}`} to={`/`}>
        <img
          className={`logoImg ${showClass}`}
          src={require('../../images/logo.png')}
        />
      </Link>
    ) : (
      <Link className={`logoBox ${showClass}`} to={`/`}>
        <div className="logoItem">
          <img
            className={`logoImg ${showClass}`}
            src={require('../../images/logo.png')}
          />
        </div>
      </Link>
    );
    const closeMenuBtn = show ? (
      <img
        className={`closeBtn ${showClass}`}
        src={require('../../images/close-black.png')}
        onClick={() => {
          this.setState({ show: false });
          if (this.props.closeMenuCallback) {
            this.props.closeMenuCallback();
          }
        }}
      />
    ) : null;

    const newMenuData = this.getLastMenuData(menuData);
    console.log('MENU DATA', menuData);
    const menuListView = newMenuData.map((item, index) => {
      const iconImage =
        window.location.pathname === item.path ? item.activeIcon : item.icon;

      return (
        <li key={`menu_${index}`} className="menuItem">
          <Link
            className={`menuLink ${showClass}`}
            to={item.path}
            activeClassName="active"
          >
            <img
              className={`menuImage ${showClass}`}
              // src={require(`../../images/${iconImage}`)}
              src={`https://storage.googleapis.com/textip/images/${iconImage}`}
            />
            <div className={`menuTitle ${showClass}`}>{item.text}</div>
          </Link>
        </li>
      );
    });

    const roomReg = /\/room/;
    if (roomReg.test(window.location.pathname)) {
      return null;
    }
    return (
      <div className={`menu ${showClass}`}>
        <div className="logoBlock">
          {logoImage}
          {closeMenuBtn}
        </div>
        <div className="menuBlock">
          <ul className="menuBox">
            {menuListView}
            <li className="menuItem">
              <div
                className={`menuLink ${showClass}`}
                onClick={() => {
                  logout();
                  alert('logout success');
                }}
              >
                <img
                  className={`menuImage ${showClass}`}
                  src={require(`../../images/menuIcon_logout.png`)}
                />
                <div className={`menuTitle ${showClass}`}>
                  {Lang.MENU_LOGOUT}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  alert: PropTypes.func,
  logout: PropTypes.func,
  closeMenuCallback: PropTypes.func,
  memberData: PropTypes.object, //用來檢查是否要顯示某一個功能
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
