import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Lang from '../Lang/default'
import Empty from '../components/Empty'
import MemberBase from '../containers/MemberBase'
import { getAuth, isEmpty } from '../Libs/Lib'
import { getCollectList } from '../actions/collect'
import Loading from '../components/Loading'
import Image from '../components/Image'
import Input from '../components/Input'
import Button from '../components/Button'
import Pagination from '../components/Pagination'
import FabricDetail from '../components/FabricDetail'
import PubSub from 'pubsub-js'
import { EVENT_COLLECT_LIST_LOAD } from '../constants/eventAction'
import '../style/fabric.sass'
import '../style/collect.sass'

class CollectFabric extends Component {
    constructor(props) {
        super(props)
        this.getCollectList = this.getCollectList.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.apiParams = this.apiParams.bind(this)
        this.handleFabricSearchChange = this.handleFabricSearchChange.bind(this)
        this.goPage = this.goPage.bind(this)
        this.openFabricDetail = this.openFabricDetail.bind(this)
        this.closeFabricDetail = this.closeFabricDetail.bind(this)
        this.state = {
            loading: false,
            listMyFabric: null,
            collectList: null,
            keyword: '',
            page: 1,
            showFabricId: null,
        }
    }

    componentDidMount() {
        this.getCollectList()
        this.pubSubCollectList_load = PubSub.subscribe(EVENT_COLLECT_LIST_LOAD, () => {
            this.getCollectList()
        })
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubSubCollectList_load)
    }

    componentWillReceiveProps(nextProps) {
        const {
            member: thisMember,
            listMyFabric: thisListMyFabric,
            collectList: thisCollectList
        } = this.props
        const { member, listMyFabric, collectList } = nextProps

        if (listMyFabric !== thisListMyFabric) {
            this.setState({ listMyFabric: listMyFabric, loading: false })
        }

        if (collectList !== thisCollectList) {
            this.setState({ collectList: collectList, loading: false })
        }

        if (thisMember !== member && thisMember && member && thisMember.id !== member.id) {
            this.getCollectList()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    apiParams() {
        const { keyword, page } = this.state
        const authData = getAuth()
        let params = {
            page: page,
            pageSize: 20,
            memberId: authData.id,
            token: authData.token,
        }
        if (keyword) {
            params = Object.assign(params, { keyword: keyword })
        }
        return params
    }

    getCollectList() {
        this.setState({ loading: true })
        const authData = getAuth()
        const { getCollectList } = this.props
        if (!isEmpty(authData)) {
            const params = this.apiParams()
            getCollectList(params)
        } else {
            this.setState({ loading: false })
        }
    }

    goPage(page) {
        this.setState({
            page: page
        })
        setTimeout(() => {
            this.getCollectList()
        }, 100);
    }

    openFabricDetail(fabricId) {
        this.setState({
            showFabricId: fabricId,
        })
    }

    closeFabricDetail() {
        this.setState({
            showFabricId: null,
        })
    }

    handleFabricSearchChange(e) {
        const { target } = e
        const { value } = target
        this.setState({
            keyword: value,
        })
    }

    handleChange(e) {
        const { target } = e
        const { name, value } = target
        this.setState({
            [name]: value,
        })
    }

    fabricDetailView() {
        const { showFabricId } = this.state
        const displayModal = showFabricId ? true : false
        let fabricDetailView = (
            <FabricDetail
                fabricId={showFabricId}
                displayModal={displayModal}
                closed={() => {
                    this.closeFabricDetail()
                }}
            />
        )
        return fabricDetailView
    }

    render() {
        const {
            loading,
            listMyFabric,
            collectList,
            keyword,
            page,
        } = this.state
        const emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.FABRIC_EMPTY_TITLE} content={Lang.FABRIC_EMPTY_CONTENT} />
            </div>
        )
        const loadingView = collectList === null || loading ? <Loading /> : null
        let contentView = null
        if (collectList !== null) {
            let fabricSearchListView = null
            if (collectList.data.length > 0) {
                const fabricDetailView = this.fabricDetailView()
                const fabricListView = collectList.data.map((item, index) => {
                    return (
                        <div
                            key={`fabricItem_${index}`}
                            className="fabricItem col-6 col-md-2 layoutBlock"
                            onClick={() => {
                                this.openFabricDetail(item.id)
                            }}
                        >
                            <div className="card">
                                <div className="fabricImage">
                                    <Image src={item.images} className="card-img-top" />
                                </div>
                                <div className="card-body">
                                    <p className="card-text">{item.headline}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
                fabricSearchListView = (
                    <div className="">
                        <div className="fabricListView row">{fabricListView}</div>
                        <Pagination data={collectList.info} page={page} goPage={this.goPage} />
                        {fabricDetailView}
                    </div>
                )
            } else {
                fabricSearchListView = (
                    <div className="fabricListView row">
                        {emptyView}
                    </div>
                )
            }
            contentView = (
                <div className="fabricListBlock">
                    <div className="fabricListTitleBlock">
                        <div className="searchBarBlock">
                            <div className="input-group">
                                <Input
                                    type="text"
                                    name="keyword"
                                    ref="keyword"
                                    placeholder={Lang.FABRIC_LIST_SEARCH}
                                    className="searchBox"
                                    onChange={this.handleFabricSearchChange}
                                    idName="input_keyword"
                                    value={keyword}
                                />
                                <div className="input-group-append searchBtnBox">
                                    <Button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.getCollectList()
                                        }}
                                        text={Lang.FABRIC_SEARCH_SUBMIT}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {fabricSearchListView}
                </div>
            )
        }
        return (
            <MemberBase validUseLevel={2}>
                {loadingView}
                <div className="fabricListContainerBase collectFabricContainer">
                    {contentView}
                </div>
            </MemberBase>
        )
    }
}

CollectFabric.propTypes = {
    listMyFabric: PropTypes.object,
    collectList: PropTypes.object,
    getCollectList: PropTypes.func.isRequired
}

const mapStateToProps = () => (state) => {
    return {
        listMyFabric: state.myFabric.listMyFabric,
        member: state.member.member,
        collectList: state.collect.collectList,
    }
}

const mapDispatchToProps = {
    getCollectList
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectFabric)
