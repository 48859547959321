import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    requestList: {
        info: {},
        data: []
    },
    requestDetailData: {},
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case ActionTypes.REQUEST_ADD_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.REQUEST_ADD_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.REQUEST_ADD_SUCCESS:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.REQUEST_LIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.REQUEST_LIST_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.REQUEST_LIST_SUCCESS:
            const requestListRes = action.payload.response
            let requestList = Object.assign({}, state.requestList)
            requestList.data = requestListRes.data
            requestList.info = {
                pageSize: requestListRes.pageSize,
                totalPage: requestListRes.totalPage,
                totalRow: requestListRes.count,
            }
            return Object.assign({}, state, {
                isDataFetching: false,
                requestList,
            })
        case ActionTypes.REQUEST_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.REQUEST_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.REQUEST_DETAIL_SUCCESS:
            const data = action.payload.response
            return Object.assign({}, state, {
                isDataFetching: false,
                requestDetailData: data,
            })

        default:
            return state
    }
}
