import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Validation from '../Libs/Validation'
import Autosuggest from 'react-autosuggest';
import '../style/autoCompleteCustom.sass'

export default class AutoCompleteCustom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '', //輸入的字串
            errorMessage: '',
            currentTags: [], //用來傳給api的資料，會員id陣列
            currentEmails: [], //用來顯示的資料，email字串陣列
            suggestValue: '',
            suggestions: [],
            completeData: props.completeData || [],
            showValueKey: props.showValueKey || 'title',
        }

        // this.myRefInput = React.createRef();
        this.handleChange = this.handleChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this);
        this.focusInput = this.focusInput.bind(this);
        this.getCurrentTagViews = this.getCurrentTagViews.bind(this)
        this.onTagSizeChange = this.onTagSizeChange.bind(this)
        this.onFocusout = this.onFocusout.bind(this)
        this.getEmailFromId = this.getEmailFromId.bind(this)

        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
        this.getSuggestions = this.getSuggestions.bind(this)
        this.renderSuggestion = this.renderSuggestion.bind(this)
        this.storeInputReference = this.storeInputReference.bind(this)
        this.suggestionSelected = this.suggestionSelected.bind(this)
        this.getMemberIdFromEmail = this.getMemberIdFromEmail.bind(this)
    }

    componentDidMount() {
        if(this.props.value){
            //如果近來有資料，表示為修改
            const tags = this.props.value.split(',');
            //因為切割出來為會員id，所以將該會員id拿去匹配會員資料獲取email
            const cEmails = this.getEmailFromId(tags)
            this.setState({currentTags:tags,currentEmails:cEmails})
            
        }
    }

    componentWillReceiveProps(prevProps, prevState) {
        const { triggerValidate: thisTriggerValidate , completeData:thisCompleteData} = this.props
        const { triggerValidate ,completeData } = prevProps
        const { value ,currentTags } = this.state
        
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.currentTags.length != nextState.currentTags.length){
            this.onTagSizeChange(nextState.currentTags)
        }
        return true
    }

    handleValidation(value) {
        const { role } = this.props
        let status = true
        if (role && role.length > 0) {
            let i
            for (i = 0; i < role.length; i++) {
                const item = role[i]
                const validate = new Validation()
                if (validate[item.name]) {
                    const validateInfo = validate[item.name](value)
                    if (!validateInfo.status) {
                        status = false
                        this.setState({
                            errorMessage: validateInfo.errorMessage,
                        })
                        break
                    }
                    this.setState({
                        errorMessage: '',
                    })
                }
            }
        }
        return status
    }

    getEmailFromId(tags){

        const {completeData} = this.state
        if(!completeData || completeData == [] || completeData.length==0){
            return []
        }

        let myEmails = []
        tags.map((item ,index )=>{
            for(let i=0;i<completeData.length;i++){
                if(item == completeData[i].id){
                    const mValue = completeData[i][this.state.showValueKey]
                    const mEmail = mValue
                    myEmails.push(mEmail)
                }
            }
        })
        return myEmails
    }

    handleChange(e) {
        const { onChange } = this.props
        const { value } = e.target
        if(value!=undefined){
            this.setState({
                inputValue: value,
            })
        }
    }

    handleDelete(i) {
        const { currentTags ,currentEmails} = this.state;
        this.setState({
            currentTags: currentTags.filter((tag, index) => index !== i),
            currentEmails: currentEmails.filter((email ,index) => index !==i)
        });
    }

    focusInput() {
        this.myRefInput.focus()
    }
    
    getCurrentTagViews() {
        let items = <div />
        if(this.state.currentEmails){
            items = this.state.currentEmails.map((item,index) => {
                return (
                    <div
                        key={'index_'+index}
                        className="cusTagsItem"
                    >
                        {item}
                        <img
                            src={require('../images/icon_x.png')}
                            className="cusTagsItemDeleteIcon"
                            onClick={() => {
                                this.handleDelete(index)
                            }}
                        />
                    </div>
                )
            })
        }
        
        return (
            items
        )
    }


    onTagSizeChange(currentTagArray) {
        if (this.props.onTagSizeChange) {
            this.props.onTagSizeChange(this.makeTagArrayToString(currentTagArray))
        }
    }

    onFocusout() {
        //輸入匡失去焦點
        this.setState({inputValue:''})
        // if (this.state.suggestValue) {
        //     if (this.state.suggestValue.trim().length > 0) {
        //         this.setState(state => ({ currentTags: [...state.currentEmails, this.state.suggestValue] ,inputsuggestValueValue:''}));
        //     }
        // }
    }

    makeTagArrayToString(tags) {
        let tagString = ''
        for (let i = 0; i < tags.length; i++) {
            if( i == tags.length - 1 ) {
                //最後一個，所以不用逗號
                tagString = tagString + tags[i]
            } else {
                tagString = tagString + tags[i] + ','
            }
        }
        return tagString
    }


    onSuggestionsFetchRequested( value ){
        if(value.reason=='suggestion-selected'){
            // console.log('is select')
        }else{
            this.setState({
                suggestions: this.getSuggestions(value.value)
            });
        }
    }

  
    onSuggestionsClearRequested(){
        this.setState({
            suggestions: []
        });
    };

    suggestionSelected(event,obj){
        this.setState(state=>
            ({ currentEmails: [...state.currentEmails, obj.suggestionValue] ,
                currentTags: [...state.currentTags, this.getMemberIdFromEmail(obj.suggestionValue)] ,
                inputValue:'',
                suggestions: this.getSuggestions('')
            })
        )
    }

    getMemberIdFromEmail(email){
        let mId = -1    
        const cData = this.state.completeData
        for(let i=0;i < cData.length;i++){
            const memberEmail = cData[i][this.state.showValueKey]
            if( memberEmail === email){
                mId = cData[i].id
                break
            }
        }

        return mId
    }

    //由輸入的文字獲取建議文字
    getSuggestions(value){
        if(value && this.state.completeData){
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
            const newDatas = this.state.completeData.filter(lang =>
                lang[this.state.showValueKey].toLowerCase().indexOf(inputValue) != -1
            )
            const newEmails = []
            if(newDatas.length>0){
                for(let i=0;i<newDatas.length;i++){
                    newEmails.push(newDatas[i][this.state.showValueKey])
                }
            }
        
            return inputLength === 0 ? [] : newEmails
        }else if(this.state.completeData){
            const cData = this.state.completeData
            let nValues = []
            for(let i=0 ;i < cData.length ; i++){
                nValues.push(cData[i][this.state.showValueKey])
            }
            return nValues
        }else{
            return []
        }
    };

    //render建議的項目view
    renderSuggestion(suggestion){
        return (<div>
          {suggestion}
        </div>)
    }

    getSuggestionValue(suggestion){
        return suggestion
    }
    storeInputReference(autosuggest){
        if (autosuggest !== null) {
          this.myRefInput = autosuggest.input;
          this.myRefInput
        }
      };

    render() {
        const {  className, idName, placeholder } = this.props
        const { errorMessage,inputValue ,suggestions  } = this.state
        const inputClassName = className || ''
        const inputIdName = idName || ''
        const inputPlaceholder = placeholder || ''

        const inputProps = {
            placeholder: inputPlaceholder,
            value:inputValue,
            autoComplete:"new-password",
            onBlur: this.onFocusout,
            onChange: this.handleChange
          };        

        return (
             <div className={className?className:'autoCompleteCustomContainer'} onClick={()=>{
                 this.focusInput()
             }}>
                {this.getCurrentTagViews()}
                <Autosuggest
                    style={{width:'100px',height:'fit-content'}} 
                    alwaysRenderSuggestions={true}
                    ref={this.storeInputReference}
                    suggestions={suggestions} //建議的項目
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested} //要去獲取建議的方法
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested} //刪除建議的方法
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    onSuggestionSelected={this.suggestionSelected}
                />
            </div>
        )
    }
}

AutoCompleteCustom.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    showValueKey: PropTypes.string.isRequired, //用來獲取顯示文字的key
    type: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    role: PropTypes.array,
    triggerValidate: PropTypes.bool,
    onTagSizeChange:PropTypes.func,
    completeData: PropTypes.array //用來檢查匹配的資料
}
