import RecordRTC, { invokeSaveAsDialog } from 'recordrtc'

export default class VideoRecord {
    constructor() {
        this.recorder = null
        this.masterId = null
    }

    init(stream) {
        this.masterId = stream.id
        this.recorder = RecordRTC([stream], {
            type: 'video',
            mimeType: 'video/mp4;codecs=h264',
            bitrate: 512000,
            canvas: {
                width: 1024,
                height: 576
            },
        })
        return this.recorder
    }

    addVideoToRecorder(stream) {
        if (!this.recorder) {
            this.recorder = this.init(stream)
        } else {
            if(this.masterId != stream.id){
                this.recorder.getInternalRecorder().addStreams([stream])
            }
        }
    }

    startRecording() {
        if (!this.recorder) {
            return
        }
        this.recorder.startRecording()
    }

    stopAndGetSingleBlob(callback) {
        if (!this.recorder) {
            return
        }
        this.recorder.stopRecording(() => {
            const blob = this.recorder.getBlob()
            callback(blob)
            // invokeSaveAsDialog(blob, 'video.mp4')
            this.recorder = null
            this.destroy()
        })
    }

    destroy() {
        if (!this.recorder) {
            return
        }
        this.masterId = null
        this.recorder.reset()
        this.recorder.destroy()
    }
}
