// header
export const EVENT_CART = 'EVENT_CART';
export const EVENT_CART_REFRESH = 'EVENT_CART_REFRESH';

export const EVENT_REQUEST_REFRESH = 'EVENT_REQUEST_REFRESH';

export const EVENT_UPLOAD_CATALOG_MAIN_PHOTO =
  'EVENT_UPLOAD_CATALOG_MAIN_PHOTO';
export const EVENT_FASHIONVIEW_CLEAR = 'EVENT_FASHIONVIEW_CLEAR'; //fashionView 用來監聽清空頁面的事件
export const EVENT_FASHIONVIEW_SHOW_FROM_JSON =
  'EVENT_FASHIONVIEW_SHOW_FROM_JSON'; //fashionView 用來監聽產生畫面的事件
export const EVENT_CATALOG_DETAIL_SHOW_FABRIC =
  'EVENT_CATALOG_DETAIL_SHOW_FABRIC'; //fashionView 用來呼叫需要顯示布料明細modal
export const EVENT_FASHIONVIEW_CHECK_SHOW_PAGE_CONFIRM =
  'EVENT_FASHIONVIEW_CHECK_SHOW_PAGE_CONFIRM'; //fashionView 檢查要不要跳詢問儲存視窗

export const EVENT_COLLECT_LIST_LOAD = 'EVENT_COLLECT_LIST_LOAD';
