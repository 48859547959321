import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    listTextipFabric: {
        info: {},
        data: [],
        cusTags: []
    },
}

export default function (state = defaultState, action) {
    switch (action.type) {
        // 獲取TEXTIP所有布料
        case ActionTypes.TEXTIPFABRIC_LIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.TEXTIPFABRIC_LIST_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.TEXTIPFABRIC_LIST_SUCCESS:
            const listTextipFabricRes = action.payload.response
            let listTextipFabric = Object.assign({}, state.listTextipFabric)
            listTextipFabric.data = listTextipFabricRes.data
            listTextipFabric.cusTags = listTextipFabricRes.cusTagsList
            listTextipFabric.info = {
                pageSize: listTextipFabricRes.pageSize,
                totalPage: listTextipFabricRes.totalPage,
                totalRow: listTextipFabricRes.count,
            }
            return Object.assign({}, state, {
                isDataFetching: false,
                listTextipFabric,
            })

        default:
            return state
    }
}
