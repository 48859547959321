import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

// 新增request
function fetchAddRequest(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.REQUEST_ADD_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.REQUEST_ADD_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.REQUEST_ADD_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'POST',
            endpoint: '/request',
            body: JSON.stringify(params),
        },
    }
}

export function addRequest(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchAddRequest(params)).then((res) => {
            callback(res)
        })
    }
}
// request list
function fetchGetRequestList(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.REQUEST_LIST_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.REQUEST_LIST_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.REQUEST_LIST_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/request',
            query: params,
        },
    }
}

export function getRequestList(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetRequestList(params)).then((res) => {
            callback(res)
        })
    }
}
// request detail
function fetchGetRequestDetail(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.REQUEST_DETAIL_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.REQUEST_DETAIL_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.REQUEST_DETAIL_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/request/' + params.id,
            query: params,
        },
    }
}

export function getRequestDetail(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetRequestDetail(params)).then((res) => {
            callback(res)
        })
    }
}
