import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    recordsData: {},
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case ActionTypes.RECORDS_LIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.RECORDS_LIST_FAILURE:
            const recordsError = action.payload.response
            return Object.assign({}, state, {
                isDataFetching: false,
                recordsData: recordsError
            })
        case ActionTypes.RECORDS_LIST_SUCCESS:
            const recordsData = action.payload.data
            return Object.assign({}, state, {
                isDataFetching: false,
                recordsData,
            })

        default:
            return state
    }
}
