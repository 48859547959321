import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';

import configureStore from './store/configureStore';
import MyRoom from './containers/MyRoom';
import RoomList from './containers/RoomList';
import FabricManagement from './containers/FabricManagement';
import FabricEdit from './containers/FabricEdit';
import CollectFabric from './containers/CollectFabric';
import Request from './containers/Request';
import RequestDetail from './containers/RequestDetail';
import OrderManagement from './containers/OrderManagement';
import AddRoom from './containers/AddRoom';
import Room from './containers/Room';
import RoomEdit from './containers/RoomEdit';
import RoomCopy from './containers/RoomCopy';
import Catalog from './containers/Catalog';
import AddCatalog from './containers/AddCatalog';
import CatalogDetail from './containers/CatalogDetail';
import Records from './containers/Records';
import Trend from './containers/Trend';
import TrendDetail from './containers/TrendDetail';
import AddTrend from './containers/AddTrend';
import EditTrend from './containers/EditTrend';
import Firebase, { FirebaseContext } from './components/Firebase';

import './style/main.sass';

function App() {
  const store = configureStore();
  const firebase = new Firebase();

  return (
    <Provider store={store}>
      <FirebaseContext.Provider value={firebase}>
        <Router history={browserHistory}>
          <Route path="/" component={RoomList} />
          <Route path="/room/:presentationNo" component={Room} />
          <Route path="/room/:presentationNo/edit" component={RoomEdit} />
          <Route path="/room/:presentationNo/copy" component={RoomCopy} />
          <Route path="/myRoom" component={MyRoom} />
          <Route path="/fabric" component={FabricManagement} />
          <Route path="/fabric/add" component={FabricEdit} />
          <Route path="/fabric/:fabricId/edit" component={FabricEdit} />
          <Route path="/collect" component={CollectFabric} />
          <Route path="/request" component={Request} />
          <Route path="/request/:requestId" component={RequestDetail} />
          <Route path="/order" component={OrderManagement} />
          <Route path="/records" component={Records} />
          <Route path="/addRoom" component={AddRoom} />
          {/* 電子型錄 */}
          <Route path="/catalog" component={Catalog} />
          <Route path="/addCatalog" component={AddCatalog} />
          <Route path="/addCatalog/:catalogId" component={AddCatalog} />
          <Route path="/catalogDetail" component={CatalogDetail} />
          <Route path="/catalogDetail/:catalogId" component={CatalogDetail} />
          {/* <Route path="/catalogPreview/:catalogId" component={AddCatalog} /> */}
          {/* trendBoard */}
          <Route path="/trend" component={Trend} />
          <Route path="/trend/:trendId" component={TrendDetail} />
          <Route path="/addTrendTest" component={AddTrend} />
          <Route path="/addTrend" component={AddTrend} />
          <Route path="/trend/:trendId/edit" component={EditTrend} />
        </Router>
      </FirebaseContext.Provider>
    </Provider>
  );
}

export default App;
