// Member
export const MEMBERLOGIN_REQUEST = 'MEMBERLOGIN_REQUEST';
export const MEMBERLOGIN_SUCCESS = 'MEMBERLOGIN_SUCCESS';
export const MEMBERLOGIN_FAILURE = 'MEMBERLOGIN_FAILURE';
export const MEMBERDETAIL_REQUEST = 'MEMBERDETAIL_REQUEST';
export const MEMBERDETAIL_SUCCESS = 'MEMBERDETAIL_SUCCESS';
export const MEMBERDETAIL_FAILURE = 'MEMBERDETAIL_FAILURE';
export const MEMBERLOGOUT_SUCCESS = 'MEMBERLOGOUT_SUCCESS';

// Presentation
export const PRESENTATION_LIST_REQUEST = 'PRESENTATION_LIST_REQUEST';
export const PRESENTATION_LIST_SUCCESS = 'PRESENTATION_LIST_SUCCESS';
export const PRESENTATION_LIST_FAILURE = 'PRESENTATION_LIST_FAILURE';
export const PRESENTATION_MYLIST_REQUEST = 'PRESENTATION_MYLIST_REQUEST';
export const PRESENTATION_MYLIST_SUCCESS = 'PRESENTATION_MYLIST_SUCCESS';
export const PRESENTATION_MYLIST_FAILURE = 'PRESENTATION_MYLIST_FAILURE';
export const PRESENTATION_DETAIL_REQUEST = 'PRESENTATION_DETAIL_REQUEST';
export const PRESENTATION_DETAIL_SUCCESS = 'PRESENTATION_DETAIL_SUCCESS';
export const PRESENTATION_DETAIL_FAILURE = 'PRESENTATION_DETAIL_FAILURE';
export const PRESENTATION_ADD_REQUEST = 'PRESENTATION_ADD_REQUEST';
export const PRESENTATION_ADD_SUCCESS = 'PRESENTATION_ADD_SUCCESS';
export const PRESENTATION_ADD_FAILURE = 'PRESENTATION_ADD_FAILURE';
export const PRESENTATION_DELETE_REQUEST = 'PRESENTATION_DELETE_REQUEST';
export const PRESENTATION_DELETE_SUCCESS = 'PRESENTATION_DELETE_SUCCESS';
export const PRESENTATION_DELETE_FAILURE = 'PRESENTATION_DELETE_FAILURE';
export const PRESENTATION_GET_EMAIL_REQUEST = 'PRESENTATION_GET_EMAIL_REQUEST';
export const PRESENTATION_GET_EMAIL_SUCCESS = 'PRESENTATION_GET_EMAIL_SUCCESS';
export const PRESENTATION_GET_EMAIL_FAILURE = 'PRESENTATION_GET_EMAIL_FAILURE';
export const PRESENTATION_CLEAR_CHAT_MESSAGE_REQUEST =
  'PRESENTATION_CLEAR_CHAT_MESSAGE_REQUEST';
export const PRESENTATION_CLEAR_CHAT_MESSAGE_SUCCESS =
  'PRESENTATION_CLEAR_CHAT_MESSAGE_SUCCESS';
export const PRESENTATION_CLEAR_CHAT_MESSAGE_FAILURE =
  'PRESENTATION_CLEAR_CHAT_MESSAGE_FAILURE';

// FabricDetail
export const FABRICDETAIL_REQUEST = 'FABRICDETAIL_REQUEST';
export const FABRICDETAIL_SUCCESS = 'FABRICDETAIL_SUCCESS';
export const FABRICDETAIL_FAILURE = 'FABRICDETAIL_FAILURE';
export const FABRICDETAIL_VIEW_REQUEST = 'FABRICDETAIL_VIEW_REQUEST';
export const FABRICDETAIL_VIEW_SUCCESS = 'FABRICDETAIL_VIEW_SUCCESS';
export const FABRICDETAIL_VIEW_FAILURE = 'FABRICDETAIL_VIEW_FAILURE';

// MyFabric
export const MYFABRIC_ADDPERSONALITEM_REQUEST =
  'MYFABRIC_ADDPERSONALITEM_REQUEST';
export const MYFABRIC_ADDPERSONALITEM_SUCCESS =
  'MYFABRIC_ADDPERSONALITEM_SUCCESS';
export const MYFABRIC_ADDPERSONALITEM_FAILURE =
  'MYFABRIC_ADDPERSONALITEM_FAILURE';
export const MYFABRIC_GETPERSONALITEMS_REQUEST =
  'MYFABRIC_GETPERSONALITEMS_REQUEST';
export const MYFABRIC_GETPERSONALITEMS_SUCCESS =
  'MYFABRIC_GETPERSONALITEMS_SUCCESS';
export const MYFABRIC_GETPERSONALITEMS_FAILURE =
  'MYFABRIC_GETPERSONALITEMS_FAILURE';
export const MYFABRIC_GETPERSONALITEM_REQUEST =
  'MYFABRIC_GETPERSONALITEM_REQUEST';
export const MYFABRIC_GETPERSONALITEM_SUCCESS =
  'MYFABRIC_GETPERSONALITEM_SUCCESS';
export const MYFABRIC_GETPERSONALITEM_FAILURE =
  'MYFABRIC_GETPERSONALITEM_FAILURE';
export const MYFABRIC_DELETEPERSONALITEM_REQUEST =
  'MYFABRIC_DELETEPERSONALITEM_REQUEST';
export const MYFABRIC_DELETEPERSONALITEM_SUCCESS =
  'MYFABRIC_DELETEPERSONALITEM_SUCCESS';
export const MYFABRIC_DELETEPERSONALITEM_FAILURE =
  'MYFABRIC_DELETEPERSONALITEM_FAILURE';
export const MYFABRIC_GETTAGFORFABRIC_REQUEST =
  'MYFABRIC_GETTAGFORFABRIC_REQUEST';
export const MYFABRIC_GETTAGFORFABRIC_SUCCESS =
  'MYFABRIC_GETTAGFORFABRIC_SUCCESS';
export const MYFABRIC_GETTAGFORFABRIC_FAILURE =
  'MYFABRIC_GETTAGFORFABRIC_FAILURE';

// Category
export const CATEGORY_COLOR_FAILURE = 'CATEGORY_COLOR_FAILURE';
export const CATEGORY_COLOR_SUCCESS = 'CATEGORY_COLOR_SUCCESS';
export const CATEGORY_COLOR_REQUEST = 'CATEGORY_COLOR_REQUEST';

// Request
export const REQUEST_ADD_FAILURE = 'REQUEST_ADD_FAILURE';
export const REQUEST_ADD_SUCCESS = 'REQUEST_ADD_SUCCESS';
export const REQUEST_ADD_REQUEST = 'REQUEST_ADD_REQUEST';
export const REQUEST_LIST_FAILURE = 'REQUEST_LIST_FAILURE';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_LIST_REQUEST = 'REQUEST_LIST_REQUEST';
export const REQUEST_DETAIL_FAILURE = 'REQUEST_DETAIL_FAILURE';
export const REQUEST_DETAIL_SUCCESS = 'REQUEST_DETAIL_SUCCESS';
export const REQUEST_DETAIL_REQUEST = 'REQUEST_DETAIL_REQUEST';

// Catalog 目錄
export const CATALOG_UPLOAD_PAGE_MAIN_PHOTO_FAILURE =
  'CATALOG_UPLOAD_PAGE_MAIN_PHOTO_FAILURE';
export const CATALOG_UPLOAD_PAGE_MAIN_PHOTO_SUCCESS =
  'CATALOG_UPLOAD_PAGE_MAIN_PHOTO_SUCCESS';
export const CATALOG_UPLOAD_PAGE_MAIN_PHOTO_REQUEST =
  'CATALOG_UPLOAD_PAGE_MAIN_PHOTO_REQUEST';
//新增一本目錄
export const CATALOG_CREATE_CATALOG_FAILURE = 'CATALOG_CREATE_CATALOG_FAILURE';
export const CATALOG_CREATE_CATALOG_SUCCESS = 'CATALOG_CREATE_CATALOG_SUCCESS';
export const CATALOG_CREATE_CATALOG_REQUEST = 'CATALOG_CREATE_CATALOG_REQUEST';
//更新某一個目錄
export const CATALOG_UPDATE_CATALOG_FAILURE = 'CATALOG_UPDATE_CATALOG_FAILURE';
export const CATALOG_UPDATE_CATALOG_SUCCESS = 'CATALOG_UPDATE_CATALOG_SUCCESS';
export const CATALOG_UPDATE_CATALOG_REQUEST = 'CATALOG_UPDATE_CATALOG_REQUEST';
//刪除某一個目錄
export const CATALOG_DELETE_CATALOG_FAILURE = 'CATALOG_DELETE_CATALOG_FAILURE';
export const CATALOG_DELETE_CATALOG_SUCCESS = 'CATALOG_DELETE_CATALOG_SUCCESS';
export const CATALOG_DELETE_CATALOG_REQUEST = 'CATALOG_DELETE_CATALOG_REQUEST';
//獲取目錄列表
export const CATALOG_GET_CATALOG_LIST_FAILURE =
  'CATALOG_GET_CATALOG_LIST_FAILURE';
export const CATALOG_GET_CATALOG_LIST_SUCCESS =
  'CATALOG_GET_CATALOG_LIST_SUCCESS';
export const CATALOG_GET_CATALOG_LIST_REQUEST =
  'CATALOG_GET_CATALOG_LIST_REQUEST';
//獲取目錄明細資料
export const CATALOG_GET_CATALOG_DETAIL_FAILURE =
  'CATALOG_GET_CATALOG_DETAIL_FAILURE';
export const CATALOG_GET_CATALOG_DETAIL_SUCCESS =
  'CATALOG_GET_CATALOG_DETAIL_SUCCESS';
export const CATALOG_GET_CATALOG_DETAIL_REQUEST =
  'CATALOG_GET_CATALOG_DETAIL_REQUEST';

// Chat
export const CHAT_ADD_FAILURE = 'CHAT_ADD_FAILURE';
export const CHAT_ADD_SUCCESS = 'CHAT_ADD_SUCCESS';
export const CHAT_ADD_REQUEST = 'CHAT_ADD_REQUEST';
export const CHAT_LIST_FAILURE = 'CHAT_LIST_FAILURE';
export const CHAT_LIST_SUCCESS = 'CHAT_LIST_SUCCESS';
export const CHAT_LIST_REQUEST = 'CHAT_LIST_REQUEST';
export const CHAT_DETAIL_FAILURE = 'CHAT_DETAIL_FAILURE';
export const CHAT_DETAIL_SUCCESS = 'CHAT_DETAIL_SUCCESS';
export const CHAT_DETAIL_REQUEST = 'CHAT_DETAIL_REQUEST';

// Like
export const LIKE_ADD_FAILURE = 'LIKE_ADD_FAILURE';
export const LIKE_ADD_SUCCESS = 'LIKE_ADD_SUCCESS';
export const LIKE_ADD_REQUEST = 'LIKE_ADD_REQUEST';
export const LIKE_CANCEL_FAILURE = 'LIKE_CANCEL_FAILURE';
export const LIKE_CANCEL_SUCCESS = 'LIKE_CANCEL_SUCCESS';
export const LIKE_CANCEL_REQUEST = 'LIKE_CANCEL_REQUEST';
export const LIKE_LIST_FAILURE = 'LIKE_LIST_FAILURE';
export const LIKE_LIST_SUCCESS = 'LIKE_LIST_SUCCESS';
export const LIKE_LIST_REQUEST = 'LIKE_LIST_REQUEST';

// Records
export const RECORDS_LIST_FAILURE = 'RECORDS_LIST_FAILURE';
export const RECORDS_LIST_SUCCESS = 'RECORDS_LIST_SUCCESS';
export const RECORDS_LIST_REQUEST = 'RECORDS_LIST_REQUEST';

// Textip Fabric
export const TEXTIPFABRIC_LIST_FAILURE = 'TEXTIPFABRIC_LIST_FAILURE';
export const TEXTIPFABRIC_LIST_SUCCESS = 'TEXTIPFABRIC_LIST_SUCCESS';
export const TEXTIPFABRIC_LIST_REQUEST = 'TEXTIPFABRIC_LIST_REQUEST';

// Presentation Video
export const PRESENTATIONVIDEO_DELETE_FAILURE =
  'PRESENTATIONVIDEO_DELETE_FAILURE';
export const PRESENTATIONVIDEO_DELETE_SUCCESS =
  'PRESENTATIONVIDEO_DELETE_SUCCESS';
export const PRESENTATIONVIDEO_DELETE_REQUEST =
  'PRESENTATIONVIDEO_DELETE_REQUEST';

//新增一本trendBoard
export const TREND_CREATE_TRENDBOARD_FAILURE =
  'TREND_CREATE_TRENDBOARD_FAILURE';
export const TREND_CREATE_TRENDBOARD_SUCCESS =
  'TREND_CREATE_TRENDBOARD_SUCCESS';
export const TREND_CREATE_TRENDBOARD_REQUEST =
  'TREND_CREATE_TRENDBOARD_REQUEST';
//更新某一個trendBoard
export const TREND_UPDATE_TRENDBOARD_FAILURE =
  'TREND_UPDATE_TRENDBOARD_FAILURE';
export const TREND_UPDATE_TRENDBOARD_SUCCESS =
  'TREND_UPDATE_TRENDBOARD_SUCCESS';
export const TREND_UPDATE_TRENDBOARD_REQUEST =
  'TREND_UPDATE_TRENDBOARD_REQUEST';
//刪除某一個trendBoard
export const TREND_DELETE_TRENDBOARD_FAILURE =
  'TREND_DELETE_TRENDBOARD_FAILURE';
export const TREND_DELETE_TRENDBOARD_SUCCESS =
  'TREND_DELETE_TRENDBOARD_SUCCESS';
export const TREND_DELETE_TRENDBOARD_REQUEST =
  'TREND_DELETE_TRENDBOARD_REQUEST';
//獲取trendBoard列表
export const TREND_GET_TRENDBOARD_LIST_FAILURE =
  'TREND_GET_TRENDBOARD_LIST_FAILURE';
export const TREND_GET_TRENDBOARD_LIST_SUCCESS =
  'TREND_GET_TRENDBOARD_LIST_SUCCESS';
export const TREND_GET_TRENDBOARD_LIST_REQUEST =
  'TREND_GET_TRENDBOARD_LIST_REQUEST';
//獲取trendBoard明細資料
export const TREND_GET_TRENDBOARD_DETAIL_FAILURE =
  'TREND_GET_TRENDBOARD_DETAIL_FAILURE';
export const TREND_GET_TRENDBOARD_DETAIL_SUCCESS =
  'TREND_GET_TRENDBOARD_DETAIL_SUCCESS';
export const TREND_GET_TRENDBOARD_DETAIL_REQUEST =
  'TREND_GET_TRENDBOARD_DETAIL_REQUEST';
