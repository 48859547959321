import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Login from './Login';
import {
  isEmpty,
  getAuth,
  isMenuShow,
  setMenuShowStatus,
  getMemberData as getLibMemberData,
} from '../Libs/Lib';
import { getMemberData } from '../actions/member';
import Menu from '../components/layout/Menu';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Alert from '../components/Alert';
import Mask from '../components/Mask';
import Shopcart from '../components/layout/Shopcart';
import { EVENT_CART, EVENT_CART_REFRESH } from '../constants/eventAction';
import PubSub from 'pubsub-js';

class MemberBase extends Component {
  constructor(props) {
    super(props);
    this.alert = this.alert.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.closeMask = this.closeMask.bind(this);
    this.clickMenu = this.clickMenu.bind(this);
    this.closeMenuCallback = this.closeMenuCallback.bind(this);
    this.clickShopcart = this.clickShopcart.bind(this);
    this.chackCanStay = this.checkCanStay.bind(this);

    this.state = {
      validUseLevel: props.validUseLevel || 1, //可允許的使用等級，0\1\2\3
      kickToPath: props.kickToPath || '/', //如果非合法要踢去哪裡
      isFirstCome: true,
      loginChecked: false,
      showAlert: false,
      showMask: false,
      showMenu: isMenuShow(),
      showCart: false,
      alertData: {},
      requireLogin: props.requireLogin,
      member: {},
      memberDetail: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { member: thisMember, memberDetail: thisMemberDetail } = this.props;
    const { member, memberDetail } = nextProps;
    if (
      !this.state.isFirstCome &&
      this.state.memberDetail !== memberDetail &&
      !isEmpty(memberDetail)
    ) {
      this.setState({ memberDetail: memberDetail, loginChecked: true });
    }

    if (
      !this.state.isFirstCome &&
      this.state.member !== member &&
      !isEmpty(member)
    ) {
      this.setState({ member: member, loginChecked: true });
    }

    if (
      !this.state.isFirstCome &&
      !isEmpty(this.state.member) &&
      isEmpty(member) &&
      isEmpty(getAuth())
    ) {
      this.setState({ member: member, loginChecked: true });
    }
    if (
      !this.state.isFirstCome &&
      !isEmpty(this.state.memberDetail) &&
      isEmpty(memberDetail) &&
      isEmpty(getLibMemberData())
    ) {
      this.setState({
        memberDetail: memberDetail,
        loginChecked: true,
        isFirstCome: true,
      });
    }
    if (!this.state.isFirst && !isEmpty(memberDetail)) {
      this.checkCanStay(memberDetail.presentationType);
    }
  }

  componentDidMount() {
    const { member, getMemberData } = this.props;
    if (this.state.isFirstCome) {
      // this.setState({isFirstCome:false})

      const authData = getAuth();
      const authMemberData = getLibMemberData();
      //先檢查有沒有登入，如果有登入就去檢查會員資料有沒有拿到
      if (!isEmpty(authData)) {
        if (!isEmpty(authMemberData)) {
          this.setState({
            member: authData,
            memberDetail: authMemberData,
            loginChecked: true,
            isFirstCome: false,
          });
          this.checkCanStay(authMemberData.presentationType);
        } else {
          this.props.getMemberData(authData.id);
        }

        //註冊購物車pubsub事件
        this.pubSubToken = PubSub.subscribe(EVENT_CART, (message, data) => {
          if (data && data.postTime) {
            const now = new Date();
            const nowTime = now.getTime();
            const diffTime = (nowTime - data.postTime) / 1000;
            // console.log(diffTime + ' 秒')
          }
          this.clickShopcart(data);
        });
      } else {
        this.setState({
          loginChecked: true,
        });
      }
    }
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.pubSubToken);
    PubSub.unsubscribe(this.pubSubToken_notUseFeature);
  }

  alert(content, title = '', type = '') {
    this.setState({
      showAlert: true,
      alertData: {
        type: type,
        title: title,
        content: content,
      },
    });
  }

  closeAlert() {
    this.setState({
      showAlert: false,
    });
  }

  closeMask() {
    this.setState({
      showMask: false,
      showCart: false,
    });
  }

  clickMenu() {
    const type = !this.state.showMenu;
    setMenuShowStatus(type);

    this.setState({
      showMenu: !this.state.showMenu,
      showMask: !this.state.showMenu,
      showCart: false,
    });
  }

  closeMenuCallback() {
    setMenuShowStatus(false);
    this.setState({
      showMenu: false,
      showMask: false,
      showCart: false,
    });
  }

  clickShopcart(data = 'undefined') {
    if (data && data != 'undefined') {
      //表示有資料，
      this.setState({ showCart: data.open });
      //發推播叫購物車去更新資料
      PubSub.publish(EVENT_CART_REFRESH);
    } else {
      this.setState({
        showCart: !this.state.showCart,
      });
    }
  }

  //檢查可不可以停留在此畫面
  checkCanStay(presentationType) {
    let userLevel = 99;
    switch (presentationType) {
      case 'manager':
        userLevel = 0;
        break;
      case 'sales':
        userLevel = 1;
        break;
      case 'normal':
        userLevel = 2;
        break;
      default:
        userLevel = 99;
        break;
    }
    //檢查是否合法使用者
    if (userLevel <= this.state.validUseLevel) {
      this.setState({ isFirstCome: false, loginChecked: true });
      //可以使用
    } else {
      //不能使用，踢到目標路徑
      this.setState({ isFirstCome: false });
      if (window.location.pathname != '/')
        window.location.href = this.state.kickToPath;
    }
  }

  handleGoBack() {
    window.location.href = '/';
  }

  render() {
    const { children, member, memberDetail } = this.props;
    const {
      isFirstCome,
      loginChecked,
      showAlert,
      alertData,
      showMenu,
      showMask,
      showCart,
      requireLogin,
    } = this.state;
    let contentView = null;
    let alertView = null;
    let menuView = null;
    let headerView = null;
    let footerView = null;
    let maskView = null;
    let cartView = null;
    const showClass = showMenu ? 'showMenu' : 'hideMenu';
    const showMenuMask = showMenu ? 'showMask' : 'hideMask';
    // 已登入
    if (!isEmpty(this.state.memberDetail)) {
      menuView = (
        <Menu
          show={showMenu}
          clickMenu={this.clickMenu}
          memberData={this.state.memberDetail}
          closeMenuCallback={this.closeMenuCallback}
        />
      );

      headerView = (
        <Header
          alert={this.alert}
          clickMenu={this.clickMenu}
          goBack={this.handleGoBack}
        />
      );
      // footerView = <Footer />
      cartView = <Shopcart show={showCart} />;
    } else if (requireLogin == false) {
      //不需要登入就可以使用
      headerView = <Header />;
      // footerView = <Footer />
    }

    //手機版才需要出現遮罩
    maskView = showMenu ? (
      <div
        className={`memberBaseMask ${showMenuMask}`}
        onClick={this.clickMenu}
      />
    ) : null;

    if (showAlert) {
      const { type, title, content } = alertData;
      alertView = (
        <Alert
          type={type}
          title={title}
          content={content}
          close={this.closeAlert}
        />
      );
    }

    if (requireLogin == false) {
      contentView = (
        <div className={`contentBlock ${showClass}`}>
          {cartView}
          {headerView}
          {children}
        </div>
      );
    } else if (isFirstCome) {
      contentView = !isEmpty(this.state.memberDetail) ? (
        <div className={`contentBlock ${showClass}`}>
          {cartView}
          {headerView}
          {children}
        </div>
      ) : (
        <Login alert={this.alert} />
      );
    } else if (loginChecked) {
      contentView = !isEmpty(this.state.memberDetail) ? (
        <div className={`contentBlock ${showClass}`}>
          {cartView}
          {headerView}
          {children}
        </div>
      ) : (
        <Login alert={this.alert} />
      );
    }

    return (
      <div className="container-fluid">
        <div className="row">
          {menuView}
          {contentView}
          {alertView}
          {maskView}
        </div>
      </div>
    );
  }
}

MemberBase.propTypes = {
  requireLogin: PropTypes.bool || true, //檢查是不是有必要登入才能使用該功能
  validUseLevel: PropTypes.number,
  kickToPath: PropTypes.string,
};

const mapStateToProps = () => (state) => {
  return {
    member: state.member.member,
    memberDetail: state.member.memberDetail,
  };
};

const mapDispatchToProps = {
  getMemberData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberBase);
