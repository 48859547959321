import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Lang from '../Lang/default'
import Empty from '../components/Empty'
import MemberBase from '../containers/MemberBase'
// import '../style/roomList.sass'

class OrderManagement extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillMount() {}

    componentDidMount() {}

    componentWillUnmount() {}

    componentWillReceiveProps(nextProps) {}

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    render() {
        let emptyView = null
        emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.ORDER_EMPTY_TITLE} content={Lang.ORDER_EMPTY_CONTENT} />
            </div>
        )
        return <MemberBase>{emptyView}</MemberBase>
    }
}

OrderManagement.propTypes = {}

const mapStateToProps = () => (state) => {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OrderManagement)
