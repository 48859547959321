import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMenuShow } from '../Libs/Lib';

import '../style/loading.sass';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.setLoading = this.setLoading.bind(this);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    // const setLoading = this.setLoading
    // setTimeout(function () {
    //     setLoading()
    // }, 200)
  }

  setLoading() {
    this.setState({
      loading: true,
    });
  }

  render() {
    const isMShow = isMenuShow();
    const { loading } = this.state;
    const loadingStyle = loading ? { opacity: 1 } : {};
    // const loadingStyle = { opacity: 1 }
    return (
      <div className="loading-container" style={loadingStyle}>
        <div className="loading-view">
          <div
            style={{
              width: '100px',
              height: '100px',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
            }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
