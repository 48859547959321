// Room
export const ROOM_TITLE = 'Room Management'
export const ROOM_ADD = 'Add Room'
export const ROOM_INFORMATION = 'Information'
export const ROOM_ACTIONS = 'Actions'
export const ROOM_BOARD = 'Board'
export const ROOM_BOARD2 = 'Board2'
export const ROOM_TREND_BOARD = 'Trend Board'
export const ROOM_EMPTY_TITLE = 'No Rooms'
export const ROOM_CHAT_TITLE = 'Chat'
export const ROOM_EMPTY_CONTENT = 'Try to create a new room'
export const ROOM_CREATE_ROOM = 'Create a new room'
export const ROOM_CREATE_SUBMIT = 'Create'
export const ROOM_UPDATE_SUBMIT = 'Update'
export const ROOM_FIELDS_THEME = 'Theme Name'
export const ROOM_FIELDS_STATUS = 'Status'
export const ROOM_FIELDS_STATUS_OPTIONS_DEFAULT = '--- select status ---'
export const ROOM_FIELDS_STATUS_PUBLIC = 'Public'
export const ROOM_FIELDS_STATUS_PRIVATE = 'Private'
export const ROOM_FIELDS_TYPE = 'TYPE'
export const ROOM_FIELDS_TYPE_OPTIONS_DEFAULT = '--- select type ---'
export const ROOM_FIELDS_TYPE_PRESENTATION = 'Presentation'
export const ROOM_FIELDS_TYPE_LIVE = 'Live'
export const ROOM_FIELDS_INVITED = 'Invited email'
export const ROOM_JOIN = 'Join'
export const ROOM_EDIT = 'EDIT'
export const ROOM_NOT_FOUND = 'Room not found'
export const ROOM_FABRIC_VIEW = 'View'
export const ROOM_CHAT_SUBMIT = 'Submit'
export const ROOM_CHAT_PLACEHOLDER = 'I want to say ...'
export const ROOM_BOARD_DESCRIPTION = 'Description'
export const ROOM_COLORSELECT_CONFIRM = 'Confirm'
export const ROOM_COLORSELECT_CANCEL = 'Cancel'
export const ROOM_COLORSELECT_DELETE = 'Delete'
export const ROOM_TEMPLATE_BTN = 'Template'
export const ROOM_FABRICLIST_TITLE = 'Fabric'
export const ROOM_MAIN_IMAGE = 'Main Image'
export const ROOM_BOARD_STATUS_ON = 'ON'
export const ROOM_BOARD_STATUS_OFF = 'OFF'
export const ROOM_VIDEOCALL_JOIN_BTN = 'Join a Video Call'
export const ROOM_VIDEOS_TITLE = 'Videos'
export const ROOM_VIDEO_DELETE_CONFIRM_CONTENT = 'Delete presentation video name'
export const ROOM_VIDEO_UPLOAD_CONFIRM = 'Video Save Information Confirm'
export const ROOM_VIDEO_DELETE_CONFIRM_BTN = 'Confirm'
export const ROOM_VIDEO_CONFIRM_MEMBERNAME = 'Sales Name'
export const ROOM_VIDEO_CONFIRM_CUSTOMERNAME = 'Customer Name'
export const ROOM_FABRICGROUP_ITEM_TITLE_PLACEHOLDER = 'fabric group name'

// RoomList
export const ROOMLIST_CARD_BTN_ENTER = 'Enter'
export const ROOMLIST_CARD_TAG_PRIVATE = 'Private'
export const ROOMLIST_CARD_TITLE_NO = 'No:'
export const ROOMLIST_CARD_TITLE_OWNER = 'Owner:'
export const ROOMLIST_TAB_ROOM = 'ROOM LIST'
export const ROOMLIST_TAB_MYROOM = 'MY ROOM'
export const ROOMLIST_DELETE_CONFIRM = 'Confirm'
export const ROOMLIST_DELETE_CONFIRM_CONTENT = 'Remove presentation no.'
export const ROOMLIST_DELETE_CONFIRM_BTN = 'Confirm'

// Request
export const REQUEST_EMPTY_TITLE = 'No Request'
export const REQUEST_EMPTY_CONTENT = 'Try to get request'
export const REQUEST_TITLE = 'Request Detail'
export const REQUEST_INFORMATION = 'Information'
export const REQUEST_PRODUCT_ITEM = 'Product Item'
export const REQUEST_COLUMN_REQUESTNO = 'No.'
export const REQUEST_COLUMN_ADDRESS = 'Address'
export const REQUEST_COLUMN_NOTE = 'Note'
export const REQUEST_COLUMN_CREATED_AT = 'Created'
export const REQUEST_COLUMN_PRODUCT = 'Product'
export const REQUEST_COLUMN_TYPE = 'Type'
export const REQUEST_COLUMN_QTY = 'Qty'
export const REQUEST_COLUMN_UNITPRICE = 'Unit Price'

// Order
export const ORDER_EMPTY_TITLE = 'No Order'
export const ORDER_EMPTY_CONTENT = 'Try to get order'

// Fabric
export const FABRIC_TITLE = 'Fabric Management'
export const FABRIC_INFORMATION = 'Information'
export const FABRIC_IMAGELIST = 'Image List'
export const FABRIC_CREATE_SUBMIT = 'Create'
export const FABRIC_UPDATE_SUBMIT = 'Update'
export const FABRIC_EMPTY_TITLE = 'No Fabric'
export const FABRIC_EMPTY_CONTENT = 'Try to create fabric'
export const FABRIC_CREATE_FABRIC = 'Create fabric'
export const FABRIC_DETAIL_BTN_ADD = 'Add'
export const FABRIC_DETAIL_BTN_CANCEL = 'Cancel'
export const FABRIC_DETAIL_BTN_CONFIRM = 'Confirm'
export const FABRIC_DETAIL_BTN_DELETE = 'Delete'
export const FABRIC_DETAIL_TXT_FABRICNO = 'fabric No.'
export const FABRIC_DETAIL_TXT_TITLE = 'title'
export const FABRIC_DETAIL_TXT_CONTENTS = 'contents'
export const FABRIC_DETAIL_TXT_CUTTABLE = 'cuttable'
export const FABRIC_DETAIL_TXT_WIDTH = 'width'
export const FABRIC_DETAIL_TXT_WEIGHT = 'weight'
export const FABRIC_DETAIL_TXT_FUNCTION = 'function'
export const FABRIC_DETAIL_TXT_FINISH = 'finish'
export const FABRIC_DETAIL_TXT_QUALITY = 'quality'
export const FABRIC_DETAIL_TXT_PRICE = 'price'
export const FABRIC_DETAIL_TXT_CUSTAG = 'tags'
export const FABRIC_DETAIL_TXT_ATTRIBUTE = 'attribute'
export const FABRIC_DETAIL_TXT_OTHERS = 'others'
export const FABRIC_DETAIL_BTN_GET_SWATCH = 'Get Swatch'
export const FABRIC_DETAIL_BTN_GET_FABRIC = 'Get Yardage'
export const FABRIC_DETAIL_BTN_CHAT = 'chat'
export const FABRIC_DETAIL_BTN_BROADCAST_FABRIC = 'Broadcast'
export const FABRIC_LIST_SEARCH = 'Search ...'
export const FABRIC_SEARCH_SUBMIT = 'Search'
export const FABRIC_IMAGE_SUBMIT = 'Image'
export const FABRIC_LABLE_WIDTH = 'WIDTH'
export const FABRIC_LABLE_WEIGHT = 'WEIGHT'
export const FABRIC_FILTER_TITLE = 'Filter'
export const FABRIC_FILTER_FUNCTION = 'Function'
export const FABRIC_FILTER_ATTRIBUTE = 'Attribute'
export const FABRIC_FILTER_OTHERS = 'Others'
export const FABRIC_FILTER_COLOR = 'Color'
export const FABRIC_FILTER_PRICE = 'Price Range (USD/Yard)'
export const FABRIC_FILTER_WEIGHT = 'Weight Range (g/sm)'
export const FABRIC_FILTER_MIN = 'min'
export const FABRIC_FILTER_MAX = 'max'
export const FABRIC_FILTER_SUBMIT = 'Submit'
export const FABRIC_IMAGESEARCH_TYPE1 = 'Pattern Search'
export const FABRIC_IMAGESEARCH_TYPE2 = 'Concept Search'
export const FABRIC_DATATYPE_NEW = 'New Product'
export const FABRIC_DATATYPE_TEXTIP = 'TEXTIP'
export const FABRIC_FILTER_BTN = 'Filter'

// member
export const MEMBER_LOGIN = 'SIGN IN'

// menu
export const MENU_LOGOUT = 'Logout'

//catalog
export const CATALOG_TITLE = 'Catalog Management'
export const CATALOG_EMPTY_TITLE = 'No catalog'
export const CATALOG_EMPTY_CONTENT = 'Try to create catalog'
export const CATALOG_CREATE_CATALOG = 'Create catalog'
export const CATALOG_PAGE_INFORMATION = 'Catalog information'
export const CATALOG_PAGE_LIST = 'Page list'
export const CATALOG_PAGE_CONTENT = 'Page content'
export const CATALOG_CHECK_DELETE_PAGE = 'Do you want to delete the page?'
export const CATALOG_ADD_WHICH_TYPE = 'Which type do you want to add?'
export const CATALOG_ADD_WHICH_TYPE_CANCEL = 'cancel'
export const CATALOG_CHECK_CHANGE_CURRENT_PAGE = 'Do you want to save data before change page?'
export const CATALOG_LIST_BTN_PREVIEW = 'Preview'
export const CATALOG_LIST_BTN_EDIT = 'Edit'
export const CATALOG_DELETE_CATALOG_CONFIRM = 'Remove catalog '

// records
export const RECORDS_VIEWS = 'Views'
export const RECORDS_REQUEST = 'Request'
export const RECORDS_TYPE_VIEWS = 'Views'
export const RECORDS_TYPE_REQUEST = 'Request'
export const RECORDS_EMPTY_TITLE = 'No Records'

//trend
export const TREND_CREATE_TREND = 'Create trend'
export const TREND_TITLE = 'Trend Management'
export const TREND_FIELDS_TRENDNAME = 'Trend Name'
export const TREND_FIELDS_BOARDNAME = 'Board Name'
export const TREND_EMPTY_TITLE = 'No trendBoard'
export const TREND_EMPTY_CONTENT = 'Try to create TrendBoard'
export const TREND_DELETE_TREND_CONFIRM = 'Remove trendBoard '
export const TREND_NOT_FOUND = 'Trend not found'
export const TREND_CREATE_TRENDBOARD = 'Create a new trendboard'
