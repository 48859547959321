import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Mask extends Component {
    constructor(props) {
        super(props)
        this.state = {
            maskClass: '',
        }
    }

    componentDidMount() {
        const that = this
        setTimeout(function () {
            that.setState({
                maskClass: 'show',
            })
        }, 1)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    render() {
        const { maskClass } = this.state
        const { close } = this.props
        return (
            <div
                className={`maskDark ${maskClass}`}
                onClick={() => {
                    close()
                }}
            ></div>
        )
    }
}

Mask.propTypes = {
    close: PropTypes.func.isRequired,
}
