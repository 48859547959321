import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty, getAuth } from '../Libs/Lib'
import { getPresentationData, getPresentationEmail,clearChatMessage } from '../actions/presentation'
import { deletePresentationVideo } from '../actions/presentationVideo'
import { getMessageList } from '../actions/chat'
import { Link } from 'react-router'
import * as Lang from '../Lang/default'
import Empty from '../components/Empty'
import MemberBase from '../containers/MemberBase'
import Form from '../components/Form'
import Loading from '../components/Loading'
import Image from '../components/Image'
import Button from '../components/Button'
import Textarea from '../components/Textarea'
import Modal from '../components/Modal'
import FabricList from '../components/FabricList'
import FabricDetail from '../components/FabricDetail'
import ContentBlock from '../components/ContentBlock'
import TrendBoardList from '../components/TrendBoardList'
import Input from '../components/Input'
import Alert from '../components/Alert'
import { SketchPicker } from 'react-color'
import '../style/room.sass'
import '../style/board.sass'

class RoomEdit extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.removeListener = this.removeListener.bind(this)
        this.changeBoardFile = this.changeBoardFile.bind(this)
        this.previewImage = this.previewImage.bind(this)
        this.previewBoardImage = this.previewBoardImage.bind(this)
        this.changeBoardStatus = this.changeBoardStatus.bind(this)
        this.confirmFabricListSelect = this.confirmFabricListSelect.bind(this)
        this.confirmBoardFabricSelect1 = this.confirmBoardFabricSelect1.bind(this)
        this.confirmBoardFabricSelect2 = this.confirmBoardFabricSelect2.bind(this)
        this.confirmBoardFabricSelect3 = this.confirmBoardFabricSelect3.bind(this)
        this.clickAddFabric = this.clickAddFabric.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.closeTrendBoardModal = this.closeTrendBoardModal.bind(this)
        this.openFabricDetail = this.openFabricDetail.bind(this)
        this.closeFabricDetail = this.closeFabricDetail.bind(this)
        this.openSelectColorBox = this.openSelectColorBox.bind(this)
        this.closeSelectColorBox = this.closeSelectColorBox.bind(this)
        this.handleColorChangeComplete = this.handleColorChangeComplete.bind(this)
        this.handleColorSelectAccept = this.handleColorSelectAccept.bind(this)
        this.handleColorSelectCancel = this.handleColorSelectCancel.bind(this)
        this.handleColorSelectDelete = this.handleColorSelectDelete.bind(this)
        this.deletePresentationVideo = this.deletePresentationVideo.bind(this)
        this.clickDeletePresentationVideo = this.clickDeletePresentationVideo.bind(this)
        this.closeDeleteVideoConfirmModal = this.closeDeleteVideoConfirmModal.bind(this)
        this.getPresentationData = this.getPresentationData.bind(this)
        this.getNeedToUploadImages = this.getNeedToUploadImages.bind(this)
        this.gotoUploadImage = this.gotoUploadImage.bind(this)

        this.changeMainImageFile = this.changeMainImageFile.bind(this)

        this.getTrendBoardContent = this.getTrendBoardContent.bind(this)
        this.deleteTrendBoardItem = this.deleteTrendBoardItem.bind(this)
        this.addFabricGroupInWhichTrendBoardIndex = this.addFabricGroupInWhichTrendBoardIndex.bind(this)
        this.getFabricGroupItem = this.getFabricGroupItem.bind(this)
        this.deleteFabricGroupItem = this.deleteFabricGroupItem.bind(this)
        this.makeDataIntoApiFormat = this.makeDataIntoApiFormat.bind(this)
        this.formatDataToSelectedTrendBoard = this.formatDataToSelectedTrendBoard.bind(this)
        this.formatDataToFabricGroup = this.formatDataToFabricGroup.bind(this)

        this.downloadMessage = this.downloadMessage.bind(this)
        this.removeAllMessage = this.removeAllMessage.bind(this)
        

        this.setDropRef = (element) => {
            this.dropRef = element
            let dropDev = this.dropRef
            if (dropDev) {
                dropDev.addEventListener('dragenter', this.handleDragIn)
                dropDev.addEventListener('dragleave', this.handleDragOut)
                dropDev.addEventListener('dragover', this.handleDrag)
                dropDev.addEventListener('drop', this.handleDrop)
            }
        }
        this.state = {
            presentationNo: props.params.presentationNo,
            presentationData: null,
            boardData: null,
            loading: false,
            description: null,
            firstImage: null,
            secendImage: null,
            thirdImage: null,
            color: null,
            template: null,
            showFabricModal: false,
            fabricListData: [],
            groupItemFabricListData: [], //單一group item裡面有選擇的布料
            currentModifyGroupItemIndex: 0, //目前是新增或是修改哪一個group item項目裡面的布料
            fabricSelectData: {},
            boardFabric1: null,
            boardFabric2: null,
            boardFabric3: null,
            fabricModalType: 'fabric',
            showFabricId: null,
            selectColorData: null,
            colorList: ['#6e4f3a', '#9c453b', '#5a2f43', '#7b7f32', '#b68a3a', '#46434a'],
            board2ImageList: [null, null, null, null, null, null],
            board1Status: 'on',
            board2Status: 'on',
            boardAData: {},
            boardBData: {},
            deletePresentationVideoConfirm: null,
            memberDatas: [], //會員資料
            mainImageData:'', //主要圖片的base64
            boardADataTemp: {},
            boardBDataTemp: {},
            showAddTrendBoardModal: false,
            selectedTrendboard: [], //目前選了哪些trendboard
            trendSelectListData: {},
            currentSelectTrendboardIndex: 0, //目前選到哪一個trendboard項目要進行編輯
            fabricGroup: [], //trendboard的布料群組
            latestMessageId: -1, //最後一筆聊天訊息id

            showAlert: false,
            alertMessage:'',
        }
    }

    componentWillMount() {}

    componentDidMount() {
        const authData = getAuth()
        if (!isEmpty(authData) && authData.id && authData.token) {
            let params = { memberId: authData.id, token: authData.token}
            this.props.getPresentationEmail(params,(res)=>{
                this.setState({memberDatas:res.payload.data})
            })
        }

        this.getPresentationData()
    }

    componentWillUnmount() {
        this.removeListener()
    }

    componentWillReceiveProps(nextProps) {
        const { presentationData: thisPresentationData } = this.props
        const { presentationData, params } = nextProps
        if (
            thisPresentationData !== presentationData &&
            params.presentationNo &&
            presentationData[params.presentationNo]
        ) {
            const boardData = presentationData[params.presentationNo].board || {}
            const fabricListData = presentationData[params.presentationNo].fabricList || []
            const boardFabricListData = boardData.A && boardData.A.fabric ? boardData.A.fabric : []
            let fabricSelectData = {}
            fabricListData.map((item) => {
                fabricSelectData[item.id] = item
            })
            //將回來的資料進行格式化
            const nSelectedTrendBoard = this.formatDataToSelectedTrendBoard(presentationData[params.presentationNo])

            const nFabricGroup = this.formatDataToFabricGroup(presentationData[params.presentationNo])
            this.setState({
                presentationData: presentationData[params.presentationNo],
                boardData: boardData,
                boardAData: boardData.A || {},
                boardBData: boardData.B || {},
                board1Status: boardData.A && boardData.A.status ? boardData.A.status : 'on',
                board2Status: boardData.B && boardData.B.status ? boardData.B.status : 'on',
                description: boardData.A && boardData.A.description ? boardData.A.description : '',
                color: boardData.A && boardData.A.color ? boardData.A.color : [],
                template: boardData.template || '',
                firstImage: boardData.A && boardData.A.firstImage ? boardData.A.firstImage : '',
                secendImage: boardData.A && boardData.A.secendImage ? boardData.A.secendImage : '',
                thirdImage: boardData.A && boardData.A.thirdImage ? boardData.A.thirdImage : '',
                fabricListData: fabricListData || [],
                fabricSelectData: fabricSelectData,
                boardFabric1: boardFabricListData[0] || null,
                boardFabric2: boardFabricListData[1] || null,
                boardFabric3: boardFabricListData[2] || null,
                mainImageData: presentationData[params.presentationNo].mainImage,
                selectedTrendboard: nSelectedTrendBoard,
                fabricGroup: nFabricGroup

            })
            this.getApiMessageList(presentationData[params.presentationNo].id)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    removeListener() {
        if (this.dropRef) {
            this.dropRef.removeEventListener('dragenter', this.handleDragIn)
            this.dropRef.removeEventListener('dragleave', this.handleDragOut)
            this.dropRef.removeEventListener('dragover', this.handleDrag)
            this.dropRef.removeEventListener('drop', this.handleDrop)
        }
    }

    handleDrag(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragOut(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDrop(e) {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.previewImage(e.dataTransfer.files, e.dataTransfer.name)
        }
    }

    getApiMessageList(id) {
        // const { presentationData } = this.state
        const { getMessageList } = this.props
        const authData = getAuth()
        if (!isEmpty(authData) && authData.id && authData.token && id) {
            getMessageList({ memberId: authData.id, token: authData.token, presentationId: id, ordering:'desc', pageSize: 1 }, (res) => {
                if (res.error) {
                    this.setState({
                        messageList: [],
                        messagePageInfo: {}
                    })
                }else{
                    if(res.payload.response.data.length>0){
                        this.setState({
                            latestMessageId:res.payload.response.data[0].id
                        })
                    }
                }
            })
        }
    }

    changeMainImageFile(e){
        const files = Array.from(e.target.files)
        if (files && files.length > 0) {
            files.map((item, index) => {
                let reader = new FileReader()
                reader.readAsDataURL(item)
                reader.onloadend = () => {
                    this.setState({
                        mainImageData: reader.result
                    })
                }
            })
        }
    }

    getPresentationData() {
        const { presentationNo } = this.state
        const { getPresentationData } = this.props
        const authData = getAuth()
        if (!isEmpty(authData) && authData.id && authData.token) {
            getPresentationData(presentationNo, { memberId: authData.id, token: authData.token }, (res) => {
                if (res.error) {
                    this.setState({
                        presentationData: {},
                    })
                }
            })
        }
    }

    changeBoardFile(e, template) {
        this.previewBoardImage(template, e.target.files, e.target.name)
    }

    previewImage(fileList, columnName) {
        const files = Array.from(fileList)
        if (files && files.length > 0) {
            const { boardAData } = this.state
            files.map((item, index) => {
                let reader = new FileReader()
                reader.readAsDataURL(item)
                reader.onloadend = () => {
                    boardAData[columnName] = reader.result
                    this.setState({
                        boardAData
                    })
                }
            })
        }
    }

    previewBoardImage(template, fileList, columnName) {
        const files = Array.from(fileList)
        if (files && files.length > 0) {
            files.map((item, index) => {
                let reader = new FileReader()
                reader.readAsDataURL(item)
                reader.onloadend = () => {
                    const templateDataParams = `board${template}Data`
                    const templateDataParamsTemp = `board${template}DataTemp`

                    this.state[templateDataParams][columnName] = reader.result
                    this.state[templateDataParamsTemp][columnName] = reader.result
                    this.state[templateDataParamsTemp][columnName+'File'] = item
                    this.setState({
                        [templateDataParams]: this.state[templateDataParams],
                        [templateDataParamsTemp]: this.state[templateDataParamsTemp]
                    })
                }
            })
        }
    }

    changeBoardStatus(status, columnName) {
        this.setState({
            [columnName]: status,
        })
    }

    formConfig() {
        const { presentationData } = this.state
        return {
            config: {},
            data: [
                {
                    component: 'Input',
                    type: 'text',
                    name: 'themeName',
                    value: presentationData.themeName,
                    placeholder: Lang.ROOM_FIELDS_THEME,
                    role: [{ name: 'required' }],
                },
                {
                    component: 'Select',
                    name: 'status',
                    value: presentationData.status,
                    placeholder: Lang.ROOM_FIELDS_STATUS,
                    role: [{ name: 'required' }],
                    options: [
                        { text: Lang.ROOM_FIELDS_STATUS_OPTIONS_DEFAULT, value: '' },
                        { text: Lang.ROOM_FIELDS_STATUS_PUBLIC, value: 'public' },
                        { text: Lang.ROOM_FIELDS_STATUS_PRIVATE, value: 'private' },
                    ],
                },
                {
                    component: 'AutoComplete',
                    name: 'invited',
                    value: presentationData.invited,
                    placeholder: Lang.ROOM_FIELDS_INVITED,
                    completeData: this.state.memberDatas || []
                },
            ],
        }
    }

    updatePresentation(params, callback = () => {}) {
        const { presentationNo } = this.props.params
        const config = {
            method: 'POST',
            body: JSON.stringify(params),
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        }
        fetch(`${process.env.REACT_APP_API_ROOT}/presentation/${presentationNo}`, config)
            .then((res) => res.json())
            .then((res) => {
                callback(res)
            })
    }


    getNeedToUploadImages(boardADataTemp, boardBDataTemp){
        let allUploadImages = []
        const board1ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage', 'fourthImage', 'fifthImage', 'sixthImage', 'seventhImage']
        const board2ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage', 'fourthImage', 'fifthImage', 'sixthImage']
        if(boardADataTemp){
            for(let i=0;i<board1ImageColumnMap.length;i++){
                if(boardADataTemp[`${board1ImageColumnMap[i]}`+'File']){
                    allUploadImages.push({boardName:'A',imageName:board1ImageColumnMap[i], imageFile:boardADataTemp[`${board1ImageColumnMap[i]}`+'File']})
                }
            }
        }
        if(boardBDataTemp){
            for(let i=0;i<board2ImageColumnMap.length;i++){
                if(boardBDataTemp[`${board2ImageColumnMap[i]}`+'File']){
                    allUploadImages.push({boardName:'B',imageName:board2ImageColumnMap[i], imageFile:boardBDataTemp[`${board2ImageColumnMap[i]}`+'File']})
                }
            }
        }

        return allUploadImages
    }

    gotoUploadImage(fileUploadDatas,uploadImagesNew ,fabricIds,mainImageData ,data){
        const now = new Date()
        const postTime = now.getTime()
        const authData = getAuth()
        let uploadDatas = fileUploadDatas
        let isUploadError = false
        let promiseArray = []
            
        for(let i=0;i<uploadImagesNew.length;i++){
            if(isUploadError){
                break
            }
            let fData = new FormData()
            fData.append('memberId', authData.id)
            fData.append('token', authData.token)
            fData.append('file', uploadImagesNew[i].imageFile)
            fData.append('columnName', uploadImagesNew[i].imageName)
            
            const config = {
                method: 'POST',
                body: fData,
            }
            promiseArray.push(new Promise((resolve,reject)=>{
                fetch(`${process.env.REACT_APP_API_ROOT}/presentation/image`, config)
                    .then((res) => res.json())
                    .then((res) => {
                        if(res.status=='success'){
                            //上傳成功
                            const fileUrl = res.data.url
                            if(uploadImagesNew[i].boardName=='A'){
                                uploadDatas[0][uploadImagesNew[i].imageName] = fileUrl
                            }else if(uploadImagesNew[i].boardName=='B'){
                                uploadDatas[1][uploadImagesNew[i].imageName] = fileUrl
                            }
                            resolve(fileUrl)
                        }else{
                            isUploadError = true
                            reject('error')
                        }
                    })
                })
            )
        }

        Promise.all(promiseArray)
        .then((res)=>{
            //這邊去更新房間api
            const params = Object.assign(data, {
                memberId: authData.id,
                token: authData.token,
                fabricIds: fabricIds,
                boardData: uploadDatas,
                mainImage: mainImageData
            })
            
            this.updatePresentation(params, (res) => {
                if (res.status === 'success') {
                    const resDate = new Date()
                    const resTime = resDate.getTime()
                    const diffTime = (resTime - postTime) / 1000
                    this.setState({
                        loading: false,
                    })
                    const presentationData = res.response
                    window.location.href = `/room/${presentationData.presentationNo}`
                }else{
                    this.setState({
                        loading: false,
                    })
                }
            })

        }).catch((error)=>{
            // rej(error)
            console.log('error = ',error)
        })

    }

    //把資料轉型成api可以吃的格式
    makeDataIntoApiFormat(fabricGroup , selectedTrendboard){
        let tabFormat = []
        tabFormat = selectedTrendboard.map((item,index)=>{
            let trendObj = {trendId:item.id }

            const mFG = fabricGroup[index].fabricGroups.map((item,index)=>{
                let mFGItem = {id:item.id,name:item.name}
                let mFabricIds = []
                for(let i=0;i<item.fabrics.length;i++){
                    const mF = item.fabrics[i]
                    mFabricIds.push(mF.id)
                }
                return Object.assign(mFGItem,{fabricIds:mFabricIds})
            })



            return Object.assign(trendObj ,{fabricGroups:mFG})
        })
        
        

        return tabFormat
    }

    handleSubmit(e, data) {
        // e.preventDefault()
        const {
            description,
            fabricSelectData,
            boardFabric1,
            boardFabric2,
            boardFabric3,
            boardAData,
            boardBData,
            board1Status,
            board2Status,
            mainImageData, //主圖的base64
            boardADataTemp,
            boardBDataTemp,
            fabricGroup,    //布料群組
            selectedTrendboard, //trendboard陣列
        } = this.state
        const authData = getAuth()

        const fabricIds = Object.keys(fabricSelectData)
        let boardFabricIds = []
        boardFabricIds.push(boardFabric1 ? boardFabric1.id : '')
        boardFabricIds.push(boardFabric2 ? boardFabric2.id : '')
        boardFabricIds.push(boardFabric3 ? boardFabric3.id : '')
        boardAData['boardFabricIds'] = boardFabricIds
        boardAData['description'] = description
        boardAData['template'] = 'A'
        boardAData['status'] = board1Status
        boardBData['template'] = 'B'
        boardBData['status'] = board2Status

        // const boardData = [boardAData, boardBData]
        // const uploadImagesNew = this.getNeedToUploadImages(boardADataTemp,boardBDataTemp)

        // let fileUploadDatas = boardData
        const tabData = this.makeDataIntoApiFormat(fabricGroup,selectedTrendboard)

        this.setState({
            loading: true,
        })

        // let fileUploadDatas = boardData

        if (!isEmpty(authData)) {
            //先去整理看有幾張圖片需要上傳
            // this.gotoUploadImage(fileUploadDatas,uploadImagesNew , fabricIds , mainImageData ,data)

            //這邊去更新房間api
            const params = Object.assign(data, {
                memberId: authData.id,
                token: authData.token,
                // fabricIds: fabricIds,
                // boardData: uploadDatas,
                tabs: tabData,
                mainImage: mainImageData
            })
            
            this.updatePresentation(params, (res) => {
                if (res.status === 'success') {
                    
                    this.setState({
                        loading: false,
                    })
                    const presentationData = res.response
                    window.location.href = `/room/${presentationData.presentationNo}`
                }else{
                    this.setState({
                        loading: false,
                    })
                }
            })
            
        }
    }

    handleChange(e) {
        const { target } = e
        const { name, value } = target
        this.setState({
            [name]: value,
        })
    }

    videoListView() {
        const { presentationData } = this.state
        const videos = presentationData.videos
        if (videos.length === 0) {
            return null
        }
        const videosView = videos.map((element, index) => {
            return (
                <div key={`videos_${index}`} className="col-4 col-md-4 layoutBlock videosItemBox">
                    <video
                        className="videosItem"
                        controls
                        src={element.filePath}
                    >
                    </video>
                    <div className="row">
                        <Link to={element.filePath} target="_blank" download className="downloadBtn" >
                            <img src={require('../images/icon_download.png')} />
                        </Link>

                        <div className="removeBtn" onClick={() => {
                            this.clickDeletePresentationVideo(element)
                        }}>
                            <img src={require('../images/icon_remove.png')} />
                        </div>
                    </div>
                </div>
            )
        })
        return videosView
    }

    boardView1() {
        const { boardAData } = this.state
        const drogDropView = (
            <div className="drogDropBlock">
                <div className="drogDropBox" ref={this.setDropRef}>
                    <div className="selectFileIcon">
                        <img src={require('../images/icon_plus.png')} />
                    </div>
                </div>
            </div>
        )
        const image1View = boardAData && boardAData.firstImage ? <Image src={boardAData.firstImage} /> : drogDropView
        const image2View = boardAData && boardAData.secendImage ? <Image src={boardAData.secendImage} /> : drogDropView
        const image3View = boardAData && boardAData.thirdImage ? <Image src={boardAData.thirdImage} /> : drogDropView
        const image4View = boardAData && boardAData.fourthImage ? <Image src={boardAData.fourthImage} /> : drogDropView
        const image5View = boardAData && boardAData.fifthImage ? <Image src={boardAData.fifthImage} /> : drogDropView
        const image6View = boardAData && boardAData.sixthImage ? <Image src={boardAData.sixthImage} /> : drogDropView
        const image7View = boardAData && boardAData.seventhImage ? <Image src={boardAData.seventhImage} /> : drogDropView
        return (
            <div className="board1ContentBox">
                <div className="leftSide">
                    <div className="boardImage">
                        {image1View}
                        <input
                            type="file"
                            name="firstImage"
                            className="chooseFile"
                            onChange={e => {
                                this.changeBoardFile(e, 'A')
                            }}
                        />
                    </div>
                    <div className="boardImage">
                        {image2View}
                        <input
                            type="file"
                            name="secendImage"
                            className="chooseFile"
                            onChange={e => {
                                this.changeBoardFile(e, 'A')
                            }}
                        />
                    </div>
                </div>
                <div className="rightSide">
                    <div className="topSide">
                        <div className="topLeftSide">
                            <div className="boardImage">
                                {image3View}
                                <input
                                    type="file"
                                    name="thirdImage"
                                    className="chooseFile"
                                    onChange={e => {
                                        this.changeBoardFile(e, 'A')
                                    }}
                                />
                            </div>
                        </div>
                        <div className="topRightSide">
                            <div className="boardImage">
                                {image4View}
                                <input
                                    type="file"
                                    name="fourthImage"
                                    className="chooseFile"
                                    onChange={e => {
                                        this.changeBoardFile(e, 'A')
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bottomSide">
                        <div className="bottomLeftSide">
                            <div className="boardImage">
                                {image5View}
                                <input
                                    type="file"
                                    name="fifthImage"
                                    className="chooseFile"
                                    onChange={e => {
                                        this.changeBoardFile(e, 'A')
                                    }}
                                />
                            </div>
                        </div>
                        <div className="bottomRightSide">
                            <div className="bottomRightTopSide">
                                <div className="boardImage">
                                    {image6View}
                                    <input
                                        type="file"
                                        name="sixthImage"
                                        className="chooseFile"
                                        onChange={e => {
                                            this.changeBoardFile(e, 'A')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="bottomRightBottomSide">
                                <div className="boardImage">
                                    {image7View}
                                    <input
                                        type="file"
                                        name="seventhImage"
                                        className="chooseFile"
                                        onChange={e => {
                                            this.changeBoardFile(e, 'A')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    boardView2() {
        const { boardBData } = this.state
        const drogDropView = (
            <div className="drogDropBlock">
                <div className="drogDropBox" ref={this.setDropRef}>
                    <div className="selectFileIcon">
                        <img src={require('../images/icon_plus.png')} />
                    </div>
                </div>
            </div>
        )
        const board2ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage', 'fourthImage', 'fifthImage', 'sixthImage']
        const board2ImageListView = board2ImageColumnMap.map((element, index) => {
            const imageView = boardBData && boardBData[element] ? <Image src={boardBData[element]} /> : drogDropView
            return (
                <div key={`board2Image_${index}`} className="col-4 col-md-4 layoutBlock boardFigures">
                    <div className="boardImage">
                        {imageView}
                        <input
                            type="file"
                            name={element}
                            className="chooseFile"
                            onChange={e => {
                                this.changeBoardFile(e, 'B')
                            }}
                        />
                    </div>
                </div>
            )
        })
        return (
            <div className="boardContentBlock">
                {board2ImageListView}
            </div>
        )
    }

    fabricListView() {
        const { fabricListData } = this.state
        const fabricListView = fabricListData.map((item, index) => {
            return (
                <div
                    key={`fabricItem_${index}`}
                    className="fabricItem col-6 col-md-2 layoutBlock"
                    onClick={() => {
                        this.openFabricDetail(item.id)
                    }}
                >
                    <div className="card">
                        <div className="fabricImage">
                            <Image src={item.images} />
                        </div>
                    </div>
                </div>
            )
        })
        return (
            <div className="fabricListView row">
                {fabricListView}
                <div
                    className="fabricItem col-6 col-md-2 layoutBlock addFabric"
                    onClick={() => {
                        this.clickAddFabric()
                    }}
                >
                    <div className="card">
                        <div className="addNewFabirc">
                            <Image src={require('../images/icon_plus.png')} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    groupItemfabricListView(groupItemIndex) {
        const { currentSelectTrendboardIndex ,fabricGroup  } = this.state
        const mFabrics = fabricGroup[currentSelectTrendboardIndex].fabricGroups[groupItemIndex].fabrics
        const fabricListView = mFabrics.map((item, index) => {
            return (
                <div key={`fabricItem_${index}`} className="fabricItem col-6 col-md-2 layoutBlock" style={{position:'relative'}}>
                    <div className="card">
                        <div className="fabricImage">
                            <Image src={item.images} />
                        </div>
                    </div>
                    <div className="close" onClick={(e)=>{
                            e.stopPropagation()
                            fabricGroup[currentSelectTrendboardIndex].fabricGroups[groupItemIndex].fabrics.splice(index,1)
                            // fabricGroup[currentSelectTrendboardIndex].fabricGroups[groupItemIndex].fabrics = newFabrics
                            this.setState({
                                groupItemFabricListData:fabricGroup[currentSelectTrendboardIndex].fabricGroups[groupItemIndex].fabrics,
                                fabricGroup:fabricGroup
                            })
                            // let check = confirm('Deleting this item will also delete the related fabric group')
                            // if (check) {
                            //     this.deleteTrendBoardItem(index)
                            // }
                            
                        }}>
                        <img src={require('../images/close-black.png')} />
                    </div>
                </div>
            )
        })
        return (
            // <div>
                <div className="fabricListView row" style={{padding:'0px 10px' ,marginBottom:'0px'}}>
                    {fabricListView}
                    <div
                        className="fabricItem col-6 col-md-2 layoutBlock addFabric"
                        onClick={() => {
                            this.setState({groupItemFabricListData:mFabrics,
                                currentModifyGroupItemIndex: groupItemIndex,
                                showFabricModal: true})
                        }}
                    >
                        <div className="card">
                            <div className="addNewFabirc">
                                <Image
                                    src={require('../images/icon_plus.png')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            // </div>
        )
    }

    fabricDetailView() {
        const { showFabricId } = this.state
        const displayModal = showFabricId ? true : false
        return (
            <FabricDetail
                fabricId={showFabricId}
                displayModal={displayModal}
                closed={() => {
                    this.closeFabricDetail()
                }}
            />
        )
    }

    fabricModalView() {
        const {
            showFabricModal,
            groupItemFabricListData, //單一group item裡面有選擇的布料
        } = this.state
        let selectedFabrics = []
        for(let i=0;i<groupItemFabricListData.length;i++){
            selectedFabrics.push(groupItemFabricListData[i])
        }
        let confirmSelect = this.confirmFabricListSelect
        let multiple = true
        
        return (
            <Modal displayModal={showFabricModal} closeModal={this.closeModal}>
                <FabricList
                    className="myFabricSelectContainer"
                    confirmSelect={confirmSelect}
                    selectedFabrics={selectedFabrics}
                    multiple={multiple}
                />
            </Modal>
        )
    }

    //選擇trendBoard的modal
    trendBoardModalView(){
        const {
            showAddTrendBoardModal,
        } = this.state
        let multiple = false
        return (
            <Modal displayModal={showAddTrendBoardModal} closeModal={this.closeTrendBoardModal}>
                <TrendBoardList
                    className="myTrendBoardSelectContainer"
                    confirmSelect={(d)=>{this.confirmTrendBoardSelect(d)}}
                    // selectedTrendboards={selectedTrendboards}
                    multiple={multiple}
                />
            </Modal>
        )
    }

    boardStatusView(columnName) {
        const statusState = this.state[columnName]
        return (
            <div className="statusBox">
                <div
                    className={`statusBtn ${statusState === 'on' ? 'active' : ''}`}
                    onClick={() => {
                        this.changeBoardStatus('on', columnName)
                    }}
                >
                    {Lang.ROOM_BOARD_STATUS_ON}
                </div>
                <div
                    className={`statusBtn ${statusState === 'off' ? 'active' : ''}`}
                    onClick={() => {
                        this.changeBoardStatus('off', columnName)
                    }}
                >
                    {Lang.ROOM_BOARD_STATUS_OFF}
                </div>
            </div>
        )
    }

    trendBoardAddBtn(){
        return (
            <div className="statusBox">
                <div
                    className={'statusBtn active'}
                    onClick={() => {
                        this.setState({showAddTrendBoardModal:true})
                    }}
                >
                    {'Add Board'}
                </div>
            </div>
        )
    }

    //刪除某個trendboard
    deleteTrendBoardItem(index){

        const {currentSelectTrendboardIndex , selectedTrendboard , fabricGroup} = this.state

        //把要刪除的項目從陣列中移除
        let nIndex = currentSelectTrendboardIndex

        if(index == selectedTrendboard.length-1){
            //如果刪除項目是最後一個項目
            nIndex = index-1
        }else if(index == currentSelectTrendboardIndex){
           //刪除到目前的焦點項目，所以焦點項目改為目前刪除項目的上一個
        }else if(index < currentSelectTrendboardIndex && currentSelectTrendboardIndex==selectedTrendboard.length-1){
            //刪除的項目比目前焦點項目還要前面
            nIndex = currentSelectTrendboardIndex-1
        }
        
        if(nIndex<0){
            nIndex = 0
        }

        let nSelectedTrendboard = selectedTrendboard.splice(index,1)
        fabricGroup.splice(index,1)        

        this.setState({
            currentSelectTrendboardIndex: nIndex,
            selectedTrendboard: selectedTrendboard,
            fabricGroup:fabricGroup
        })
    }
    
    //刪除某個布料群組
    deleteFabricGroupItem(index){
        const {currentSelectTrendboardIndex ,fabricGroup} = this.state

        let targetItemFg = fabricGroup[currentSelectTrendboardIndex].fabricGroups

        targetItemFg.splice(index,1)        

        fabricGroup[currentSelectTrendboardIndex].fabricGroups = targetItemFg

        this.setState({fabricGroup: fabricGroup})
        
    }

    getFabricGroupItem(item,index){
        //獲取一組布料groupItem
        const {currentSelectTrendboardIndex ,fabricGroup} = this.state
        const fabricListView = this.groupItemfabricListView(index)

        return (
            <div key={'fg_item_'+index} className='fabricGroupItem'>
                <div className="rightArea">
                    <div className="headContainer">
                        
                            <Input name={'item_'+index} value={item.name} placeholder={Lang.ROOM_FABRICGROUP_ITEM_TITLE_PLACEHOLDER} canShowError={false} onChange={(e)=>{
                                
                                let mFabricGroupds = fabricGroup[currentSelectTrendboardIndex].fabricGroups
                                mFabricGroupds[index].name = e.target.value
                                // this.setState({boardAtitle: e.target.value})
                            }}/>
                        
                        <div style={{flex:1 ,display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                            <button className="btn btn-primary" style={{padding:'5px 10px',backgroundColor:'#dc3545'}} onClick={()=>{
                                let check = confirm('Do you want to delete the group ?')
                                if (check) {
                                    this.deleteFabricGroupItem(index)
                                }}}
                            >
                                delete group
                            </button>
                        </div>
                    </div>
                    <div className="contentContainer">
                        {fabricListView}
                        {/* <div
                            className="fabricItem col-6 col-md-2 layoutBlock addFabric"
                            onClick={() => {
                                this.clickAddFabric('fabric')
                            }}
                        >
                            <div className="card">
                                <div className="addNewFabirc">
                                    <Image src={require('../images/icon_plus.png')} />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                
            </div>
        )
    }

    //獲取trendBoard裡面要呈現的畫面
    getTrendBoardContent(){
        const {selectedTrendboard,currentSelectTrendboardIndex ,fabricGroup} = this.state

        const tbListView = selectedTrendboard.map((item,index)=>{
            const activeClass = currentSelectTrendboardIndex == index? 'active' : ''
            return (
                <div
                    key={`tbItem_${index}`}
                    className={`tbItem col-6 col-md-3 layoutBlock ${activeClass}`}
                    onClick={() => {
                        this.setState({currentSelectTrendboardIndex:index})
                    }}
                >
                    <div className="card" style={{position:'relative'}}>
                        <div className="fabricImage">
                            <Image src={item.mainImage} className="card-img-top" />
                        </div>
                        <div className="card-body">
                            <p className="card-text textOverflow">{item.name}</p>
                        </div>
                        
                        <div className="close" onClick={(e)=>{
                            e.stopPropagation()
                            let check = confirm('Deleting this item will also delete the related fabric group')
                            if (check) {
                                this.deleteTrendBoardItem(index)
                            }
                            
                        }}>
                            <img src={require('../images/close-black.png')} />
                        </div>

                    </div>


                </div>
            )
        })        

        const fabricGroupList = fabricGroup[currentSelectTrendboardIndex] && fabricGroup[currentSelectTrendboardIndex].fabricGroups ? 
            fabricGroup[currentSelectTrendboardIndex].fabricGroups.map((item,index)=>{
                return this.getFabricGroupItem(item,index)
                
            }):null

        const addFabricGroupBtn = <button className="btn btn-primary" onClick={()=>this.addFabricGroupInWhichTrendBoardIndex()}>create fabric group</button>


        //呈現下面布料區域
        const fabricContainer = (
            <div className="fabricContainer">
                {fabricGroupList}
                {addFabricGroupBtn}
            </div>)
        const sperateLine = selectedTrendboard.length>0 ? <div style={{width:'100%',height:'1px',backgroundColor:'#eceff3',margin:'15px 0px'}}/> : null
        const fabricArea = selectedTrendboard.length>0 ? fabricContainer : null

        return (
        <div style={{display:'flex',flexDirection:'column'}}>
            <div className="trendBoardContainer">{tbListView}</div>
            {sperateLine}
            {fabricArea}
        </div>
        )
    }

    addFabricGroupInWhichTrendBoardIndex(){
        const {currentSelectTrendboardIndex ,fabricGroup} = this.state
        const fabricGroupItem = {
            id:'',
            name:'',
            fabrics:[]
        }
        let targetItemFg = fabricGroup[currentSelectTrendboardIndex].fabricGroups
        targetItemFg.push(fabricGroupItem)
        fabricGroup[currentSelectTrendboardIndex].fabricGroups = targetItemFg

        this.setState({fabricGroup: fabricGroup})
        
    }

    //點了布料modal的同意
    confirmFabricListSelect(fabricSelectData) {
        const fabricListData = Object.values(fabricSelectData)
        const {currentSelectTrendboardIndex ,fabricGroup ,currentModifyGroupItemIndex} = this.state
        fabricGroup[currentSelectTrendboardIndex].fabricGroups[currentModifyGroupItemIndex].fabrics = fabricListData

        this.setState({
            showFabricModal: false,
        })
    }

    //點了trendboard的同意
    confirmTrendBoardSelect(trendSelectDatas){
        const k = Object.keys(trendSelectDatas)
        const {selectedTrendboard} = this.state
        
        let cc = selectedTrendboard

        cc.push(trendSelectDatas[k])
        
        let fg = this.state.fabricGroup
        fg.push({fabricGroups:[]})
        
        this.setState({
            showAddTrendBoardModal: false,
            selectedTrendboard: cc,
            fabricGroup:fg
            // trendboardListData: trendboardListData,
        })   
        
    }

    confirmBoardFabricSelect(fabricSelectData, sortNo) {
        const boardFabricListData = Object.values(fabricSelectData)
        this.setState({
            showFabricModal: false,
            [`boardFabric${sortNo}`]: boardFabricListData[0],
        })
    }

    confirmBoardFabricSelect1(fabricSelectData) {
        this.confirmBoardFabricSelect(fabricSelectData, 1)
    }

    confirmBoardFabricSelect2(fabricSelectData) {
        this.confirmBoardFabricSelect(fabricSelectData, 2)
    }

    confirmBoardFabricSelect3(fabricSelectData) {
        this.confirmBoardFabricSelect(fabricSelectData, 3)
    }

    deletePresentationVideoConfirmView() {
        const { deletePresentationVideoConfirm } = this.state
        if (!deletePresentationVideoConfirm) {
            return null
        }
        const confirmText = Lang.ROOM_VIDEO_DELETE_CONFIRM_CONTENT + ' [' + deletePresentationVideoConfirm.name + '] ?'
        return (
            <div className="modal roomRemoveModel">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{Lang.ROOMLIST_DELETE_CONFIRM}</h5>
                        <div
                            className="closeImgBox"
                            onClick={() => {
                                this.closeDeleteVideoConfirmModal()
                            }}
                        >
                            <img src={require('../images/icon_x.png')} className="closeImg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        <p>{confirmText}</p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                this.deletePresentationVideo(deletePresentationVideoConfirm.id)
                            }}
                        >
                            {Lang.ROOMLIST_DELETE_CONFIRM_BTN}
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    clickAddFabric() {
        this.setState({
            showFabricModal: true,
        })
    }

    clickDeletePresentationVideo(videoObject) {
        this.setState({
            deletePresentationVideoConfirm: videoObject
        })
    }

    closeDeleteVideoConfirmModal() {
        this.setState({
            deletePresentationVideoConfirm: null
        })
    }

    deletePresentationVideo(videoId) {
        const { deletePresentationVideo, presentationNo } = this.props
        const authData = getAuth()
        if (!isEmpty(authData) && authData.id && authData.token) {
            deletePresentationVideo({
                memberId: authData.id,
                token: authData.token,
                presentationNo: presentationNo,
                presentationVideoId: videoId
            }, res => {
                this.setState({
                    deletePresentationVideoConfirm: null
                })
                this.getPresentationData()
            })
        }
    }

    closeModal(e) {
        this.setState({
            showFabricModal: false,
        })
    }
    
    closeTrendBoardModal(e) {
        this.setState({
            showAddTrendBoardModal: false,
        })
    }

    openFabricDetail(fabricId) {
        this.setState({
            showFabricId: fabricId,
        })
    }

    closeFabricDetail() {
        this.setState({
            showFabricId: null,
        })
    }

    openSelectColorBox(index, colorHex) {
        this.setState({
            selectColorData: {
                key: index,
                hex: colorHex,
            },
        })
    }

    closeSelectColorBox() {
        this.setState({
            selectColorData: null,
        })
    }

    handleColorChangeComplete(color) {
        const { selectColorData } = this.state
        selectColorData.hex = color.hex
        this.setState({
            selectColorData,
        })
    }

    handleColorSelectAccept() {
        const { selectColorData, color } = this.state
        if (selectColorData !== null) {
            color[selectColorData.key] = selectColorData.hex
            this.setState(color)
        }
        this.closeSelectColorBox()
    }

    handleColorSelectCancel() {
        this.closeSelectColorBox()
    }

    handleColorSelectDelete() {
        const { selectColorData, color } = this.state
        if (selectColorData !== null) {
            delete color[selectColorData.key]
            this.setState(color)
        }
        this.closeSelectColorBox()
    }
    
    //將回來的資料進行格式化成trendboard
    formatDataToSelectedTrendBoard(data){
        let nTabs = []
        if(data.tabs){
            data.tabs.map((item,index)=>{
                nTabs.push(item.trend)
            })
        }
        return nTabs
    }

    //將回來的資料進行格式化成fabricGroup
    formatDataToFabricGroup(data){
        let mFabricGroup = []
        if(data.tabs){
            data.tabs.map((item,index)=>{
                mFabricGroup.push({fabricGroups:item.fabricGroups})
            })
        }
        return mFabricGroup
    }

    downloadMessage(){
        const { presentationData } = this.state
        const authData = getAuth()
        let url = `${process.env.REACT_APP_API_ROOT}/chat/export?memberId=`+authData.id+'&token='+authData.token+'&presentationId='+presentationData.id
        return (
            <Link to={url} target="_blank" download className="downloadBtn" >
                <button className="btn btn-primary" style={{padding:'5px 10px',marginBottom:'10px'}} >
                    download message(csv)
                </button>
            </Link>
        )
        
    }

    removeAllMessage(){
        const { presentationNo , latestMessageId, presentationData } = this.state
        const authData = getAuth()
        const params = {memberId:authData.id, token:authData.token, presentationId:presentationData.id ,lastestChatId: latestMessageId}
        this.props.clearChatMessage(params ,(res)=>{
            if(res.payload.status=='success'){
                //成功
                this.setState({latestMessageId:-1 ,
                showAlert:true,
                alertMessage:'delete message success ~ '})
                
                
            }
            
        })
    }

    getAlertView(){
        if(this.state.showAlert){
            return <Alert content={this.state.alertMessage} close={()=>this.alertClose()} />
        }else{
            return <div />
        }
    }
    alertClose(){
        this.setState({showAlert:false,alertMessage:''})
    }

    render() {
        const { loading, presentationData ,mainImageData ,latestMessageId} = this.state
        const emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.ROOM_NOT_FOUND} content={Lang.ROOM_EMPTY_CONTENT} />
                <div className="btnBox">
                    <Link className="btn btn-primary" to={`/addRoom`}>
                        {Lang.ROOM_CREATE_ROOM}
                    </Link>
                </div>
            </div>
        )
        const drogDropView = (
            <div className="drogDropBlock">
                <div className="drogDropBox" ref={this.setDropRef}>
                    <div className="selectFileIcon">
                        <img src={require('../images/icon_plus.png')} />
                    </div>
                    {Lang.ROOM_MAIN_IMAGE}
                </div>
                
            </div>
        )
        let alertView = this.getAlertView()  //獲取alertView
        const mainImageView = mainImageData ? <Image src={mainImageData} /> : drogDropView
        const loadingView = presentationData === null || loading ? <Loading /> : null
        const fabricModalView = this.fabricModalView()
        const trendBoardModalView = this.trendBoardModalView()
        const fabricDetailView = this.fabricDetailView()

        const trendBoardContent = this.getTrendBoardContent()

        let contentView = null
        if (presentationData !== null) {
            if (presentationData.presentationNo) {
                const formConfig = this.formConfig()
                const videoListView = this.videoListView()
                const boardView1 = this.boardView1()
                const boardView2 = this.boardView2()
                // const fabricListView = this.fabricListView()
                const deletePresentationVideoConfirmView = this.deletePresentationVideoConfirmView()
                contentView = (
                    <div className="addRoomContainer">
                        <div className="titleBlock">{Lang.ROOM_TITLE}</div>
                        <div className="roomContentContainer">
                            <div className="layoutBlock col-12 col-md-4 roomInfoBlock">
                                <ContentBlock title={Lang.ROOM_INFORMATION} className="roomInfoContentBlock">
                                    <div style={{width:'100%',height:'100%' ,position:'relative'}}>
                                        {mainImageView}
                                        <input
                                            type="file"
                                            name="mainImage"
                                            className="chooseFile"
                                            onChange={e => {
                                                this.changeMainImageFile(e)
                                            }}
                                        />
                                    </div>
                                    <Form
                                        className="addRoomForm"
                                        onSubmit={this.handleSubmit}
                                        formConfig={formConfig}
                                        btnText={Lang.ROOM_UPDATE_SUBMIT}
                                        cancelLink="/myRoom"
                                    />
                                </ContentBlock>
                                {latestMessageId != -1 &&
                                    <ContentBlock title={Lang.ROOM_ACTIONS} className="roomInfoContentBlock">
                                        
                                        {this.downloadMessage()}
                                        <button className="btn btn-primary" style={{padding:'5px 10px',backgroundColor:'#dc3545'}} onClick={()=>{
                                            let check = confirm('Do you want to delete all message ?')
                                            if (check) {
                                                this.removeAllMessage()
                                            }}}>
                                            remove all message
                                        </button>


                                    </ContentBlock>
                                }
                            </div>
                            <div className="layoutBlock col-12 col-md-8 roomBoardBlock">
                                <ContentBlock title={Lang.ROOM_VIDEOS_TITLE} className="boardContainer videoListContainer">
                                    {videoListView}
                                    {deletePresentationVideoConfirmView}
                                </ContentBlock>
                                <div className="contentContainer boardContainer">
                                    <div className="titleBox">
                                        {Lang.ROOM_TREND_BOARD}
                                        {this.trendBoardAddBtn()}
                                    </div>
                                    <div className="contentBox">{trendBoardContent}</div>
                                </div>

                            </div>

                        </div>
                    </div>
                )
            } else {
                contentView = <div className="addRoomContainer">{emptyView}</div>
            }
        }
        return (
            <MemberBase>
                {loadingView}
                {alertView}
                {fabricModalView}
                {/* 顯示有幾個trendBoard可以讓他加入 */}
                {trendBoardModalView} 
                {contentView}
            </MemberBase>
        )
    }
}

RoomEdit.propTypes = {
    getPresentationData: PropTypes.func.isRequired,
    deletePresentationVideo: PropTypes.func.isRequired,
}

const mapStateToProps = () => (state) => {
    return {
        presentationData: state.presentation.presentationData,

    }
}

const mapDispatchToProps = {
    getPresentationData,
    deletePresentationVideo,
    getPresentationEmail,
    clearChatMessage,
    getMessageList
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomEdit)
