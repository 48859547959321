import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    errorMessage: '',
    member: {},
    memberDetail: {},

}

export default function(state = defaultState, action) {
    switch (action.type) {
        case ActionTypes.MEMBERLOGIN_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.MEMBERLOGIN_FAILURE:
            const memberFailRes = action.payload.response
            return Object.assign({}, state, {
                isDataFetching: false,
                errorMessage: memberFailRes.errorMessage
            })
        case ActionTypes.MEMBERLOGIN_SUCCESS:
            const loginRes = action.payload.data
            localStorage.setItem('auth', JSON.stringify(loginRes))
            return Object.assign({}, state, {
                isDataFetching: false,
                member: loginRes
            })
        case ActionTypes.MEMBERLOGOUT_SUCCESS:
            localStorage.removeItem('auth')
            localStorage.removeItem('memberData')
            return Object.assign({}, state, {
                member: {},
                memberDetail: {}
            })
        case ActionTypes.MEMBERDETAIL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.MEMBERDETAIL_FAILURE:
            localStorage.removeItem('auth')
            localStorage.removeItem('memberData')
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.MEMBERDETAIL_SUCCESS:
            const memberRes = action.payload.response
            localStorage.setItem('memberData', JSON.stringify(memberRes))
            return Object.assign({}, state, {
                isDataFetching: false,
                memberDetail: memberRes
            })
        default:
            return state
    }
}
