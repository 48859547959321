import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Validation from '../Libs/Validation'
import '../style/textareaHashtag.sass'

const KeyCodes = {
    comma: 188,
    enter: 13,
};

export default class TextareaHashtag extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '',
            errorMessage: '',
            currentTags: [],
        }

        this.myRefInput = React.createRef();
        this.handleChange = this.handleChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this);
        this.focusInput = this.focusInput.bind(this);
        this.getCurrentTagViews = this.getCurrentTagViews.bind(this)
        this.keyPress = this.keyPress.bind(this);
        this.onTagSizeChange = this.onTagSizeChange.bind(this)
        this.onFocusout = this.onFocusout.bind(this)
    }

    componentDidMount() {
        if(this.props.value){
            console.log('this.props.value = ',this.props.value)
            const tags = this.props.value.split(',');
            console.log('tags = ',tags)
            this.setState({currentTags:tags})
            //用逗點切割字串
        }
    }

    componentWillReceiveProps(prevProps, prevState) {
        const { triggerValidate: thisTriggerValidate ,} = this.props
        const { triggerValidate } = prevProps
        const { value ,currentTags } = this.state
        // console.log('---- currentTags = ',currentTags)
        // console.log('---- prevState.currentTags = ',prevState.currentTags)
        if (thisTriggerValidate !== triggerValidate) {
            this.handleValidation(value)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.currentTags.length != nextState.currentTags.length){
            this.onTagSizeChange(nextState.currentTags)
        }
        return true
    }

    handleValidation(value) {
        const { role } = this.props
        let status = true
        if (role && role.length > 0) {
            let i
            for (i = 0; i < role.length; i++) {
                const item = role[i]
                const validate = new Validation()
                if (validate[item.name]) {
                    const validateInfo = validate[item.name](value)
                    if (!validateInfo.status) {
                        status = false
                        this.setState({
                            errorMessage: validateInfo.errorMessage,
                        })
                        break
                    }
                    this.setState({
                        errorMessage: '',
                    })
                }
            }
        }
        return status
    }

    handleChange(e) {
        const { onChange } = this.props
        const { value } = e.target
        
        this.setState({
            inputValue: value,
        })
    }

    handleDelete(i) {
        const { currentTags } = this.state;
        this.setState({
            currentTags: currentTags.filter((tag, index) => index !== i),
        });
    }

    focusInput() {
        this.myRefInput.focus()
    }
    
    getCurrentTagViews() {
        const items = this.state.currentTags.map((item,index) => {
            return (
                <div
                    key={'index_'+index}
                    className="cusTagsItem"
                >
                    {item}
                    <img
                        src={require('../images/icon_x.png')}
                        className="cusTagsItemDeleteIcon"
                        onClick={() => {
                            this.handleDelete(index)
                        }}
                    />
                </div>
            )
        })
        
        return (
            items
        )
    }

    keyPress(e) {
        if (e.keyCode == KeyCodes.enter) {
            if (this.state.inputValue.trim().length > 0) {
                this.setState(state => ({ currentTags: [...state.currentTags, this.state.inputValue] ,inputValue:''}));
            }
        }
    }

    onTagSizeChange(currentTagArray) {
        if (this.props.onTagSizeChange) {
            this.props.onTagSizeChange(this.makeTagArrayToString(currentTagArray))
        }
    }

    onFocusout() {
        //輸入匡失去焦點
        if (this.state.inputValue) {
            if (this.state.inputValue.trim().length > 0) {
                this.setState(state => ({ currentTags: [...state.currentTags, this.state.inputValue] ,inputValue:''}));
            }
        }
    }

    makeTagArrayToString(tags) {
        let tagString = ''
        for (let i = 0; i < tags.length; i++) {
            if( i == tags.length - 1 ) {
                //最後一個，所以不用逗號
                tagString = tagString + tags[i]
            } else {
                tagString = tagString + tags[i] + ','
            }
        }
        return tagString
    }

    render() {
        const {  className, idName, placeholder } = this.props
        const { errorMessage,inputValue} = this.state
        const inputClassName = className || ''
        const inputIdName = idName || ''
        const inputPlaceholder = placeholder || ''
        return (
             <div className={className?className:'textareaHashtagContainer'} onClick={()=>{
                 this.focusInput()
             }}>
                {this.getCurrentTagViews()}
                <input style={{width:'100px',height:'fit-content',margin:'5px 4px'}} 
                    placeholder={'input tag'}
                    ref={(input)=>{this.myRefInput = input}} 
                    onChange={this.handleChange}
                    onKeyDown={this.keyPress}
                    value={inputValue}
                    onBlur={this.onFocusout}
                />
            </div>
        )
    }
}

TextareaHashtag.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    valueKey: PropTypes.string, 
    type: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    role: PropTypes.array,
    triggerValidate: PropTypes.bool,
    onTagSizeChange:PropTypes.func
}
