import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../style/modal.sass'

export default class Modal extends Component {
    constructor(props) {
        super(props)
        this.closeModal = this.closeModal.bind(this)
        this.state = {
            display: props.displayModal || false,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('in Modal.jsx shouldComponentUpdate()')
        if (this.state !== nextState) {
            return true
        }
        if (this.state.children != nextProps.children) {
            return true
        }

        return false
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.displayModal !== this.props.displayModal) {
            this.setState({ display: nextProps.displayModal })
        }
    }

    closeModal(e) {
        e.stopPropagation()
        const { closeModal } = this.props
        this.setState({
            display: false,
        })
        if (closeModal) {
            closeModal(e)
        }
    }

    render() {
        const { children } = this.props
        const { display } = this.state
        const divStyle = {
            display: display ? 'flex' : 'none',
        }
        return (
            <div className="modal" onClick={this.closeModal} style={divStyle}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="close" onClick={this.closeModal}>
                        <img src={require('../images/close-black.png')} />
                    </div>
                    {children}
                </div>
            </div>
        )
    }
}

Modal.propTypes = {
    displayModal: PropTypes.bool,
    closeModal: PropTypes.func,
}
