import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isEmpty, getAuth, getPresentationType } from '../Libs/Lib';
import { getTrendBoardDetail } from '../actions/trendboard';
import { Link } from 'react-router';
import * as Lang from '../Lang/default';
import Empty from '../components/Empty';
import Image from '../components/Image';
import Loading from '../components/Loading';
import MemberBase from './MemberBase';
import ContentBlock from '../components/ContentBlock';
import '../style/room.sass';
import '../style/fabricListView.sass';

const LoadingShowTime = 500; //loading最少要顯示的時間

class TrendDetail extends Component {
  constructor(props) {
    super(props);
    this.changeFile = this.changeFile.bind(this);
    this.getTrendBoardItemData = this.getTrendBoardItemData.bind(this);
    this.showLoading = this.showLoading.bind(this);
    this.hideLoading = this.hideLoading.bind(this);

    this._isMounted = false;
    this.state = {
      trendId: props.params.trendId, //要編輯的項目id
      alreadyCallApi: false,
      loading: false,
      loadingStartTime: 0,
      trendName: '',
      trendBoardDetailData: {},

      memberName: '',
      customerName: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getTrendBoardItemData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    const { trendBoardDetailData: thisTrendBoardDetailData } = this.state;
    const { trendBoardDetailData } = nextProps;

    if (
      trendBoardDetailData &&
      thisTrendBoardDetailData != trendBoardDetailData
    ) {
      //資料回來了
      this.hideLoading();
      const boardData = trendBoardDetailData.data.board;

      console.log('boardData', boardData);

      this.setState({
        trendBoardDetailData: trendBoardDetailData.data,
        trendName: trendBoardDetailData.data.name,
        boardData: boardData,
        boardAData: boardData.A || {},
        boardBData: boardData.B || {},
        boardCData: boardData.C || {},
        boardDData: boardData.D || {},
        boardEData: boardData.E || {},
        boardFData: boardData.F || {},
        board1Status:
          boardData.A && boardData.A.status ? boardData.A.status : 'on',
        board2Status:
          boardData.B && boardData.B.status ? boardData.B.status : 'on',
        board3Status:
          boardData.C && boardData.C.status ? boardData.C.status : 'on',
        board4Status:
          boardData.D && boardData.D.status ? boardData.D.status : 'on',
        board5Status:
          boardData.E && boardData.E.status ? boardData.E.status : 'on',
        board6Status:
          boardData.F && boardData.F.status ? boardData.F.status : 'on',
        description:
          boardData.A && boardData.A.description ? boardData.A.description : '',

        boardAtitle: boardData.A.description,
        boardBtitle: boardData.B.description,
        boardCtitle: boardData.C.description,
        boardDtitle: boardData.D.description,
        boardEtitle: boardData.E.description,
        boardFtitle: boardData.F.description,

        alreadyCallApi: true,
      });
    } else {
      this.setState({
        alreadyCallApi: true,
      });
    }
  }

  showLoading() {
    const resDate = new Date();
    const resTime = resDate.getTime();
    this.setState({ loading: true, loadingStartTime: resTime });
  }

  hideLoading() {
    if (this.state.loading) {
      const resDate = new Date();
      const endTime = resDate.getTime();
      const diffTime = endTime - this.state.loadingStartTime;
      if (diffTime < LoadingShowTime) {
        const leftTime = LoadingShowTime - diffTime;
        setTimeout(() => {
          this.setState({ loading: false, loadingStartTime: 0 });
        }, leftTime);
      } else {
        this.setState({ loading: false, loadingStartTime: 0 });
      }
    }
  }

  //獲取明細資料
  getTrendBoardItemData() {
    const { trendId } = this.state;
    const authData = getAuth();
    if (!isEmpty(authData) && authData.id && authData.token) {
      this.showLoading();
      let params = { memberId: authData.id, token: authData.token };
      this.props.getTrendBoardDetail(params, trendId);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  boardView1() {
    const { trendBoardDetailData } = this.state;
    const boardData = trendBoardDetailData.board.A || {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const image1View = <Image src={boardData.firstImage} />;
    const image2View = <Image src={boardData.secendImage} />;
    const image3View = <Image src={boardData.thirdImage} />;
    const image4View = <Image src={boardData.fourthImage} />;
    const image5View = <Image src={boardData.fifthImage} />;
    const image6View = <Image src={boardData.sixthImage} />;
    const image7View = <Image src={boardData.seventhImage} />;
    return (
      <ContentBlock
        title={boardData.description}
        className="boardBlockView boardContainer"
      >
        <div className="board1ContentBox">
          <div className="leftSide">
            <div className="boardImage">{image1View}</div>
            <div className="boardImage">{image2View}</div>
          </div>
          <div className="rightSide">
            <div className="topSide">
              <div className="topLeftSide">
                <div className="boardImage">{image3View}</div>
              </div>
              <div className="topRightSide">
                <div className="boardImage">{image4View}</div>
              </div>
            </div>
            <div className="bottomSide">
              <div className="bottomLeftSide">
                <div className="boardImage">{image5View}</div>
              </div>
              <div className="bottomRightSide">
                <div className="bottomRightTopSide">
                  <div className="boardImage">{image6View}</div>
                </div>
                <div className="bottomRightBottomSide">
                  <div className="boardImage">{image7View}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentBlock>
    );
  }

  boardView2() {
    const { trendBoardDetailData } = this.state;
    const boardData = trendBoardDetailData.board.B || {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board2ImageColumnMap = [
      'firstImage',
      'secendImage',
      'thirdImage',
      'fourthImage',
      'fifthImage',
      'sixthImage',
    ];
    const board2ImageListView = board2ImageColumnMap.map((element, index) => {
      return (
        <div
          key={`board2Image_${index}`}
          className="col-4 col-md-4 layoutBlock boardFigures"
        >
          <div className="boardImage">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={boardData.description}
        className="boardBlockView boardContainer"
      >
        <div className="boardContentBlock">{board2ImageListView}</div>
      </ContentBlock>
    );
  }

  boardView3() {
    const { trendBoardDetailData } = this.state;
    const boardData = trendBoardDetailData.board.C || {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board3ImageColumnMap = ['firstImage', 'secendImage'];
    const board3ImageListView = board3ImageColumnMap.map((element, index) => {
      return (
        <div
          key={`board3Image_${index}`}
          className="col-4 col-md-4 layoutBlock boardFigures"
        >
          <div className="boardImage board3Image">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={boardData.description}
        className="boardBlockView boardContainer"
      >
        <div className="boardContentBlock boardContent3">
          {board3ImageListView}
        </div>
      </ContentBlock>
    );
  }

  boardView4() {
    const { trendBoardDetailData } = this.state;
    const boardData = trendBoardDetailData.board.D || {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board4ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage'];
    const board4ImageListView = board4ImageColumnMap.map((element, index) => {
      return (
        <div key={`board4Image_${index}`} className="layoutBlock boardFigures">
          <div className="boardImage">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={boardData.description}
        className="boardClockView boardContainer"
      >
        <div className="boardContentBlock boardContent4">
          {board4ImageListView}
        </div>
      </ContentBlock>
    );
  }

  boardView5() {
    const { trendBoardDetailData } = this.state;
    const boardData = trendBoardDetailData.board.E || {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board5ImageColumnMap = ['firstImage', 'secendImage'];
    const board5ImageListView = board5ImageColumnMap.map((element, index) => {
      return (
        <div
          key={`board5Image_${index}`}
          className="layoutBlock boardFigures boardView5"
        >
          <div className="boardImage board5Image">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={boardData.description}
        className="boardBlockView boardContainer"
      >
        <div className="boardContentBlock boardContent5">
          {board5ImageListView}
        </div>
      </ContentBlock>
    );
  }

  boardView6() {
    const { trendBoardDetailData } = this.state;
    const boardData = trendBoardDetailData.board.F || {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board6ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage'];
    const board6ImageListView = board6ImageColumnMap.map((element, index) => {
      return (
        <div
          key={`board6Image_${index}`}
          className="layoutBlock boardFigures boardView3"
        >
          <div className="boardImage">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={boardData.description}
        className="boardBlockView boardContainer"
      >
        <div className="boardContentBlock boardContent6">
          {board6ImageListView}
        </div>
      </ContentBlock>
    );
  }

  changeFile(e) {
    this.previewImage(e.target.files, e.target.name);
  }

  previewImage(fileList, columnName) {
    const files = Array.from(fileList);
    if (files && files.length > 0) {
      files.map((item, index) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onloadend = () => {
          this.setState({
            postImage: reader.result,
          });
        };
      });
    }
  }

  render() {
    const { trendBoardDetailData, loading, alreadyCallApi } = this.state;
    const emptyView = alreadyCallApi ? (
      <div className="emptyBlock">
        <Empty
          title={Lang.TREND_NOT_FOUND}
          content={Lang.TREND_EMPTY_CONTENT}
        />
        <div className="btnBox">
          <Link className="btn btn-primary" to={`/addTrend`}>
            {Lang.TREND_CREATE_TRENDBOARD}
          </Link>
        </div>
      </div>
    ) : null;
    const loadingView = loading ? <Loading /> : null;
    let contentView = null;
    if (trendBoardDetailData !== null) {
      if (trendBoardDetailData.id) {
        const boardView1 = this.boardView1();
        const boardView2 = this.boardView2();
        const boardView3 = this.boardView3();
        const boardView4 = this.boardView4();
        const boardView5 = this.boardView5();
        const boardView6 = this.boardView6();
        contentView = (
          <div className="roomContainer">
            <div className="layoutBlock titleBlock">
              <div className="themeInfoBox">
                <div className="themeName">{trendBoardDetailData.name}</div>
              </div>
            </div>
            {boardView1}
            {boardView2}
            {boardView3}
            {boardView4}
            {boardView5}
            {boardView6}
          </div>
        );
      } else {
        contentView = <div className="roomContainer">{emptyView}</div>;
      }
    }
    return (
      <MemberBase>
        {loadingView}
        {contentView}
      </MemberBase>
    );
  }
}

TrendDetail.propTypes = {};

const mapStateToProps = () => (state) => {
  return {
    trendBoardDetailData: state.trendboard.trendboardDetailData,
  };
};

const mapDispatchToProps = {
  getTrendBoardDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendDetail);
