import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

function fetchGetTextipFabricDatas(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.TEXTIPFABRIC_LIST_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.TEXTIPFABRIC_LIST_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.TEXTIPFABRIC_LIST_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/fabric',
            query: params,
        },
    }
}

export function getTextipFabricDatas(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetTextipFabricDatas(params)).then((res) => {
            callback(res)
        })
    }
}
