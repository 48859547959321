import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import * as Lang from '../Lang/default'
import PropTypes from 'prop-types'
import { getAuth, isEmpty , getMemberData as getLibMemberData ,checkPresentationTypeIsNone ,getPresentationType} from '../Libs/Lib'
import { getMemberData ,} from '../actions/member'
import MemberBase from '../containers/MemberBase'
import { getCatalogList ,deleteCatalogItem} from '../actions/catalog'
import Empty from '../components/Empty'
import CatalogItem from '../components/CatalogItem'
import Loading from '../components/Loading'

const LoadingShowTime = 500 //loading最少要顯示的時間

class Catalog extends Component {
    constructor(props) {
        super(props)
        this.getApiCatalogList = this.getApiCatalogList.bind(this)
        this.deleteTargetItem = this.deleteTargetItem.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.showLoading = this.showLoading.bind(this)
        this.hideLoading = this.hideLoading.bind(this)

        this.state = {
            loading: false,
            loadingStartTime: 0,

            myCatalogListData: null,
            openDeleteTargetItemConfirm: null,
            openDeleteTargetItemTitleConfirm: '',
        }
    }

    componentDidMount() {

        this.getApiCatalogList()
    }

    componentWillReceiveProps(nextProps) {
        const { catalogListData: thisCAtalogListData, memberDetail: thisMemberDetail ,deleteCatalogData: thisDeleteCatalogData} = this.props
        const { catalogListData, memberDetail ,deleteCatalogData} = nextProps
        if (thisCAtalogListData !== catalogListData) {
            this.hideLoading()
            this.setState({
                myCatalogListData: catalogListData.data,
            })
        }
        //檢查刪除狀態
        if(thisDeleteCatalogData != deleteCatalogData && deleteCatalogData){
            if(deleteCatalogData.status=='success'){
                this.hideLoading()
                //刪除成功，刷新列表
                this.getApiCatalogList()
            }
        }

        if (thisMemberDetail !== memberDetail && thisMemberDetail && memberDetail && thisMemberDetail.id !== memberDetail.id) {
            this.getApiCatalogList()
        }
    }

    showLoading() {
        const resDate = new Date()
        const resTime = resDate.getTime()
        this.setState({loading:true ,loadingStartTime:resTime})
    }

    hideLoading() {
        if(this.state.loading){
            const resDate = new Date()
            const endTime = resDate.getTime()
            const diffTime = endTime - this.state.loadingStartTime
            if(diffTime<LoadingShowTime){
                const leftTime = LoadingShowTime - diffTime
                setTimeout(()=>{
                    this.setState({loading:false ,loadingStartTime:0})
                },leftTime)
            }else{
                this.setState({loading:false ,loadingStartTime:0})
            }
            
        }
    }

    getApiCatalogList() {
        const isNone = checkPresentationTypeIsNone()
        if(!isNone){
            //如果不是none才能抓資料，避免知道路徑自己打
            const authData = getAuth()
            if (!isEmpty(authData) && authData.id && authData.token) {
                const mData = getLibMemberData();
                if(mData == null){
                    //去獲取memberData資料
                    this.props.getMemberData(authData.id)
                }
                this.showLoading()
                let params = { memberId: authData.id, token: authData.token}
                this.props.getCatalogList(params)
            }
        }else{
            this.setState({myCatalogListData:[]})
        }
    }

    deleteTargetItem(id,title) {
        this.setState({openDeleteTargetItemConfirm:id ,openDeleteTargetItemTitleConfirm:title})
    }

    deleteTargetItemConfirmView() {
        const { openDeleteTargetItemConfirm , openDeleteTargetItemTitleConfirm} = this.state
        if (!openDeleteTargetItemConfirm) {
            return null
        }
        const confirmText = Lang.CATALOG_DELETE_CATALOG_CONFIRM + ' [' + openDeleteTargetItemTitleConfirm + '] ?'
        return (
            <div className="modal roomRemoveModel">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{Lang.ROOMLIST_DELETE_CONFIRM}</h5>
                        <div
                            className="closeImgBox"
                            onClick={() => {
                                this.closeModal()
                            }}
                        >
                            <img src={require('../images/icon_x.png')} className="closeImg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        <p>{confirmText}</p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                //打api刪除項目
                                // openDeleteTargetItemConfirm
                                const authData = getAuth()
                                if (!isEmpty(authData) && authData.id && authData.token) {
                                    let params = { memberId: authData.id, token: authData.token}
                                    // this.props.getCatalogList(params)
                                    this.props.deleteCatalogItem(JSON.stringify(params) ,this.state.openDeleteTargetItemConfirm)
                                    this.showLoading()
                                    this.setState({openDeleteTargetItemConfirm:null})
                                }
                            }}
                        >
                            {Lang.ROOMLIST_DELETE_CONFIRM_BTN}
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    closeModal() {
        this.setState({
            openDeleteTargetItemConfirm: null,
            openDeleteTargetItemTitleConfirm: ''
        })
    }
    
    render() {
        const {myCatalogListData ,openDeleteTargetItemConfirm ,loading}  = this.state

        const memberType = getPresentationType()
        //以下兩種身份才可創建目錄
        let canShowCreateBtn = (memberType=='manager' || memberType=='sales')?true:false

        const emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.CATALOG_EMPTY_TITLE} content={Lang.CATALOG_EMPTY_CONTENT} />
                {canShowCreateBtn &&
                    <div className="btnBox">
                        <Link className="btn btn-primary" to={`/addCatalog`}>
                            {Lang.CATALOG_CREATE_CATALOG}
                        </Link>
                    </div>
                }
            </div>
        )
        let loadingView = <div />
        if(loading){
            loadingView = <Loading />
        }
        let contentView = null
        let deleteConfirmView = <div />
        if(openDeleteTargetItemConfirm){
            deleteConfirmView = this.deleteTargetItemConfirmView();
        }
        if (myCatalogListData !== null) {
            if (myCatalogListData.length > 0) {
                const catalogListView = myCatalogListData.map((item, index) => {
                    return (
                        <div className="catalogListViewItem" key={`catalog_${index}`}>
                            <CatalogItem data={item} 
                                clickPreview={(id)=>{
                                    this.props.router.push('/catalogDetail/'+id)
                                }} 
                                clickEdit={(id)=>{
                                    this.props.router.push('addCatalog/'+id)
                                }}
                                removeFunc={(id,title)=>{
                                    this.deleteTargetItem(id ,title)    
                                }}
                                />
                        </div>
                    )
                })
                contentView = (
                    <div className="catalogListBlock">
                        <div className="roomListTitleBlock">
                            <div style={{flex:1}}/>
                            {canShowCreateBtn &&
                                 <div className="btnBox">
                                    <Link className="btn btn-primary" to={`/addCatalog`}>
                                        {Lang.CATALOG_CREATE_CATALOG}
                                    </Link>
                                </div>
                            }
                            
                        </div>
                        <div className="catalogListView">{catalogListView}</div>
                    </div>
                )
            } else {
                contentView = emptyView
            }
        }
        return (
            <MemberBase validUseLevel={2}>
                <div className="catalogListContainerBase">
                    {deleteConfirmView}
                    {contentView}
                    {loadingView}
                </div>
            </MemberBase>
        )
    }
}


Catalog.propTypes = {
    catalogListData: PropTypes.object,
}

const mapStateToProps = () => (state) => {
    return {
        catalogListData: state.catalog.catalogListData,
        deleteCatalogData: state.catalog.deleteCatalogData, //刪除之後回傳的狀態
        member: state.member.member,
        memberDetail: state.member.memberDetail,
    }
}

const mapDispatchToProps = {
    getCatalogList,
    deleteCatalogItem,
    getMemberData,
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog)
