import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import pptxgen from 'pptxgenjs';
import * as Lang from '../Lang/default';
import Empty from '../components/Empty';
import MemberBase from '../containers/MemberBase';
import { getAuth, isEmpty } from '../Libs/Lib';
import { getMyFabricDatas, getMyFabricWithId } from '../actions/myFabric';
import { getTextipFabricDatas } from '../actions/textipFabric';
import { getCategoryColorData } from '../actions/category';
import { getMessageDetail } from '../actions/chat';
import Loading from '../components/Loading';
import Image from '../components/Image';
import Input from '../components/Input';
import Button from '../components/Button';
import Pagination from '../components/Pagination';
import FabricDetail from '../components/FabricDetail';

import '../style/fabric.sass';
import '../style/fabricManagement.sass';
import CustomerModal from '../components/Common/CustomerModal';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXmark } from '@fortawesome/free-solid-svg-icons';

class FabricManagement extends Component {
  constructor(props) {
    super(props);
    this.clickFabricDataType = this.clickFabricDataType.bind(this);
    this.getFabricDatas = this.getFabricDatas.bind(this);
    this.getMyFabricDatas = this.getMyFabricDatas.bind(this);
    this.getTextipFabricDatas = this.getTextipFabricDatas.bind(this);
    this.getCategoryColorData = this.getCategoryColorData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.apiParams = this.apiParams.bind(this);
    this.handleFabricSearchChange = this.handleFabricSearchChange.bind(this);
    this.goPage = this.goPage.bind(this);
    this.colorListView = this.colorListView.bind(this);
    this.clickColorItem = this.clickColorItem.bind(this);
    this.checkIsActiveColor = this.checkIsActiveColor.bind(this);
    this.cusTagsListView = this.cusTagsListView.bind(this);
    this.clickCusTagsItem = this.clickCusTagsItem.bind(this);
    this.checkIsActiveCusTags = this.checkIsActiveCusTags.bind(this);
    this.changeFile = this.changeFile.bind(this);
    this.removeImageSearch = this.removeImageSearch.bind(this);
    this.postImageSearchFabricNos = this.postImageSearchFabricNos.bind(this);
    this.patternSearchClick = this.patternSearchClick.bind(this);
    this.conceptSearchClick = this.conceptSearchClick.bind(this);
    this.conceptAreaClick = this.conceptAreaClick.bind(this);
    this.openFabricDetail = this.openFabricDetail.bind(this);
    this.closeFabricDetail = this.closeFabricDetail.bind(this);
    this.openFilter = this.openFilter.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
    this.handleSetBatchOn = this.handleSetBatchOn.bind(this);
    this.handleSelectedFabric = this.handleSelectedFabric.bind(this);
    this.handleFabricModalOpen = this.handleFabricModalOpen.bind(this);
    this.handleFabricModalClose = this.handleFabricModalClose.bind(this);
    this.handleCancelBatch = this.handleCancelBatch.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.state = {
      loading: false,
      listMyFabric: null,
      listTextipFabric: null,
      colorList: [],
      minPrice: '',
      maxPrice: '',
      minWeight: '',
      maxWeight: '',
      keyword: '',
      colorSelected: [],
      functionTagsSelected: [],
      attributeTagsSelected: [],
      otherTagsSelected: [],
      page: 1,
      textipPage: 1,
      searchImage: '',
      fabricNos: null,
      imageSearchType: 'pattern',
      conceptSearchData: null,
      conceptAreaActive: null,
      fabricDataType: 'new',
      showFabricId: null,
      showFilterBlock: false,
      isBatch: false,
      fabricContainer: [],
      isFabricModalOpen: false,
      fabricDataList: [],
    };
  }

  componentDidMount() {
    const authData = getAuth();
    const { getMessageDetail } = this.props;
    // console.log('MSG DETAIL', getMessageDetail)
    if (!isEmpty(authData)) {
      const query = this.props.location.query;
      if (query.messageId) {
        getMessageDetail(
          {
            memberId: authData.id,
            token: authData.token,
            id: query.messageId,
          },
          (res) => {
            const resData = res.payload.response;
            if (resData.postImage) {
              this.setState({
                searchImage: resData.postImage,
              });
            }
            this.postImageSearchFabricNos();
          }
        );
      } else {
        this.getFabricDatas();
      }
    }
    this.getCategoryColorData();
  }

  componentWillReceiveProps(nextProps) {
    const {
      member: thisMember,
      listMyFabric: thisListMyFabric,
      listTextipFabric: thisListTextipFabric,
      colorList: thisColorList,
      route,
    } = this.props;
    const {
      member,
      listMyFabric,
      listTextipFabric,
      colorList,
      myFabricDataItem,
    } = nextProps;

    console.log('DATAAAAAA', myFabricDataItem);
    console.log('CURRENT PROPS', this.props);
    console.log('NEXT PROPS', nextProps);

    if (listMyFabric !== thisListMyFabric) {
      this.setState({ listMyFabric: listMyFabric, loading: false });
    }

    if (listTextipFabric !== thisListTextipFabric) {
      this.setState({ listTextipFabric: listTextipFabric, loading: false });
    }

    if (colorList !== thisColorList) {
      this.setState({ colorList: colorList });
    }

    if (
      myFabricDataItem &&
      this.props.myFabricDataItem.id !== nextProps.myFabricDataItem.id
    ) {
      if (myFabricDataItem.images_detail) {
        const temp = [];
        temp.push(myFabricDataItem);
        this.setState({
          fabricDataList: [...this.state.fabricDataList, ...temp],
          loading: false,
        });
      }
    }

    if (
      thisMember !== member &&
      thisMember &&
      member &&
      thisMember.id !== member.id
    ) {
      this.getFabricDatas();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  apiParams() {
    const {
      keyword,
      page,
      textipPage,
      minPrice,
      maxPrice,
      minWeight,
      maxWeight,
      colorSelected,
      functionTagsSelected,
      attributeTagsSelected,
      otherTagsSelected,
      fabricNos,
      fabricDataType,
    } = this.state;
    const authData = getAuth();
    let params = {
      page: fabricDataType === 'new' ? page : textipPage,
      pageSize: 18,
      memberId: authData.id,
      token: authData.token,
      minPrice: parseInt(minPrice) || '',
      maxPrice: parseInt(maxPrice) || '',
      minGsm: parseInt(minWeight) || '',
      maxGsm: parseInt(maxWeight) || '',
    };
    if (keyword) {
      params = Object.assign(params, { query: keyword });
    }
    if (colorSelected.length > 0) {
      Object.assign(params, {
        color: JSON.stringify(colorSelected),
      });
    }
    if (functionTagsSelected.length > 0) {
      params = Object.assign(params, { functionTags: functionTagsSelected });
    }
    if (attributeTagsSelected.length > 0) {
      params = Object.assign(params, { attributeTags: attributeTagsSelected });
    }
    if (otherTagsSelected.length > 0) {
      params = Object.assign(params, { otherTags: otherTagsSelected });
    }
    if (fabricNos !== null) {
      const fabricNosParams = fabricNos.length === 0 ? [-1] : fabricNos;
      Object.assign(params, {
        fabricNos: fabricNosParams,
      });
    }
    return params;
  }

  getFabricDatas() {
    const { fabricDataType } = this.state;
    const fabricApi =
      fabricDataType === 'new' ? 'getMyFabricDatas' : 'getTextipFabricDatas';
    this.setState({
      showFilterBlock: false,
    });
    this[fabricApi]();
  }

  getMyFabricDatas() {
    const now = new Date();
    const postTime = now.getTime();
    this.setState({ loading: true });
    const authData = getAuth();
    const { getMyFabricDatas } = this.props;
    if (!isEmpty(authData)) {
      const params = this.apiParams();
      getMyFabricDatas(params, () => {
        const resDate = new Date();
        const resTime = resDate.getTime();
        const diffTime = (resTime - postTime) / 1000;
        console.log(diffTime + ' 秒');
      });
    } else {
      this.setState({ loading: false });
    }
  }

  getTextipFabricDatas() {
    this.setState({ loading: true });
    const authData = getAuth();
    const { getTextipFabricDatas } = this.props;
    if (!isEmpty(authData)) {
      const params = this.apiParams();
      getTextipFabricDatas(params);
    } else {
      this.setState({ loading: false });
    }
  }

  getCategoryColorData() {
    this.props.getCategoryColorData();
  }

  postImageSearchFabricNos() {
    const { searchImage, fabricDataType } = this.state;
    const authData = getAuth();
    const searchImageBase64 = searchImage.replace(
      'data:image/jpeg;base64,',
      ''
    );
    const params = {
      memberId: authData.id,
      token: authData.token,
      imageBase64: searchImageBase64,
      isMyfabric: fabricDataType === 'new',
    };
    const config = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
    const apiUrl = `${process.env.REACT_APP_API_ROOT}/myFabric/imageSearchFabricNos`;
    fetch(apiUrl, config)
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          fabricNos: res,
        });
        setTimeout(() => {
          this.getFabricDatas();
        }, 100);
      });
  }

  goPage(page) {
    const { fabricDataType } = this.state;
    const pageParams = fabricDataType === 'new' ? 'page' : 'textipPage';
    const fabricApi =
      fabricDataType === 'new' ? 'getMyFabricDatas' : 'getTextipFabricDatas';
    this.setState({
      [pageParams]: page,
    });
    setTimeout(() => {
      this[fabricApi]();
    }, 100);
  }

  checkIsActiveColor(color) {
    const { colorSelected } = this.state;
    for (let i = 0; i < colorSelected.length; i++) {
      const itemK = colorSelected[i].color;
      if (itemK === color) return true;
    }
    return false;
  }

  clickColorItem(colorItem) {
    const { colorSelected } = this.state;
    const isActive = this.checkIsActiveColor(colorItem.color.toUpperCase());
    if (isActive) {
      let index = -1;
      //這邊從陣列內移除此顏色
      for (let i = 0; i < colorSelected.length; i++) {
        const oldK = colorSelected[i].color;
        if (colorItem.color.toUpperCase() === oldK) {
          index = i;
          break;
        }
      }

      if (index > -1) {
        colorSelected.splice(index, 1);
        this.setState({ colorSelected: colorSelected });
      }
    } else {
      //把顏色加入陣列內
      colorSelected.push({
        color: colorItem.color.toUpperCase(),
        ratio: 1,
      });
      this.setState({ colorSelected: colorSelected });
    }
  }

  checkIsActiveCusTags(cusTagsLabel, cusTagsId) {
    for (let i = 0; i < this.state[cusTagsLabel].length; i++) {
      const itemId = this.state[cusTagsLabel][i];
      if (itemId === cusTagsId) return true;
    }
    return false;
  }

  clickFabricDataType(type) {
    const { searchImage } = this.state;
    this.setState({
      fabricDataType: type,
      imageSearchType: 'pattern',
      isBatch: false,
      fabricContainer: [],
      fabricDataList: [],
    });
    setTimeout(() => {
      if (searchImage) {
        this.postImageSearchFabricNos();
      } else {
        this.getFabricDatas();
      }
    }, 100);
  }

  clickCusTagsItem(cusTagsLabel, cusTagsId) {
    const isActive = this.checkIsActiveCusTags(cusTagsLabel, cusTagsId);
    if (isActive) {
      //這邊從陣列內移除此tagID
      for (let i = 0; i < this.state[cusTagsLabel].length; i++) {
        const oldId = this.state[cusTagsLabel][i];
        if (cusTagsId === oldId) {
          this.state[cusTagsLabel].splice(i, 1);
          this.setState({ [cusTagsLabel]: this.state[cusTagsLabel] });
          break;
        }
      }
    } else {
      //把tagID加入陣列內
      this.state[cusTagsLabel].push(cusTagsId);
      this.setState({ [cusTagsLabel]: this.state[cusTagsLabel] });
    }
  }

  conceptAreaClick(areaIndex) {
    const { conceptAreaActive, conceptSearchData } = this.state;
    const newConceptAreaActive =
      areaIndex === conceptAreaActive ? null : areaIndex;
    this.setState({
      conceptAreaActive: newConceptAreaActive,
      fabricNos:
        areaIndex === newConceptAreaActive
          ? conceptSearchData.area[newConceptAreaActive].fabricNos
          : conceptSearchData.fabricNos,
    });
    setTimeout(() => {
      this.getFabricDatas();
    }, 100);
  }

  conceptSearchClick() {
    const { fabricDataType } = this.state;
    this.setState({
      loading: true,
      imageSearchType: 'concept',
    });
    const { searchImage } = this.state;
    const authData = getAuth();
    const params = {
      memberId: authData.id,
      token: authData.token,
      imageBase64: searchImage,
      fileName: 'Search.jpg',
      isMyfabric: fabricDataType === 'new',
    };
    const config = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
    const apiUrl = `${process.env.REACT_APP_API_ROOT}/myFabric/conceptSearch`;
    fetch(apiUrl, config)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          const areaData = res.response.area.map((item) => {
            item['fabricNos'] = res.response.searchResultData.filter((row) => {
              if (row.tag === item.tag) {
                return row.fabricNo;
              }
            });
            item['fabricNos'] = item['fabricNos'].map((row) => {
              return row.fabricNo;
            });
            return item;
          });
          res.response.area = areaData;
          this.setState({
            loading: false,
            conceptSearchData: res.response,
            fabricNos: res.response.fabricNos,
          });
          setTimeout(() => {
            this.getFabricDatas();
          }, 100);
        } else {
          alert(res.errorMessage);
        }
      });
  }

  patternSearchClick() {
    this.setState({
      imageSearchType: 'pattern',
      conceptAreaActive: null,
    });
    this.postImageSearchFabricNos();
  }

  handleFabricSearchChange(e) {
    const { target } = e;
    const { value } = target;
    this.setState({
      keyword: value,
    });
  }

  handleChange(e) {
    const { target } = e;
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  }

  changeFile(e) {
    const fileList = e.target.files;
    const files = Array.from(fileList);

    if (files && files.length > 0) {
      files.map((item, index) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onloadend = () => {
          this.setState({ searchImage: reader.result });
          this.postImageSearchFabricNos();
        };
      });
    }
  }

  removeImageSearch() {
    this.setState({
      searchImage: '',
      fabricNos: null,
    });
    setTimeout(() => {
      this.getFabricDatas();
    }, 100);
  }

  colorListView() {
    const { colorList } = this.state;
    const colorListView = colorList.map((item, index) => {
      const isActive = this.checkIsActiveColor(item.color.toUpperCase());
      const activeClass = isActive ? 'active' : '';
      return (
        <div
          key={`colorItem_${index}`}
          className={`colorItem ${activeClass}`}
          style={{ backgroundColor: item.color }}
          onClick={() => {
            this.clickColorItem(item);
          }}
        ></div>
      );
    });
    return colorListView;
  }

  cusTagsListView(cusTagsLabel) {
    const cusTagsLabelName = `${cusTagsLabel}TagsSelected`;
    const cusTagsDataLabelName = `${cusTagsLabel}Tags`;
    const { listMyFabric, listTextipFabric, fabricDataType } = this.state;
    const fabricListData =
      fabricDataType === 'new' ? listMyFabric : listTextipFabric;
    let cusTagsListView = null;
    if (fabricListData && fabricListData[cusTagsDataLabelName]) {
      cusTagsListView = fabricListData[cusTagsDataLabelName].map(
        (item, index) => {
          const isActive = this.checkIsActiveCusTags(cusTagsLabelName, item.id);
          const activeClass = isActive ? 'active' : '';
          const checkBoxIcon = isActive
            ? require('../images/checkbox-check.png')
            : require('../images/checkbox.png');
          return (
            <div
              key={`cusTagsItem_${index}`}
              className={`tagItem ${activeClass}`}
              onClick={() => {
                this.clickCusTagsItem(cusTagsLabelName, item.id);
              }}
            >
              <div className="tagCheckBox">
                <img src={checkBoxIcon} />
              </div>
              <div className="tagText">{item.text}</div>
            </div>
          );
        }
      );
    }
    return cusTagsListView;
  }

  openFabricDetail(fabricId) {
    this.setState({
      showFabricId: fabricId,
    });
  }

  closeFabricDetail() {
    this.setState({
      showFabricId: null,
    });
  }

  fabricDetailView() {
    const { showFabricId } = this.state;
    const displayModal = showFabricId ? true : false;
    let fabricDetailView = (
      <FabricDetail
        fabricId={showFabricId}
        displayModal={displayModal}
        closed={() => {
          this.closeFabricDetail();
        }}
      />
    );
    return fabricDetailView;
  }

  openFilter() {
    this.setState({
      showFilterBlock: true,
    });
  }

  closeFilter() {
    this.setState({
      showFilterBlock: false,
    });
  }

  handleSetBatchOn() {
    this.setState({ isBatch: true });
  }

  handleSelectedFabric(item) {
    const { fabricContainer } = this.state;
    const arr = fabricContainer;
    const isHasItem = arr.includes(item);

    isHasItem ? arr.splice(arr.indexOf(item), 1) : arr.push(item);

    this.setState({ fabricContainer: arr });
  }

  handleFabricModalOpen() {
    const { fabricContainer } = this.state;
    const authData = getAuth();
    if (fabricContainer.length) {
      this.setState({ loading: true });
      for (let i = 0; i < fabricContainer.length; i++) {
        const params = {
          memberId: authData.id,
          token: authData.token,
          id: fabricContainer[i].id,
        };
        this.props.getMyFabricWithId(params);
      }
    }

    this.setState({ isFabricModalOpen: true });
  }

  handleFabricModalClose() {
    this.setState({ isFabricModalOpen: false, fabricDataList: [] });
  }

  exportPDF() {
    const { fabricDataList } = this.state;
    const pptx = new pptxgen();
    console.log('???', fabricDataList);
    for (let i = 0; i < fabricDataList.length; i++) {
      const slide = pptx.addSlide();

      const imageListLen =
        fabricDataList[i].images_detail.length > 2
          ? 2
          : fabricDataList[i].images_detail.length;
      console.log('INDEXXXX', imageListLen);

      if (fabricDataList[i].images_detail[0].filePath) {
        slide.addImage({
          path: fabricDataList[i].images_detail[0].filePath,
          w: '55%',
          h: '90%',
          y: '5%',
          x: '42%',
        });
      }

      if (imageListLen > 1) {
        slide.addImage({
          path: fabricDataList[i].images_detail[1].filePath,
          w: '32%',
          h: '50%',
          y: '45%',
          x: '5%',
        });
      }

      const rows = [
        [
          {
            text: 'OUTERAWAR',
            options: { align: 'center', fill: 'b3b3b3', color: 'fff' },
          },
        ],
        [{ text: fabricDataList[i].headline || 'N/A' }],
        [{ text: fabricDataList[i].contents || 'N/A' }],
        [{ text: fabricDataList[i].width || 'N/A' }],
        [{ text: fabricDataList[i].weight || 'N/A' }],
      ];

      slide.addTable(rows, {
        colW: 3,
        x: '2%',
        y: '15%',
      });
    }

    pptx.writeFile({ fileName: `fabric-${new Date()}.pptx` });
    this.setState({
      fabricContainer: [],
      isBatch: false,
      fabricDataList: [],
    });
  }

  handleCancelBatch() {
    this.setState({
      fabricContainer: [],
      isBatch: false,
      fabricDataList: [],
    });
  }

  render() {
    const {
      loading,
      listMyFabric,
      listTextipFabric,
      minPrice,
      maxPrice,
      minWeight,
      maxWeight,
      keyword,
      page,
      textipPage,
      searchImage,
      imageSearchType,
      conceptSearchData,
      conceptAreaActive,
      fabricDataType,
      showFilterBlock,
      isBatch,
      fabricContainer,
      isFabricModalOpen,
      fabricDataList,
    } = this.state;
    const emptyView = (
      <div className="emptyBlock">
        <Empty
          title={Lang.FABRIC_EMPTY_TITLE}
          content={Lang.FABRIC_EMPTY_CONTENT}
        />
        <div className="btnBox">
          <Link className="btn btn-primary" to={`/fabric/add`}>
            {Lang.FABRIC_CREATE_FABRIC}
          </Link>
        </div>
      </div>
    );
    const fabricListData =
      fabricDataType === 'new' ? listMyFabric : listTextipFabric;
    const thisPage = fabricDataType === 'new' ? page : textipPage;
    const loadingView = fabricListData === null || loading ? <Loading /> : null;
    const isLoadingFabric = fabricContainer.length !== fabricDataList.length;
    let contentView = null;
    if (fabricListData !== null) {
      let fabricSearchListView = null;
      let conceptAreaListView = null;
      let searchImageDisplay = searchImage;
      if (fabricListData.data.length > 0) {
        const fabricDetailView = this.fabricDetailView();

        const fabricListView = fabricListData.data.map((item, index) => {
          const isActive = fabricContainer.includes(item);
          const activeClass = isActive ? 'active' : '';
          const checkBoxIcon = isActive
            ? require('../images/checkbox-check.png')
            : require('../images/checkbox.png');
          const fabricCardView = (
            <div className="card" key={item.id}>
              <div className="fabricImage">
                <Image src={item.images} className="card-img-top" />
              </div>
              <div className="card-body">
                <p className="card-text textOverflow">{item.headline}</p>
              </div>
            </div>
          );
          if (fabricDataType === 'new') {
            return (
              <div className="fabricItem col-6 col-md-2 layoutBlock">
                {isBatch && (
                  <div
                    className={`tagItem ${activeClass} fabricCheckbox`}
                    onClick={() => {
                      this.handleSelectedFabric(item);
                    }}
                  >
                    <div className="tagCheckBox">
                      <img src={checkBoxIcon} />
                    </div>
                  </div>
                )}
                <Link
                  key={`fabricItem_${index}`}
                  className="col-12 fabric-block"
                  to={`/fabric/${item.id}/edit`}
                >
                  {fabricCardView}
                </Link>
              </div>
            );
          } else {
            return (
              <div className="fabricItem col-6 col-md-2 layoutBlock">
                {isBatch && (
                  <div
                    className={`tagItem ${activeClass} fabricCheckbox`}
                    onClick={() => {
                      this.handleSelectedFabric(item);
                    }}
                  >
                    <div className="tagCheckBox">
                      <img src={checkBoxIcon} />
                    </div>
                  </div>
                )}
                <div
                  key={`fabricItem_${index}`}
                  className="col-12 fabric-block"
                  onClick={() => {
                    this.openFabricDetail(item.id);
                  }}
                >
                  {fabricCardView}
                </div>
              </div>
            );
          }
        });
        fabricSearchListView = (
          <div className="">
            <div className="fabricListView row">{fabricListView}</div>
            <Pagination
              data={fabricListData.info}
              page={thisPage}
              goPage={this.goPage}
            />
            {fabricDetailView}
          </div>
        );
      } else {
        fabricSearchListView = (
          <div className="fabricListView row">{emptyView}</div>
        );
      }

      if (imageSearchType === 'concept' && conceptSearchData) {
        const conceptArea = conceptSearchData.area;
        conceptAreaListView = conceptArea.map((item, index) => {
          const imageBase64 = `data:image/jpeg;base64,${item.max_sub_square}`;
          let activeClass = '';
          if (conceptAreaActive === index) {
            activeClass = 'active';
            searchImageDisplay = `data:image/jpeg;base64,${item.content}`;
          }
          return (
            <div
              key={`conceptAreaItem_${item.tag}`}
              className={`conceptAreaItem ${activeClass}`}
              onClick={() => {
                this.conceptAreaClick(index);
              }}
            >
              <img src={imageBase64} className="conceptAreaImage" />
            </div>
          );
        });
      }
      const createBtnView =
        fabricDataType === 'new' ? (
          <div className="btnBox createFabricBtn">
            {isBatch ? (
              <div className="batchContainer">
                <div
                  style={{ marginRight: 6 }}
                  className="btn btn-primary"
                  onClick={this.handleCancelBatch}
                >
                  Cancel
                </div>
                <div className="batchDownloadContainer">
                  {fabricContainer.length !== 0 && (
                    <div className="batchCount">{fabricContainer.length}</div>
                  )}
                  <div
                    className="btn btn-primary"
                    onClick={this.handleFabricModalOpen}
                  >
                    Show List
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="btn btn-primary"
                style={{ marginRight: 6 }}
                onClick={this.handleSetBatchOn}
              >
                Batch
              </div>
            )}
            <Link className="btn btn-primary" to={`/fabric/add`}>
              {Lang.FABRIC_CREATE_FABRIC}
            </Link>
          </div>
        ) : (
          <div className="btnBox createFabricBtn">
            {isBatch ? (
              <div className="batchContainer">
                <div
                  style={{ marginRight: 6 }}
                  className="btn btn-primary"
                  onClick={this.handleCancelBatch}
                >
                  Cancel
                </div>
                <div className="batchDownloadContainer">
                  {fabricContainer.length !== 0 && (
                    <div className="batchCount">{fabricContainer.length}</div>
                  )}
                  <div
                    className="btn btn-primary"
                    onClick={this.handleFabricModalOpen}
                  >
                    Show List
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="btn btn-primary"
                style={{ marginRight: 6 }}
                onClick={this.handleSetBatchOn}
              >
                Batch
              </div>
            )}
          </div>
        );
      contentView = (
        <div className="fabricListBlock">
          <div className="tabBlock">
            <div className="tabBox">
              <div
                className={`tabItem ${
                  fabricDataType === 'new' ? 'active' : ''
                }`}
                onClick={() => {
                  this.clickFabricDataType('new');
                }}
              >
                {Lang.FABRIC_DATATYPE_NEW}
              </div>
              <div
                className={`tabItem ${
                  fabricDataType === 'textip' ? 'active' : ''
                }`}
                onClick={() => {
                  this.clickFabricDataType('textip');
                }}
              >
                {Lang.FABRIC_DATATYPE_TEXTIP}
              </div>
            </div>
          </div>
          <div className="fabricListTitleBlock">
            <div className="searchBarBlock">
              <div className="input-group">
                <Input
                  type="text"
                  name="keyword"
                  ref="keyword"
                  placeholder={Lang.FABRIC_LIST_SEARCH}
                  className="searchBox"
                  onChange={this.handleFabricSearchChange}
                  idName="input_keyword"
                  value={keyword}
                />
                <div className="input-group-append searchBtnBox">
                  <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      this.getFabricDatas();
                    }}
                    text={Lang.FABRIC_SEARCH_SUBMIT}
                  />
                </div>
              </div>
              <label className="imageSearchBtnBox">
                <input
                  type="file"
                  multiple
                  name="file"
                  style={{ display: 'none' }}
                  onChange={this.changeFile}
                />
                {Lang.FABRIC_IMAGE_SUBMIT}
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <div
                className="btnBox filterBtn"
                onClick={() => {
                  this.openFilter();
                }}
              >
                <div className="btn btn-primary">{Lang.FABRIC_FILTER_BTN}</div>
              </div>
              {createBtnView}
            </div>
          </div>
          {searchImage && (
            <div className="imageSearchContainer">
              <div className="imageSearchTypeBox">
                <div
                  className={`imageSearchTypeItem ${
                    imageSearchType === 'pattern' ? 'active' : ''
                  }`}
                  onClick={() => {
                    this.patternSearchClick();
                  }}
                >
                  {Lang.FABRIC_IMAGESEARCH_TYPE1}
                </div>
                <div
                  className={`imageSearchTypeItem ${
                    imageSearchType === 'concept' ? 'active' : ''
                  }`}
                  onClick={() => {
                    this.conceptSearchClick();
                  }}
                >
                  {Lang.FABRIC_IMAGESEARCH_TYPE2}
                </div>
              </div>
              <div className="searchImageContainer">
                <div
                  className="searchImageDeleteBox"
                  onClick={() => {
                    this.removeImageSearch();
                  }}
                >
                  <img
                    src={require('../images/icon_x.png')}
                    className="searchImageDelete"
                  />
                </div>
                <img
                  src={searchImageDisplay}
                  className="searchImage col-12 col-md-5 layoutBlock"
                />
                <div className="conceptSearchAreaBox col-12 col-md-7 layoutBlock">
                  {conceptAreaListView}
                </div>
              </div>
            </div>
          )}
          {fabricSearchListView}
        </div>
      );
    }

    const functionTagsListView = this.cusTagsListView('function');
    const attributeTagsListView = this.cusTagsListView('attribute');
    const otherTagsListView = this.cusTagsListView('other');
    const colorListView = this.colorListView();
    const showFilterBlockClass = showFilterBlock ? 'active' : '';
    console.log('CONTAINER', fabricContainer);
    console.log('DATA LIST', fabricDataList);
    return (
      <MemberBase>
        {loadingView}
        <div className="fabricListContainerBase myFabricListContainer">
          <div className={`fabricFilterBlock ${showFilterBlockClass}`}>
            <div className="filterTitleBox">
              <div className="filterTitle">{Lang.FABRIC_FILTER_TITLE}</div>
              <div className="closeFilterBtn" onClick={this.closeFilter}>
                <img src={require('../images/close-black.png')} />
              </div>
            </div>
            <div className="filterContentBlock">
              <div className="filterContentTitle">
                {Lang.FABRIC_FILTER_FUNCTION}
              </div>
              <div className="filterContentBox">{functionTagsListView}</div>
            </div>
            <div className="filterContentBlock">
              <div className="filterContentTitle">
                {Lang.FABRIC_FILTER_ATTRIBUTE}
              </div>
              <div className="filterContentBox">{attributeTagsListView}</div>
            </div>
            <div className="filterContentBlock">
              <div className="filterContentTitle">
                {Lang.FABRIC_FILTER_OTHERS}
              </div>
              <div className="filterContentBox">{otherTagsListView}</div>
            </div>
            <div className="filterContentBlock">
              <div className="filterContentTitle">
                {Lang.FABRIC_FILTER_COLOR}
              </div>
              <div className="filterContentBox">{colorListView}</div>
            </div>
            <div className="filterContentBlock">
              <div className="filterContentTitle">
                {Lang.FABRIC_FILTER_PRICE}
              </div>
              <div className="filterContentBox">
                <div className="filterRangeBox">
                  <Input
                    type="text"
                    name="minPrice"
                    placeholder={Lang.FABRIC_FILTER_MIN}
                    className="filterRangeColumn"
                    onChange={this.handleChange}
                    idName={`input_minPrice`}
                    value={minPrice}
                  />
                  <div className="dashBox">-</div>
                  <Input
                    type="text"
                    name="maxPrice"
                    placeholder={Lang.FABRIC_FILTER_MAX}
                    className="filterRangeColumn"
                    onChange={this.handleChange}
                    idName={`input_maxPrice`}
                    value={maxPrice}
                  />
                </div>
              </div>
            </div>
            <div className="filterContentBlock">
              <div className="filterContentTitle">
                {Lang.FABRIC_FILTER_WEIGHT}
              </div>
              <div className="filterContentBox">
                <div className="filterRangeBox">
                  <Input
                    type="text"
                    name="minWeight"
                    placeholder={Lang.FABRIC_FILTER_MIN}
                    className="filterRangeColumn"
                    onChange={this.handleChange}
                    idName={`input_minWeight`}
                    value={minWeight}
                  />
                  <div className="dashBox">-</div>
                  <Input
                    type="text"
                    name="maxWeight"
                    placeholder={Lang.FABRIC_FILTER_MAX}
                    className="filterRangeColumn"
                    onChange={this.handleChange}
                    idName={`input_maxWeight`}
                    value={maxWeight}
                  />
                </div>
              </div>
            </div>
            <div className="filterBtnBlock">
              <div className="filterBtnBox">
                <div
                  className="btn btn-primary"
                  onClick={() => {
                    this.getFabricDatas();
                  }}
                >
                  {Lang.FABRIC_FILTER_SUBMIT}
                </div>
              </div>
            </div>
          </div>
          {contentView}
        </div>
        <CustomerModal
          disabled={loading || !fabricContainer.length || isLoadingFabric}
          isOpen={isFabricModalOpen}
          handleClose={this.handleFabricModalClose}
          handleSubmit={this.exportPDF}
          header="Fabric List"
          submitText={
            isLoadingFabric
              ? `Loading ${fabricDataList.length} / ${fabricContainer.length}`
              : 'Download'
          }
          onlySubmit
        >
          {!fabricContainer.length && (
            <div style={{ textAlign: 'center' }}>No Any Fabric Selected.</div>
          )}
          {fabricContainer.map((fabric) => (
            <div key={fabric.id} className="fabric-modal-block">
              <Image src={fabric.images} className="fabric-block-img" />
              <div className="fabric-block-content">
                <h4>Headline: {fabric.headline}</h4>
              </div>
            </div>
          ))}
        </CustomerModal>
      </MemberBase>
    );
  }
}

FabricManagement.propTypes = {
  listMyFabric: PropTypes.object,
  listTextipFabric: PropTypes.object,
  colorList: PropTypes.array,
  getMessageDetail: PropTypes.func.isRequired,
  getTextipFabricDatas: PropTypes.func.isRequired,
};

const mapStateToProps = () => (state) => {
  return {
    listMyFabric: state.myFabric.listMyFabric,
    listTextipFabric: state.textipFabric.listTextipFabric,
    colorList: state.category.color,
    member: state.member.member,
    messageDetailData: state.chat.messageDetailData,
    myFabricDataItem: state.myFabric.myFabricDataItem,
  };
};

const mapDispatchToProps = {
  getMyFabricDatas,
  getTextipFabricDatas,
  getCategoryColorData,
  getMessageDetail,
  getMyFabricWithId,
};

export default connect(mapStateToProps, mapDispatchToProps)(FabricManagement);
