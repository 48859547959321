export function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  let key;
  for (key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export function getAuth() {
  const authString = localStorage.getItem('auth');
  const authData = JSON.parse(authString);
  return authData;
}
export function getMemberData() {
  const memberString = localStorage.getItem('memberData');
  const memberData = JSON.parse(memberString);
  return memberData;
}
export function checkPresentationTypeIsNone() {
  const memberString = localStorage.getItem('memberData');
  const memberData = JSON.parse(memberString);
  if (memberData && memberData != {} && memberData.presentationType != 'none') {
    return false;
  }
  return true;
}
export function getPresentationType() {
  const memberString = localStorage.getItem('memberData');
  const memberData = JSON.parse(memberString);

  if (memberData && memberData != {} && memberData.presentationType) {
    return memberData.presentationType;
  }
  return 'none';
}
export function getValidationVideoUrl(url) {
  let nUrl = url;
  // 檢查是不是youtube
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return '//www.youtube.com/embed/' + match[2];
  }
  // 檢查是不是vimeo
  var pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/g;
  if (pattern.test(url)) {
    var replacement = '//player.vimeo.com/video/$1';
    nUrl = url.replace(pattern, replacement);
    return nUrl;
  }
  return nUrl;
}

export function getUrlObj() {
  let url = window.location.href,
    urlArray = url.split('?'),
    parameterObj = {};

  if (urlArray.length >= 2) {
    const urlParaArray = urlArray[1].split('&');

    urlParaArray.map((value) => {
      const keyNameArray = value.split('=');
      parameterObj[keyNameArray[0]] = decodeURIComponent(keyNameArray[1]);
    });
  }

  return parameterObj;
}

// 獲取目前menu狀態，是開是關
export function isMenuShow() {
  const isShow = localStorage.getItem('isMenuShow');

  if (isShow != null) {
    return isShow == 'true' ? true : false;
  } else {
    localStorage.setItem('isMenuShow', 'false');
    return false;
  }
}
// 設定menu開關狀態
export function setMenuShowStatus(status) {
  localStorage.setItem('isMenuShow', status ? 'true' : 'false');
}
