import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { isEmpty, getAuth } from '../Libs/Lib'
import * as Lang from '../Lang/default'
import { getMyPresentationList, deletePresentation } from '../actions/presentation'
import MemberBase from '../containers/MemberBase'
import Empty from '../components/Empty'
import Loading from '../components/Loading'
import RoomBlock from '../components/RoomBlock'
import Pagination from '../components/Pagination'
import '../style/roomlist.sass'

class MyRoom extends Component {
    constructor(props) {
        super(props)
        this.fetchDeletePresentation = this.fetchDeletePresentation.bind(this)
        this.clickDeletePresentation = this.clickDeletePresentation.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.goPage = this.goPage.bind(this)
        this.state = {
            presentationMyList: null,
            pageInfo: {},
            openDeletePresentationConfirm: null,
            page: 1,
            loading: false
        }
    }

    componentDidMount() {
        this.getApiMyPresentationList()
    }

    componentWillUnmount() {}

    componentWillReceiveProps(nextProps) {
        const { listMyPresentation: thisListMyPresentation, member: thisMember } = this.props
        const { listMyPresentation, member } = nextProps
        if (thisListMyPresentation !== listMyPresentation) {
            this.setState({
                presentationMyList: listMyPresentation.data,
                pageInfo: listMyPresentation.info,
            })
        }

        if (thisMember !== member && thisMember && member && thisMember.id !== member.id) {
            this.getApiMyPresentationList()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    getApiMyPresentationList() {
        const { getMyPresentationList } = this.props
        const { page } = this.state
        const authData = getAuth()
        if (!isEmpty(authData) && authData.id && authData.token) {
            this.setState({
                loading: true
            })
            getMyPresentationList({ memberId: authData.id, token: authData.token, page: page, pageSize: 9 }, (res) => {
                if (res.error) {
                    this.setState({
                        presentationMyList: [],
                    })
                    const errorData = res.payload.response
                    alert(errorData.errorMessage)
                }
                this.setState({
                    loading: false
                })
            })
        }
    }

    goPage(page) {
        this.setState({
            page: page
        })
        setTimeout(() => {
            this.getApiMyPresentationList()
        }, 100);
    }

    clickDeletePresentation(presentationNo) {
        this.setState({
            openDeletePresentationConfirm: presentationNo
        })
    }

    closeModal() {
        this.setState({
            openDeletePresentationConfirm: null
        })
    }

    fetchDeletePresentation(presentationNo) {
        const { deletePresentation } = this.props
        const authData = getAuth()
        if (!isEmpty(authData) && authData.id && authData.token) {
            deletePresentation(presentationNo, { memberId: authData.id, token: authData.token }, res => {
                this.setState({
                    openDeletePresentationConfirm: null
                })
                this.getApiMyPresentationList()
            })
        }
    }

    deletePresentationConfirmView() {
        const { openDeletePresentationConfirm } = this.state
        if (!openDeletePresentationConfirm) {
            return null
        }
        const confirmText = Lang.ROOMLIST_DELETE_CONFIRM_CONTENT + ' [' + openDeletePresentationConfirm + '] ?'
        return (
            <div className="modal roomRemoveModel">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{Lang.ROOMLIST_DELETE_CONFIRM}</h5>
                        <div
                            className="closeImgBox"
                            onClick={() => {
                                this.closeModal()
                            }}
                        >
                            <img src={require('../images/icon_x.png')} className="closeImg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        <p>{confirmText}</p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                this.fetchDeletePresentation(openDeletePresentationConfirm)
                            }}
                        >
                            {Lang.ROOMLIST_DELETE_CONFIRM_BTN}
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { presentationMyList, pageInfo, page, loading } = this.state
        const emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.ROOM_EMPTY_TITLE} content={Lang.ROOM_EMPTY_CONTENT} />
                <div className="btnBox">
                    <Link className="btn btn-primary" to={`/addRoom`}>
                        {Lang.ROOM_CREATE_ROOM}
                    </Link>
                </div>
            </div>
        )
        const loadingView = presentationMyList === null || loading ? <Loading /> : null
        const deletePresentationConfirmView = this.deletePresentationConfirmView()
        let contentView = null
        if (presentationMyList !== null) {
            if (presentationMyList.length > 0) {
                const roomListView = presentationMyList.map((item, index) => {
                    return (
                        <div className="roomListViewItem" key={`room_${index}`}>
                            <RoomBlock data={item}  edit={item.editable} removeFunc={this.clickDeletePresentation} />
                        </div>
                    )
                })
                contentView = (
                    <div className="roomListBlock">
                        <div className="roomListTitleBlock">
                            <div className="tabBlock">
                                <Link className="tabItem" to={`/`}>
                                    {Lang.ROOMLIST_TAB_ROOM}
                                </Link>
                                <div className="tabItem active">
                                    {Lang.ROOMLIST_TAB_MYROOM}
                                </div>
                            </div>
                            <div className="btnBox">
                                <Link className="btn btn-primary" to={`/addRoom`}>
                                    {Lang.ROOM_CREATE_ROOM}
                                </Link>
                            </div>
                        </div>
                        <div className="roomListView">{roomListView}</div>
                        <Pagination data={pageInfo} page={page} goPage={this.goPage} />
                    </div>
                )
            } else {
                contentView = emptyView
            }
        }

        return (
            <MemberBase>
                {loadingView}
                {deletePresentationConfirmView}
                <div className="roomListContainerBase">
                    {contentView}
                </div>
            </MemberBase>
        )
    }
}

MyRoom.propTypes = {
    getMyPresentationList: PropTypes.func.isRequired,
    listMyPresentation: PropTypes.object,
    deletePresentation: PropTypes.func.isRequired
}

const mapStateToProps = () => (state) => {
    return {
        listMyPresentation: state.presentation.listMyPresentation,
        member: state.member.member,
    }
}

const mapDispatchToProps = {
    getMyPresentationList,
    deletePresentation
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRoom)
