import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    messageList: {
        info: {},
        data: []
    },
    messageDetailData: {},
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case ActionTypes.CHAT_ADD_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.CHAT_ADD_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.CHAT_ADD_SUCCESS:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.CHAT_LIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.CHAT_LIST_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.CHAT_LIST_SUCCESS:
            const messageListRes = action.payload.response
            let messageList = Object.assign({}, state.messageList)
            messageList.data = messageListRes.data
            messageList.info = {
                pageSize: messageListRes.pageSize,
                totalPage: messageListRes.totalPage,
                totalRow: messageListRes.count,
            }
            return Object.assign({}, state, {
                isDataFetching: false,
                messageList,
            })
        case ActionTypes.CHAT_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.CHAT_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.CHAT_DETAIL_SUCCESS:
            const data = action.payload.response
            return Object.assign({}, state, {
                isDataFetching: false,
                messageDetailData: data,
            })

        default:
            return state
    }
}
