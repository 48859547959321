import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

//分類的顏色
function fetchGetCategoryColorData() {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.CATEGORY_COLOR_REQUEST,
                ActionTypes.CATEGORY_COLOR_SUCCESS,
                ActionTypes.CATEGORY_COLOR_FAILURE
            ],
            method: 'GET',
            endpoint: '/color'
        }
    }
}

export function getCategoryColorData() {
    return dispatch => {
        dispatch(fetchGetCategoryColorData())
    }
}
