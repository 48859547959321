import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    createTrendboardData:{},
    updateTrendboardData:{},
    deleteTrendboardData:{},
    trendboardListData:{},  //trendboard列表物件
    trendboardDetailData:{}, //trendboard明細資料
    
}

export default function (state = defaultState, action) {
    switch (action.type) {
        //獲取trendBoard列表
        case ActionTypes.TREND_GET_TRENDBOARD_LIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.TREND_GET_TRENDBOARD_LIST_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.TREND_GET_TRENDBOARD_LIST_SUCCESS:
            let trendboardListData = action.payload
            return Object.assign({} , state,{
                isDataFetching: false,
                trendboardListData: trendboardListData
            })

        //獲取trendBoard明細資料
        case ActionTypes.TREND_GET_TRENDBOARD_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.TREND_GET_TRENDBOARD_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
                catalogDetailData: action.payload.response  //錯誤訊息
            })
        case ActionTypes.TREND_GET_TRENDBOARD_DETAIL_SUCCESS:
            let tbDetailData = action.payload
            return Object.assign({} , state,{
                isDataFetching: false,
                trendboardDetailData: tbDetailData
            })
        //創建trendBoard
        case ActionTypes.TREND_CREATE_TRENDBOARD_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.TREND_CREATE_TRENDBOARD_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.TREND_CREATE_TRENDBOARD_SUCCESS:
            let createData = action.payload.data
            
            return Object.assign({} , state,{
                isDataFetching: false,
                createCatalogData: createData
            })

        //修改trendBoard
        case ActionTypes.TREND_UPDATE_TRENDBOARD_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.TREND_UPDATE_TRENDBOARD_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.TREND_UPDATE_TRENDBOARD_SUCCESS:
            let updateData = action.payload.data
            return Object.assign({} , state,{
                isDataFetching: false,
                updateTrendboardData: updateData
            })
        
        //刪除trendBoard
        case ActionTypes.TREND_DELETE_TRENDBOARD_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.TREND_DELETE_TRENDBOARD_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.TREND_DELETE_TRENDBOARD_SUCCESS:
            let deleteData = action.payload
            return Object.assign({} , state,{
                isDataFetching: false,
                deleteTrendboardData: deleteData
            })

            

        default:
            return state
    }
}
