import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAuth, isEmpty } from '../Libs/Lib';
import * as Lang from '../Lang/default';
import { createTrendBoard } from '../actions/trendboard';
import MemberBase from './MemberBase';
import Form from '../components/Form';
import Loading from '../components/Loading';
import Image from '../components/Image';
import ContentBlock from '../components/ContentBlock';
import Input from '../components/Input';
import '../style/trend.sass';

class AddTrend extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.changeBoardFile = this.changeBoardFile.bind(this);
    this.previewImage = this.previewImage.bind(this);
    this.previewBoardImage = this.previewBoardImage.bind(this);
    this.changeBoardStatus = this.changeBoardStatus.bind(this);
    this.confirmFabricListSelect = this.confirmFabricListSelect.bind(this);
    this.getNeedToUploadImages = this.getNeedToUploadImages.bind(this);
    this.gotoUploadImage = this.gotoUploadImage.bind(this);
    this.setDropRef = (element) => {
      this.dropRef = element;
      const dropDev = this.dropRef;
      if (dropDev) {
        dropDev.addEventListener('dragenter', this.handleDragIn);
        dropDev.addEventListener('dragleave', this.handleDragOut);
        dropDev.addEventListener('dragover', this.handleDrag);
        dropDev.addEventListener('drop', this.handleDrop);
      }
    };
    this.state = {
      loading: false,
      description: null,
      firstImage: null,
      secendImage: null,
      thirdImage: null,
      boardFabric1: null,
      boardFabric2: null,
      boardFabric3: null,
      showFabricModal: false,
      fabricListData: [],
      fabricSelectData: {},
      board2ImageList: [null, null, null, null, null, null],
      board1Status: 'on',
      board2Status: 'on',
      board3Status: 'off',
      board4Status: 'off',
      board5Status: 'off',
      board6Status: 'off',
      boardAData: {},
      boardBData: {},
      boardCData: {},
      boardDData: {},
      boardEData: {},
      boardFData: {},
      memberDatas: [], // 會員資料
      mainImageData: '', // 主要圖片的base64
      boardADataTemp: {},
      boardBDataTemp: {},
      boardCDataTemp: {},
      boardDDataTemp: {},
      boardEDataTemp: {},
      boardFDataTemp: {},
      boardAtitle: '', // board A的title
      boardBtitle: '', // board B的title
      boardCtitle: '', // board C的title
      boardDtitle: '', // board D的title
      boardEtitle: '', // board E的title
      boardFtitle: '', // board F的title
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this.removeListener();
  }

  handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragIn(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragOut(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.previewImage(e.dataTransfer.files, e.dataTransfer.name);
    }
  }

  changeBoardFile(e, template) {
    this.previewBoardImage(template, e.target.files, e.target.name);
  }

  previewImage(fileList, columnName) {
    const files = Array.from(fileList);
    if (files && files.length > 0) {
      const { boardAData } = this.state;
      files.map((item, index) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onloadend = () => {
          boardAData[columnName] = reader.result;
          this.setState({
            boardAData,
          });
        };
      });
    }
  }

  previewBoardImage(template, fileList, columnName) {
    const files = Array.from(fileList);
    if (files && files.length > 0) {
      files.map((item, index) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onloadend = () => {
          const templateDataParams = `board${template}Data`;
          const templateDataParamsTemp = `board${template}DataTemp`;

          this.state[templateDataParams][columnName] = reader.result;
          this.state[templateDataParamsTemp][columnName] = reader.result;
          this.state[templateDataParamsTemp][columnName + 'File'] = item;
          this.setState({
            [templateDataParams]: this.state[templateDataParams],
            [templateDataParamsTemp]: this.state[templateDataParamsTemp],
          });
        };
      });
    }
  }

  changeBoardStatus(status, columnName) {
    this.setState({
      [columnName]: status,
    });
  }

  formConfig() {
    return {
      config: {},
      data: [
        {
          component: 'Input',
          type: 'text',
          name: 'name',
          value: '',
          placeholder: Lang.TREND_FIELDS_TRENDNAME,
          role: [{ name: 'required' }],
        },
      ],
    };
  }

  addTrendBoardData(params, callback = () => {}) {
    const config = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
    fetch(`${process.env.REACT_APP_API_ROOT}/trend`, config)
      .then((res) => res.json())
      .then((res) => {
        callback(res);
      });
  }

  getNeedToUploadImages(
    boardADataTemp,
    boardBDataTemp,
    boardCDataTemp,
    boardDDataTemp,
    boardEDataTemp,
    boardFDataTemp
  ) {
    const allUploadImages = [];
    const board1ImageColumnMap = [
      'firstImage',
      'secendImage',
      'thirdImage',
      'fourthImage',
      'fifthImage',
      'sixthImage',
      'seventhImage',
    ];
    const board2ImageColumnMap = [
      'firstImage',
      'secendImage',
      'thirdImage',
      'fourthImage',
      'fifthImage',
      'sixthImage',
    ];
    const board3ImageColumnMap = ['firstImage', 'secendImage'];
    const board4ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage'];
    const board5ImageColumnMap = ['firstImage', 'secendImage'];
    const board6ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage'];
    if (boardADataTemp) {
      for (let i = 0; i < board1ImageColumnMap.length; i += 1) {
        if (boardADataTemp[`${board1ImageColumnMap[i]}File`]) {
          allUploadImages.push({
            boardName: 'A',
            imageName: board1ImageColumnMap[i],
            imageFile: boardADataTemp[`${board1ImageColumnMap[i]}File`],
          });
        }
      }
    }
    if (boardBDataTemp) {
      for (let i = 0; i < board2ImageColumnMap.length; i += 1) {
        if (boardBDataTemp[`${board2ImageColumnMap[i]}File`]) {
          allUploadImages.push({
            boardName: 'B',
            imageName: board2ImageColumnMap[i],
            imageFile: boardBDataTemp[`${board2ImageColumnMap[i]}File`],
          });
        }
      }
    }
    if (boardCDataTemp) {
      for (let i = 0; i < board3ImageColumnMap.length; i += 1) {
        if (boardCDataTemp[`${board3ImageColumnMap[i]}File`]) {
          allUploadImages.push({
            boardName: 'C',
            imageName: board3ImageColumnMap[i],
            imageFile: boardCDataTemp[`${board3ImageColumnMap[i]}File`],
          });
        }
      }
    }

    if (boardDDataTemp) {
      for (let i = 0; i < board4ImageColumnMap.length; i += 1) {
        if (boardDDataTemp[`${board4ImageColumnMap[i]}File`]) {
          allUploadImages.push({
            boardName: 'D',
            imageName: board4ImageColumnMap[i],
            imageFile: boardDDataTemp[`${board4ImageColumnMap[i]}File`],
          });
        }
      }
    }

    if (boardEDataTemp) {
      for (let i = 0; i < board5ImageColumnMap.length; i += 1) {
        if (boardEDataTemp[`${board5ImageColumnMap[i]}File`]) {
          allUploadImages.push({
            boardName: 'E',
            imageName: board5ImageColumnMap[i],
            imageFile: boardEDataTemp[`${board5ImageColumnMap[i]}File`],
          });
        }
      }
    }

    if (boardFDataTemp) {
      for (let i = 0; i < board6ImageColumnMap.length; i += 1) {
        if (boardFDataTemp[`${board6ImageColumnMap[i]}File`]) {
          allUploadImages.push({
            boardName: 'F',
            imageName: board6ImageColumnMap[i],
            imageFile: boardFDataTemp[`${board6ImageColumnMap[i]}File`],
          });
        }
      }
    }

    return allUploadImages;
  }

  removeListener() {
    if (this.dropRef) {
      this.dropRef.removeEventListener('dragenter', this.handleDragIn);
      this.dropRef.removeEventListener('dragleave', this.handleDragOut);
      this.dropRef.removeEventListener('dragover', this.handleDrag);
      this.dropRef.removeEventListener('drop', this.handleDrop);
    }
  }

  gotoUploadImage(fileUploadDatas, uploadImagesNew, data) {
    const authData = getAuth();
    const uploadDatas = fileUploadDatas;
    let isUploadError = false;
    const promiseArray = [];

    for (let i = 0; i < uploadImagesNew.length; i += 1) {
      if (isUploadError) {
        break;
      }
      const fData = new FormData();
      fData.append('memberId', authData.id);
      fData.append('token', authData.token);
      fData.append('file', uploadImagesNew[i].imageFile);
      fData.append('columnName', uploadImagesNew[i].imageName);

      const config = {
        method: 'POST',
        body: fData,
      };
      promiseArray.push(
        new Promise((resolve, reject) => {
          fetch(`${process.env.REACT_APP_API_ROOT}/trend/image`, config)
            .then((res) => res.json())
            .then((res) => {
              if (res.status === 'success') {
                // 上傳成功
                const fileUrl = res.data.url;
                if (uploadImagesNew[i].boardName === 'A') {
                  uploadDatas[0][uploadImagesNew[i].imageName] = fileUrl;
                } else if (uploadImagesNew[i].boardName === 'B') {
                  uploadDatas[1][uploadImagesNew[i].imageName] = fileUrl;
                } else if (uploadImagesNew[i].boardName === 'C') {
                  uploadDatas[2][uploadImagesNew[i].imageName] = fileUrl;
                } else if (uploadImagesNew[i].boardName === 'D') {
                  uploadDatas[3][uploadImagesNew[i].imageName] = fileUrl;
                } else if (uploadImagesNew[i].boardName === 'E') {
                  uploadDatas[4][uploadImagesNew[i].imageName] = fileUrl;
                } else if (uploadImagesNew[i].boardName === 'F') {
                  uploadDatas[5][uploadImagesNew[i].imageName] = fileUrl;
                }

                resolve(fileUrl);
              } else {
                isUploadError = true;
                reject('error');
              }
            });
        })
      );
    }

    Promise.all(promiseArray)
      .then((res) => {
        console.log('該上傳的圖片都上傳完');
        // 這邊去建立trendBoard api
        const params = Object.assign(data, {
          memberId: authData.id,
          token: authData.token,
          boardData: uploadDatas,
          // mainImage: mainImageData
        });

        this.addTrendBoardData(params, (res) => {
          if (res.status === 'success') {
            this.setState({
              loading: false,
            });
            console.log('res.response = ', res.response);
            // const presentationData = res.response
            // window.location.href = `/room/${presentationData.presentationNo}`
            window.location.href = '/trend';
          } else {
            this.setState({
              loading: false,
            });
          }
        });
      })
      .catch((error) => {
        // rej(error)
        console.log('error = ', error);
      });
  }

  handleSubmit(e, data) {
    // e.preventDefault()
    const {
      // fabricSelectData,
      // boardFabric1,
      // boardFabric2,
      // boardFabric3,
      // color,
      boardAData,
      boardBData,
      boardCData,
      boardDData,
      boardEData,
      boardFData,
      board1Status,
      board2Status,
      board3Status,
      board4Status,
      board5Status,
      board6Status,
      // mainImageData, //主圖的base64
      boardADataTemp,
      boardCDataTemp,
      boardDDataTemp,
      boardEDataTemp,
      boardFDataTemp,
      boardBDataTemp,
      boardAtitle,
      boardBtitle,
      boardCtitle,
      boardDtitle,
      boardEtitle,
      boardFtitle,
    } = this.state;
    const authData = getAuth();

    // const fabricIds = Object.keys(fabricSelectData)
    // let boardFabricIds = []
    // boardFabricIds.push(boardFabric1 ? boardFabric1.id : '')
    // boardFabricIds.push(boardFabric2 ? boardFabric2.id : '')
    // boardFabricIds.push(boardFabric3 ? boardFabric3.id : '')
    // boardAData['boardFabricIds'] = boardFabricIds
    boardAData['description'] = boardAtitle;
    boardAData['template'] = 'A';
    boardAData['status'] = board1Status;
    boardAData['memberId'] = authData.id;

    boardBData['description'] = boardBtitle;
    boardBData['template'] = 'B';
    boardBData['status'] = board2Status;
    boardBData['memberId'] = authData.id;

    boardCData['description'] = boardCtitle;
    boardCData['template'] = 'C';
    boardCData['status'] = board3Status;
    boardCData['memberId'] = authData.id;

    boardDData['description'] = boardDtitle;
    boardDData['template'] = 'D';
    boardDData['status'] = board4Status;
    boardDData['memberId'] = authData.id;

    boardEData['description'] = boardEtitle;
    boardEData['template'] = 'E';
    boardEData['status'] = board5Status;
    boardEData['memberId'] = authData.id;

    boardFData['description'] = boardFtitle;
    boardFData['template'] = 'F';
    boardFData['status'] = board6Status;
    boardFData['memberId'] = authData.id;

    const boardData = [
      boardAData,
      boardBData,
      boardCData,
      boardDData,
      boardEData,
      boardFData,
    ];
    const uploadImagesNew = this.getNeedToUploadImages(
      boardADataTemp,
      boardBDataTemp,
      boardCDataTemp,
      boardDDataTemp,
      boardEDataTemp,
      boardFDataTemp
    );
    // 先去將board的圖片傳到雲端，拿回圖片路徑

    let isUploadError = false;
    let howManyImagesNeedUpload = uploadImagesNew.length;

    this.setState({
      loading: true,
    });

    const fileUploadDatas = boardData;

    console.log(fileUploadDatas);

    if (!isEmpty(authData)) {
      // 先去整理看有幾張圖片需要上傳
      this.gotoUploadImage(fileUploadDatas, uploadImagesNew, data);
    }
  }

  handleChange(e) {
    const { target } = e;
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  }

  boardView1() {
    const { boardAData } = this.state;
    const drogDropView = (
      <div className="drogDropBlock">
        <div className="drogDropBox" ref={this.setDropRef}>
          <div className="selectFileIcon">
            <img src={require('../images/icon_plus.png')} />
          </div>
        </div>
      </div>
    );
    const image1View =
      boardAData && boardAData.firstImage ? (
        <Image src={boardAData.firstImage} />
      ) : (
        drogDropView
      );
    const image2View =
      boardAData && boardAData.secendImage ? (
        <Image src={boardAData.secendImage} />
      ) : (
        drogDropView
      );
    const image3View =
      boardAData && boardAData.thirdImage ? (
        <Image src={boardAData.thirdImage} />
      ) : (
        drogDropView
      );
    const image4View =
      boardAData && boardAData.fourthImage ? (
        <Image src={boardAData.fourthImage} />
      ) : (
        drogDropView
      );
    const image5View =
      boardAData && boardAData.fifthImage ? (
        <Image src={boardAData.fifthImage} />
      ) : (
        drogDropView
      );
    const image6View =
      boardAData && boardAData.sixthImage ? (
        <Image src={boardAData.sixthImage} />
      ) : (
        drogDropView
      );
    const image7View =
      boardAData && boardAData.seventhImage ? (
        <Image src={boardAData.seventhImage} />
      ) : (
        drogDropView
      );
    return (
      <div className="board1ContentBox">
        <div className="leftSide">
          <div className="boardImage">
            {image1View}
            <input
              type="file"
              name="firstImage"
              className="chooseFile"
              onChange={(e) => {
                this.changeBoardFile(e, 'A');
              }}
            />
          </div>
          <div className="boardImage">
            {image2View}
            <input
              type="file"
              name="secendImage"
              className="chooseFile"
              onChange={(e) => {
                this.changeBoardFile(e, 'A');
              }}
            />
          </div>
        </div>
        <div className="rightSide">
          <div className="topSide">
            <div className="topLeftSide">
              <div className="boardImage">
                {image3View}
                <input
                  type="file"
                  name="thirdImage"
                  className="chooseFile"
                  onChange={(e) => {
                    this.changeBoardFile(e, 'A');
                  }}
                />
              </div>
            </div>
            <div className="topRightSide">
              <div className="boardImage">
                {image4View}
                <input
                  type="file"
                  name="fourthImage"
                  className="chooseFile"
                  onChange={(e) => {
                    this.changeBoardFile(e, 'A');
                  }}
                />
              </div>
            </div>
          </div>
          <div className="bottomSide">
            <div className="bottomLeftSide">
              <div className="boardImage">
                {image5View}
                <input
                  type="file"
                  name="fifthImage"
                  className="chooseFile"
                  onChange={(e) => {
                    this.changeBoardFile(e, 'A');
                  }}
                />
              </div>
            </div>
            <div className="bottomRightSide">
              <div className="bottomRightTopSide">
                <div className="boardImage">
                  {image6View}
                  <input
                    type="file"
                    name="sixthImage"
                    className="chooseFile"
                    onChange={(e) => {
                      this.changeBoardFile(e, 'A');
                    }}
                  />
                </div>
              </div>
              <div className="bottomRightBottomSide">
                <div className="boardImage">
                  {image7View}
                  <input
                    type="file"
                    name="seventhImage"
                    className="chooseFile"
                    onChange={(e) => {
                      this.changeBoardFile(e, 'A');
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  boardView2() {
    const { boardBData } = this.state;
    const drogDropView = (
      <div className="drogDropBlock">
        <div className="drogDropBox" ref={this.setDropRef}>
          <div className="selectFileIcon">
            <img src={require('../images/icon_plus.png')} />
          </div>
        </div>
      </div>
    );
    const board2ImageColumnMap = [
      'firstImage',
      'secendImage',
      'thirdImage',
      'fourthImage',
      'fifthImage',
      'sixthImage',
    ];
    const board2ImageListView = board2ImageColumnMap.map((element, index) => {
      const imageView =
        boardBData && boardBData[element] ? (
          <Image src={boardBData[element]} />
        ) : (
          drogDropView
        );
      return (
        <div
          key={`board2Image_${element}`}
          className="col-4 col-md-4 layoutBlock boardFigures"
        >
          <div className="boardImage">
            {imageView}
            <input
              type="file"
              name={element}
              className="chooseFile"
              onChange={(e) => {
                this.changeBoardFile(e, 'B');
              }}
            />
          </div>
        </div>
      );
    });
    return <div className="boardContentBlock">{board2ImageListView}</div>;
  }

  boardView3() {
    const { boardCData } = this.state;
    const drogDropView = (
      <div className="drogDropBlock">
        <div className="drogDropBox" ref={this.setDropRef}>
          <div className="selectFileIcon">
            <img src={require('../images/icon_plus.png')} />
          </div>
        </div>
      </div>
    );
    const board3ImageColumnMap = ['firstImage', 'secendImage'];
    const board3ImageListView = board3ImageColumnMap.map((element, index) => {
      const imageView =
        boardCData && boardCData[element] ? (
          <Image src={boardCData[element]} />
        ) : (
          drogDropView
        );
      return (
        <div
          key={`board3Image_${element}`}
          className="layoutBlock boardFigures boardView3"
        >
          <div className="boardImage board3Image">
            {imageView}
            <input
              type="file"
              name={element}
              className="chooseFile"
              onChange={(e) => {
                this.changeBoardFile(e, 'C');
              }}
            />
          </div>
        </div>
      );
    });
    return (
      <div className="boardContentBlock boardContent3">
        {board3ImageListView}
      </div>
    );
  }

  boardView4() {
    const { boardDData } = this.state;
    const drogDropView = (
      <div className="drogDropBlock">
        <div className="drogDropBox" ref={this.setDropRef}>
          <div className="selectFileIcon">
            <img src={require('../images/icon_plus.png')} />
          </div>
        </div>
      </div>
    );
    const board4ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage'];
    const board4ImageListView = board4ImageColumnMap.map((element, index) => {
      const imageView =
        boardDData && boardDData[element] ? (
          <Image src={boardDData[element]} />
        ) : (
          drogDropView
        );
      return (
        <div key={`board4Image_${index}`} className=" layoutBlock boardFigures">
          <div className="boardImage">
            {imageView}
            <input
              type="file"
              name={element}
              className="chooseFile"
              onChange={(e) => {
                this.changeBoardFile(e, 'D');
              }}
            />
          </div>
        </div>
      );
    });
    return (
      <div className="boardContentBlock boardContent4">
        {board4ImageListView}
      </div>
    );
  }

  boardView5() {
    const { boardEData } = this.state;
    const drogDropView = (
      <div className="drogDropBlock">
        <div className="drogDropBox" ref={this.setDropRef}>
          <div className="selectFileIcon">
            <img src={require('../images/icon_plus.png')} />
          </div>
        </div>
      </div>
    );
    const board5ImageColumnMap = ['firstImage', 'secendImage'];
    const board5ImageListView = board5ImageColumnMap.map((element, index) => {
      const imageView =
        boardEData && boardEData[element] ? (
          <Image src={boardEData[element]} />
        ) : (
          drogDropView
        );
      return (
        <div
          key={`board5Image_${index}`}
          className="layoutBlock boardFigures boardView5"
        >
          <div className="boardImage board5Image">
            {imageView}
            <input
              type="file"
              name={element}
              className="chooseFile"
              onChange={(e) => {
                this.changeBoardFile(e, 'E');
              }}
            />
          </div>
        </div>
      );
    });
    return (
      <div className="boardContentBlock boardContent5">
        {board5ImageListView}
      </div>
    );
  }

  boardView6() {
    const { boardFData } = this.state;
    const drogDropView = (
      <div className="drogDropBlock">
        <div className="drogDropBox" ref={this.setDropRef}>
          <div className="selectFileIcon">
            <img src={require('../images/icon_plus.png')} />
          </div>
        </div>
      </div>
    );
    const board6ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage'];
    const board6ImageListView = board6ImageColumnMap.map((element, index) => {
      const imageView =
        boardFData && boardFData[element] ? (
          <Image src={boardFData[element]} />
        ) : (
          drogDropView
        );
      return (
        <div
          key={`board6Image_${index}`}
          className="layoutBlock boardFigures boardView3"
        >
          <div className="boardImage">
            {imageView}
            <input
              type="file"
              name={element}
              className="chooseFile"
              onChange={(e) => {
                this.changeBoardFile(e, 'F');
              }}
            />
          </div>
        </div>
      );
    });
    return (
      <div className="boardContentBlock boardContent6">
        {board6ImageListView}
      </div>
    );
  }

  boardStatusView(columnName) {
    const statusState = this.state[columnName];
    return (
      <div className="statusBox">
        <div
          className={`statusBtn ${statusState === 'on' ? 'active' : ''}`}
          onClick={() => {
            this.changeBoardStatus('on', columnName);
          }}
        >
          {Lang.ROOM_BOARD_STATUS_ON}
        </div>
        <div
          className={`statusBtn ${statusState === 'off' ? 'active' : ''}`}
          onClick={() => {
            this.changeBoardStatus('off', columnName);
          }}
        >
          {Lang.ROOM_BOARD_STATUS_OFF}
        </div>
      </div>
    );
  }

  confirmFabricListSelect(fabricSelectData) {
    const fabricListData = Object.values(fabricSelectData);
    this.setState({
      showFabricModal: false,
      fabricSelectData: fabricSelectData,
      fabricListData: fabricListData,
    });
  }

  confirmBoardFabricSelect(fabricSelectData, sortNo) {
    const boardFabricListData = Object.values(fabricSelectData);
    this.setState({
      showFabricModal: false,
      [`boardFabric${sortNo}`]: boardFabricListData[0],
    });
  }

  render() {
    const {
      loading,
      mainImageData,
      boardAtitle,
      boardBtitle,
      boardCtitle,
      boardDtitle,
      boardEtitle,
      boardFtitle,
    } = this.state;
    const loadingView = loading ? <Loading /> : null;
    let contentView = null;
    const formConfig = this.formConfig();
    const boardView1 = this.boardView1();
    const boardView2 = this.boardView2();
    const boardView3 = this.boardView3();
    const boardView4 = this.boardView4();
    const boardView5 = this.boardView5();
    const boardView6 = this.boardView6();
    const drogDropView = (
      <div className="drogDropBlock">
        <div className="drogDropBox" ref={this.setDropRef}>
          <div className="selectFileIcon">
            <img src={require('../images/icon_plus.png')} />
          </div>
          {Lang.ROOM_MAIN_IMAGE}
        </div>
      </div>
    );
    const mainImageView = mainImageData ? (
      <Image src={mainImageData} />
    ) : (
      drogDropView
    );
    contentView = (
      <div className="addRoomContainer">
        <div className="titleBlock">{Lang.TREND_TITLE}</div>
        <div className="roomContentContainer">
          <div className="layoutBlock col-12 col-md-4 roomInfoBlock">
            <ContentBlock title={Lang.ROOM_INFORMATION}>
              <Form
                className="addRoomForm"
                onSubmit={this.handleSubmit}
                formConfig={formConfig}
                btnText={Lang.ROOM_CREATE_SUBMIT}
                cancelLink="/trend"
              />
            </ContentBlock>
          </div>
          <div className="layoutBlock col-12 col-md-8 roomBoardBlock">
            <div className="contentContainer boardContainer">
              <div className="titleBox">
                {/* {Lang.ROOM_BOARD} */}
                <Input
                  name=""
                  value={boardAtitle}
                  placeholder={Lang.TREND_FIELDS_BOARDNAME}
                  canShowError={false}
                  onChange={(e) => {
                    this.setState({ boardAtitle: e.target.value });
                  }}
                />
                {this.boardStatusView('board1Status')}
              </div>
              <div className="contentBox">{boardView1}</div>
            </div>
            <div className="contentContainer boardContainer">
              <div className="titleBox">
                {/* {Lang.ROOM_BOARD2} */}
                <Input
                  name=""
                  value={boardBtitle}
                  placeholder={Lang.TREND_FIELDS_BOARDNAME}
                  canShowError={false}
                  onChange={(e) => {
                    this.setState({ boardBtitle: e.target.value });
                  }}
                />
                {this.boardStatusView('board2Status')}
              </div>
              <div className="contentBox">{boardView2}</div>
            </div>
            <div className="contentContainer boardContainer">
              <div className="titleBox">
                {/* {Lang.ROOM_BOARD2} */}
                <Input
                  name=""
                  value={boardCtitle}
                  placeholder={Lang.TREND_FIELDS_BOARDNAME}
                  canShowError={false}
                  onChange={(e) => {
                    this.setState({ boardCtitle: e.target.value });
                  }}
                />
                {this.boardStatusView('board3Status')}
              </div>
              <div className="contentBox">{boardView3}</div>
            </div>
            <div className="contentContainer boardContainer">
              <div className="titleBox">
                {/* {Lang.ROOM_BOARD2} */}
                <Input
                  name=""
                  value={boardDtitle}
                  placeholder={Lang.TREND_FIELDS_BOARDNAME}
                  canShowError={false}
                  onChange={(e) => {
                    this.setState({ boardDtitle: e.target.value });
                  }}
                />
                {this.boardStatusView('board4Status')}
              </div>
              <div className="contentBox">{boardView4}</div>
            </div>
            <div className="contentContainer boardContainer">
              <div className="titleBox">
                {/* {Lang.ROOM_BOARD2} */}
                <Input
                  name=""
                  value={boardEtitle}
                  placeholder={Lang.TREND_FIELDS_BOARDNAME}
                  canShowError={false}
                  onChange={(e) => {
                    this.setState({ boardEtitle: e.target.value });
                  }}
                />
                {this.boardStatusView('board5Status')}
              </div>
              <div className="contentBox">{boardView5}</div>
            </div>
            <div className="contentContainer boardContainer">
              <div className="titleBox">
                {/* {Lang.ROOM_BOARD2} */}
                <Input
                  name=""
                  value={boardFtitle}
                  placeholder={Lang.TREND_FIELDS_BOARDNAME}
                  canShowError={false}
                  onChange={(e) => {
                    this.setState({ boardFtitle: e.target.value });
                  }}
                />
                {this.boardStatusView('board6Status')}
              </div>
              <div className="contentBox">{boardView6}</div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <MemberBase>
        {loadingView}
        {contentView}
      </MemberBase>
    );
  }
}

AddTrend.propTypes = {};

const mapStateToProps = () => (state) => {
  return {};
};

const mapDispatchToProps = {
  createTrendBoard,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTrend);
