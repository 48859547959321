import { combineReducers } from 'redux'
import member from './member'
import presentation from './presentation'
import fabricDetail from './fabricDetail'
import myFabric from './myFabric'
import category from './category'
import request from './request'
import catalog from './catalog'
import chat from './chat'
import collect from './collect'
import records from './records'
import textipFabric from './textipFabric'
import presentationVideo from './presentationVideo'
import trendboard from './trendboard'

const rootReducer = combineReducers({
    member,
    presentation,
    fabricDetail,
    myFabric,
    category,
    request,
    catalog,
    chat,
    collect,
    records,
    textipFabric,
    presentationVideo,
    trendboard,
})

export default rootReducer
