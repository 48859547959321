import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { logout } from '../../actions/member';
import { getAuth } from '../../Libs/Lib';
import '../../style/header.sass';
import { EVENT_CART } from '../../constants/eventAction';
import PubSub from 'pubsub-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

class Header extends Component {
  render() {
    const { alert, logout, clickMenu, isShowMenu, goBack } = this.props;
    const authData = getAuth();
    const roomReg = /\/room/;
    return (
      <div className="header">
        <div className="headerContent">
          <div className="logoBox">
            {roomReg.test(window.location.pathname) ? (
              <div style={{ cursor: 'pointer' }} onClick={goBack}>
                go back
              </div>
            ) : (
              <div
                className="burger"
                onClick={() => {
                  clickMenu();
                }}
              >
                <img src={require('../../images/burger.png')} />
              </div>
            )}
          </div>
          <div className="userInfoBox">
            <div
              className="cartImageBox"
              onClick={() => {
                PubSub.publish(EVENT_CART);
              }}
            >
              <img src={require('../../images/menuIcon_order_active.png')} />
            </div>
            <div className="userImageBox">
              {/* {authData && authData.image && <img src={authData.image} />} */}
              {authData && authData.image && (
                <FontAwesomeIcon icon={faCircleUser} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  alert: PropTypes.func,
  logout: PropTypes.func,
  clickMenu: PropTypes.func,
  //用來判斷目前menu有沒有顯示，如果有顯示就需要縮短動畫(被推移的感覺)
  isShowMenu: PropTypes.bool,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
