import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Lang from '../Lang/default'
import { Link } from 'react-router'

export default class RoomBlock extends Component {
    render() {
        const { data, edit, removeFunc } = this.props
        const editBtn = edit ? (
            <Link className="edit" to={`/room/${data.presentationNo}/edit`}>
                <img src={require('../images/icon_edit.png')} />
            </Link>
        ) : null
        const copyBtn = edit ? (
            <Link className="edit" to={`/room/${data.presentationNo}/copy`}>
                <img src={require('../images/icon_copy.png')} />
            </Link>
        ) : null
        const deleteBtn = edit ? (removeFunc ? (
            <div className="edit" onClick={() => {
                removeFunc(data.presentationNo)
            }}>
                <img src={require('../images/icon_remove.png')} />
            </div>
        ) : null) : null
        const bottomBtn = (
            <div className="card-bottomBtn">
                <Link className="btn btn-primary" to={`/room/${data.presentationNo}`}>
                    {Lang.ROOMLIST_CARD_BTN_ENTER}
                </Link>
            </div>
        )

        //是否私密房間public or private
        const privateTag =
            data.status == 'public' ? (
                <div />
            ) : (
                <div className="privateTagBox">
                    <img src={require('../images/icon_lock.png')} className="privateTagImg" />
                    <div className="privateTagTitle">{Lang.ROOMLIST_CARD_TAG_PRIVATE}</div>
                </div>
            )

        const mainImage = data.mainImage || require('../images/cardDefaultImg.jpg')
        return (
            <div className="card">
                <div className="mainImageBox">
                    <img
                        src={mainImage}
                        className="card-img-top"
                        style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                    />
                </div>
                <div className="card-body">
                    <div className="titleRow">
                        <div className="title">{data.themeName}</div>
                        {privateTag}
                    </div>
                    <div className="roomRow">
                        <div className="title">
                            {Lang.ROOMLIST_CARD_TITLE_NO}
                            {data.presentationNo}
                        </div>
                    </div>

                    <div className="authorRow">
                        <div className="title">
                            {Lang.ROOMLIST_CARD_TITLE_OWNER}
                            {data.userName}
                        </div>
                        {editBtn}
                        {copyBtn}
                        {deleteBtn}
                    </div>

                    {bottomBtn}
                </div>
            </div>
        )
    }
}

RoomBlock.propTypes = {
    data: PropTypes.object,
    edit: PropTypes.bool,
}
