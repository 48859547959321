import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { isEmpty, getAuth, getPresentationType } from '../Libs/Lib'
import * as Lang from '../Lang/default'
import { getPresentationList } from '../actions/presentation'
import MemberBase from '../containers/MemberBase'
import Empty from '../components/Empty'
import Loading from '../components/Loading'
import RoomBlock from '../components/RoomBlock'
import Pagination from '../components/Pagination'
import '../style/roomlist.sass'

class RoomList extends Component {
    constructor(props) {
        super(props)
        this.goPage = this.goPage.bind(this)
        this.state = {
            presentationList: null,
            pageInfo: {},
            page: 1,
            loading: false
        }
    }

    componentDidMount() {
        this.getApiPresentationList()
    }

    componentWillUnmount() {}

    componentWillReceiveProps(nextProps) {
        const { listPresentation: thisListPresentation, member: thisMember } = this.props
        const { listPresentation, member } = nextProps
        if (thisListPresentation !== listPresentation) {
            this.setState({
                presentationList: listPresentation.data,
                pageInfo: listPresentation.info,
            })
        }

        if (thisMember !== member && thisMember && member && thisMember.id !== member.id) {
            this.getApiPresentationList()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    getApiPresentationList() {
        const { getPresentationList } = this.props
        const { page } = this.state
        const authData = getAuth()
        if (!isEmpty(authData) && authData.id && authData.token) {
            this.setState({
                loading: true
            })
            getPresentationList({ memberId: authData.id, token: authData.token, page: page, pageSize: 9 }, (res) => {
                if (res.error) {
                    this.setState({
                        presentationList: [],
                    })
                    const errorData = res.payload.response
                    alert(errorData.errorMessage)
                }
                this.setState({
                    loading: false
                })
            })
        }
    }

    goPage(page) {
        this.setState({
            page: page
        })
        setTimeout(() => {
            this.getApiPresentationList()
        }, 100);
    }

    render() {
        const { presentationList, pageInfo, page, loading } = this.state

        const memberType = getPresentationType()
        //以下兩種身份才可已看到  MyRoom
        let canShowCreateBtn = (memberType=='manager' || memberType=='sales')?true:false

        const emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.ROOM_EMPTY_TITLE} content={Lang.ROOM_EMPTY_CONTENT} />
                {canShowCreateBtn && 
                    <div className="btnBox">
                        <Link className="btn btn-primary" to={`/addRoom`}>
                            {Lang.ROOM_CREATE_ROOM}
                        </Link>
                    </div>
                }
            </div>
        )

        const loadingView = presentationList === null || loading ? <Loading /> : null
        let contentView = null
        if (presentationList !== null) {
            if (presentationList.length > 0) {
                const roomListView = presentationList.map((item, index) => {
                    return (
                        <div className="roomListViewItem" key={`room_${index}`}>
                            <RoomBlock data={item} />
                        </div>
                    )
                })
                contentView = (
                    <div className="roomListBlock">
                        <div className="roomListTitleBlock">
                            <div className="tabBlock">
                                <div className="tabItem active">
                                    {Lang.ROOMLIST_TAB_ROOM}
                                </div>
                                { canShowCreateBtn && 
                                    <Link className="tabItem" to={`/myRoom`}>
                                        {Lang.ROOMLIST_TAB_MYROOM}
                                    </Link>
                                }
                            </div>
                            
                        </div>
                        <div className="roomListView">{roomListView}</div>
                        <Pagination data={pageInfo} page={page} goPage={this.goPage} />
                    </div>
                )
            } else {
                contentView = emptyView
            }
        }

        return (
            <MemberBase>
                {loadingView}
                <div className="roomListContainerBase">
                    {contentView}
                </div>
            </MemberBase>
        )
    }
}

RoomList.propTypes = {
    getPresentationList: PropTypes.func.isRequired,
    listPresentation: PropTypes.object,
}

const mapStateToProps = () => (state) => {
    return {
        listPresentation: state.presentation.listPresentation,
        member: state.member.member,
    }
}

const mapDispatchToProps = {
    getPresentationList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomList)
