import { CALL_API } from 'redux-api-middleware';
import * as ActionTypes from '../constants/ActionTypes';

function fetchGetFabricDetail(params) {
  return {
    [CALL_API]: {
      types: [
        {
          type: ActionTypes.FABRICDETAIL_REQUEST,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.FABRICDETAIL_SUCCESS,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.FABRICDETAIL_FAILURE,
          meta: { params: Object.assign({}, params) },
        },
      ],
      method: 'GET',
      endpoint: '/fabric/' + params.id,
      query: params,
    },
  };
}

export function getFabricDetail(params, callback = () => {}) {
  return (dispatch) => {
    dispatch(fetchGetFabricDetail(params)).then((res) => {
      callback(res);
    });
  };
}

function fetchPostFabricView(params) {
  return {
    [CALL_API]: {
      types: [
        {
          type: ActionTypes.FABRICDETAIL_VIEW_REQUEST,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.FABRICDETAIL_VIEW_SUCCESS,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.FABRICDETAIL_VIEW_FAILURE,
          meta: { params: Object.assign({}, params) },
        },
      ],
      method: 'POST',
      endpoint: '/presentation/memberViewFabric',
      body: JSON.stringify(params),
    },
  };
}

export function postFabricView(params, callback = () => {}) {
  return (dispatch) => {
    dispatch(fetchPostFabricView(params)).then((res) => {
      callback(res);
    });
  };
}
