import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getAuth ,isEmpty} from '../Libs/Lib'
import { getPresentationEmail } from '../actions/presentation'
import * as Lang from '../Lang/default'
import MemberBase from '../containers/MemberBase'
import Form from '../components/Form'
import Loading from '../components/Loading'
import Image from '../components/Image'
import Modal from '../components/Modal'
import FabricList from '../components/FabricList'
import TrendBoardList from '../components/TrendBoardList'
import Input from '../components/Input'

import ContentBlock from '../components/ContentBlock'
import '../style/room.sass'

class AddRoom extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.removeListener = this.removeListener.bind(this)
        this.changeBoardFile = this.changeBoardFile.bind(this)
        this.previewImage = this.previewImage.bind(this)
        this.previewBoardImage = this.previewBoardImage.bind(this)
        this.clickAddFabric = this.clickAddFabric.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.closeTrendBoardModal = this.closeTrendBoardModal.bind(this)
        this.changeBoardStatus = this.changeBoardStatus.bind(this)
        this.confirmFabricListSelect = this.confirmFabricListSelect.bind(this)
        this.confirmTrendBoardSelect = this.confirmTrendBoardSelect.bind(this)
        this.changeMainImageFile = this.changeMainImageFile.bind(this)
        this.getNeedToUploadImages = this.getNeedToUploadImages.bind(this)
        this.gotoUploadImage = this.gotoUploadImage.bind(this)
        this.getTrendBoardContent = this.getTrendBoardContent.bind(this)
        this.deleteTrendBoardItem = this.deleteTrendBoardItem.bind(this)
        this.addFabricGroupInWhichTrendBoardIndex = this.addFabricGroupInWhichTrendBoardIndex.bind(this)
        this.getFabricGroupItem = this.getFabricGroupItem.bind(this)
        this.deleteFabricGroupItem = this.deleteFabricGroupItem.bind(this)
        this.makeDataIntoApiFormat = this.makeDataIntoApiFormat.bind(this)
        this.setDropRef = (element) => {
            this.dropRef = element
            let dropDev = this.dropRef
            if (dropDev) {
                dropDev.addEventListener('dragenter', this.handleDragIn)
                dropDev.addEventListener('dragleave', this.handleDragOut)
                dropDev.addEventListener('dragover', this.handleDrag)
                dropDev.addEventListener('drop', this.handleDrop)
            }
        }
        this.state = {
            loading: false,
            description: null,
            firstImage: null,
            secendImage: null,
            thirdImage: null,
            boardFabric1: null,
            boardFabric2: null,
            boardFabric3: null,
            showFabricModal: false,
            fabricListData: [],
            groupItemFabricListData: [], //單一group item裡面有選擇的布料
            currentModifyGroupItemIndex: 0, //目前是新增或是修改哪一個group item項目裡面的布料
            fabricSelectData: {},
            board2ImageList: [null, null, null, null, null, null],
            board1Status: 'on',
            board2Status: 'on',
            boardAData: {},
            boardBData: {},
            memberDatas: [], //會員資料
            mainImageData:'', //主要圖片的base64
            boardADataTemp: {},
            boardBDataTemp: {},
            showAddTrendBoardModal: false,
            selectedTrendboard: [], //目前選了哪些trendboard
            trendSelectListData: {},
            currentSelectTrendboardIndex: 0, //目前選到哪一個trendboard項目要進行編輯
            fabricGroup: [], //trendboard的布料群組
            
        }
    }

    componentWillMount() {}

    componentDidMount() {
        const authData = getAuth()
        if (!isEmpty(authData) && authData.id && authData.token) {
            let params = { memberId: authData.id, token: authData.token}
            this.props.getPresentationEmail(params,(res)=>{
                this.setState({memberDatas:res.payload.data})
            })
        }
    }

    componentWillUnmount() {
        this.removeListener()
    }

    componentWillReceiveProps(nextProps) {}

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    removeListener() {
        if (this.dropRef) {
            this.dropRef.removeEventListener('dragenter', this.handleDragIn)
            this.dropRef.removeEventListener('dragleave', this.handleDragOut)
            this.dropRef.removeEventListener('dragover', this.handleDrag)
            this.dropRef.removeEventListener('drop', this.handleDrop)
        }
    }

    handleDrag(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragOut(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDrop(e) {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.previewImage(e.dataTransfer.files, e.dataTransfer.name)
        }
    }

    changeMainImageFile(e){
        const files = Array.from(e.target.files)
        if (files && files.length > 0) {
            files.map((item, index) => {
                let reader = new FileReader()
                reader.readAsDataURL(item)
                reader.onloadend = () => {
                    this.setState({
                        mainImageData: reader.result
                    })
                }
            })
        }
    }

    changeBoardFile(e, template) {
        this.previewBoardImage(template, e.target.files, e.target.name)
    }

    previewImage(fileList, columnName) {
        const files = Array.from(fileList)
        if (files && files.length > 0) {
            const { boardAData } = this.state
            files.map((item, index) => {
                let reader = new FileReader()
                reader.readAsDataURL(item)
                reader.onloadend = () => {
                    boardAData[columnName] = reader.result
                    this.setState({
                        boardAData
                    })
                }
            })
        }
    }

    previewBoardImage(template, fileList, columnName) {
        const files = Array.from(fileList)
        if (files && files.length > 0) {
            files.map((item, index) => {
                let reader = new FileReader()
                reader.readAsDataURL(item)
                reader.onloadend = () => {
                    const templateDataParams = `board${template}Data`
                    const templateDataParamsTemp = `board${template}DataTemp`
                    
                    this.state[templateDataParams][columnName] = reader.result
                    this.state[templateDataParamsTemp][columnName] = reader.result
                    this.state[templateDataParamsTemp][columnName+'File'] = item
                    this.setState({
                        [templateDataParams]: this.state[templateDataParams],
                        [templateDataParamsTemp]: this.state[templateDataParamsTemp]
                    })
                }
            })
        }
    }

    changeBoardStatus(status, columnName) {
        this.setState({
            [columnName]: status,
        })
    }

    formConfig() {
        return {
            config: {},
            data: [
                {
                    component: 'Input',
                    type: 'text',
                    name: 'themeName',
                    value: '',
                    placeholder: Lang.ROOM_FIELDS_THEME,
                    role: [{ name: 'required' }],
                },
                {
                    component: 'Select',
                    name: 'status',
                    value: '',
                    placeholder: Lang.ROOM_FIELDS_STATUS,
                    role: [{ name: 'required' }],
                    options: [
                        { text: Lang.ROOM_FIELDS_STATUS_OPTIONS_DEFAULT, value: '' },
                        { text: Lang.ROOM_FIELDS_STATUS_PUBLIC, value: 'public' },
                        { text: Lang.ROOM_FIELDS_STATUS_PRIVATE, value: 'private' },
                    ],
                },
                {
                    component: 'AutoComplete',
                    name: 'invited',
                    value: '',
                    placeholder: Lang.ROOM_FIELDS_INVITED,
                    completeData: this.state.memberDatas || []
                },
            ],
        }
    }

    addPresentation(params, callback = () => {}) {
        const config = {
            method: 'POST',
            body: JSON.stringify(params),
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        }
        fetch(`${process.env.REACT_APP_API_ROOT}/presentation`, config)
            .then((res) => res.json())
            .then((res) => {
                callback(res)
            })
    }
    
    getNeedToUploadImages(boardADataTemp, boardBDataTemp){
        let allUploadImages = []
        const board1ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage', 'fourthImage', 'fifthImage', 'sixthImage', 'seventhImage']
        const board2ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage', 'fourthImage', 'fifthImage', 'sixthImage']
        if(boardADataTemp){
            for(let i=0;i<board1ImageColumnMap.length;i++){
                if(boardADataTemp[`${board1ImageColumnMap[i]}`+'File']){
                    allUploadImages.push({boardName:'A',imageName:board1ImageColumnMap[i], imageFile:boardADataTemp[`${board1ImageColumnMap[i]}`+'File']})
                }
            }
        }
        if(boardBDataTemp){
            for(let i=0;i<board2ImageColumnMap.length;i++){
                if(boardBDataTemp[`${board2ImageColumnMap[i]}`+'File']){
                    allUploadImages.push({boardName:'B',imageName:board2ImageColumnMap[i], imageFile:boardBDataTemp[`${board2ImageColumnMap[i]}`+'File']})
                }
            }
        }

        return allUploadImages
    }

    gotoUploadImage(fileUploadDatas,uploadImagesNew ,fabricIds,mainImageData ,data){
        const authData = getAuth()
        let uploadDatas = fileUploadDatas
        let isUploadError = false
        let promiseArray = []
            
        for(let i=0;i<uploadImagesNew.length;i++){
            if(isUploadError){
                break
            }
            let fData = new FormData()
            fData.append('memberId', authData.id)
            fData.append('token', authData.token)
            fData.append('file', uploadImagesNew[i].imageFile)
            fData.append('columnName', uploadImagesNew[i].imageName)
            
            const config = {
                method: 'POST',
                body: fData,
            }
            promiseArray.push(new Promise((resolve,reject)=>{
                fetch(`${process.env.REACT_APP_API_ROOT}/presentation/image`, config)
                    .then((res) => res.json())
                    .then((res) => {
                        if(res.status=='success'){
                            //上傳成功
                            const fileUrl = res.data.url
                            if(uploadImagesNew[i].boardName=='A'){
                                uploadDatas[0][uploadImagesNew[i].imageName] = fileUrl
                            }else if(uploadImagesNew[i].boardName=='B'){
                                uploadDatas[1][uploadImagesNew[i].imageName] = fileUrl
                            }
                            resolve(fileUrl)
                        }else{
                            isUploadError = true
                            reject('error')
                        }
                    })
                })
            )
        }

        Promise.all(promiseArray)
        .then((res)=>{
            //這邊去建立房間api
            const params = Object.assign(data, {
                memberId: authData.id,
                token: authData.token,
                fabricIds: fabricIds,
                boardData: uploadDatas,
                mainImage: mainImageData
            })
            
            this.addPresentation(params, (res) => {
                if (res.status === 'success') {
                    this.setState({
                        loading: false,
                    })
                    const presentationData = res.response
                    window.location.href = `/room/${presentationData.presentationNo}`
                }else{
                    this.setState({
                        loading: false,
                    })
                }
            })

        }).catch((error)=>{
            // rej(error)
            console.log('error = ',error)
        })

    }
    //把資料轉型成api可以吃的格式
    makeDataIntoApiFormat(fabricGroup , selectedTrendboard){
        let tabFormat = []
        tabFormat = selectedTrendboard.map((item,index)=>{
            let trendObj = {trendId:item.id }

            const mFG = fabricGroup[index].fabricGroups.map((item,index)=>{
                let mFGItem = {id:'',name:item.name}
                let mFabricIds = []
                for(let i=0;i<item.fabrics.length;i++){
                    const mF = item.fabrics[i]
                    mFabricIds.push(mF.id)
                }
                return Object.assign(mFGItem,{fabricIds:mFabricIds})
            })



            return Object.assign(trendObj ,{fabricGroups:mFG})
        })
        
        

        return tabFormat
    }

    handleSubmit(e, data) {
        // e.preventDefault()
        const {
            description,
            fabricSelectData,
            boardFabric1,
            boardFabric2,
            boardFabric3,
            boardAData,
            boardBData,
            board1Status,
            board2Status,
            mainImageData, //主圖的base64
            boardADataTemp,
            boardBDataTemp,
            fabricGroup,    //布料群組
            selectedTrendboard, //trendboard陣列
        } = this.state
        const authData = getAuth()
        
        const fabricIds = Object.keys(fabricSelectData)
        let boardFabricIds = []
        boardFabricIds.push(boardFabric1 ? boardFabric1.id : '')
        boardFabricIds.push(boardFabric2 ? boardFabric2.id : '')
        boardFabricIds.push(boardFabric3 ? boardFabric3.id : '')
        boardAData['boardFabricIds'] = boardFabricIds
        boardAData['description'] = description
        boardAData['template'] = 'A'
        boardAData['status'] = board1Status
        boardAData['memberId'] = authData.id
        boardBData['template'] = 'B'
        boardBData['status'] = board2Status
        boardBData['memberId'] = authData.id

        const tabData = this.makeDataIntoApiFormat(fabricGroup,selectedTrendboard)

        this.setState({
            loading: true,
        })
        
        if (!isEmpty(authData)) {

            //這邊去建立房間api
            const params = Object.assign(data, {
                memberId: authData.id,
                token: authData.token,
                tabs: tabData,
                mainImage: mainImageData
            })
            
            
            this.addPresentation(params, (res) => {
                if (res.status === 'success') {
                    
                    this.setState({
                        loading: false,
                    })
                    const presentationData = res.response
                    window.location.href = `/room/${presentationData.presentationNo}`
                }else{
                    this.setState({
                        loading: false,
                    })
                }
            })

        }
    }

    handleChange(e) {
        const { target } = e
        const { name, value } = target
        this.setState({
            [name]: value,
        })
    }

    fabricListView() {
        const { fabricListData } = this.state
        const fabricListView = fabricListData.map((item, index) => {
            return (
                <div key={`fabricItem_${index}`} className="fabricItem col-6 col-md-2 layoutBlock">
                    <div className="card">
                        <div className="fabricImage">
                            <Image src={item.images} />
                        </div>
                    </div>
                </div>
            )
        })
        return (
            <div>
                <div className="fabricListView row">
                    {fabricListView}
                    <div
                        className="fabricItem col-6 col-md-2 layoutBlock addFabric"
                        onClick={() => {
                            this.clickAddFabric()
                        }}
                    >
                        <div className="card">
                            <div className="addNewFabirc">
                                <Image
                                    src={require('../images/icon_plus.png')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    groupItemfabricListView(groupItemIndex) {
        const { currentSelectTrendboardIndex ,fabricGroup  } = this.state
        const mFabrics = fabricGroup[currentSelectTrendboardIndex].fabricGroups[groupItemIndex].fabrics
        const fabricListView = mFabrics.map((item, index) => {
            return (
                <div key={`fabricItem_${index}`} className="fabricItem col-6 col-md-2 layoutBlock" style={{position:'relative'}}>
                    <div className="card">
                        <div className="fabricImage">
                            <Image src={item.images} />
                        </div>
                    </div>
                    <div className="close" onClick={(e)=>{
                            e.stopPropagation()
                            fabricGroup[currentSelectTrendboardIndex].fabricGroups[groupItemIndex].fabrics.splice(index,1)
                            this.setState({
                                groupItemFabricListData:fabricGroup[currentSelectTrendboardIndex].fabricGroups[groupItemIndex].fabrics,
                                fabricGroup:fabricGroup
                            })
                            
                        }}>
                        <img src={require('../images/close-black.png')} />
                    </div>
                </div>
            )
        })
        return (
            // <div>
                <div className="fabricListView row" style={{padding:'0px 10px' ,marginBottom:'0px'}}>
                    {fabricListView}
                    <div
                        className="fabricItem col-6 col-md-2 layoutBlock addFabric"
                        onClick={() => {
                            this.setState({groupItemFabricListData:mFabrics,
                                currentModifyGroupItemIndex:groupItemIndex,
                                showFabricModal: true})
                        }}
                    >
                        <div className="card">
                            <div className="addNewFabirc">
                                <Image
                                    src={require('../images/icon_plus.png')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            // </div>
        )
    }

    fabricModalView() {
        const {
            showFabricModal,
            groupItemFabricListData, //單一group item裡面有選擇的布料
        } = this.state
        let selectedFabrics = []
        for(let i=0;i<groupItemFabricListData.length;i++){
            selectedFabrics.push(groupItemFabricListData[i])
        }
        let confirmSelect = this.confirmFabricListSelect
        let multiple = true

        return (
            <Modal displayModal={showFabricModal} closeModal={this.closeModal}>
                <FabricList
                    className="myFabricSelectContainer"
                    confirmSelect={confirmSelect}
                    selectedFabrics={selectedFabrics}
                    multiple={multiple}
                />
            </Modal>
        )
    }

    //選擇trendBoard的modal
    trendBoardModalView(){
        const {
            showAddTrendBoardModal,
            selectedTrendboard,
        } = this.state
        let selectedTrendboards = []
        let confirmSelect = this.confirmTrendBoardSelect
        let multiple = false
        selectedTrendboards = selectedTrendboard
        return (
            <Modal displayModal={showAddTrendBoardModal} closeModal={this.closeTrendBoardModal}>
                <TrendBoardList
                    className="myTrendBoardSelectContainer"
                    confirmSelect={confirmSelect}
                    multiple={multiple}
                />
            </Modal>
        )
    }

    boardStatusView(columnName) {
        const statusState = this.state[columnName]
        return (
            <div className="statusBox">
                <div
                    className={`statusBtn ${statusState === 'on' ? 'active' : ''}`}
                    onClick={() => {
                        this.changeBoardStatus('on', columnName)
                    }}
                >
                    {Lang.ROOM_BOARD_STATUS_ON}
                </div>
                <div
                    className={`statusBtn ${statusState === 'off' ? 'active' : ''}`}
                    onClick={() => {
                        this.changeBoardStatus('off', columnName)
                    }}
                >
                    {Lang.ROOM_BOARD_STATUS_OFF}
                </div>
            </div>
        )
    }

    trendBoardAddBtn(){
        return (
            <div className="statusBox">
                <div
                    className={'statusBtn active'}
                    onClick={() => {
                        this.setState({showAddTrendBoardModal:true})
                    }}
                >
                    {'Add Board'}
                </div>
            </div>
        )
    }

    //刪除某個trendboard
    deleteTrendBoardItem(index){

        const {currentSelectTrendboardIndex , selectedTrendboard , fabricGroup} = this.state

        //把要刪除的項目從陣列中移除
        

        let nIndex = currentSelectTrendboardIndex

        if(index == selectedTrendboard.length-1){
            //如果刪除項目是最後一個項目
            nIndex = index-1
        }else if(index == currentSelectTrendboardIndex){
           //刪除到目前的焦點項目，所以焦點項目改為目前刪除項目的上一個
        }else if(index < currentSelectTrendboardIndex && currentSelectTrendboardIndex==selectedTrendboard.length-1){
            //刪除的項目比目前焦點項目還要前面
            nIndex = currentSelectTrendboardIndex-1
        }
        
        if(nIndex<0){
            nIndex = 0
        }

        let nSelectedTrendboard = selectedTrendboard.splice(index,1)
        fabricGroup.splice(index,1)        

        this.setState({
            currentSelectTrendboardIndex: nIndex,
            selectedTrendboard: selectedTrendboard,
            fabricGroup:fabricGroup
        })
    }
    
    //刪除某個布料群組
    deleteFabricGroupItem(index){
        const {currentSelectTrendboardIndex ,fabricGroup} = this.state

        let targetItemFg = fabricGroup[currentSelectTrendboardIndex].fabricGroups

        targetItemFg.splice(index,1)        

        fabricGroup[currentSelectTrendboardIndex].fabricGroups = targetItemFg

        this.setState({fabricGroup: fabricGroup})
        
    }

    getFabricGroupItem(item,index){
        //獲取一組布料groupItem
        const {currentSelectTrendboardIndex ,fabricGroup} = this.state

        const fabricListView = this.groupItemfabricListView(index)

        return (
            <div key={'fg_item_'+index} className='fabricGroupItem'>
                <div className="rightArea">
                    <div className="headContainer">
                        
                            <Input name={'item_'+index} value={item.name} placeholder={Lang.ROOM_FABRICGROUP_ITEM_TITLE_PLACEHOLDER} canShowError={false} onChange={(e)=>{
                                
                                let mFabricGroupds = fabricGroup[currentSelectTrendboardIndex].fabricGroups
                                mFabricGroupds[index].name = e.target.value
                            }}/>
                        
                        <div style={{flex:1 ,display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                            <button className="btn btn-primary" style={{padding:'5px 10px' ,backgroundColor:'#dc3545'}} onClick={()=>{
                                let check = confirm('Do you want to delete the group ?')
                                if (check) {
                                    this.deleteFabricGroupItem(index)
                                }}}
                            >
                                delete group
                            </button>
                        </div>
                    </div>
                    <div className="contentContainer">
                        {fabricListView}
                    </div>
                </div>
                
            </div>
        )
    }

    //獲取trendBoard裡面要呈現的畫面
    getTrendBoardContent(){
        const {selectedTrendboard,currentSelectTrendboardIndex ,fabricGroup} = this.state

        const tbListView = selectedTrendboard.map((item,index)=>{
            const activeClass = currentSelectTrendboardIndex == index? 'active' : ''
            return (
                <div
                    key={`tbItem_${index}`}
                    className={`tbItem col-6 col-md-3 layoutBlock ${activeClass}`}
                    onClick={() => {
                        this.setState({currentSelectTrendboardIndex:index})
                    }}
                >
                    <div className="card" style={{position:'relative'}}>
                        <div className="fabricImage">
                            <Image src={item.mainImage} className="card-img-top" />
                        </div>
                        <div className="card-body">
                            <p className="card-text textOverflow">{item.name}</p>
                        </div>
                        
                        <div className="close" onClick={(e)=>{
                            e.stopPropagation()
                            let check = confirm('Deleting this item will also delete the related fabric group')
                            if (check) {
                                this.deleteTrendBoardItem(index)
                            }
                            
                        }}>
                            <img src={require('../images/close-black.png')} />
                        </div>

                    </div>


                </div>
            )
        })
        
        const fabricGroupList = fabricGroup[currentSelectTrendboardIndex] && fabricGroup[currentSelectTrendboardIndex].fabricGroups ? 
            fabricGroup[currentSelectTrendboardIndex].fabricGroups.map((item,index)=>{
                return this.getFabricGroupItem(item,index)
                
            }):null

        const addFabricGroupBtn = <button className="btn btn-primary" onClick={()=>this.addFabricGroupInWhichTrendBoardIndex()}>create fabric group</button>


        //呈現下面布料區域
        const fabricContainer = (
            <div className="fabricContainer">
                {fabricGroupList}
                {addFabricGroupBtn}
            </div>)
        const sperateLine = selectedTrendboard.length>0 ? <div style={{width:'100%',height:'1px',backgroundColor:'#eceff3',margin:'15px 0px'}}/> : null
        const fabricArea = selectedTrendboard.length>0 ? fabricContainer : null

        return (
        <div style={{display:'flex',flexDirection:'column'}}>
            <div className="trendBoardContainer">{tbListView}</div>
            {sperateLine}
            {fabricArea}
        </div>
        )
    }

    addFabricGroupInWhichTrendBoardIndex(){
        const {currentSelectTrendboardIndex ,fabricGroup} = this.state
        const fabricGroupItem = {
            id:'',
            name:'',
            fabrics:[]
        }
        let targetItemFg = fabricGroup[currentSelectTrendboardIndex].fabricGroups
        targetItemFg.push(fabricGroupItem)
        fabricGroup[currentSelectTrendboardIndex].fabricGroups = targetItemFg

        this.setState({fabricGroup: fabricGroup})
        
    }
    
    //點了布料modal的同意
    confirmFabricListSelect(fabricSelectData) {
        const fabricListData = Object.values(fabricSelectData)
        const {currentSelectTrendboardIndex ,fabricGroup ,currentModifyGroupItemIndex} = this.state
        fabricGroup[currentSelectTrendboardIndex].fabricGroups[currentModifyGroupItemIndex].fabrics = fabricListData

        this.setState({
            showFabricModal: false,
        })
    }

    //點了trendboard的同意
    confirmTrendBoardSelect(trendSelectDatas){
        const k = Object.keys(trendSelectDatas)
        
        let cc = this.state.selectedTrendboard

        cc.push(trendSelectDatas[k])
        
        let fg = this.state.fabricGroup
        fg.push({fabricGroups:[]})
        
        this.setState({
            showAddTrendBoardModal: false,
            selectedTrendboard: cc,
            fabricGroup:fg
        })   
        
    }


    clickAddFabric() {
        this.setState({
            showFabricModal: true,
        })
    }
    openFabricDetail(fabricId) {
        this.setState({
            showFabricId: fabricId,
        })
    }

    closeModal(e) {
        this.setState({
            showFabricModal: false,
        })
    }
    closeTrendBoardModal(e) {
        this.setState({
            showAddTrendBoardModal: false,
        })
    }

    render() {
        const { loading ,mainImageData} = this.state
        const loadingView = loading ? <Loading /> : null
        const fabricModalView = this.fabricModalView()
        const trendBoardModalView = this.trendBoardModalView()

        let contentView = null
        const formConfig = this.formConfig()
        const trendBoardContent = this.getTrendBoardContent()

        // const fabricListView = this.fabricListView()
        const drogDropView = (
            <div className="drogDropBlock">
                <div className="drogDropBox" ref={this.setDropRef}>
                    <div className="selectFileIcon">
                        <img src={require('../images/icon_plus.png')} />
                    </div>
                    {Lang.ROOM_MAIN_IMAGE}
                </div>
                
            </div>
        )
        const mainImageView = mainImageData ? <Image src={mainImageData} /> : drogDropView
        contentView = (
            <div className="addRoomContainer">
                <div className="titleBlock">{Lang.ROOM_TITLE}</div>
                <div className="roomContentContainer">
                    <div className="layoutBlock col-12 col-md-4 roomInfoBlock">
                        <ContentBlock title={Lang.ROOM_INFORMATION}>
                            <div style={{width:'100%',height:'100%' ,position:'relative'}}>
                                {mainImageView}
                                <input
                                    type="file"
                                    name="mainImage"
                                    className="chooseFile"
                                    onChange={e => {
                                        this.changeMainImageFile(e)
                                    }}
                                />
                            </div>
                            <Form
                                className="addRoomForm"
                                onSubmit={this.handleSubmit}
                                formConfig={formConfig}
                                btnText={Lang.ROOM_CREATE_SUBMIT}
                                cancelLink="/myRoom"
                            />
                        </ContentBlock>
                    </div>
                    <div className="layoutBlock col-12 col-md-8 roomBoardBlock">
                        <div className="contentContainer boardContainer">
                            <div className="titleBox">
                                {Lang.ROOM_TREND_BOARD}
                                {this.trendBoardAddBtn()}
                            </div>
                            <div className="contentBox">{trendBoardContent}</div>
                        </div>
                        
                        {/* <ContentBlock title={Lang.ROOM_FABRICLIST_TITLE} className="boardContainer">
                            {fabricListView}
                        </ContentBlock> */}
                    </div>
                </div>
            </div>
        )
        return (
            <MemberBase>
                {loadingView}
                {fabricModalView}
                {/* 顯示有幾個trendBoard可以讓他加入 */}
                {trendBoardModalView} 
                {contentView}
            </MemberBase>
        )
    }
}

AddRoom.propTypes = {
    // addPresentation: PropTypes.func.isRequired,
}

const mapStateToProps = () => (state) => {
    return {}
}

const mapDispatchToProps = {
    // addPresentation,
    getPresentationEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRoom)
