import { CALL_API } from 'redux-api-middleware';
import * as ActionTypes from '../constants/ActionTypes';

// function fetchAddPersonalFabric(params) {
//     return {
//         [CALL_API]: {
//             types: [
//                 {
//                     type: ActionTypes.FABRICDETAIL_REQUEST,
//                     meta: { params: Object.assign({}, params) },
//                 },
//                 {
//                     type: ActionTypes.FABRICDETAIL_SUCCESS,
//                     meta: { params: Object.assign({}, params) },
//                 },
//                 {
//                     type: ActionTypes.FABRICDETAIL_FAILURE,
//                     meta: { params: Object.assign({}, params) },
//                 },
//             ],
//             method: 'POST',
//             endpoint: '/myFabric',
//             query: params,
//         },
//     }
// }

// export function addPersonalFabric(params) {
//     return (dispatch) => {
//         dispatch(fetchAddPersonalFabric(params))
//     }
// }

function fetchGetMyFabricDatas(params) {
  return {
    [CALL_API]: {
      types: [
        {
          type: ActionTypes.MYFABRIC_GETPERSONALITEMS_REQUEST,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.MYFABRIC_GETPERSONALITEMS_SUCCESS,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.MYFABRIC_GETPERSONALITEMS_FAILURE,
          meta: { params: Object.assign({}, params) },
        },
      ],
      method: 'GET',
      endpoint: '/myFabric',
      query: params,
    },
  };
}

export function getMyFabricDatas(params, callback = () => {}) {
  return (dispatch) => {
    dispatch(fetchGetMyFabricDatas(params)).then((res) => {
      callback(res);
    });
  };
}

function fetchGetMyFabricWithId(params) {
  return {
    [CALL_API]: {
      types: [
        {
          type: ActionTypes.MYFABRIC_GETPERSONALITEM_REQUEST,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.MYFABRIC_GETPERSONALITEM_SUCCESS,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.MYFABRIC_GETPERSONALITEM_FAILURE,
          meta: { params: Object.assign({}, params) },
        },
      ],
      method: 'GET',
      endpoint: '/myFabric/' + params.id,
      query: params,
    },
  };
}

export function getMyFabricWithId(params) {
  return (dispatch) => {
    dispatch(fetchGetMyFabricWithId(params));
  };
}

function fetchDeleteMyFabricWithId(params) {
  return {
    [CALL_API]: {
      types: [
        {
          type: ActionTypes.MYFABRIC_DELETEPERSONALITEM_REQUEST,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.MYFABRIC_DELETEPERSONALITEM_SUCCESS,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.MYFABRIC_DELETEPERSONALITEM_FAILURE,
          meta: { params: Object.assign({}, params) },
        },
      ],
      method: 'DELETE',
      endpoint: '/myFabric/' + params.id,
      query: params,
    },
  };
}

export function deleteMyFabricWithId(params, callback = () => {}) {
  return (dispatch) => {
    dispatch(fetchDeleteMyFabricWithId(params)).then((res) => {
      callback(res);
    });
  };
}

function fetchGetTagForFabric(params) {
  return {
    [CALL_API]: {
      types: [
        {
          type: ActionTypes.MYFABRIC_GETTAGFORFABRIC_REQUEST,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.MYFABRIC_GETTAGFORFABRIC_SUCCESS,
          meta: { params: Object.assign({}, params) },
        },
        {
          type: ActionTypes.MYFABRIC_GETTAGFORFABRIC_FAILURE,
          meta: { params: Object.assign({}, params) },
        },
      ],
      method: 'GET',
      endpoint: '/myFabric/tagsForEdit',
      query: params,
    },
  };
}

export function getTagForFabric(params) {
  return (dispatch) => {
    dispatch(fetchGetTagForFabric(params));
  };
}
