import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import '../../style/shopcart.sass'
import { EVENT_CART, EVENT_CART_REFRESH, EVENT_REQUEST_REFRESH } from '../../constants/eventAction'
import PubSub from 'pubsub-js'
import cookie from 'react-cookies'
import { getAuth } from '../../Libs/Lib'
import { addRequest } from '../../actions/request'

class Shopcart extends Component {
    constructor(props) {
        super(props)
        // this.clickMenu = this.clickMenu.bind(this)
        this.getContentItems = this.getContentItems.bind(this)
        this.getShopcartData = this.getShopcartData.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.deleteAllItems = this.deleteAllItems.bind(this)
        this.deleteSingleItem = this.deleteSingleItem.bind(this)
        this.plusItemNumber = this.plusItemNumber.bind(this)
        this.minusItemNumber = this.minusItemNumber.bind(this)
        this.state = {
            show: props.show,
            cartData: {},
        }
        this.requestFabric = this.requestFabric.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    componentWillReceiveProps(nextProps) {
        const { show: thisShow } = this.props
        const { show } = nextProps
        if (thisShow !== show) {
            this.setState({
                show: show,
            })
        }
    }

    componentDidMount() {
        //註冊購物車刷新pubsub事件
        PubSub.subscribe(EVENT_CART_REFRESH, () => {
            this.refreshData()
        })
        //獲取購物車資料
        this.getShopcartData()
    }

    plusItemNumber(item,index){
        if(item.amount==99){
            return
        }
        let newItem = item
        newItem.amount = parseInt(item.amount)+1

        let newCart = { items: [] }
        const oldData = this.state.cartData
        for (let i = 0; i < oldData.items.length; i++) {
            if (i != index) {
                newCart.items.push(oldData.items[i])
            }else{
                //loop到了要修改的項目
                newCart.items.push(newItem)
            }
        }
        this.setState({ cartData: newCart })
        cookie.save('shopcart', JSON.stringify(newCart), { path: '/' }) 
    }

    minusItemNumber(item,index){
        if(item.amount==1){
            return
        }
        let newItem = item
        newItem.amount = parseInt(item.amount)-1

        let newCart = { items: [] }
        const oldData = this.state.cartData
        for (let i = 0; i < oldData.items.length; i++) {
            if (i != index) {
                newCart.items.push(oldData.items[i])
            }else{
                //loop到了要修改的項目
                newCart.items.push(newItem)
            }
        }
        this.setState({ cartData: newCart })
        cookie.save('shopcart', JSON.stringify(newCart), { path: '/' }) 
    }

    getContentItems() {
        const items = this.state.cartData.items.map((item, index) => {
            const unitText = item.type === 'fabric' ? 'yard(s)' : 'piece(s)'
            return (
                <div className="singleItem" key={item.id + '_' + index}>
                    <img src={item.image} className="itemImage" />
                    <div className="singleItemRight">
                        <div className="top">
                            <div style={{flex:1}}>
                                <img
                                    src={require('../../images/icon_cart_plus.png')}
                                    className="imgControl"
                                    onClick={() => {
                                        this.plusItemNumber(item , index)
                                    }}
                                />
                                <img
                                    src={require('../../images/icon_cart_minus.png')}
                                    className="imgControl"
                                    onClick={() => {
                                        this.minusItemNumber(item , index)
                                    }}
                                />
                            </div>
                            <img
                                src={require('../../images/icon_trash.png')}
                                className="imgTrash"
                                onClick={() => {
                                    let check = confirm('Do you really want to delete this item ?')
                                    if (check) {
                                        this.deleteSingleItem(index)
                                    }
                                }}
                            />
                        </div>
                        <div className="itemQuantityText">
                            {item.type}：{item.amount} {unitText}<br />
                            contents：{item.contents}
                        </div>
                    </div>
                </div>
            )
        })
        return items
    }

    //刷新購物車資料
    refreshData() {
        this.getShopcartData()
    }

    deleteAllItems() {
        cookie.save('shopcart', 'undefined', { path: '/' })
        this.setState({ cartData: {} })
    }

    deleteSingleItem(delIndex) {
        let newCart = { items: [] }
        const oldData = this.state.cartData
        for (let i = 0; i < oldData.items.length; i++) {
            if (i != delIndex) {
                newCart.items.push(oldData.items[i])
            }
        }
        this.setState({ cartData: newCart })
        cookie.save('shopcart', JSON.stringify(newCart), { path: '/' })
    }

    //獲取購物車資料
    getShopcartData() {
        let shopcart = cookie.load('shopcart')

        let newCart
        if (!shopcart || shopcart == 'undefined') {
            newCart = { items: [] }
        } else {
            newCart = shopcart
        }
        this.setState({ cartData: newCart })
    }
    
    requestFabric() {
        const now = new Date()
        const postTime = now.getTime()
        const { cartData } = this.state
        const { addRequest } = this.props
        const submitCartData = cartData.items.map(element => {
            return {
                fabricId: element['id'],
                quantity: element['amount'],
                type: element['type'],
                unitPrice: '',
                note: '',
                presentationNo: element.presentationNo
            }
        })
        const authData = getAuth()
        addRequest({ memberId: authData.id, token: authData.token, cartData: submitCartData }, (res) => {
            if (res.error) {
                const errorData = res.payload.response
                alert(errorData.errorMessage)
            } else {
                //清空購物車
                cookie.save('shopcart', 'undefined', { path: '/' })
                this.setState({ cartData: {} })
                PubSub.publish(EVENT_REQUEST_REFRESH, { postTime: postTime })
                alert('send request success')
            }
        })
    }

    render() {
        const { show, cartData } = this.state
        const showClass = show ? 'show' : ''

        let myContent
        if (cartData && cartData.items && cartData.items.length > 0) {
            const contentItems = this.getContentItems()

            const requestBtn = (
                <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginTop: '10px' }}
                    onClick={() => {this.requestFabric()}}
                >
                    Send Request
                </button>
            )
            const clearBtn = (
                <button
                    type="button"
                    className="btn btn-danger"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                        let check = confirm('Do you really want to delete all items ?')
                        if (check) {
                            this.deleteAllItems()
                        }
                    }}
                >
                    Clear All
                </button>
            )
            myContent = (
                <div className="contentArea">
                    <div className="itemArea">{contentItems}</div>

                    {requestBtn}
                    {clearBtn}
                </div>
            )
        } else {
            myContent = (
                <div className="contentArea">
                    <div className="title" style={{ textAlign: 'center' }}>
                        No item!
                    </div>
                </div>
            )
        }
        return (
            <div className={`shopcart ${showClass}`}>
                <div className="logoBox">
                    <div
                        className="closeImgBox"
                        onClick={() => {
                            PubSub.publish(EVENT_CART)
                        }}
                    >
                        <img src={require('../../images/close-black.png')} className="closeImg" />
                    </div>
                    <div className="title">
                        <img src={require('../../images/menuIcon_order_active.png')} />
                    </div>
                </div>
                {myContent}
            </div>
        )
    }
}

Shopcart.propTypes = {
    addRequest: PropTypes.func.isRequired,
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    addRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Shopcart)
