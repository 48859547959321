import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContentBlock from './ContentBlock';
import Image from './Image';

import '../style/boardDetail.sass';

class BoardDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  render() {
    const {
      handleClose,
      isOpen,
      data,
      board1,
      board2,
      board3,
      board4,
      board5,
      board6,
    } = this.props;

    if (!isOpen || !data) {
      return null;
    }

    const { board } = data;

    let boardView;

    switch (board) {
      case 'A':
        boardView = board1;
        break;
      case 'B':
        boardView = board2;
        break;
      case 'C':
        boardView = board3;
        break;
      case 'D':
        boardView = board4;
        break;
      case 'E':
        boardView = board5;
        break;
      case 'F':
        boardView = board6;
        break;
      default:
        break;
    }

    return (
      <div className="boardDetail" onClick={handleClose}>
        <div className="containerOuter">
          <div className="mainContainer">
            {!Object.keys(data).length ? 'loading' : boardView}
          </div>
        </div>
      </div>
    );
  }
}

BoardDetail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default BoardDetail;
