import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    collectList: {
        info: {},
        data: []
    },
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case ActionTypes.LIKE_ADD_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.LIKE_ADD_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.LIKE_ADD_SUCCESS:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.LIKE_CANCEL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.LIKE_CANCEL_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.LIKE_CANCEL_SUCCESS:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.LIKE_LIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.LIKE_LIST_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.LIKE_LIST_SUCCESS:
            const collectListRes = action.payload.response
            let collectList = Object.assign({}, state.collectList)
            collectList.data = collectListRes.data
            collectList.info = {
                pageSize: collectListRes.pageSize,
                totalPage: collectListRes.totalPage,
                totalRow: collectListRes.count,
            }
            return Object.assign({}, state, {
                isDataFetching: false,
                collectList,
            })

        default:
            return state
    }
}
