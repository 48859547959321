import React, { Component } from 'react';
import 'whatwg-fetch';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Lang from '../Lang/default';
import '../style/login.sass';
import Form from '../components/Form';
import { login, getMemberData } from '../actions/member';
import { withFirebase } from '../components/Firebase';

class LoginBase extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    var screenView = 'Login';
    this.props.firebase.addLogEvent('custom_screen', {
      screen_view: screenView,
    });
  }

  handleSubmit(e, value) {
    // e.preventDefault()
    console.log(value.error);
    console.log(process.env.REACT_APP_PORT);
    const { login, alert } = this.props;
    login(value, res => {
      if (res.error) {
        const errorData = res.payload.response;
        alert(errorData.errorMessage);
      } else {
        const successData = res.payload.data;
        alert(`Welcome: ${successData.name}`);
        //馬上去獲取會員資料
        this.props.getMemberData(successData.id);
      }
    });
  }

  formConfig() {
    return {
      config: {},
      data: [
        {
          component: 'Input',
          type: 'text',
          name: 'email',
          placeholder: 'E-mail',
          role: [{ name: 'required' }],
        },
        {
          component: 'Input',
          type: 'password',
          name: 'password',
          placeholder: 'password',
          role: [{ name: 'required' }],
        },
      ],
    };
  }

  render() {
    const formConfig = this.formConfig();
    return (
      <div className='loginContainer'>
        <div className='loginBox'>
          <div className='bannerBox'>
            <img src={require('../images/loginBanner.jpg')} />
          </div>
          <div className='formBox'>
            <div className='logoBox'>
              <img src={require('../images/herminLogo.png')} />
            </div>
            <Form
              onSubmit={this.handleSubmit}
              formConfig={formConfig}
              btnText={Lang.MEMBER_LOGIN}
            />
          </div>
        </div>
      </div>
    );
  }
}

LoginBase.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  login,
  getMemberData,
};

const Login = withFirebase(LoginBase);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
