import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FashionView from './FashionView';

class CatalogPageItem extends PureComponent {
  render() {
    const { jsonData } = this.props;

    return (
      <div className="contentContainer" style={{ width: '100%' }}>
        <div className="contentBox fashionPageContentBox">
          <div style={{ width: 'auto' }}>
            <FashionView previewJson={jsonData} isEdit={false} />
          </div>
        </div>
      </div>
    );
  }
}

CatalogPageItem.propTypes = {
  jsonData: PropTypes.string,
  pageTitle: PropTypes.string,
};

export default CatalogPageItem;
