import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, getAuth, getPresentationType } from '../Libs/Lib';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import PubSub from 'pubsub-js';

import VideoCall from '../Libs/VideoCall';
import VideoRecord from '../Libs/VideoRecord';
import { getPresentationData } from '../actions/presentation';
import { addMessage, getMessageList } from '../actions/chat';
import { addCollect, cancelCollect } from '../actions/collect';
import { Link } from 'react-router';
import * as Lang from '../Lang/default';
import webSocket from 'socket.io-client';
import Empty from '../components/Empty';
import Button from '../components/Button';
import Image from '../components/Image';
import Loading from '../components/Loading';
import MemberBase from '../containers/MemberBase';
import FabricDetail from '../components/FabricDetail';
import ContentBlock from '../components/ContentBlock';
import BoardDetail from '../components/BoardDetail';
import Input from '../components/Input';
import { EVENT_COLLECT_LIST_LOAD } from '../constants/eventAction';

import '../style/room.sass';
import '../style/fabricListView.sass';

let peers = {};

class Room extends Component {
  constructor(props) {
    super(props);
    this.openFabricDetail = this.openFabricDetail.bind(this);
    this.closeFabricDetail = this.closeFabricDetail.bind(this);
    this.closeBoardcast = this.closeBoardcast.bind(this);
    this.collectFabric = this.collectFabric.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
    this.clickBoardcast = this.clickBoardcast.bind(this);
    this.setBoardcast = this.setBoardcast.bind(this);
    this.changeFile = this.changeFile.bind(this);
    this.joinVideoCall = this.joinVideoCall.bind(this);
    this.clickVideoRecord = this.clickVideoRecord.bind(this);
    this.clickVideoRecordStart = this.clickVideoRecordStart.bind(this);
    this.clickVideoRecordStop = this.clickVideoRecordStop.bind(this);
    this.clickVideoCall = this.clickVideoCall.bind(this);
    this.clickAudioCall = this.clickAudioCall.bind(this);
    this.clickVideoCallConnectControl =
      this.clickVideoCallConnectControl.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addPeer = this.addPeer.bind(this);
    this.removePeer = this.removePeer.bind(this);
    this.getFabricGroupItem = this.getFabricGroupItem.bind(this);
    this.clickToggleBoardModal = this.clickToggleBoardModal.bind(this);
    this.clickCloseBoardModal = this.clickCloseBoardModal.bind(this);

    this.handleScroll = this.handleScroll.bind(this);

    this.videoCall = new VideoCall();
    this.videoRecord = new VideoRecord();
    this.socket = null;
    this._isMounted = false;
    this.state = {
      presentationNo: props.params.presentationNo,
      presentationData: null,
      presentationType: getPresentationType(),
      fabricListData: [],
      showFabricId: null,
      localStream: false,
      remoteStreamUrl: '',
      streamUrl: '',
      initiator: false,
      peer: {},
      connecting: false,
      waiting: true,
      micState: true,
      camState: true,
      messageData: [],
      boardcastData: {},
      postImage: null,
      messageList: [],
      messagePageInfo: {},
      messageLoading: false,
      recording: false,
      videoCall: true,
      audioCall: true,
      videoCallJoin: false,
      uploadPresentationVideoConfirm: null,
      memberName: '',
      customerName: '',
      currentChooseTabIndex: 0, //目前是選擇哪一個trend的tab
      scrollLeaveTop: false,
      boardData: null,
      isBoardModalOpen: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getApiPresentationData();
    this.pubSubCollectList_load = PubSub.subscribe(
      EVENT_COLLECT_LIST_LOAD,
      () => {
        this.getApiPresentationData();
      }
    );
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    const authData = getAuth();
    this._isMounted = false;
    const { localStream, presentationNo } = this.state;
    if (localStream && localStream.getTracks().length > 0) {
      localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.socket.emit('leave', presentationNo, authData.id);
    this.socket.disconnect();
    PubSub.unsubscribe(this.pubSubCollectList_load);
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {
    const {
      presentationData: thisPresentationData,
      member: thisMember,
      messageList: thisMessageList,
    } = this.props;
    const { presentationData, params, member, messageList } = nextProps;
    if (
      thisPresentationData !== presentationData &&
      params.presentationNo &&
      presentationData[params.presentationNo]
    ) {
      const fabricListData =
        presentationData[params.presentationNo].fabricList || [];
      this.setState({
        presentationData: presentationData[params.presentationNo],
        fabricListData: fabricListData,
      });
    }

    if (thisMessageList !== messageList) {
      const messageDataInit = messageList.data.map((element) => {
        return {
          memberName: element.memberId,
          message: element.message,
          messageId: element.id,
          postImage: element.postImage,
        };
      });
      this.setState({
        messageList: messageList.data,
        messagePageInfo: messageList.info,
        messageData: messageDataInit.reverse(),
      });
      this.scrollEnd.scrollTo({
        top: this.scrollEnd.scrollHeight,
        behavior: 'smooth',
      });
    }

    if (
      thisMember !== member &&
      thisMember &&
      member &&
      thisMember.id !== member.id
    ) {
      this.getApiPresentationData();
    }
  }

  handleScroll() {
    let scrollTop = window.scrollY;

    this.setState({ scrollLeaveTop: scrollTop > 500 ? true : false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  socketInit() {
    const { presentationNo } = this.state;
    this.socket = webSocket(process.env.REACT_APP_SOCKET_SERVER);
    const socket = this.socket;

    const that = this;
    socket.on('initReceive', (socketId) => {
      that.addPeer(socketId, false);
      socket.emit('initSend', socketId, presentationNo);
    });
    socket.on('initSend', (socketId) => {
      this.addPeer(socketId, true);
    });
    socket.on('signal', (data) => {
      peers[data.socketId].signal(data.signal);
    });
    socket.on('removePeer', (socketId) => {
      this.removePeer(socketId);
    });
    // socket.on('init', (initiator) => {
    //     this.joinVideoCall()
    //     that.setState({ initiator: initiator })
    // })
    // socket.on('ready', (data) => {
    //     if (this._isMounted) {
    //         that.enter(presentationNo, data)
    //     }
    // })
    // socket.on('desc', (data) => {
    //     const desc = data.desc
    //     if (desc.type === 'offer' && that.state.initiator) return
    //     if (desc.type === 'answer' && !that.state.initiator) return
    //     that.call(data)
    // })
    socket.on('disconnected', () => {
      that.setState({ initiator: true });
    });

    // 聊聊
    socket.emit('addRoom', presentationNo);
    socket.on('getMessage', (data) => {
      const now = new Date();
      const diffTime = now.getTime() - data.postTime;
      const diffSecond = diffTime / 1000;
      this.setMessage(data);
    });
    socket.on('boardcast', (data) => {
      const now = new Date();
      const diffTime = now.getTime() - data.postTime;
      const diffSecond = diffTime / 1000;
      this.setBoardcast(data);
    });
    socket.on('addRoom', (message) => {
      // console.log(message)
    });

    // post image
    socket.on('postImage', (data) => {
      this.postImage(data);
    });

    // recording control
    socket.on('recordingControl', (recording) => {
      that.setState({ recording: recording });
    });

    socket.on('toggleBoard', (data) => {
      console.log(data);
      this.setState({ boardData: data, isBoardModalOpen: true });
    });
  }

  getApiPresentationData() {
    const { presentationNo } = this.state;
    const { getPresentationData } = this.props;
    const authData = getAuth();
    if (!isEmpty(authData) && authData.id && authData.token) {
      getPresentationData(
        presentationNo,
        { memberId: authData.id, token: authData.token },
        (res) => {
          if (res.error) {
            this.setState({
              presentationData: {},
            });
          } else {
            this.getApiMessageList();
            if (this.socket === null) {
              this.socketInit();
            }
          }
        }
      );
    }
  }

  getApiMessageList() {
    const { presentationData } = this.state;
    const { getMessageList } = this.props;
    const authData = getAuth();
    if (
      !isEmpty(authData) &&
      authData.id &&
      authData.token &&
      presentationData &&
      presentationData.id
    ) {
      getMessageList(
        {
          memberId: authData.id,
          token: authData.token,
          presentationId: presentationData.id,
          pageSize: 20,
        },
        (res) => {
          if (res.error) {
            this.setState({
              messageList: [],
              messagePageInfo: {},
            });
          }
        }
      );
    }
  }

  uploadPresentationVideo(blob) {
    const { presentationData, memberName, customerName } = this.state;
    const authData = getAuth();
    if (
      !isEmpty(authData) &&
      authData.id &&
      authData.token &&
      presentationData &&
      presentationData.presentationNo
    ) {
      const fileObject = new File([blob], 'presentation_records_video.mp4', {
        type: 'video/mp4',
      });
      let data = new FormData();
      data.append('presentationNo', presentationData.presentationNo);
      data.append('memberId', authData.id);
      data.append('token', authData.token);
      data.append('file', fileObject);
      data.append('customerId', authData.id);
      data.append('memberName', memberName);
      data.append('customerName', customerName);

      const config = {
        method: 'POST',
        body: data,
      };
      fetch(
        `${process.env.REACT_APP_API_ROOT}/presentation/${presentationData.presentationNo}/video`,
        config
      ).then((res) => {
        const resJson = res.json();
        this.setState({
          uploadPresentationVideoConfirm: null,
        });
        this.getApiPresentationData();
      });
    }
  }

  setBoardData(data) {
    this.setState({ boardData: data });
  }

  clickToggleBoardModal(board) {
    // this.setState({ isBoardModalOpen: true });
    this.socket.emit('toggleBoard', { board });
  }

  clickCloseBoardModal() {
    this.setState({ isBoardModalOpen: false });
  }

  presentationView() {
    const authData = getAuth();
    const memberPresentationType = getPresentationType();
    const {
      messageData,
      boardcastData,
      postImage,
      recording,
      videoCall,
      audioCall,
      videoCallJoin,
      presentationData,
      messageLoading,
    } = this.state;
    const messageLoadingView = messageLoading ? <Loading /> : null;
    const messageView = messageData.map((element, index) => {
      let imageView = null;
      if (element.postImage) {
        const postImage = <Image src={element.postImage} />;
        if (['manager', 'sales'].indexOf(memberPresentationType) !== -1) {
          imageView = (
            <Link
              className="postImageView"
              to={`/fabric?messageId=${element.messageId}`}
              target="_blank"
            >
              {postImage}
            </Link>
          );
        } else {
          imageView = <div className="postImageView">{postImage}</div>;
        }
      }

      return (
        <div key={`message_${index}`} className="messageItemBox">
          <div className="messageItem">
            <div className="userImage">
              <Image src={require('../images/userDefault.png')} />
            </div>
            <div className="userMessage">
              <label>{element.memberName}: </label>
              <span>{element.message}</span>
            </div>
          </div>
          {imageView}
        </div>
      );
    });
    const boardcastHeadline = boardcastData.headline || '--';
    const boardcastWidth = boardcastData.width || '--';
    const boardcastWeight = boardcastData.weight || '--';
    let boardcastImage = null;
    let boardcastViewBtn = null;
    let boardcastView = null;
    if (
      boardcastData.images_detail &&
      boardcastData !== {} &&
      boardcastData != 'undefined'
    ) {
      const closeBtn = (
        <div
          className="closeImgBox"
          onClick={() => {
            this.closeBoardcast();
          }}
        >
          <img src={require('../images/icon_x.png')} className="closeImg" />
        </div>
      );
      boardcastImage = (
        <Image
          src={boardcastData.images_detail[0].filePath}
          className="card-img-top"
        />
      );
      boardcastViewBtn = (
        <Button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            this.openFabricDetail(boardcastData.id);
          }}
          text={Lang.ROOM_FABRIC_VIEW}
        />
      );
      boardcastView = (
        <div className="card boadcastView">
          {closeBtn}
          <div className="fabricImage">{boardcastImage}</div>
          <div className="card-body">
            <h5 className="card-title">{boardcastHeadline}</h5>
            <p className="card-text">
              {Lang.FABRIC_LABLE_WIDTH}: {boardcastWidth}
              <br />
              {Lang.FABRIC_LABLE_WEIGHT}: {boardcastWeight}
            </p>
            {boardcastViewBtn}
          </div>
        </div>
      );
    }
    const postImageView =
      postImage !== null ? (
        <Image src={postImage} className="card-img-top" />
      ) : (
        <Image
          src={require('../images/icon_plus.png')}
          className="card-img-top uploadImageBtn"
        />
      );

    const videoRecordIcon = recording ? (
      <img src={require('../images/icon_records_active.png')} />
    ) : (
      <img src={require('../images/icon_records.png')} />
    );
    const videoCallIcon = videoCall ? (
      <img src={require('../images/icon_video.png')} />
    ) : (
      <img src={require('../images/icon_video_off.png')} />
    );
    const videoCallClass = videoCall ? '' : 'active';
    const audioCallIcon = audioCall ? (
      <img src={require('../images/icon_mic.png')} />
    ) : (
      <img src={require('../images/icon_mic_off.png')} />
    );
    const audioCallClass = audioCall ? '' : 'active';
    const videoRecordBtn =
      authData.id === presentationData.memberId ? (
        <div
          className={`videoControlItem`}
          onClick={() => {
            this.clickVideoRecord();
          }}
        >
          {videoRecordIcon}
        </div>
      ) : null;
    const videoCallControlView = videoCallJoin ? (
      <div className="videoControl">
        {videoRecordBtn}
        <div
          className={`videoControlItem ${audioCallClass}`}
          onClick={() => {
            this.clickAudioCall();
          }}
        >
          {audioCallIcon}
        </div>
        <div
          className={`videoControlItem ${videoCallClass}`}
          onClick={() => {
            this.clickVideoCall();
          }}
        >
          {videoCallIcon}
        </div>
      </div>
    ) : null;
    const joinVideoCallBtn = videoCallJoin ? null : (
      <div className="joinVideoCallBtnBox">
        <div
          className="btn joinVideoCallBtn"
          onClick={() => {
            this.clickVideoCallConnectControl();
          }}
        >
          {Lang.ROOM_VIDEOCALL_JOIN_BTN}
        </div>
      </div>
    );

    const videoBoxStyle = {
      position: 'fixed',
      bottom: 0,
      right: 20,
      zIndex: 98,
      width: '600px',
      // height: '180px',
    };

    return (
      <div
        className="presentationView row"
        style={this.state.scrollLeaveTop ? videoBoxStyle : {}}
      >
        <div className="col-12 col-md-12 layoutBlock videoView">
          <div className="card videoBox">
            {boardcastView}
            <div id="videoList" className="videoCallBlock">
              <video
                autoPlay
                playsInline
                muted
                ref={(video) => (this.localVideo = video)}
                className="remoteVideoItem"
                style={{ width: 'inherit', height: 'inherit' }}
              />
            </div>
            {/*
                        <div className="videoList">
                            <video
                                autoPlay
                                id="localVideo"
                                muted
                                ref={(video) => (this.localVideo = video)}
                            />
                        </div>
                        */}
            {videoCallControlView}
            {joinVideoCallBtn}
          </div>
          <div className="card chatView">
            {messageLoadingView}
            <div className="titleBox">{Lang.ROOM_CHAT_TITLE}</div>
            <div
              className="card-body overflow-auto"
              ref={(el) => {
                this.scrollEnd = el;
              }}
            >
              <div className="messageList">{messageView}</div>
            </div>
            <div className="input-group messageInputBox">
              <div className="postImageBox">
                <div className="fabricImage">
                  {postImageView}
                  <input
                    type="file"
                    name="postImage"
                    className="postImage"
                    onChange={this.changeFile}
                  />
                </div>
              </div>
              <input
                type="text"
                className="form-control messageInput"
                name="messageText"
                ref={(c) => (this.messageText = c)}
                placeholder={Lang.ROOM_CHAT_PLACEHOLDER}
                onKeyPress={this.keyPressed}
              />
              <div className="sendBtn" onClick={this.sendMessage}>
                <img src={require('../images/send.png')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  videoListView() {
    const { presentationData } = this.state;
    const videos = presentationData.videos;
    if (videos.length === 0) {
      return null;
    }
    const videosView = videos.map((element, index) => {
      return (
        <div key={`videos_${index}`} className="col-12 col-md-4 layoutBlock">
          <video className="videosItem" controls src={element.filePath}></video>
        </div>
      );
    });
    return (
      <ContentBlock
        title={Lang.ROOM_VIDEOS_TITLE}
        className="boardBlockView videoListContainer"
      >
        {videosView}
      </ContentBlock>
    );
  }

  tabView() {
    const { presentationData, currentChooseTabIndex } = this.state;
    const tabViews =
      presentationData.tabs && presentationData.tabs.length > 0
        ? presentationData.tabs.map((item, index) => {
            if (currentChooseTabIndex == index) {
              //active
              return (
                <div
                  className="tabItem active"
                  key={'tabItem_' + item.trend.id + '_' + index}
                >
                  {item.trend.name}
                </div>
              );
            } else {
              //inactive
              return (
                <div
                  className="tabItem"
                  key={'tabItem_' + item.trend.id + '_' + index}
                  onClick={() => {
                    this.setState({ currentChooseTabIndex: index });
                  }}
                >
                  {item.trend.name}
                </div>
              );
            }
          })
        : null;
    return <div className="tabBlock">{tabViews}</div>;
  }

  boardView1() {
    const {
      presentationData,
      currentChooseTabIndex,
      isBoardModalOpen,
      presentationType,
    } = this.state;
    const authData = getAuth();
    const boardData =
      presentationData.tabs[currentChooseTabIndex] &&
      presentationData.tabs[currentChooseTabIndex].trend
        ? presentationData.tabs[currentChooseTabIndex].trend.board.A
        : {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }

    const image1View = <Image src={boardData.firstImage} />;
    const image2View = <Image src={boardData.secendImage} />;
    const image3View = <Image src={boardData.thirdImage} />;
    const image4View = <Image src={boardData.fourthImage} />;
    const image5View = <Image src={boardData.fifthImage} />;
    const image6View = <Image src={boardData.sixthImage} />;
    const image7View = <Image src={boardData.seventhImage} />;
    return (
      <ContentBlock
        title={
          presentationData.tabs[currentChooseTabIndex].trend.board['A']
            .description
        }
        shareIcon={
          isBoardModalOpen || presentationType === 'normal'
            ? null
            : faUpRightAndDownLeftFromCenter
        }
        handleToggleBoardModal={this.clickToggleBoardModal}
        board="A"
        className="boardBlockView boardContainer"
      >
        <div className="board1ContentBox">
          <div className="leftSide">
            <div className="boardImage">{image1View}</div>
            <div className="boardImage">{image2View}</div>
          </div>
          <div className="rightSide">
            <div className="topSide">
              <div className="topLeftSide">
                <div className="boardImage">{image3View}</div>
              </div>
              <div className="topRightSide">
                <div className="boardImage">{image4View}</div>
              </div>
            </div>
            <div className="bottomSide">
              <div className="bottomLeftSide">
                <div className="boardImage">{image5View}</div>
              </div>
              <div className="bottomRightSide">
                <div className="bottomRightTopSide">
                  <div className="boardImage">{image6View}</div>
                </div>
                <div className="bottomRightBottomSide">
                  <div className="boardImage">{image7View}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentBlock>
    );
  }

  boardView2() {
    const {
      presentationData,
      currentChooseTabIndex,
      isBoardModalOpen,
      presentationType,
    } = this.state;
    const authData = getAuth();
    const boardData =
      presentationData.tabs[currentChooseTabIndex] &&
      presentationData.tabs[currentChooseTabIndex].trend
        ? presentationData.tabs[currentChooseTabIndex].trend.board.B
        : {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board2ImageColumnMap = [
      'firstImage',
      'secendImage',
      'thirdImage',
      'fourthImage',
      'fifthImage',
      'sixthImage',
    ];
    const board2ImageListView = board2ImageColumnMap.map((element, index) => {
      return (
        <div
          key={`board2Image_${index}`}
          className="col-4 col-md-4 layoutBlock boardFigures"
        >
          <div className="boardImage">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={
          presentationData.tabs[currentChooseTabIndex].trend.board['B']
            .description
        }
        shareIcon={
          isBoardModalOpen || presentationType === 'normal'
            ? null
            : faUpRightAndDownLeftFromCenter
        }
        board="B"
        handleToggleBoardModal={this.clickToggleBoardModal}
        className="boardBlockView boardContainer"
      >
        <div className="boardContentBlock">{board2ImageListView}</div>
      </ContentBlock>
    );
  }

  boardView3() {
    const {
      presentationData,
      currentChooseTabIndex,
      isBoardModalOpen,
      presentationType,
    } = this.state;
    const boardData =
      presentationData.tabs[currentChooseTabIndex] &&
      presentationData.tabs[currentChooseTabIndex].trend
        ? presentationData.tabs[currentChooseTabIndex].trend.board.C
        : {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board3ImageColumnMap = ['firstImage', 'secendImage'];
    const board3ImageListView = board3ImageColumnMap.map((element, index) => {
      return (
        <div
          key={`board3Image_${index}`}
          className="col-4 col-md-4 layoutBlock boardFigures"
        >
          <div className="boardImage board3Image">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={
          presentationData.tabs[currentChooseTabIndex].trend.board['C']
            .description
        }
        shareIcon={
          isBoardModalOpen || presentationType === 'normal'
            ? null
            : faUpRightAndDownLeftFromCenter
        }
        board="C"
        handleToggleBoardModal={this.clickToggleBoardModal}
        className="boardBlockView boardContainer"
      >
        <div className="boardContentBlock boardContent3">
          {board3ImageListView}
        </div>
      </ContentBlock>
    );
  }

  boardView4() {
    const {
      presentationData,
      currentChooseTabIndex,
      isBoardModalOpen,
      presentationType,
    } = this.state;
    const boardData =
      presentationData.tabs[currentChooseTabIndex] &&
      presentationData.tabs[currentChooseTabIndex].trend
        ? presentationData.tabs[currentChooseTabIndex].trend.board.D
        : {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board4ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage'];
    const board4ImageListView = board4ImageColumnMap.map((element, index) => {
      return (
        <div key={`board4Image_${index}`} className="layoutBlock boardFigures">
          <div className="boardImage">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={
          presentationData.tabs[currentChooseTabIndex].trend.board['D']
            .description
        }
        shareIcon={
          isBoardModalOpen || presentationType === 'normal'
            ? null
            : faUpRightAndDownLeftFromCenter
        }
        board="D"
        handleToggleBoardModal={this.clickToggleBoardModal}
        className="boardClockView boardContainer"
      >
        <div className="boardContentBlock boardContent4">
          {board4ImageListView}
        </div>
      </ContentBlock>
    );
  }

  boardView5() {
    const {
      presentationData,
      currentChooseTabIndex,
      isBoardModalOpen,
      presentationType,
    } = this.state;
    const boardData =
      presentationData.tabs[currentChooseTabIndex] &&
      presentationData.tabs[currentChooseTabIndex].trend
        ? presentationData.tabs[currentChooseTabIndex].trend.board.E
        : {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board5ImageColumnMap = ['firstImage', 'secendImage'];
    const board5ImageListView = board5ImageColumnMap.map((element, index) => {
      return (
        <div
          key={`board5Image_${index}`}
          className="layoutBlock boardFigures boardView5"
        >
          <div className="boardImage board5Image">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={
          presentationData.tabs[currentChooseTabIndex].trend.board['E']
            .description
        }
        shareIcon={
          isBoardModalOpen || presentationType === 'normal'
            ? null
            : faUpRightAndDownLeftFromCenter
        }
        board="E"
        handleToggleBoardModal={this.clickToggleBoardModal}
        className="boardBlockView boardContainer"
      >
        <div className="boardContentBlock boardContent5">
          {board5ImageListView}
        </div>
      </ContentBlock>
    );
  }

  boardView6() {
    const {
      presentationData,
      currentChooseTabIndex,
      isBoardModalOpen,
      presentationType,
    } = this.state;
    const boardData =
      presentationData.tabs[currentChooseTabIndex] &&
      presentationData.tabs[currentChooseTabIndex].trend
        ? presentationData.tabs[currentChooseTabIndex].trend.board.F
        : {};
    if (!boardData.status || boardData.status === 'off') {
      return null;
    }
    const board6ImageColumnMap = ['firstImage', 'secendImage', 'thirdImage'];
    const board6ImageListView = board6ImageColumnMap.map((element, index) => {
      return (
        <div
          key={`board6Image_${index}`}
          className="layoutBlock boardFigures boardView3"
        >
          <div className="boardImage">
            <Image src={boardData[element]} />
          </div>
        </div>
      );
    });
    return (
      <ContentBlock
        title={
          presentationData.tabs[currentChooseTabIndex].trend.board['F']
            .description
        }
        shareIcon={
          isBoardModalOpen || presentationType === 'normal'
            ? null
            : faUpRightAndDownLeftFromCenter
        }
        board="F"
        handleToggleBoardModal={this.clickToggleBoardModal}
        className="boardBlockView boardContainer"
      >
        <div className="boardContentBlock boardContent6">
          {board6ImageListView}
        </div>
      </ContentBlock>
    );
  }

  fabricListView() {
    const { fabricListData, presentationData, currentChooseTabIndex } =
      this.state;
    const fabricListView = fabricListData.map((item, index) => {
      const likeIcon = item.collect
        ? require('../images/like-active.png')
        : require('../images/like.png');
      return (
        <div
          key={`fabricItem_${index}`}
          className="fabricItem col-6 col-md-2 layoutBlock"
        >
          <div className="card">
            <div
              className="fabricImage"
              onClick={() => {
                this.openFabricDetail(item.id);
              }}
            >
              <Image src={item.images} className="card-img-top" />
            </div>
            <div className="card-body">
              <div className="fabricHeadline textOverflow">{item.name}</div>
              <div
                className="fabricLike"
                onClick={() => {
                  this.collectFabric(item.id, !item.collect);
                }}
              >
                <img src={likeIcon} />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return <div className="fabricListView row">{fabricListView}</div>;
  }

  getFabricGroupItem(item, index) {
    // 獲取一組布料groupItem
    const fabricListView = this.groupItemfabricListView(index);

    return (
      <div key={`fg_item_${item.name}`} className="fabricGroupItem">
        <div className="rightArea">
          <div className="headContainer">
            <div className="headTitle">{item.name}</div>
          </div>
          <div className="contentContainer">{fabricListView}</div>
        </div>
      </div>
    );
  }

  groupItemfabricListView(groupItemIndex) {
    const { currentChooseTabIndex, presentationData } = this.state;
    const mFabrics =
      presentationData.tabs[currentChooseTabIndex].fabricGroups[groupItemIndex]
        .fabrics;
    const fabricListView = mFabrics.map((item, index) => {
      const likeIcon = item.collect
        ? require('../images/like-active.png')
        : require('../images/like.png');
      return (
        <div
          key={`fabricItem_${index}`}
          className="fabricItem col-6 col-md-2 layoutBlock"
        >
          <div className="card">
            <div
              className="fabricImage"
              onClick={() => {
                this.openFabricDetail(item.id);
              }}
            >
              <Image src={item.images} className="card-img-top" />
            </div>
            <div className="card-body">
              <div className="fabricHeadline textOverflow">{item.headline}</div>
              <div
                className="fabricLike"
                onClick={() => {
                  this.collectFabric(item.id, !item.collect);
                }}
              >
                <img src={likeIcon} />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div
        className="fabricListView row"
        style={{ padding: '0px 10px', marginBottom: '0px' }}
      >
        {fabricListView}
      </div>
    );
  }

  trendBoardFabricListView() {
    const { currentChooseTabIndex, presentationData } = this.state;

    const fabricGroupList =
      presentationData.tabs &&
      presentationData.tabs.length > 0 &&
      presentationData.tabs[currentChooseTabIndex].fabricGroups
        ? presentationData.tabs[currentChooseTabIndex].fabricGroups.map(
            (item, index) => {
              return this.getFabricGroupItem(item, index);
            }
          )
        : null;

    //呈現下面布料區域
    const fabricContainer = (
      <div className="fabricContainer">{fabricGroupList}</div>
    );

    return fabricContainer;
  }

  fabricDetailView() {
    const { showFabricId, presentationNo } = this.state;
    const displayModal = showFabricId ? true : false;
    const fabricDetailView = (
      <FabricDetail
        fabricId={showFabricId}
        displayModal={displayModal}
        closed={() => {
          this.closeFabricDetail();
        }}
        clickBoardcast={(e) => {
          this.clickBoardcast(e);
        }}
        presentationNo={presentationNo}
      />
    );
    return fabricDetailView;
  }

  joinVideoCall() {
    this.setState({ videoCallJoin: true });
  }

  clickVideoCallConnectControl() {
    const authData = getAuth();
    const { presentationNo, presentationData, videoCallJoin } = this.state;
    const socket = this.socket;
    if (!videoCallJoin) {
      this.getUserMedia().then(() => {
        socket.emit('join', {
          roomNo: presentationNo,
          memberId: authData.id,
          ownerId: presentationData.memberId,
        });
        this.joinVideoCall();
      });
    }
  }

  clickVideoCall() {
    const { videoCall, localStream } = this.state;
    if (localStream && localStream.getVideoTracks().length > 0) {
      localStream.getVideoTracks()[0].enabled = !videoCall;
    }
    this.setState({
      videoCall: !videoCall,
    });
  }

  clickAudioCall() {
    const { audioCall, localStream } = this.state;
    if (localStream && localStream.getAudioTracks().length > 0) {
      localStream.getAudioTracks()[0].enabled = !audioCall;
    }
    this.setState({
      audioCall: !audioCall,
    });
  }

  clickVideoRecord() {
    const { recording } = this.state;
    if (recording) {
      this.clickVideoRecordStop();
    } else {
      this.clickVideoRecordStart();
    }
  }

  clickVideoRecordStart() {
    const localVideoStream = this.localVideo.srcObject;
    const remoteVideos = document.getElementsByClassName('remoteVideoItem');
    let i;
    if (localVideoStream) {
      const socket = this.socket;
      socket.emit('recordingControl', true);
      this.videoRecord.addVideoToRecorder(localVideoStream);
      this.videoRecord.startRecording();
      this.setState({
        recording: true,
      });
    }
    for (i = 0; i < remoteVideos.length; i++) {
      const remoteVideoStream = remoteVideos[i].srcObject;
      if (remoteVideoStream) {
        this.videoRecord.addVideoToRecorder(remoteVideoStream);
      }
    }
  }

  clickVideoRecordStop() {
    this.videoRecord.stopAndGetSingleBlob((blob) => {
      const socket = this.socket;
      socket.emit('recordingControl', false);
      this.setState({
        recording: false,
        uploadPresentationVideoConfirm: blob,
      });
      this.videoRecord.destroy();
    });
  }

  clickBoardcast(fabricDetailData) {
    const now = new Date();
    fabricDetailData = Object.assign(fabricDetailData, {
      postTime: now.getTime(),
    });
    this.socket.emit('boardcast', fabricDetailData);
  }

  collectFabric(fabricId, addLike = true) {
    const { addCollect, cancelCollect } = this.props;
    const authData = getAuth();
    if (!isEmpty(authData)) {
      const params = {
        memberId: authData.id,
        token: authData.token,
        fabricId: fabricId,
      };
      if (addLike) {
        addCollect(params, (res) => {
          this.getApiPresentationData();
        });
      } else {
        cancelCollect(params, (res) => {
          this.getApiPresentationData();
        });
      }
    }
  }

  openFabricDetail(fabricId) {
    this.setState({
      showFabricId: fabricId,
    });
  }

  closeFabricDetail() {
    this.setState({
      showFabricId: null,
    });
  }

  closeBoardcast() {
    this.socket.emit('boardcast', {});
  }

  getUserMedia() {
    return new Promise((resolve, reject) => {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
      const op = {
        video: {
          width: { min: 160, ideal: 640, max: 1280 },
          height: { min: 120, ideal: 360, max: 720 },
        },
        audio: true,
      };
      if (navigator.getUserMedia) {
        navigator.getUserMedia(
          op,
          (stream) => {
            this.setState({ streamUrl: stream, localStream: stream });
            this.localVideo.srcObject = stream;
            resolve();
          },
          () => {}
        );
      } else {
        navigator.mediaDevices.getUserMedia(op).then((stream) => {
          this.setState({ streamUrl: stream, localStream: stream });
          this.localVideo.srcObject = stream;
          resolve();
        });
      }
    });
  }

  addPeer(socketId, initiator) {
    const { localStream, presentationNo } = this.state;
    this.setState({ connecting: true });
    peers[socketId] = this.videoCall.init(localStream, initiator);
    peers[socketId].on('signal', (data) => {
      const signal = {
        signal: data,
        socketId: socketId,
        desc: data,
        presentationNo: presentationNo,
      };
      this.socket.emit('signal', signal);
    });
    peers[socketId].on('stream', (stream) => {
      let newVid = document.createElement('video');
      let videos = document.getElementById('videoList');
      newVid.srcObject = stream;
      newVid.id = socketId;
      newVid.autoplay = true;
      newVid.playsInline = true;
      newVid.className = 'remoteVideoItem';
      videos.appendChild(newVid);
      this.setRemoteVideoLayout();
    });
    peers[socketId].on('error', (err) => {
      console.log(err);
    });
  }

  removePeer(socketId) {
    let videoEl = document.getElementById(socketId);
    if (videoEl) {
      const tracks = videoEl.srcObject.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });
      videoEl.srcObject = null;
      videoEl.parentNode.removeChild(videoEl);
    }
    if (peers[socketId]) peers[socketId].destroy();
    delete peers[socketId];
    this.setRemoteVideoLayout();
  }

  setRemoteVideoLayout() {
    const remoteVideos = document.getElementsByClassName('remoteVideoItem');
    const videoCount = remoteVideos.length;
    const videoCountSqrt = Math.ceil(Math.sqrt(videoCount));
    const rowCount = Math.ceil(videoCount / videoCountSqrt);
    const columnCount = Math.ceil(videoCount / rowCount);
    let i;
    for (i = 0; i < videoCount; i++) {
      remoteVideos[i].style.width = `${100 / columnCount}%`;
      remoteVideos[i].style.height = `${100 / rowCount}%`;
    }
  }

  // call(data) {
  //     this.videoCall.connect(data.desc)
  // }

  keyPressed(event) {
    if (event.key === 'Enter') {
      this.sendMessage();
    }
  }

  sendMessage() {
    const { addMessage } = this.props;
    const { presentationData, postImage } = this.state;
    const message = this.messageText.value;
    const authData = getAuth();
    const now = new Date();
    if (
      !isEmpty(authData) &&
      (!isEmpty(message) || !isEmpty(postImage)) &&
      presentationData &&
      presentationData.id
    ) {
      this.setState({
        messageLoading: postImage ? true : false,
      });
      this.messageText.value = null;
      addMessage(
        {
          memberId: authData.id,
          token: authData.token,
          message: message,
          postImage: postImage,
          presentationId: presentationData.id,
        },
        (res) => {
          const response = res.payload.response;
          this.socket.emit('getMessage', {
            memberId: authData.id,
            memberName: authData.name,
            message: message,
            postImage: postImage,
            postTime: now.getTime(),
            messageId: response.id,
          });
          this.setState({
            postImage: null,
            messageLoading: false,
          });
        }
      );
    }
  }

  setMessage(data) {
    const { messageData } = this.state;
    messageData.push(data);
    this.setState({
      messageData,
    });
    this.scrollEnd.scrollTo({
      top: this.scrollEnd.scrollHeight,
      behavior: 'smooth',
    });
  }

  setBoardcast(data) {
    this.setState({
      boardcastData: data,
      showFabricId: null,
    });
  }

  changeFile(e) {
    this.previewImage(e.target.files, e.target.name);
  }

  previewImage(fileList, columnName) {
    const files = Array.from(fileList);
    if (files && files.length > 0) {
      files.map((item, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onloadend = () => {
          this.setState({
            postImage: reader.result,
          });
        };
      });
    }
  }

  postImage(data) {
    const { postImageList } = this.state;
    postImageList.push(data);
    this.setState({
      postImageList,
    });
  }

  handleChange(e) {
    const { target } = e;
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  }

  uploadPresentationVideoConfirmView() {
    const { uploadPresentationVideoConfirm, memberName, customerName } =
      this.state;
    if (!uploadPresentationVideoConfirm) {
      return null;
    }
    return (
      <div className="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{Lang.ROOM_VIDEO_UPLOAD_CONFIRM}</h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="input_memberName">
                  {Lang.ROOM_VIDEO_CONFIRM_MEMBERNAME}
                </label>
                <Input
                  type="text"
                  name="memberName"
                  ref="memberName"
                  placeholder={`${Lang.ROOM_VIDEO_CONFIRM_MEMBERNAME} (English)`}
                  onChange={this.handleChange}
                  idName="input_memberName"
                  value={memberName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="input_customerName">
                  {Lang.ROOM_VIDEO_CONFIRM_CUSTOMERNAME}
                </label>
                <Input
                  type="text"
                  name="customerName"
                  ref="customerName"
                  placeholder={`${Lang.ROOM_VIDEO_CONFIRM_CUSTOMERNAME} (English)`}
                  onChange={this.handleChange}
                  idName="input_customerName"
                  value={customerName}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.uploadPresentationVideo(uploadPresentationVideoConfirm);
                }}
              >
                {Lang.ROOM_VIDEO_DELETE_CONFIRM_BTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { presentationData, boardcastData, isBoardModalOpen, boardData } =
      this.state;
    const emptyView = (
      <div className="emptyBlock">
        <Empty title={Lang.ROOM_NOT_FOUND} content={Lang.ROOM_EMPTY_CONTENT} />
        <div className="btnBox">
          <Link className="btn btn-primary" to={`/addRoom`}>
            {Lang.ROOM_CREATE_ROOM}
          </Link>
        </div>
      </div>
    );

    const loadingView = presentationData === null ? <Loading /> : null;
    let contentView = null;
    if (presentationData !== null) {
      if (presentationData.presentationNo) {
        const presentationView = this.presentationView();
        const videoListView = this.videoListView();
        const boardView1 = this.boardView1();
        const boardView2 = this.boardView2();
        const boardView3 = this.boardView3();
        const boardView4 = this.boardView4();
        const boardView5 = this.boardView5();
        const boardView6 = this.boardView6();
        const fabricListViewOld = this.fabricListView();
        const fabricListView = this.trendBoardFabricListView();
        const tabView = this.tabView();
        const fabricDetailView = this.fabricDetailView();
        const uploadPresentationVideoConfirmView =
          this.uploadPresentationVideoConfirmView();
        contentView = (
          <div className="roomContainer">
            <div className="layoutBlock titleBlock">
              <div className="themeInfoBox">
                <div className="themeName">{presentationData.themeName}</div>
              </div>
            </div>
            {presentationView}
            {videoListView}
            <div className="trendBoardContainer">
              {tabView}

              {boardView1}
              {boardView2}
              {boardView3}
              {boardView4}
              {boardView5}
              {boardView6}

              {presentationData.tabs && presentationData.tabs.length > 0 ? (
                <ContentBlock
                  title={Lang.ROOM_FABRICLIST_TITLE}
                  className="fabricListBlockView"
                >
                  {fabricListView}
                </ContentBlock>
              ) : null}
            </div>
            {fabricDetailView}
            {uploadPresentationVideoConfirmView}
            <BoardDetail
              isOpen={isBoardModalOpen}
              handleClose={this.clickCloseBoardModal}
              data={boardData}
              board1={boardView1}
              board2={boardView2}
              board3={boardView3}
              board4={boardView4}
              board5={boardView5}
              board6={boardView6}
            />
          </div>
        );
      } else {
        contentView = <div className="roomContainer">{emptyView}</div>;
      }
    }
    return (
      <MemberBase validUseLevel={2}>
        {loadingView}
        {contentView}
      </MemberBase>
    );
  }
}

Room.propTypes = {
  getPresentationData: PropTypes.func.isRequired,
  getMessageList: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  addCollect: PropTypes.func.isRequired,
  cancelCollect: PropTypes.func.isRequired,
  presentationData: PropTypes.object,
};

const mapStateToProps = () => (state) => {
  return {
    presentationData: state.presentation.presentationData,
    member: state.member.member,
    messageList: state.chat.messageList,
  };
};

const mapDispatchToProps = {
  getPresentationData,
  getMessageList,
  addMessage,
  addCollect,
  cancelCollect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Room);
