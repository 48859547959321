import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import * as Lang from '../Lang/default';
import { getAuth, isEmpty } from '../Libs/Lib';
import MemberBase from './MemberBase';
import { getCatalogDetail } from '../actions/catalog';
import Empty from '../components/Empty';
import Loading from '../components/Loading';
import CatalogPageItem from '../components/CatalogPageItem';
import { EVENT_CATALOG_DETAIL_SHOW_FABRIC } from '../constants/eventAction';
import FabricDetail from '../components/FabricDetail';

import '../style/catalog.sass';

class CatalogDetail extends Component {
  constructor(props) {
    super(props);
    this.getPageListFromContent = this.getPageListFromContent.bind(this);
    this.fabricDetailView = this.fabricDetailView.bind(this);
    this.openFabricDetail = this.openFabricDetail.bind(this);
    this.closeFabricDetail = this.closeFabricDetail.bind(this);
    this.genPDf = this.genPDf.bind(this);

    this.state = {
      loading: false,
      catalogDetailData: null,
      pageList: [], // 目前有幾頁
      showFabricId: null,
      isDisabled: false,
    };
  }

  componentDidMount() {
    const { router, getCatalogDetail: getCataDetail } = this.props;
    // 先去獲取路徑看看有沒有id
    if (router.params.catalogId) {
      // 有id，表示編輯模式
      // this.setState({
      //   currentCatalogId: router.params.catalogId,
      // });
      // call api 獲取明細資料
      const authData = getAuth();
      if (!isEmpty(authData)) {
        const params = { memberId: authData.id, token: authData.token };
        getCataDetail(params, router.params.catalogId);
      } else {
        router.push('/catalog');
      }
    } else {
      router.push('/catalog');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { catalogDetailData: thisCatalogDetailData } = this.props;
    const { catalogDetailData } = nextProps;
    if (thisCatalogDetailData !== catalogDetailData) {
      this.pubSubToken = PubSub.subscribe(
        EVENT_CATALOG_DETAIL_SHOW_FABRIC,
        (message, data) => {
          this.openFabricDetail(data.fabricId);
        }
      );
      this.setState({
        catalogDetailData,
        pageList: this.getPageListFromContent(catalogDetailData.content),
      });
    }
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.pubSubToken);
  }

  getPageListFromContent(content) {
    let contentArray = [];
    try {
      contentArray = JSON.parse(content);
    } catch (e) {
      return contentArray;
    }
    return contentArray;
  }

  fabricDetailView() {
    const { showFabricId } = this.state;
    const displayModal = showFabricId ? true : false;
    return (
      <FabricDetail
        fabricId={showFabricId}
        displayModal={displayModal}
        closed={() => {
          this.closeFabricDetail();
        }}
      />
    );
  }

  openFabricDetail(fabricId) {
    this.setState({
      showFabricId: fabricId,
    });
  }

  closeFabricDetail() {
    this.setState({
      showFabricId: null,
    });
  }

  /* eslint no-await-in-loop:off */
  async genPDf() {
    this.setState({ isDisabled: true });
    const element = document.querySelectorAll('.fashionPageContentBox');
    const videoList = document.querySelectorAll('iframe');
    const elLen = element.length - (videoList.length || 0);
    const pdf = new jsPDF('p', 'mm', 'a4');
    for (let i = 0; i < elLen; i += 1) {
      await html2canvas(element[i], { allowTaint: true, useCORS: true }).then(
        (canvas) => {
          document.body.appendChild(canvas);
          const imgWidth = 210;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL('image/png');
          const positionX = i % 2 === 0 ? -2.5 : 2.5;
          const positionY = 6;
          pdf.addImage(
            imgData,
            'JPEG',
            positionX,
            positionY,
            imgWidth,
            imgHeight
          );

          if (i < elLen - 1) {
            pdf.addPage();
          }
          document.body.removeChild(canvas);
        }
      );
    }

    if (videoList.length) {
      let urlList = '';

      pdf.addPage();
      videoList.forEach((video, idx) => {
        urlList += `${idx + 1}. ${video.src}\n`;
      });
      pdf.text(urlList, 10, 15);
      pdf.setFontSize(16);
    }

    pdf.save(`Download-${Date.now()}.pdf`);
    this.setState({ isDisabled: false });
  }

  render() {
    const { loading, catalogDetailData, pageList, isDisabled } = this.state;
    const loadingView =
      catalogDetailData === null || loading ? <Loading /> : null;
    const fabricDetailView = this.fabricDetailView();

    const emptyView = (
      <div className="emptyBlock">
        <Empty title={Lang.CATALOG_EMPTY_TITLE} />
      </div>
    );
    let contentView = null;
    if (catalogDetailData !== null) {
      let pageItems = <div />;
      if (pageList.length > 0) {
        // 把所有頁面render出來
        pageItems = pageList.map((item, index) => (
          <div
            key={item.id}
            className="catalogPageItemBox layoutBlock col-xs-12 col-md-6"
            style={{ marginBottom: '10px' }}
          >
            <CatalogPageItem jsonData={item} pageTitle={`P.${index + 1}`} />
          </div>
        ));
      }
      contentView = (
        <div>
          <div
            style={{
              margin: '10px 15px',
              fontSize: '20px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            {catalogDetailData.title}
          </div>
          <div className="pageListContainer">{pageItems}</div>
          {fabricDetailView}
        </div>
      );
    } else {
      contentView = emptyView;
    }

    return (
      <MemberBase validUseLevel={2}>
        {loadingView}

        <button
          className="exportPdfButton"
          type="button"
          onClick={this.genPDf}
          disabled={isDisabled}
        >
          {isDisabled ? 'Loading...' : 'Download'}
        </button>

        <div
          id="catalogPageListContainer"
          className="catalogPageListContainerBase"
        >
          {contentView}
        </div>
      </MemberBase>
    );
  }
}

CatalogDetail.propTypes = {
  catalogDetailData: PropTypes.object,
};

const mapStateToProps = () => (state) => {
  return {
    catalogDetailData: state.catalog.catalogDetailData.data,
  };
};

const mapDispatchToProps = {
  getCatalogDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogDetail);
