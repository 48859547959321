import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import * as Lang from '../Lang/default'
import Empty from '../components/Empty'
import MemberBase from '../containers/MemberBase'
import Pagination from '../components/Pagination'
import '../style/request.sass'
import { EVENT_REQUEST_REFRESH } from '../constants/eventAction'
import PubSub from 'pubsub-js'
import { getAuth } from '../Libs/Lib'
import { getRequestList } from '../actions/request'
import '../style/requestlist.sass'

class Request extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs:[{id:0,title:'全部'},{id:1,title:'尚未確認'},{id:2,title:'已確認'}],
            fakeData:{},
            requestData: [],
            pageInfo: {},
            currentTabIndex: 0, //目前的tab
            currentActivePage: 1 //目前停留的資料頁面
        }
        this.goPage = this.goPage.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.getRequestData = this.getRequestData.bind(this)
        this.pubtoken = ''
    }

    componentDidMount() {
        this.getRequestData()
        this.pubtoken = PubSub.subscribe(EVENT_REQUEST_REFRESH, (key, data) => {
            if (data && data.postTime) {
                const resDate = new Date()
                const resTime = resDate.getTime()
                const diffTime = (resTime - data.postTime) / 1000
                console.log(diffTime + ' 秒')
            }
            this.refreshData()
        })
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.pubtoken)
    }

    componentWillReceiveProps(nextProps) {
        const { requestList: thisRequestList } = this.props
        const { requestList } = nextProps
        if (thisRequestList !== requestList) {
            this.setState({
                requestData: requestList.data,
                pageInfo: requestList.info,
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {

            return true
        }
        return false
    }

    //刷新訂單資料
    refreshData() {
        this.getRequestData()
    }

    getRequestData(page = 1) {
        const now = new Date()
        const startTime = now.getTime()
        const authData = getAuth()
        const { getRequestList } = this.props
        getRequestList({ memberId: authData.id, token: authData.token, page: page, pageSize: 10 }, (res) => {
            if (res.error) {
                const errorData = res.payload.response
                alert(errorData.errorMessage)
            }
        })
        const resDate = new Date()
        const endTime = resDate.getTime()
        const diffTime = (endTime - startTime) / 1000
        console.log(diffTime + ' 秒')
    }
    
    getTopTabView() {
        return (
            this.state.tabs.map((item,index)=>{
                if(this.state.currentTabIndex==item.id){
                    //目前選取中
                    return (
                        <li key={"index_"+index} className="nav-item">
                            <a className="nav-link active tabChoose" >
                                {item.title}
                            </a>
                        </li>
                    )
                }else{
                    return(
                        <li key={"index_"+index} className="nav-item" >
                            <a className="nav-link tabDefault" onClick={()=>{                                        
                                // console.log('click item')
                                this.setState({currentTabIndex:item.id})
                            }}>
                                {item.title}
                            </a>
                        </li>
                    )
                }
            })
        )
    }

    getTableTitleRow() {
        return ( 
            <div className='tableFirstRowContainer'>
                <div className="tableTitleRow">
                    Request No.
                </div>
                <div className="tableTitleRow">
                    Note
                </div>
                <div className="tableTitleRow">
                    Create Time
                </div>
            </div>
        )
    }

    getContentTableView() {
        const { requestData } = this.state
        return (
            <div id="requestListContainer" >
                {this.getTableTitleRow()}
                {
                    requestData.map((item, index) => {
                        return (
                            <Link
                                key={'requestItem_' + index}
                                className="tableRowItemContainer"
                                to={`/request/${item['id']}`}
                            >
                                <div className="tableRowItem">{item['requestNo']}</div>
                                <div className="tableRowItem">{item['note']}</div>
                                <div className="tableRowItem">{item['created_at']}</div>
                            </Link>
                        )
                    })
                }
            </div>

        )
    }

    getContentView() {
        return (
            <div className='requestContainer' style={{}}>
                <div className="contentBox" >
                    {/*
                    <div className="nav nav-tabs">
                        {this.getTopTabView()}
                    </div>
                    */}
                    {this.getContentTableView()}
                    {this.getPaginationView()}
                </div>
                
            </div>
        )
    }

    goPage(page) {
        this.setState({
            currentActivePage: page
        })
        this.getRequestData(page)
    }

    getPaginationView() {
        const { currentActivePage, pageInfo } = this.state
        return (
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end', width:'100%' ,marginTop:'20px'}}>
                <Pagination data={pageInfo} page={currentActivePage} goPage={this.goPage} />
            </div>
        )
    }

    render() {
        const { requestData } = this.state
        let emptyView = null
        
        emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.REQUEST_EMPTY_TITLE} content={Lang.REQUEST_EMPTY_CONTENT} />
            </div>
        )
        let contentView = emptyView

        if (requestData && requestData.length > 0) {
            contentView = this.getContentView()
        }
        return <MemberBase validUseLevel={2}>{contentView}</MemberBase>
    }
}

Request.propTypes = {
    getRequestList: PropTypes.func.isRequired,
    requestList: PropTypes.object,
}

const mapStateToProps = () => (state) => {
    return {
        requestList: state.request.requestList,
    }
}

const mapDispatchToProps = {
    getRequestList
}

export default connect(mapStateToProps, mapDispatchToProps)(Request)
