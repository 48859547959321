import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as Lang from '../Lang/default';
import {
  getAuth,
  isEmpty,
  getMemberData as getLibMemberData,
  checkPresentationTypeIsNone,
  getPresentationType,
} from '../Libs/Lib';
import { getMemberData } from '../actions/member';
import MemberBase from './MemberBase';
import { getTrendBoardList, deleteTrendBoardItem } from '../actions/trendboard';
import Empty from '../components/Empty';
import TrendBoardItem from '../components/TrendBoardItem';
import Loading from '../components/Loading';

const LoadingShowTime = 500; //loading最少要顯示的時間

class Trend extends Component {
  constructor(props) {
    super(props);
    this.getApiTrendBoardList = this.getApiTrendBoardList.bind(this);
    this.deleteTargetItem = this.deleteTargetItem.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showLoading = this.showLoading.bind(this);
    this.hideLoading = this.hideLoading.bind(this);

    this.state = {
      loading: false,
      loadingStartTime: 0,

      myTrendBoardListData: null,
      openDeleteTargetItemConfirm: null,
      openDeleteTargetItemTitleConfirm: '',
    };
  }

  componentDidMount() {
    this.getApiTrendBoardList();
  }

  componentWillReceiveProps(nextProps) {
    const {
      trendBoardListData: thisTrendBoardListData,
      deleteTrendboardData: thisDeleteCatalogData,
      memberDetail: thisMemberDetail,
    } = this.props;
    const { memberDetail, trendBoardListData, deleteTrendboardData } =
      nextProps;

    if (thisTrendBoardListData !== trendBoardListData) {
      this.hideLoading();
      this.setState({
        myTrendBoardListData: trendBoardListData.data,
      });
    }
    //檢查刪除狀態
    if (thisDeleteCatalogData != deleteTrendboardData && deleteTrendboardData) {
      if (deleteTrendboardData.status == 'success') {
        this.hideLoading();
        //刪除成功，刷新列表
        this.getApiTrendBoardList();
      }
    }

    if (
      thisMemberDetail !== memberDetail &&
      thisMemberDetail &&
      memberDetail &&
      thisMemberDetail.id !== memberDetail.id
    ) {
      this.getApiTrendBoardList();
    }
  }

  showLoading() {
    const resDate = new Date();
    const resTime = resDate.getTime();
    this.setState({ loading: true, loadingStartTime: resTime });
  }

  hideLoading() {
    if (this.state.loading) {
      const resDate = new Date();
      const endTime = resDate.getTime();
      const diffTime = endTime - this.state.loadingStartTime;
      if (diffTime < LoadingShowTime) {
        const leftTime = LoadingShowTime - diffTime;
        setTimeout(() => {
          this.setState({ loading: false, loadingStartTime: 0 });
        }, leftTime);
      } else {
        this.setState({ loading: false, loadingStartTime: 0 });
      }
    }
  }

  getApiTrendBoardList() {
    const isNone = checkPresentationTypeIsNone();
    if (!isNone) {
      //如果不是none才能抓資料，避免知道路徑自己打
      const authData = getAuth();
      if (!isEmpty(authData) && authData.id && authData.token) {
        const mData = getLibMemberData();
        if (mData == null) {
          //去獲取memberData資料
          this.props.getMemberData(authData.id);
        }
        this.showLoading();
        let params = { memberId: authData.id, token: authData.token };
        this.props.getTrendBoardList(params);
      }
    } else {
      this.setState({ myTrendBoardListData: [] });
    }
  }

  deleteTargetItem(id, title) {
    this.setState({
      openDeleteTargetItemConfirm: id,
      openDeleteTargetItemTitleConfirm: title,
    });
  }

  deleteTargetItemConfirmView() {
    const { openDeleteTargetItemConfirm, openDeleteTargetItemTitleConfirm } =
      this.state;
    if (!openDeleteTargetItemConfirm) {
      return null;
    }
    const confirmText =
      Lang.TREND_DELETE_TREND_CONFIRM +
      ' [' +
      openDeleteTargetItemTitleConfirm +
      '] ?';
    return (
      <div className="modal roomRemoveModel">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{Lang.ROOMLIST_DELETE_CONFIRM}</h5>
              <div
                className="closeImgBox"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <img
                  src={require('../images/icon_x.png')}
                  className="closeImg"
                />
              </div>
            </div>
            <div className="modal-body">
              <p>{confirmText}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  //打api刪除項目
                  // openDeleteTargetItemConfirm
                  const authData = getAuth();
                  if (!isEmpty(authData) && authData.id && authData.token) {
                    let params = {
                      memberId: authData.id,
                      token: authData.token,
                    };
                    // this.props.getCatalogList(params)
                    this.props.deleteTrendBoardItem(
                      JSON.stringify(params),
                      this.state.openDeleteTargetItemConfirm
                    );
                    this.showLoading();
                    this.setState({ openDeleteTargetItemConfirm: null });
                  }
                }}
              >
                {Lang.ROOMLIST_DELETE_CONFIRM_BTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  closeModal() {
    this.setState({
      openDeleteTargetItemConfirm: null,
      openDeleteTargetItemTitleConfirm: '',
    });
  }

  render() {
    const { myTrendBoardListData, openDeleteTargetItemConfirm, loading } =
      this.state;

    const memberType = getPresentationType();
    //以下兩種身份才可創建資料
    let canShowCreateBtn =
      memberType == 'manager' || memberType == 'sales' ? true : false;

    const emptyView = (
      <div className="emptyBlock">
        <Empty
          title={Lang.TREND_EMPTY_TITLE}
          content={Lang.TREND_EMPTY_CONTENT}
        />
        {canShowCreateBtn && (
          <div className="btnBox">
            <Link className="btn btn-primary" to={`/addTrend`}>
              {Lang.TREND_CREATE_TREND}
            </Link>
          </div>
        )}
      </div>
    );
    let loadingView = <div />;
    if (loading) {
      loadingView = <Loading />;
    }
    let contentView = null;
    let deleteConfirmView = <div />;
    if (openDeleteTargetItemConfirm) {
      deleteConfirmView = this.deleteTargetItemConfirmView();
    }
    if (myTrendBoardListData !== null) {
      if (myTrendBoardListData.length > 0) {
        const tbListView = myTrendBoardListData.map((item, index) => {
          return (
            <div className="catalogListViewItem" key={`trendBoard_${index}`}>
              <TrendBoardItem
                data={item}
                clickPreview={(id) => {
                  this.props.router.push('/trend/' + id);
                }}
                clickEdit={(id) => {
                  this.props.router.push('trend/' + id + '/edit');
                }}
                removeFunc={(id, title) => {
                  this.deleteTargetItem(id, title);
                }}
              />
            </div>
          );
        });
        contentView = (
          <div className="catalogListBlock">
            <div className="roomListTitleBlock">
              <div style={{ flex: 1 }} />
              {canShowCreateBtn && (
                <div className="btnBox">
                  <Link className="btn btn-primary" to={`/addTrend`}>
                    {Lang.TREND_CREATE_TREND}
                  </Link>
                </div>
              )}
            </div>
            <div className="catalogListView">{tbListView}</div>
          </div>
        );
      } else {
        contentView = emptyView;
      }
    }
    return (
      <MemberBase validUseLevel={2}>
        <div className="catalogListContainerBase">
          {deleteConfirmView}
          {contentView}
          {loadingView}
        </div>
      </MemberBase>
    );
  }
}

Trend.propTypes = {};

const mapStateToProps = () => (state) => {
  return {
    trendBoardListData: state.trendboard.trendboardListData,
    deleteTrendboardData: state.trendboard.deleteTrendboardData,
    member: state.member.member,
    memberDetail: state.member.memberDetail,
  };
};

const mapDispatchToProps = {
  getTrendBoardList,
  deleteTrendBoardItem,
  getMemberData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trend);
