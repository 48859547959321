import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Lang from '../Lang/default'
import { getAuth } from '../Libs/Lib'
import MemberBase from '../containers/MemberBase'
import Loading from '../components/Loading'
import ContentBlock from '../components/ContentBlock'
import { getRequestDetail } from '../actions/request'
import Image from '../components/Image'
import '../style/requestDetail.sass'

const DETAIL_IMAGE_MAX_LENGTH = 10

class RequestEdit extends Component {
    constructor(props) {
        super(props)
        this.getRequestDetail = this.getRequestDetail.bind(this)
        this.state = {
            requestId: props.params.requestId,
            requestData: null,
            loading: false
        }
    }

    componentDidMount() {
        const { requestId } = this.state
        if (requestId) {
            this.getRequestDetail(requestId)
        }
    }

    componentWillReceiveProps(nextProps) {
        const { requestDetailData } = nextProps
        const { requestDetailData: thisRequestDetailData } = this.props
        if (requestDetailData !== thisRequestDetailData) {
            this.setState({
                loading: false,
                requestData: requestDetailData,
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    getRequestDetail(id) {
        this.setState({ loading: true })
        const authData = getAuth()
        const params = { memberId: authData.id, token: authData.token, id: id }
        this.props.getRequestDetail(params)
    }

    render() {
        const { loading, requestData, requestId } = this.state
        const loadingView = (requestId && requestData === null) || loading ? <Loading /> : null
        let contentView = null
        if (!loading && requestData) {
            const requestItemData = requestData.requestItem.data
            const requestItemView = requestItemData.map((element, index) => {
                const unitText = element.type === 'fabric' ? 'yard(s)' : 'piece(s)'
                return (
                    <div key={`productItem_${index}`} className="productItemBox">
                        <div className="imageBox">
                            <Image src={element.image} className="card-img" />
                        </div>
                        <div className="infoBox">
                            <div className="infoRow">
                                <div className="infoRowLabel">{Lang.REQUEST_COLUMN_PRODUCT}</div>
                                <div className="infoRowTxt">{element.fabricNo}</div>
                            </div>
                            <div className="infoRow">
                                <div className="infoRowLabel">{Lang.REQUEST_COLUMN_TYPE}</div>
                                <div className="infoRowTxt">{element.type}</div>
                            </div>
                            <div className="infoRow">
                                <div className="infoRowLabel">{Lang.REQUEST_COLUMN_QTY}</div>
                                <div className="infoRowTxt">{element.quantity} {unitText}</div>
                            </div>
                            <div className="infoRow">
                                <div className="infoRowLabel">{Lang.REQUEST_COLUMN_UNITPRICE}</div>
                                <div className="infoRowTxt">{element.unitPrice}</div>
                            </div>
                        </div>
                    </div>
                )
            })
            contentView = (
                <div id="requestDetailContainer">
                    <div className="titleBlock">{Lang.REQUEST_TITLE}</div>
                    <div className="requestContentContainer">
                        <div className="layoutBlock col-12 col-md-4">
                            <ContentBlock title={Lang.REQUEST_INFORMATION}>
                                <div className="infoBox">
                                    <div className="infoRow">
                                        <div className="infoRowLabel">{Lang.REQUEST_COLUMN_REQUESTNO}</div>
                                        <div className="infoRowTxt">{requestData.requestNo}</div>
                                    </div>
                                    <div className="infoRow">
                                        <div className="infoRowLabel">{Lang.REQUEST_COLUMN_ADDRESS}</div>
                                        <div className="infoRowTxt">{requestData.address}</div>
                                    </div>
                                    <div className="infoRow">
                                        <div className="infoRowLabel">{Lang.REQUEST_COLUMN_NOTE}</div>
                                        <div className="infoRowTxt">{requestData.note}</div>
                                    </div>
                                    <div className="infoRow">
                                        <div className="infoRowLabel">{Lang.REQUEST_COLUMN_CREATED_AT}</div>
                                        <div className="infoRowTxt">{requestData.created_at}</div>
                                    </div>
                                </div>
                            </ContentBlock>
                        </div>
                        <div className="layoutBlock col-12 col-md-8 requestColumnBlock">
                            <ContentBlock title={Lang.REQUEST_PRODUCT_ITEM}>
                                {requestItemView}
                            </ContentBlock>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <MemberBase validUseLevel={2}>
                {loadingView}
                {contentView}
            </MemberBase>
        )
    }
}

RequestEdit.propTypes = {
    getRequestDetail: PropTypes.func.isRequired,
    requestDetailData: PropTypes.object,
}

const mapStateToProps = () => (state) => {
    return {
        requestDetailData: state.request.requestDetailData,
    }
}

const mapDispatchToProps = {
    getRequestDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestEdit)
