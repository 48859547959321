/*
output: 
    {
        status: true,
        errorMessage: ''
    }
*/
export default class Validation {
    required(value) {
        return {
            status: value.toString().trim().length > 0,
            errorMessage: 'required'
        }
    }
}
