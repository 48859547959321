import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../components/Input';
import Select from '../components/Select';
import Button from '../components/Button';
import Textarea from '../components/Textarea';
import TextareaHashtag from '../components/TextareaHashtag';
import Validation from '../Libs/Validation';
import AutoComplete from './AutoComplete';
import AutoCompleteCustom from './AutoCompleteCustom';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = this.initState(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  initState(props) {
    const { formConfig } = props;
    const { data } = formConfig;
    const state = {
      error: false,
    };
    let i;
    for (i = 0; i < data.length; i++) {
      const item = data[i];
      state[item.name] = item.value || '';
    }
    return state;
  }

  validateField(role, value) {
    let status = true;
    let errorMessage = '';
    if (role && role.length > 0) {
      const validate = new Validation();
      let i;
      for (i = 0; i < role.length; i++) {
        const item = role[i];
        if (validate[item.name]) {
          const validateInfo = validate[item.name](value);
          if (!validateInfo.status) {
            status = false;
            errorMessage = validateInfo.errorMessage;
            break;
          }
        }
      }
    }
    return {
      status: status,
      errorMessage: errorMessage,
    };
  }

  handleChange(e) {
    const { target } = e;
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  }

  handleValidation() {
    const validate = new Validation();
    const { formConfig } = this.props;
    const { data } = formConfig;
    const error = {};
    let status = true;
    let i;
    for (i = 0; i < data.length; i++) {
      const item = data[i];
      const { name, role } = item;
      const { status: checkStatus, errorMessage } = this.validateField(
        role,
        this.state[name]
      );
      if (!checkStatus) {
        status = false;
        error[name] = { errorMessage: errorMessage };
      }
    }
    return {
      status: status,
      error: error,
    };
  }

  handleSubmit(e) {
    // e.preventDefault()
    const { onSubmit } = this.props;
    const validation = this.handleValidation();
    if (validation.status) {
      this.state.error = false;
      this.setState({
        error: false,
      });
      if (onSubmit) {
        onSubmit(e, this.state);
      }
    } else {
      this.setState({
        error: true,
      });
    }
  }

  formInput(item, triggerValidate = false) {
    const { type, name, placeholder, className, role, value, format } = item;
    const inputClassName = className || 'inputStyle';
    return (
      <div key={`field_${name}`} className="form-group">
        <label htmlFor={`input_${name}`}>{placeholder}</label>
        <Input
          type={type}
          name={name}
          ref={name}
          placeholder={placeholder}
          className={inputClassName}
          role={role}
          onChange={this.handleChange}
          triggerValidate={triggerValidate}
          idName={`input_${name}`}
          value={value}
          format={format}
        />
      </div>
    );
  }

  formSelect(item, triggerValidate = false) {
    const { name, placeholder, options, className, role, value } = item;
    const selectClassName = className || 'inputStyle';
    return (
      <div key={`field_${name}`} className="form-group">
        <label htmlFor={`input_${name}`}>{placeholder}</label>
        <Select
          className={selectClassName}
          id={`input_${name}`}
          name={name}
          ref={name}
          role={role}
          options={options}
          onChange={this.handleChange}
          triggerValidate={triggerValidate}
          idName={`input_${name}`}
          value={value}
        />
      </div>
    );
  }

  formTextarea(item, triggerValidate = false) {
    const { name, placeholder, className, role, value } = item;
    const inputClassName = className || 'inputStyle';
    return (
      <div key={`field_${name}`} className="form-group">
        <label htmlFor={`input_${name}`}>{placeholder}</label>
        <Textarea
          name={name}
          ref={name}
          placeholder={placeholder}
          className={inputClassName}
          role={role}
          onChange={this.handleChange}
          triggerValidate={triggerValidate}
          idName={`input_${name}`}
          value={value}
        />
      </div>
    );
  }

  formTextareaHashtag(item, triggerValidate = false) {
    const { name, placeholder, className, role, value } = item;
    // const inputClassName = className || 'inputStyle'
    return (
      <div key={`field_${name}`} className="form-group">
        <label htmlFor={`input_${name}`}>{placeholder}</label>
        <TextareaHashtag
          name={name}
          ref={name}
          placeholder={placeholder}
          // className={inputClassName}
          role={role}
          // onChange={this.handleChange}
          triggerValidate={triggerValidate}
          idName={`input_${name}`}
          // value={'jhjhj,dddddd,qqqq,ttt,iiiii'}
          value={value}
          onTagSizeChange={(s) => {
            this.setState({
              [name]: s,
            });
          }}
        />
      </div>
    );
  }

  formAutoComplete(item, triggerValidate = false) {
    const { name, placeholder, className, role, value, completeData } = item;
    // const inputClassName = className || 'inputStyle'
    if (completeData && completeData != [] && completeData.length > 0) {
      return (
        <div key={`field_${name}`} className="form-group">
          <label htmlFor={`input_${name}`}>{placeholder}</label>
          <AutoComplete
            name={name}
            ref={name}
            placeholder={placeholder}
            // className={inputClassName}
            role={role}
            // onChange={this.handleChange}
            // triggerValidate={triggerValidate}
            idName={`input_${name}`}
            value={value}
            completeData={completeData}
            onTagSizeChange={(s) => {
              this.setState({
                [name]: s,
              });
            }}
          />
        </div>
      );
    } else {
      return <div key="nothing" />;
    }
  }

  formAutoCompleteCustom(item, triggerValidate = false) {
    const {
      name,
      placeholder,
      className,
      role,
      value,
      completeData,
      showValueKey,
    } = item;
    // const inputClassName = className || 'inputStyle'
    if (completeData && completeData != [] && completeData.length > 0) {
      return (
        <div key={`field_${name}`} className="form-group">
          <label htmlFor={`input_${name}`}>{placeholder}</label>
          <AutoCompleteCustom
            name={name}
            ref={name}
            placeholder={placeholder}
            // className={inputClassName}
            role={role}
            // onChange={this.handleChange}
            // triggerValidate={triggerValidate}
            idName={`input_${name}`}
            value={value}
            showValueKey={showValueKey}
            completeData={completeData}
            onTagSizeChange={(s) => {
              this.setState({
                [name]: s,
              });
            }}
          />
        </div>
      );
    } else {
      return <div key="nothing" />;
    }
  }

  render() {
    const { formConfig, className, btnText, cancelText, cancelLink, btnArray } =
      this.props;
    const { error } = this.state;
    const { data } = formConfig;
    const formClassName = className || '';
    const formBtnText = btnText || 'Submit';
    const formCancelText = cancelText || 'Cancel';
    const fieldsView = data.map((item) => {
      item.value = this.state[item.name];
      const { component } = item;
      return this[`form${component}`](item, error);
    });
    let btnView = null;
    if (btnArray) {
      btnView = btnArray.map((item, index) => {
        return (
          <Button
            key={`btn_${index}`}
            type="button"
            className={`btn ${item.className || ''}`}
            onClick={() => {
              if (item.clickLink) {
                item.clickLink();
              }
            }}
            text={item.text}
          />
        );
      });
    }

    return (
      <form
        className={formClassName}

        //onSubmit={this.handleSubmit}
      >
        <div className="inputBox">{fieldsView}</div>
        <div className="btnBox">
          {btnView}
          <Button
            type="button"
            className="btn"
            onClick={() => {
              if (cancelLink) {
                location.href = cancelLink;
              }
            }}
            text={formCancelText}
          />
          <Button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              this.handleSubmit(e);
            }}
            text={formBtnText}
          />
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  formConfig: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  btnText: PropTypes.string,
  cancelText: PropTypes.string,
  cancelLink: PropTypes.string,
};
