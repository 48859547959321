import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

// delete video
function fetchDeletePresentationVideo(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.PRESENTATIONVIDEO_DELETE_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATIONVIDEO_DELETE_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATIONVIDEO_DELETE_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'DELETE',
            endpoint: `/presentation/${params.presentationNo}/video/${params.presentationVideoId}`,
            body: JSON.stringify(params),
        },
    }
}

export function deletePresentationVideo(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchDeletePresentationVideo(params)).then((res) => {
            callback(res)
        })
    }
}
