import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Validation from '../Libs/Validation'

export default class Textarea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value || '',
            errorMessage: '',
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { triggerValidate: thisTriggerValidate } = this.props
        const { triggerValidate } = nextProps
        const { value } = this.state
        if (thisTriggerValidate !== triggerValidate) {
            this.handleValidation(value)
        }
    }

    handleValidation(value) {
        const { role } = this.props
        let status = true
        if (role && role.length > 0) {
            let i
            for (i = 0; i < role.length; i++) {
                const item = role[i]
                const validate = new Validation()
                if (validate[item.name]) {
                    const validateInfo = validate[item.name](value)
                    if (!validateInfo.status) {
                        status = false
                        this.setState({
                            errorMessage: validateInfo.errorMessage,
                        })
                        break
                    }
                    this.setState({
                        errorMessage: '',
                    })
                }
            }
        }
        return status
    }

    handleChange(e) {
        const { onChange } = this.props
        const { value } = e.target
        this.setState({
            value: value,
        })
        this.handleValidation(value)
        if (onChange) {
            onChange(e)
        }
    }

    render() {
        const { name, className, idName, placeholder } = this.props
        const { errorMessage, value } = this.state
        const inputClassName = className || ''
        const inputIdName = idName || ''
        const inputPlaceholder = placeholder || ''
        return (
            <div className={inputClassName}>
                <textarea
                    id={inputIdName}
                    className="form-control"
                    name={name}
                    ref={name}
                    placeholder={inputPlaceholder}
                    onChange={this.handleChange}
                    value={value}
                ></textarea>
                <div className="errorMessage">{errorMessage}</div>
            </div>
        )
    }
}

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    role: PropTypes.array,
    triggerValidate: PropTypes.bool,
}
