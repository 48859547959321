import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

function fetchGetPresentationList(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.PRESENTATION_LIST_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_LIST_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_LIST_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/presentation',
            query: params,
        },
    }
}

export function getPresentationList(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetPresentationList(params)).then((res) => {
            callback(res)
        })
    }
}

function fetchGetMyPresentationList(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.PRESENTATION_MYLIST_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_MYLIST_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_MYLIST_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/presentation/my',
            query: params,
        },
    }
}

export function getMyPresentationList(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetMyPresentationList(params)).then((res) => {
            callback(res)
        })
    }
}

function fetchGetPresentationData(presentationNo, params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.PRESENTATION_DETAIL_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_DETAIL_SUCCESS,
                    meta: { presentationNo: presentationNo, params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_DETAIL_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'GET',
            endpoint: '/presentation/' + presentationNo,
            query: params,
        },
    }
}

export function getPresentationData(presentationNo, params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetPresentationData(presentationNo, params)).then((res) => {
            callback(res)
        })
    }
}

function fetchAddPresentation(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.PRESENTATION_ADD_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_ADD_SUCCESS,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_ADD_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'POST',
            endpoint: '/presentation',
            query: params,
        },
    }
}

export function addPresentation(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchAddPresentation(params)).then((res) => {
            callback(res)
        })
    }
}

function fetchDeletePresentation(presentationNo, params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.PRESENTATION_DELETE_REQUEST,
                    meta: { params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_DELETE_SUCCESS,
                    meta: { presentationNo: presentationNo, params: Object.assign({}, params) },
                },
                {
                    type: ActionTypes.PRESENTATION_DELETE_FAILURE,
                    meta: { params: Object.assign({}, params) },
                },
            ],
            method: 'DELETE',
            endpoint: '/presentation/' + presentationNo,
            query: params,
        },
    }
}

export function deletePresentation(presentationNo, params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchDeletePresentation(presentationNo, params)).then((res) => {
            callback(res)
        })
    }
}

function fetchGetPresentationEmail(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.PRESENTATION_GET_EMAIL_REQUEST,
                },
                {
                    type: ActionTypes.PRESENTATION_GET_EMAIL_SUCCESS,
                },
                {
                    type: ActionTypes.PRESENTATION_GET_EMAIL_FAILURE,
                },
            ],
            method: 'GET',
            endpoint: '/presentation/members',
            query: params,
        },
    }
}

export function getPresentationEmail(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchGetPresentationEmail(params)).then((res) => {
            callback(res)
        })
    }
}



function fetchClearChatMessage(params) {
    return {
        [CALL_API]: {
            types: [
                {
                    type: ActionTypes.PRESENTATION_CLEAR_CHAT_MESSAGE_REQUEST,
                },
                {
                    type: ActionTypes.PRESENTATION_CLEAR_CHAT_MESSAGE_SUCCESS,
                },
                {
                    type: ActionTypes.PRESENTATION_CLEAR_CHAT_MESSAGE_FAILURE,
                },
            ],
            method: 'DELETE',
            endpoint: '/chat/'+params.lastestChatId,
            query: params,
        },
    }
}

export function clearChatMessage(params, callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchClearChatMessage(params)).then((res) => {
            callback(res)
        })
    }
}
