import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

//獲取目錄列表
function fetchGetCatalogList(params) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.CATALOG_GET_CATALOG_LIST_REQUEST,
                ActionTypes.CATALOG_GET_CATALOG_LIST_SUCCESS,
                ActionTypes.CATALOG_GET_CATALOG_LIST_FAILURE
            ],
            method: 'GET',
            endpoint: '/fabricCatalogues',
            query: params
        }
    }
}

export function getCatalogList(data) {
    return dispatch => {
        dispatch(fetchGetCatalogList(data))
    }
}

//獲取目錄內容
function fetchGetCatalogDetail(params ,id) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.CATALOG_GET_CATALOG_DETAIL_REQUEST,
                ActionTypes.CATALOG_GET_CATALOG_DETAIL_SUCCESS,
                ActionTypes.CATALOG_GET_CATALOG_DETAIL_FAILURE
            ],
            method: 'GET',
            endpoint: '/fabricCatalogue/'+id,
            query: params
        }
    }
}

export function getCatalogDetail(data,id) {
    return dispatch => {
        dispatch(fetchGetCatalogDetail(data ,id))
    }
}

//新增一本目錄
function fetchCreateCatalog(params) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.CATALOG_CREATE_CATALOG_REQUEST,
                ActionTypes.CATALOG_CREATE_CATALOG_SUCCESS,
                ActionTypes.CATALOG_CREATE_CATALOG_FAILURE
            ],
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            endpoint: '/fabricCatalogue',
            body: params
        }
    }
}

export function createCatalog(data) {
    return dispatch => {
        dispatch(fetchCreateCatalog(data))
    }
}

//修改某一本目錄資料
function fetchUpdateCatalog(params ,id) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.CATALOG_UPDATE_CATALOG_REQUEST,
                ActionTypes.CATALOG_UPDATE_CATALOG_SUCCESS,
                ActionTypes.CATALOG_UPDATE_CATALOG_FAILURE
            ],
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            endpoint: '/fabricCatalogue/' + id,
            body: params
        }
    }
}

export function updateCatalog(data ,id) {
    return dispatch => {
        dispatch(fetchUpdateCatalog(data , id))
    }
}


//刪除某一本目錄資料
function fetchDeletecatalogItem(params ,id) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.CATALOG_DELETE_CATALOG_REQUEST,
                ActionTypes.CATALOG_DELETE_CATALOG_SUCCESS,
                ActionTypes.CATALOG_DELETE_CATALOG_FAILURE
            ],
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            endpoint: '/fabricCatalogue/' + id,
            body: params
        }
    }
}

export function deleteCatalogItem(param ,id) {
    return dispatch => {
        dispatch(fetchDeletecatalogItem(param , id))
    }
}
