import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Lang from '../Lang/default'
import Empty from '../components/Empty'
import MemberBase from '../containers/MemberBase'
import { getAuth, isEmpty } from '../Libs/Lib'
import { getRecords } from '../actions/records'
import Loading from '../components/Loading'
import Image from '../components/Image'
import FabricDetail from '../components/FabricDetail'
import '../style/records.sass'

class Records extends Component {
    constructor(props) {
        super(props)
        this.getRecords = this.getRecords.bind(this)
        this.apiParams = this.apiParams.bind(this)
        this.openFabricDetail = this.openFabricDetail.bind(this)
        this.closeFabricDetail = this.closeFabricDetail.bind(this)
        this.state = {
            loading: false,
            recordsData: null,
            showFabricId: null,
            recordsType: 'viewRecords'
        }
    }

    componentDidMount() {
        this.getRecords()
    }

    componentWillReceiveProps(nextProps) {
        const {
            member: thisMember,
            recordsData: thisRecordsData
        } = this.props
        const { member, recordsData } = nextProps

        if (recordsData !== thisRecordsData) {
            this.setState({ recordsData: recordsData, loading: false })
        }

        if (thisMember !== member && thisMember && member && thisMember.id !== member.id) {
            this.getRecords()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        }
        return false
    }

    apiParams() {
        const authData = getAuth()
        let params = {
            memberId: authData.id,
            token: authData.token,
        }
        return params
    }

    getRecords() {
        this.setState({ loading: true })
        const authData = getAuth()
        const { getRecords } = this.props
        if (!isEmpty(authData)) {
            const params = this.apiParams()
            getRecords(params)
        } else {
            this.setState({ loading: false })
        }
    }

    openFabricDetail(fabricId) {
        this.setState({
            showFabricId: fabricId,
        })
    }

    closeFabricDetail() {
        this.setState({
            showFabricId: null,
        })
    }

    fabricDetailView() {
        const { showFabricId } = this.state
        const displayModal = showFabricId ? true : false
        let fabricDetailView = (
            <FabricDetail
                fabricId={showFabricId}
                displayModal={displayModal}
                closed={() => {
                    this.closeFabricDetail()
                }}
            />
        )
        return fabricDetailView
    }

    render() {
        const {
            loading,
            recordsData,
            recordsType,
        } = this.state
        const emptyView = (
            <div className="emptyBlock">
                <Empty title={Lang.RECORDS_EMPTY_TITLE} />
            </div>
        )
        const loadingView = recordsData === null || loading ? <Loading /> : null
        let contentView = null
        if (recordsData) {
            let fabricRecordsView = null
            if (recordsData.status && recordsData.status === 'fail') {
                fabricRecordsView = (
                    <div className="fabricListView row">
                        <div className="emptyBlock">
                            <Empty title={recordsData.errorMessage} />
                        </div>
                    </div>
                )
            } else if (recordsData[recordsType].length > 0) {
                const fabricDetailView = this.fabricDetailView()
                const countLabelText = recordsType === 'viewRecords' ? Lang.RECORDS_TYPE_VIEWS : Lang.RECORDS_TYPE_REQUEST
                const fabricListView = recordsData[recordsType].map((item, index) => {
                    return (
                        <div
                            key={`fabricItem_${index}`}
                            className="fabricItem col-6 col-md-2 layoutBlock"
                            onClick={() => {
                                this.openFabricDetail(item.fabricId)
                            }}
                        >
                            <div className="card">
                                <div className="fabricImage">
                                    <Image src={item.image} className="card-img-top" />
                                </div>
                                <div className="fabricInfoBox">
                                    <div className="fabricInfoItem">{item.fabricNo}</div>
                                    <div className="fabricInfoItem">{countLabelText}：{item.totalCount}</div>
                                </div>
                            </div>
                        </div>
                    )
                })
                fabricRecordsView = (
                    <div className="">
                        <div className="fabricListView row">{fabricListView}</div>
                        {fabricDetailView}
                    </div>
                )
            } else {
                fabricRecordsView = (
                    <div className="fabricListView row">
                        {emptyView}
                    </div>
                )
            }
            contentView = (
                <div className="fabricListBlock">
                    <div className="recordsDataTitleBlock">
                        <div className="fabricTypeSelectBlock">
                            <div
                                className={`tabItem ${recordsType === 'viewRecords' ? 'active' : ''}`}
                                onClick={() => {
                                    this.setState({
                                        recordsType: 'viewRecords'
                                    })
                                }}
                            >
                                {Lang.RECORDS_VIEWS}
                            </div>
                            <div
                                className={`tabItem ${recordsType === 'requestRecords' ? 'active' : ''}`}
                                onClick={() => {
                                    this.setState({
                                        recordsType: 'requestRecords'
                                    })
                                }}
                            >
                                {Lang.RECORDS_REQUEST}
                            </div>
                        </div>
                    </div>
                    {fabricRecordsView}
                </div>
            )
        }
        return (
            <MemberBase>
                {loadingView}
                <div className="fabricListContainerBase recordsContainer">
                    {contentView}
                </div>
            </MemberBase>
        )
    }
}

Records.propTypes = {
    recordsData: PropTypes.object,
    getRecords: PropTypes.func.isRequired
}

const mapStateToProps = () => (state) => {
    return {
        member: state.member.member,
        recordsData: state.records.recordsData,
    }
}

const mapDispatchToProps = {
    getRecords
}

export default connect(mapStateToProps, mapDispatchToProps)(Records)
