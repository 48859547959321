import { CALL_API } from 'redux-api-middleware'
import * as ActionTypes from '../constants/ActionTypes'

//獲取trendboard列表
function fetchGetTrendBoardList(params) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.TREND_GET_TRENDBOARD_LIST_REQUEST,
                ActionTypes.TREND_GET_TRENDBOARD_LIST_SUCCESS,
                ActionTypes.TREND_GET_TRENDBOARD_LIST_FAILURE
            ],
            method: 'GET',
            endpoint: '/trends',
            query: params
        }
    }
}

export function getTrendBoardList(data) {
    return dispatch => {
        dispatch(fetchGetTrendBoardList(data))
    }
}

//獲取trendboard內容
function fetchGetTrendBoardDetail(params ,id) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.TREND_GET_TRENDBOARD_DETAIL_REQUEST,
                ActionTypes.TREND_GET_TRENDBOARD_DETAIL_SUCCESS,
                ActionTypes.TREND_GET_TRENDBOARD_DETAIL_FAILURE
            ],
            method: 'GET',
            endpoint: '/trend/'+id,
            query: params
        }
    }
}

export function getTrendBoardDetail(data,id) {
    return dispatch => {
        dispatch(fetchGetTrendBoardDetail(data ,id))
    }
}

//新增一本目錄
function fetchCreateTrendBoard(params) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.TREND_CREATE_TRENDBOARD_REQUEST,
                ActionTypes.TREND_CREATE_TRENDBOARD_SUCCESS,
                ActionTypes.TREND_CREATE_TRENDBOARD_FAILURE
            ],
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            endpoint: '/trend',
            body: params
        }
    }
}

export function createTrendBoard(data) {
    return dispatch => {
        dispatch(fetchCreateTrendBoard(data))
    }
}

//修改某一本目錄資料
function fetchUpdateTrendBoard(params ,id) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.TREND_UPDATE_TRENDBOARD_REQUEST,
                ActionTypes.TREND_UPDATE_TRENDBOARD_SUCCESS,
                ActionTypes.TREND_UPDATE_TRENDBOARD_FAILURE
            ],
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            endpoint: '/trend/' + id,
            body: params
        }
    }
}

export function updateTrendBoard(data ,id ,callback = () => {}) {
    return (dispatch) => {
        dispatch(fetchUpdateTrendBoard(data , id)).then((res) => {
            callback(res)
        })
    } 
}


//刪除某一本目錄資料
function fetchDeleteTrendBoardItem(params ,id) {
    return {
        [CALL_API]: {
            types: [
                ActionTypes.TREND_DELETE_TRENDBOARD_REQUEST,
                ActionTypes.TREND_DELETE_TRENDBOARD_SUCCESS,
                ActionTypes.TREND_DELETE_TRENDBOARD_FAILURE
            ],
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            endpoint: '/trend/' + id,
            body: params
        }
    }
}

export function deleteTrendBoardItem(param ,id) {
    return dispatch => {
        dispatch(fetchDeleteTrendBoardItem(param , id))
    }
}
