import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import createLogger from 'redux-logger'
import rootReducer from '../reducers';
import api from '../middleware/api';
import { apiMiddleware } from 'redux-api-middleware';

export default function configureStore() {
  // const logger = createLogger()
  const store = createStore(
    rootReducer,
    applyMiddleware(thunk, api, apiMiddleware)
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
