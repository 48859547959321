import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    createCatalogData:{},
    updateCatalogData:{},
    deleteCatalogData:{},
    catalogListData:{},  //目錄列表物件
    catalogDetailData:{}, //目錄明細資料
    
}

export default function (state = defaultState, action) {
    switch (action.type) {
        //獲取目錄列表
        case ActionTypes.CATALOG_GET_CATALOG_LIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.CATALOG_GET_CATALOG_LIST_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.CATALOG_GET_CATALOG_LIST_SUCCESS:
            let catalogListData = action.payload
            
            return Object.assign({} , state,{
                isDataFetching: false,
                catalogListData: catalogListData
            })

        //獲取目錄明細資料
        case ActionTypes.CATALOG_GET_CATALOG_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.CATALOG_GET_CATALOG_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
                catalogDetailData: action.payload.response  //錯誤訊息
            })
        case ActionTypes.CATALOG_GET_CATALOG_DETAIL_SUCCESS:
            let catalogDetailData = action.payload
            return Object.assign({} , state,{
                isDataFetching: false,
                catalogDetailData: catalogDetailData
            })
        //創建目錄
        case ActionTypes.CATALOG_CREATE_CATALOG_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.CATALOG_CREATE_CATALOG_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.CATALOG_CREATE_CATALOG_SUCCESS:
            let createData = action.payload.data
            
            return Object.assign({} , state,{
                isDataFetching: false,
                createCatalogData: createData
            })

        //修改目錄
        case ActionTypes.CATALOG_UPDATE_CATALOG_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.CATALOG_UPDATE_CATALOG_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.CATALOG_UPDATE_CATALOG_SUCCESS:
            let updateData = action.payload.data

            return Object.assign({} , state,{
                isDataFetching: false,
                updateCatalogData: updateData
            })
        
        //刪除目錄
        case ActionTypes.CATALOG_DELETE_CATALOG_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true
            })
        case ActionTypes.CATALOG_DELETE_CATALOG_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false
            })
        case ActionTypes.CATALOG_DELETE_CATALOG_SUCCESS:
            let deleteData = action.payload

            return Object.assign({} , state,{
                isDataFetching: false,
                deleteCatalogData: deleteData
            })

            

        default:
            return state
    }
}
