import * as ActionTypes from '../constants/ActionTypes'

const defaultState = {
    isDataFetching: false, //是否獲取資料成功
    errorMessage: '',
    presentation: {},
    listPresentation: {
        info: {},
        data: [],
    },
    listMyPresentation: {
        info: {},
        data: [],
    },
    presentationData: {},
}

export default function (state = defaultState, action) {
    switch (action.type) {
        // 新增Presentation
        case ActionTypes.PRESENTATION_ADD_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.PRESENTATION_ADD_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.PRESENTATION_ADD_SUCCESS:
            const addPresentationRes = action.payload.response
            return Object.assign({}, state, {
                isDataFetching: false,
                member: addPresentationRes,
            })
        // Presentation列表
        case ActionTypes.PRESENTATION_LIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.PRESENTATION_LIST_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.PRESENTATION_LIST_SUCCESS:
            const listPresentationRes = action.payload.response
            let listPresentation = Object.assign({}, state.listPresentation)
            // if (parseInt(listPresentationRes.page) > 1) {
            //     listPresentation.data = listPresentation.data || []
            //     listPresentation.data = listPresentation.data.concat(listPresentationRes.data)
            // } else if (parseInt(listPresentationRes.page) <= 1) {
                listPresentation.data = listPresentationRes.data
            // }
            listPresentation.info = {
                pageSize: listPresentationRes.pageSize,
                totalPage: listPresentationRes.totalPage,
                totalRow: listPresentationRes.count,
            }
            return Object.assign({}, state, {
                isDataFetching: false,
                listPresentation,
            })
        // My Presentation列表
        case ActionTypes.PRESENTATION_MYLIST_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.PRESENTATION_MYLIST_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.PRESENTATION_MYLIST_SUCCESS:
            const listMyPresentationRes = action.payload.response
            let listMyPresentation = Object.assign({}, state.listMyPresentation)
            // if (parseInt(listMyPresentationRes.page) > 1) {
            //     listMyPresentation.data = listMyPresentation.data || []
            //     listMyPresentation.data = listMyPresentation.data.concat(listMyPresentationRes.data)
            // } else if (parseInt(listMyPresentationRes.page) <= 1) {
                listMyPresentation.data = listMyPresentationRes.data
            // }
            listMyPresentation.info = {
                pageSize: listMyPresentationRes.pageSize,
                totalPage: listMyPresentationRes.totalPage,
                totalRow: listMyPresentationRes.count,
            }
            return Object.assign({}, state, {
                isDataFetching: false,
                listMyPresentation,
            })
        // Presentation 內頁
        case ActionTypes.PRESENTATION_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.PRESENTATION_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.PRESENTATION_DETAIL_SUCCESS:
            const getPresentationRes = action.payload.response
            let presentationData = Object.assign({}, state.presentationData)
            if (getPresentationRes.id) {
                presentationData[getPresentationRes.presentationNo] = getPresentationRes
            }
            return Object.assign({}, state, {
                isDataFetching: false,
                presentationData,
            })
        // 刪除 Presentation
        case ActionTypes.PRESENTATION_DELETE_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.PRESENTATION_DELETE_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.PRESENTATION_DELETE_SUCCESS:
            return Object.assign({}, state, {
                isDataFetching: false,
            })

        // 獲取email
        case ActionTypes.PRESENTATION_GET_EMAIL_REQUEST:
            return Object.assign({}, state, {
                isDataFetching: true,
            })
        case ActionTypes.PRESENTATION_GET_EMAIL_FAILURE:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        case ActionTypes.PRESENTATION_GET_EMAIL_SUCCESS:
            return Object.assign({}, state, {
                isDataFetching: false,
            })
        default:
            return state
    }
}
